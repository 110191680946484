import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'tabler-react';
import { createApiClient } from '../../../../services/api-client';
import DocumentRequestReasonModal from './DocumentRequestReasonModal';

const DocumentRequestButton = ({ transactionId, isParentLoading }) => {
    const [isLoading, setLoading] = useState(true);
    const [currentTransactionDocumentRequest, setCurrentTransactionDocumentRequests] = useState({
        status: '',
        transaction_id: '',
        id: '',
    });
    const [hasCurrentRequestedDocuments, setCurrentRequestedDocuments] = useState(false);
    const [isDocumentRequestReasonModalOpen, setDocumentRequestModalOpen] = useState(false);

    const api = useMemo(() => createApiClient(), []);

    const fetchCurrentTransactionDocumentRequest = useCallback(async () => {
        if (transactionId === null) {
            return;
        }

        setLoading(true);

        try {
            const { data } = await api.get(`admin-transaction-document/transactions/${transactionId}/document-requests/current`);

            setCurrentTransactionDocumentRequests(data);
        } catch (error) {
            if (error.data.code === 'transaction_document_request_not_found') {
                setCurrentRequestedDocuments(false);

                return;
            }

            setCurrentRequestedDocuments(true);
        } finally {
            setLoading(false);
        }
    }, [api, transactionId]);

    const updateCurrentTransactionDocumentRequest = (data) =>
        setCurrentTransactionDocumentRequests((prevRequests) => ({ ...prevRequests, ...data }));

    const isRequestedDocuments = currentTransactionDocumentRequest.status === 'created' || hasCurrentRequestedDocuments;

    const setDocumentRequestModalVisible = useCallback((value) => () => setDocumentRequestModalOpen(value), []);

    useEffect(() => {
        fetchCurrentTransactionDocumentRequest();
    }, [fetchCurrentTransactionDocumentRequest]);

    return (
        <>
            <Button
                size='sm'
                icon='plus'
                color='info'
                onClick={setDocumentRequestModalVisible(true)}
                loading={isLoading || isParentLoading}
                disabled={isRequestedDocuments || isParentLoading}
            >
                {isRequestedDocuments ? 'Requested' : 'Request'}
            </Button>
            <DocumentRequestReasonModal
                modalIsOpen={isDocumentRequestReasonModalOpen}
                onModalClose={setDocumentRequestModalVisible(false)}
                updateCurrentTransactionDocumentRequest={updateCurrentTransactionDocumentRequest}
                transactionId={transactionId}
            />
        </>
    );
};

DocumentRequestButton.propTypes = {
    transactionId: PropTypes.number,
    isParentLoading: PropTypes.bool.isRequired,
};

export default DocumentRequestButton;
