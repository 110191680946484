import React, { Component } from 'react';
import { Form } from 'tabler-react';
import PropTypes from 'prop-types';
import InputTextField from '../../../../Common/FilterFormFields/InputTextField';

class FormFields extends Component {
    renderNameInput () {
        const { documentType, onChange } = this.props;

        return <InputTextField name='name' title='Name' value={documentType.name} handleChange={onChange} />;
    }

    renderDocumentTypeSelection () {
        const { documentType, onChange } = this.props;

        return (
            <Form.Group label='Document type'>
                <Form.Radio
                    checked={documentType.document_type === 'mandatory'}
                    isInline
                    label='Mandatory'
                    name='document_type'
                    value='mandatory'
                    onChange={onChange}
                />
                <Form.Radio
                    isInline
                    checked={documentType.document_type === 'additional'}
                    label='Additional'
                    name='document_type'
                    value='additional'
                    onChange={onChange}
                />
            </Form.Group>
        );
    }

    renderUserTypeSelection () {
        const { documentType, onChange } = this.props;

        return (
            <Form.Group label='User type'>
                <Form.Radio
                    checked={documentType.user_type === 'private'}
                    isInline
                    label='Private'
                    name='user_type'
                    value='private'
                    onChange={onChange}
                />
                <Form.Radio
                    isInline
                    checked={documentType.user_type === 'business'}
                    label='Business'
                    name='user_type'
                    value='business'
                    onChange={onChange}
                />
            </Form.Group>
        );
    }

    render () {
        return (
            <>
                {this.renderNameInput()}
                {this.renderDocumentTypeSelection()}
                {this.renderUserTypeSelection()}
            </>
        );
    }
}

FormFields.propTypes = {
    onChange: PropTypes.func.isRequired,
    documentType: PropTypes.object.isRequired,
};

export default FormFields;
