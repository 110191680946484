import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'tabler-react';

const ExternalLink = ({ url, children, className }) => (
    <a
        href={url}
        rel='noreferrer noopener'
        target='_blank'
        className={className}
    >
        {children} <Icon name='external-link' />
    </a>
);

ExternalLink.propTypes = {
    url: PropTypes.string.isRequired,
    className: PropTypes.string,
    children: PropTypes.node.isRequired,
};

export default ExternalLink;
