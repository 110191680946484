import FileViewer from 'react-file-viewer';
import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Button, Icon } from 'tabler-react';
import Modal from 'react-modal';
import { toast } from 'react-toastify';
import { createApiClient } from '../../services/api-client';

const FilePreview = ({ downloadUrl, fileType }) => {
    const [isFilePreviewModalOpen, setFilePreviewModalOpen] = useState(false);
    const [isFileDownloading, setFileDownloading] = useState(true);
    const [file, setFile] = useState({
        filePath: '',
        fileType: '',
    });

    const api = createApiClient();

    const onFilePreview = useCallback(async () => {
        setFileDownloading(true);

        try {
            const response = await api.get(downloadUrl, {
                responseType: 'blob',
            });

            const url = URL.createObjectURL(new window.Blob([response.data]));

            setFile({ filePath: url, fileType });
        } catch (error) {
            toast.error(error.data.message);
            setFilePreviewModalOpen(false);
        } finally {
            setFileDownloading(false);
        }
    }, [api, downloadUrl, fileType]);

    const setFilePreviewModalVisible = useCallback(
        (value) => () => {
            setFilePreviewModalOpen(value);
            value && onFilePreview();
        },
        [onFilePreview],
    );

    return (
        <>
            {isFilePreviewModalOpen && (
                <Modal isOpen={isFilePreviewModalOpen} className='react-modal full-screen'>
                    <div className='react-modal__closeButton' onClick={setFilePreviewModalVisible(false)}>
                        <Icon name='x-circle' className='w-4' />
                    </div>
                    {!isFileDownloading && <FileViewer fileType={file.fileType} filePath={file.filePath} />}
                </Modal>
            )}

            <Button outline size='sm' icon='eye' color='info' onClick={setFilePreviewModalVisible(true)} />
        </>
    );
};

FilePreview.propTypes = {
    fileType: PropTypes.string.isRequired,
    downloadUrl: PropTypes.string.isRequired,
};

export default FilePreview;
