import React, { useState, useEffect, useCallback, useMemo } from 'react';
import SiteWrapper from '../../SiteWrapper';
import { Button, Card, Dimmer, Grid } from 'tabler-react';
import ApiError from '../../Common/ApiError';
import { createApiClient } from '../../../services/api-client';
import PropTypes from 'prop-types';
import { navigate } from '../../../actions/navigate';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import InputTextField from '../../Common/FilterFormFields/InputTextField';

const CompanyTypesCreateOrEdit = ({ navigate, match }) => {
    const [isLoading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [companyType, setCompanyType] = useState({
        name: '',
        code: '',
    });

    const api = useMemo(() => createApiClient(), []);

    const companyTypeId = useMemo(() => match.params.id, [match.params.id]);

    const handleChange = (event) => {
        const { target } = event;

        setCompanyType((prevCompanyType) => ({
            ...prevCompanyType,
            [target.name]: target.value.length > 0 ? target.value : '',
        }));
    };

    const handleSubmit = useCallback(
        async (event) => {
            event.preventDefault();

            setLoading(true);
            setError(null);

            try {
                if (companyTypeId === undefined) {
                    await api.post('/admin-company-type/company-types', companyType);

                    return toast.success('Company type created successfully');
                }

                await api.put(`/admin-company-type/company-types/${companyTypeId}`, companyType);
                toast.success('Company type edited successfully');
            } catch ({ data }) {
                setError(data);
                setLoading(false);
            } finally {
                navigate('/company-types');
            }
        },
        [api, companyType, companyTypeId, navigate],
    );

    const fetchCompanyType = useCallback(async () => {
        setLoading(true);

        try {
            const { data } = await api.get(`/admin-company-type/company-types/${companyTypeId}`);

            setCompanyType(data);
        } catch ({ data }) {
            setError(data);
        } finally {
            setLoading(false);
        }
    }, [api, companyTypeId]);

    useEffect(() => {
        if (companyTypeId !== undefined) {
            fetchCompanyType();
        }
    }, [companyTypeId, fetchCompanyType]);

    return (
        <SiteWrapper title={companyTypeId !== undefined ? 'Edit company type' : 'Create company type'}>
            <Grid.Row>
                <Grid.Col md={4} sm={6} xs={12}>
                    <Card>
                        <form onSubmit={handleSubmit}>
                            <Dimmer active={isLoading} loader>
                                <Card.Body>
                                    <InputTextField name='name' title='Name' value={companyType.name} handleChange={handleChange} />
                                    <InputTextField name='code' title='Code' value={companyType.code} handleChange={handleChange} />
                                    <ApiError error={error} />
                                </Card.Body>
                                <Card.Footer className='text-center'>
                                    <div className='d-flex'>
                                        <Button type='button' link onClick={() => navigate('/company-types')}>
                                            Cancel
                                        </Button>

                                        <Button type='submit' color='primary' className='ml-auto' onClick={handleSubmit}>
                                            Submit
                                        </Button>
                                    </div>
                                </Card.Footer>
                            </Dimmer>
                        </form>
                    </Card>
                </Grid.Col>
            </Grid.Row>
        </SiteWrapper>
    );
};

CompanyTypesCreateOrEdit.propTypes = {
    navigate: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            navigate,
        },
        dispatch,
    );

export default connect(null, mapDispatchToProps)(CompanyTypesCreateOrEdit);
