import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Table } from 'tabler-react';
import Radio from './AnswerTypes/Radio';
import String from './AnswerTypes/String';
import Checkboxes from './AnswerTypes/Checkboxes';
import Country from './AnswerTypes/Country';
import CountryList from './AnswerTypes/CountryList';
import Bool from './AnswerTypes/Bool';
import FinancialInstitutions from './AnswerTypes/Custom/FinancialInstitutions';
import isUndefined from 'lodash/isUndefined';
import isArray from 'lodash/isArray';
import isString from 'lodash/isString';
import AccountsInBanks from './AnswerTypes/Custom/AccountsInBanks';
import BeneficialOwners from './AnswerTypes/Custom/BeneficialOwners';
import BusinessPartners from './AnswerTypes/Custom/BusinessPartners';
import '../Questionnaire.scss';
import MainActivityType from './AnswerTypes/Custom/MainActivityType';

class Answers extends Component {
    renderAnswer (key) {
        const { answers, previousAnswers } = this.props;

        if (isUndefined(answers[key])) {
            return null;
        }

        if (this.isAnswerChanged(key)) {
            return (
                <>
                    <div className='current-answer'>{this.renderField(key, answers[key])}</div>
                    {previousAnswers[key] !== '' &&
                        <div className='previous-answer'>{this.renderField(key, previousAnswers[key])}</div>
                    }
                </>
            );
        }

        return this.renderField(key, answers[key]);
    }

    isAnswerChanged (key) {
        const { previousAnswers, answers, fields } = this.props;

        if (isUndefined(previousAnswers[key]) || isUndefined(answers[key])) {
            return null;
        }

        if (fields[key].type === 'checkboxes') {
            previousAnswers[key].sort();
            answers[key].sort();
        }

        return JSON.stringify(previousAnswers[key]) !== JSON.stringify(answers[key]);
    }

    renderField (key, answer) {
        const { fields } = this.props;

        const props = {
            answer,
            key,
            field: fields[key],
        };

        switch (fields[key].type) {
            case 'radio':
                return <Radio {...props} />;
            case 'checkboxes':
                return <Checkboxes {...props} />;
            case 'country':
                return <Country {...props} />;
            case 'country-list':
                return <CountryList {...props} />;
            case 'bool':
                return <Bool {...props} />;
            case 'financial-institutions':
                return <FinancialInstitutions {...props} />;
            case 'accounts-in-banks':
                return <AccountsInBanks {...props} />;
            case 'beneficial-owners':
                return <BeneficialOwners {...props} />;
            case 'business-partners':
                return <BusinessPartners {...props} />;
            case 'main-activity-type':
                return <MainActivityType {...props} />;
            default:
                return <String {...props} />;
        }
    }

    skipAnswerRendering (field, answers, key) {
        if (field.hideIfEmpty === true) {
            if (isUndefined(answers) || answers === '') {
                return true;
            }

            if (
                key === 'financial_institutions' &&
                answers.length === 1 &&
                answers[0].financial_institution_name === '' &&
                answers[0].financial_institution_country === ''
            ) {
                return true;
            }
        }

        return false;
    }

    renderRow (key) {
        const { fields, answers } = this.props;

        const field = fields[key];

        if (!isUndefined(field.showIf)) {
            if (isArray(answers[field.showIf.field]) && !answers[field.showIf.field].includes(field.showIf.value)) {
                return null;
            } else if (isString(answers[field.showIf.field]) && answers[field.showIf.field] !== field.showIf.value) {
                return null;
            }
        }

        if (this.skipAnswerRendering(field, answers[key], key) === true) {
            return null;
        }

        if (isUndefined(field.title)) {
            return (
                <Table.Row key={key}>
                    <Table.Col colSpan={2}>
                        {this.renderAnswer(key)}
                    </Table.Col>
                </Table.Row>
            );
        }

        return (
            <Table.Row key={key}>
                {!isUndefined(field.title) && (
                    <Table.Col className='w-50' alignContent='right'>
                        <strong>{field.title}:</strong>
                    </Table.Col>
                )}
                <Table.Col className='w-50'>{this.renderAnswer(key)}</Table.Col>
            </Table.Row>
        );
    }

    render () {
        const { fields } = this.props;

        return (
            <Table striped={true} cards={true}>
                <Table.Body>{Object.keys(fields).map((key) => this.renderRow(key))}</Table.Body>
            </Table>
        );
    }
}

Answers.propTypes = {
    fields: PropTypes.object.isRequired,
    answers: PropTypes.object.isRequired,
    previousAnswers: PropTypes.object.isRequired,
};

export default Answers;
