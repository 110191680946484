const ACCESS_TOKEN = 'access_token';
const REFRESH_TOKEN = 'refresh_token';

export const setAccessToken = (token) => window.localStorage.setItem(ACCESS_TOKEN, token);
export const setRefreshToken = (token) => window.localStorage.setItem(REFRESH_TOKEN, token);

export const getAccessToken = () => window.localStorage.getItem(ACCESS_TOKEN);
export const getRefreshToken = () => window.localStorage.getItem(REFRESH_TOKEN);

export const clear = () => {
    window.localStorage.removeItem(ACCESS_TOKEN);
    window.localStorage.removeItem(REFRESH_TOKEN);
};
