import React, { Component } from 'react';
import PropTypes from 'prop-types';
import String from '../String';
import Country from '../Country';
import { Table } from 'tabler-react';
import isUndefined from 'lodash/isUndefined';

class BeneficialOwners extends Component {
    renderOwner (owner, key) {
        return (
            <Table.Row key={key}>
                <Table.Col>
                    <String answer={isUndefined(owner.full_name) ? '' : owner.full_name} />
                </Table.Col>
                <Table.Col>
                    <String answer={isUndefined(owner.first_name) ? '' : owner.first_name} />
                </Table.Col>
                <Table.Col>
                    <String answer={isUndefined(owner.last_name) ? '' : owner.last_name} />
                </Table.Col>
                <Table.Col>
                    <String answer={owner.personal_code} />
                </Table.Col>
                <Table.Col>
                    <Country answer={owner.tax_residency} />
                </Table.Col>
                <Table.Col>
                    <Country answer={owner.citizenship} />
                </Table.Col>
                <Table.Col>
                    <String answer={owner.proportion_of_shares} />
                </Table.Col>
                <Table.Col>
                    <String answer={isUndefined(owner.public_source) ? '' : owner.public_source} />
                </Table.Col>
            </Table.Row>
        );
    }

    render () {
        const { answer } = this.props;

        return (
            <Table>
                <Table.Header>
                    <Table.Row>
                        <Table.ColHeader>Full name</Table.ColHeader>
                        <Table.ColHeader>First name</Table.ColHeader>
                        <Table.ColHeader>Last name</Table.ColHeader>
                        <Table.ColHeader>Personal code</Table.ColHeader>
                        <Table.ColHeader>Tax residency</Table.ColHeader>
                        <Table.ColHeader>Citizenship</Table.ColHeader>
                        <Table.ColHeader>Proportion of shares, %</Table.ColHeader>
                        <Table.ColHeader>Public sources</Table.ColHeader>
                    </Table.Row>
                </Table.Header>
                <Table.Body>{answer.map((owner, key) => this.renderOwner(owner, key))}</Table.Body>
            </Table>
        );
    }
}

BeneficialOwners.propTypes = {
    answer: PropTypes.array,
    field: PropTypes.object.isRequired,
};

export default BeneficialOwners;
