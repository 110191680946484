import React from 'react';
import { Form } from 'tabler-react';
import PropTypes from 'prop-types';
import { OUTGOING_TRANSACTION, INCOMING_TRANSACTION } from '../../../../../constants';

const Direction = ({ value, onChange }) => (
    <Form.Group label='Direction'>
        <Form.Radio
            checked={value === OUTGOING_TRANSACTION}
            isInline
            label='Outgoing'
            name='direction'
            value={OUTGOING_TRANSACTION}
            onChange={onChange}
        />
        <Form.Radio
            isInline
            checked={value === INCOMING_TRANSACTION}
            label='Incoming'
            name='direction'
            value={INCOMING_TRANSACTION}
            onChange={onChange}
        />
    </Form.Group>
);

Direction.propTypes = {
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string,
};

export default Direction;
