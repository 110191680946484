import React, { useState } from 'react';
import { Button, Dimmer, Grid, Icon } from 'tabler-react';
import ApiError from '../../../Common/ApiError';
import Modal from 'react-modal';
import InputTextField from '../../../Common/FilterFormFields/InputTextField';
import { createApiClient as api } from '../../../../services/api-client';
import { toast } from 'react-toastify';

import './CreateSatchelPayMasterAccountModal.scss';

const modalStyle = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: 'auto',
        minWidth: '400px',
        height: 'auto',
    },
    overlay: {
        backgroundColor: 'rgba(0,0,0,0.15)',
    },
};

const CreateSatchelPayMasterAccountModal = ({ opened, onClose, onSuccess }) => {
    const [newAccount, setNewAccount] = useState({
        name: '',
        iban: '',
        bic: '',
        account_number: '',
    });
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const handleChange = ({ target }) => {
        setNewAccount((prev) => ({
            ...prev,
            [target.name]: target.value,
        }));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        setLoading(true);
        setError(null);

        try {
            const { data } = await api().post('/admin-satchel-pay-integration/master-accounts', newAccount);

            setLoading(false);

            toast.success('SatchelPay master account created successfully');
            onSuccess([data]);
            onClose();
        } catch ({ data: error }) {
            setLoading(false);
            setError(error);
        }
    };

    return (
        <Modal isOpen={opened} style={modalStyle}>
            <Grid.Row>
                <Icon name='x-circle' className='w-4 react-modal__closeButton' onClick={onClose} />
                <div style={{ margin: '30px 0 0 0', flexGrow: 1 }}>
                    <Grid.Col>
                        <form onSubmit={handleSubmit}>
                            <Dimmer active={loading} loader>
                                <InputTextField
                                    name='name'
                                    title='Name'
                                    value={newAccount.name}
                                    handleChange={handleChange}
                                />
                                <InputTextField
                                    name='iban'
                                    title='Iban number'
                                    value={newAccount.iban}
                                    handleChange={handleChange}
                                />
                                <InputTextField
                                    name='bic'
                                    title='BIC number'
                                    value={newAccount.bic}
                                    handleChange={handleChange}
                                />
                                <InputTextField
                                    name='account_number'
                                    title='Account Number'
                                    value={newAccount.account_number}
                                    handleChange={handleChange}
                                />
                                <ApiError error={error} />
                                <div className='sp-ma-actions'>
                                    <Button color='secondary' onClick={onClose}>
                                        Cancel
                                    </Button>
                                    <Button type='submit' color='primary'>
                                        Submit
                                    </Button>
                                </div>
                            </Dimmer>
                        </form>
                    </Grid.Col>
                </div>
            </Grid.Row>
        </Modal>
    );
};

export default CreateSatchelPayMasterAccountModal;
