import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'tabler-react';
import { createApiClient as api } from '../../../../../services/api-client';
import { toast } from 'react-toastify';

class ConfirmButton extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
        };

        this.onConfirmQuestionnaire = this.onConfirmQuestionnaire.bind(this);
    }

    async onConfirmQuestionnaire() {
        const { id, checksum, updateStatus, fetchQuestionnaire } = this.props;

        this.setState({
            isLoading: true,
        });

        await api()
            .put(`admin-user-questionnaire-verification/verifications/${id}/confirm`, {
                checksum,
            })
            .then((response) => {
                updateStatus(response.data.status);

                this.setState({
                    isLoading: false,
                });
            })
            .catch((data) => {
                this.setState({
                    isLoading: false,
                });

                if (data.data.code === 'invalid_checksum') {
                    fetchQuestionnaire();
                    toast.error('Questionnaire has been updated. Please review again.');
                } else {
                    toast.error(data.data.message);
                }
            });
    }

    render() {
        const { isLoading } = this.state;
        const { status, isUnconfirmedScreenedClients, isParentLoading } = this.props;

        if (status === null) {
            return null;
        }

        return isUnconfirmedScreenedClients ? (
            <Button className='ml-3' size='sm' icon='help-circle' color='danger' disabled={true}>
                Please confirm screenings first
            </Button>
        ) : (
            <Button
                className='ml-3'
                size='sm'
                icon='check'
                color={status === 'rejected' ? 'warning' : 'success'}
                onClick={this.onConfirmQuestionnaire}
                loading={isLoading || isParentLoading}
                disabled={status === 'confirmed' || status !== 'completed' || isLoading}
            >
                {status === 'confirmed'
                    ? 'Questionnaire confirmed'
                    : status === 'rejected' ? 'Questionnaire rejected' : 'Confirm questionnaire'}
            </Button>
        );
    }
}

ConfirmButton.propTypes = {
    id: PropTypes.number,
    status: PropTypes.string,
    checksum: PropTypes.string,
    updateStatus: PropTypes.func.isRequired,
    isUnconfirmedScreenedClients: PropTypes.bool,
    fetchQuestionnaire: PropTypes.func.isRequired,
    isParentLoading: PropTypes.bool.isRequired,
};

export default ConfirmButton;
