import React, { Component } from 'react';
import { Form } from 'tabler-react';
import PropTypes from 'prop-types';
import isUndefined from 'lodash/isUndefined';

class InputTextField extends Component {
    constructor (props) {
        super(props);

        this.onChange = this.onChange.bind(this);
    }

    filterField (value) {
        const { filter } = this.props;

        if (isUndefined(filter)) {
            return value;
        }

        let valueToFilter = value;

        filter.map((filter) => (valueToFilter = filter(valueToFilter)));

        return valueToFilter;
    }

    onChange (event) {
        const { handleChange, isNumber } = this.props;
        const { name } = event.target;
        let { value } = event.target;

        if (value && value.indexOf(' ') === 0) {
            value = value.trim();
        }

        value = this.filterField(value).replace(/\s\s+/g, ' ');

        handleChange({
            target: {
                name,
                value: isNumber ? parseInt(value, 10) : value,
            },
        });
    }

    render () {
        const { name, value, title, mask, placeholder, invalid, feedback, handleBlur, maxLength } = this.props;

        if (mask) {
            return (
                <Form.Group label={title}>
                    <Form.MaskedInput
                        name={name}
                        value={value}
                        onChange={this.onChange}
                        mask={mask}
                        placeholder={placeholder}
                        invalid={invalid}
                        feedback={feedback}
                        maxLength={maxLength}
                    />
                </Form.Group>
            );
        }

        return (
            <Form.Input
                name={name}
                value={value}
                label={title}
                onChange={this.onChange}
                onBlur={handleBlur}
                placeholder={placeholder}
                invalid={invalid}
                feedback={feedback}
                maxLength={maxLength}
            />
        );
    }
}

InputTextField.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    title: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    mask: PropTypes.array,
    handleChange: PropTypes.func.isRequired,
    handleBlur: PropTypes.func,
    invalid: PropTypes.bool,
    feedback: PropTypes.string,
    filter: PropTypes.array,
    isNumber: PropTypes.bool,
    maxLength: PropTypes.number,
};

InputTextField.defaultProps = {
    value: '',
    maxLength: 255,
};

export default InputTextField;
