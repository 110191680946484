import React, { Component } from 'react';
import SiteWrapper from '../../../SiteWrapper';
import { Button, Card, Dimmer, Grid } from 'tabler-react';
import ApiError from '../../../Common/ApiError';
import { createApiClient as api } from '../../../../services/api-client';
import PropTypes from 'prop-types';
import { navigate } from '../../../../actions/navigate';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import FormFields from './FormFields/FormFields';

class DocumentTypesEdit extends Component {
    constructor (props) {
        super(props);

        this.state = {
            isLoading: false,
            error: null,
            documentType: {
                id: '',
                name: '',
                document_type: '',
                user_type: '',
            },
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount () {
        this.fetchDocumentType();
    }

    fetchDocumentType () {
        const { id } = this.props.match.params;

        this.setState({
            isLoading: true,
            error: null,
        });

        api()
            .get(`/admin-user-document-verification/document-types/${id}`)
            .then((data) => {
                this.setState({
                    documentType: data.data,
                    isLoading: false,
                });
            })
            .catch((data) => {
                this.setState({
                    error: data.data,
                    isLoading: false,
                });
            });
    }

    handleChange (event) {
        const { name, value } = event.target;

        this.setState((prevState) => ({
            documentType: {
                ...prevState.documentType,
                [name]: value.length > 0 ? value : '',
            },
        }));
    }

    handleSubmit (event) {
        event.preventDefault();

        const { documentType } = this.state;
        const { navigate } = this.props;
        const { id } = this.props.match.params;

        this.setState({
            isLoading: true,
            error: null,
        });

        api()
            .put(`/admin-user-document-verification/document-types/${id}`, documentType)
            .then(() => {
                navigate('/compliance/document-types');

                toast.success('Document type updated successfully');
            })
            .catch((data) => {
                this.setState({
                    error: data.data,
                    isLoading: false,
                });
            });
    }

    render () {
        const { error, isLoading, documentType } = this.state;
        const { navigate } = this.props;

        return (
            <SiteWrapper title='Edit document type'>
                <Grid.Row>
                    <Grid.Col md={4} sm={6} xs={12}>
                        <Card>
                            <form onSubmit={this.handleSubmit}>
                                <Dimmer active={isLoading} loader>
                                    <Card.Body>
                                        <FormFields onChange={this.handleChange} documentType={documentType} />

                                        <ApiError error={error} />
                                    </Card.Body>
                                    <Card.Footer className='text-center'>
                                        <div className='d-flex'>
                                            <Button type='button' link onClick={() => navigate('/compliance/document-types')}>
                                                Cancel
                                            </Button>

                                            <Button type='submit' color='primary' className='ml-auto' onClick={this.handleSubmit}>
                                                Submit
                                            </Button>
                                        </div>
                                    </Card.Footer>
                                </Dimmer>
                            </form>
                        </Card>
                    </Grid.Col>
                </Grid.Row>
            </SiteWrapper>
        );
    }
}

DocumentTypesEdit.propTypes = {
    navigate: PropTypes.func.isRequired,
    match: PropTypes.object,
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            navigate,
        },
        dispatch,
    );

export default connect(null, mapDispatchToProps)(DocumentTypesEdit);
