import { createAction } from 'redux-actions';

export const RISK_LEVEL_UPDATE_REQUEST = 'user/RISK_LEVEL_UPDATE_REQUEST';
export const RISK_LEVEL_UPDATE_SUCCESS = 'user/RISK_LEVEL_UPDATE_SUCCESS';
export const RISK_LEVEL_UPDATE_FAILURE = 'user/RISK_LEVEL_UPDATE_FAILURE';

const riskLevelUpdateRequest = createAction(RISK_LEVEL_UPDATE_REQUEST);
const riskLevelUpdateSuccess = createAction(RISK_LEVEL_UPDATE_SUCCESS);
const riskLevelUpdateFailure = createAction(RISK_LEVEL_UPDATE_FAILURE);

export const riskLevelUpdate = (userId, data) => async (dispatch, _getState, { api }) => new Promise(async (resolve) => {
    try {
        dispatch(riskLevelUpdateRequest());

        const result = await api.put(`/admin-user/users/${userId}`, data);

        dispatch(riskLevelUpdateSuccess());
        resolve(result.data);
    } catch (response) {
        dispatch(riskLevelUpdateFailure());
    }
});
