import { createAction } from 'redux-actions';

export const FETCH_TRANSACTION_FEE_CONFIGURATIONS_REQUEST = 'fee/FETCH_TRANSACTION_FEE_CONFIGURATIONS_REQUEST';
export const FETCH_TRANSACTION_FEE_CONFIGURATIONS_SUCCESS = 'fee/FETCH_TRANSACTION_FEE_CONFIGURATIONS_SUCCESS';
export const FETCH_TRANSACTION_FEE_CONFIGURATIONS_FAILURE = 'fee/FETCH_TRANSACTION_FEE_CONFIGURATIONS_FAILURE';

const fetchTransactionFeeConfigurationsRequest = createAction(FETCH_TRANSACTION_FEE_CONFIGURATIONS_REQUEST);
const fetchTransactionFeeConfigurationsSuccess = createAction(FETCH_TRANSACTION_FEE_CONFIGURATIONS_SUCCESS);
const fetchTransactionFeeConfigurationsFailure = createAction(FETCH_TRANSACTION_FEE_CONFIGURATIONS_FAILURE);

export const fetchTransactionFeeConfigurations = () => (dispatch, getState, { api }) =>
    new Promise(async (resolve) => {
        try {
            dispatch(fetchTransactionFeeConfigurationsRequest());

            const result = await api.get('admin-fee/transaction-fee-configurations');

            dispatch(fetchTransactionFeeConfigurationsSuccess(result.data.items));
            resolve(result.data.items);
        } catch (response) {
            dispatch(fetchTransactionFeeConfigurationsFailure());
        }
    });
