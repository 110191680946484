import React, { useMemo, useState } from 'react';
import { formatDateAndTime } from '../../../helpers';
import SiteWrapper from '../../SiteWrapper';
import PaginatedFilteredList from '../../Common/PaginatedFilteredList';
import { Grid, Text } from 'tabler-react';

const participantsURI = '/admin-sepa-integration/parts-participants';

const Participants = () => {
    const [items, setItems] = useState([]);
    const [metadataDate, setMetadataDate] = useState(null);

    const updateItems = (items) => {
        setItems(items);
    };

    const updateMetadata = (data) => {
        setMetadataDate(data.updated_at);
    };

    const columns = useMemo(
        () => [
            {
                Header: 'Participant name',
                accessor: 'name',
                sortable: false,
            },
            {
                Header: 'Code',
                accessor: 'code',
                sortable: false,
            },
            {
                Header: 'BIC',
                accessor: 'bic',
                sortable: false,
            },
            {
                Header: 'Sepa Message ID',
                accessor: 'sepa_message_id',
                sortable: false,
            },
            {
                Header: 'Date',
                accessor: 'updated_at',
                sortable: false,
                Cell: (row) => formatDateAndTime(row.value),
            },
        ],
        [],
    );

    const renderTableHeader = () => metadataDate && (
        <Grid.Col>
            <Text className='m-3' size='small' left='true' color='secondary'>
                <div>Updated at: {formatDateAndTime(metadataDate)}</div>
            </Text>
        </Grid.Col>
    );

    return (
        <SiteWrapper title='Participants'>
            <PaginatedFilteredList
                uri={participantsURI}
                columns={columns}
                updateItems={updateItems}
                updateMetadata={updateMetadata}
                items={items}
                listHeader={renderTableHeader()}
            />
        </SiteWrapper>
    );
};

export default Participants;
