import isUndefined from 'lodash/isUndefined';

export const getLabelForAmountRange = (amount_from, amount_to) => {
    let label;

    const issetAmountFrom = !isUndefined(amount_from) && amount_from !== null;
    const issetAmountTo = !isUndefined(amount_to) && amount_to !== null;

    if (issetAmountFrom && issetAmountTo) {
        label = `${amount_from} - ${amount_to}`;
    } else if (issetAmountFrom) {
        label = `From ${amount_from}`;
    }

    return label;
};

export const getLabelFromDirectionAndType = (type, direction) => {
    if (type === 'internal_transfer') {
        return `${direction} internal transactions`;
    }

    if (type === 'external_transfer') {
        return `${direction} external transactions`;
    }

    return null;
};
