import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { fetchPricingGroups } from '../../../../../../../actions/fee/pricing';

class PricingGroupSelect extends Component {
    constructor (props) {
        super(props);

        this.state = {
            error: null,
            isLoading: false,
        };
    }

    componentDidMount () {
        this.props.fetchPricingGroups();
    }

    render () {
        const { user, onChange, disabled } = this.props;

        if (user.is_verified && user.pricing_group_id) {
            const pricingGroup = this.props.pricingGroups.find((pricingGroup) => pricingGroup.id === user.pricing_group_id);

            return pricingGroup ? pricingGroup.name : '-';
        }

        return (
            <>
                <select disabled={disabled} className='w-100' name='pricing_group_id' onChange={onChange}>
                    <option />
                    {this.props.pricingGroups.map((pricingGroup) => (
                        <option key={pricingGroup.id} value={pricingGroup.id}>
                            {pricingGroup.name}
                        </option>
                    ))}
                </select>
            </>
        );
    }
}

PricingGroupSelect.propTypes = {
    user: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    fetchPricingGroups: PropTypes.func.isRequired,
    pricingGroups: PropTypes.array.isRequired,
    disabled: PropTypes.bool,
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            fetchPricingGroups,
        },
        dispatch,
    );

const mapStateToProps = (state) => ({
    pricingGroups: state.fee.pricing,
});

export default connect(mapStateToProps, mapDispatchToProps)(PricingGroupSelect);
