import { Component } from 'react';
import PropTypes from 'prop-types';

class String extends Component {
    render () {
        const { answer } = this.props;

        return answer;
    }
}

String.propTypes = {
    answer: PropTypes.string.isRequired,
};

export default String;
