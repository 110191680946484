import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { isLoading } from '../../../../../selectors/isLoading';
import { fetchCurrencies } from '../../../../../actions/currency/currencies';
import { Form } from 'tabler-react';

class Currency extends Component {
    componentDidMount () {
        this.props.fetchCurrencies();
    }

    renderCurrenciesOptions () {
        const { currencies } = this.props;

        return currencies.map((currency) => (
            <option key={currency.code} value={currency.code}>
                {currency.code.toUpperCase()}
            </option>
        ));
    }

    render () {
        const { onChange, value } = this.props;

        return (
            <Form.Select name='currency' label='From Currency' value={value === null ? '' : value} onChange={onChange}>
                <option></option>
                {this.renderCurrenciesOptions()}
            </Form.Select>
        );
    }
}

Currency.propTypes = {
    currencies: PropTypes.array.isRequired,
    fetchCurrencies: PropTypes.func.isRequired,
    isFetchingCurrencies: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string,
};

const mapStateToProps = (state) => ({
    currencies: state.currency.currencies,
    isFetchingCurrencies: isLoading(state, 'currencies'),
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            fetchCurrencies,
        },
        dispatch,
    );

export default connect(mapStateToProps, mapDispatchToProps)(Currency);
