import React, { Component } from 'react';
import { Card, Dimmer, Table, Text } from 'tabler-react';
import PropTypes from 'prop-types';
import { boolToYesNo, formatDateAndTime, userStatus, userStatusColor } from '../../../../helpers';
import { createApiClient as api } from '../../../../services/api-client';
import { getName } from '../../../../selectors/user/get-participants-information';

class MainData extends Component {
    constructor (props) {
        super(props);

        this.state = {
            isLoading: true,
            error: null,
            user: {
                language: null,
                email: null,
                number: null,
                phone: null,
                is_active: null,
                is_verified: null,
                created_at: null,
            },
        };
    }

    componentDidMount () {
        this.fetchUser();
    }

    fetchUser () {
        const { fetchCallback, userId } = this.props;

        this.setState({
            isLoading: true,
        });

        api()
            .get(`/admin-user/users/${userId}`)
            .then((data) => {
                this.setState({
                    user: data.data,
                    isLoading: false,
                });

                fetchCallback(data.data);
            })
            .catch((data) => {
                this.setState({
                    error: data.data.message,
                    isLoading: false,
                });
            });
    }

    renderBody () {
        const { user, isLoading } = this.state;

        return (
            <>
                <Dimmer active={this.state.isLoading} loader>
                    <Table className='small' striped={true} cards={true}>
                        <Table.Body>
                            <Table.Row>
                                <Table.Col>Name</Table.Col>
                                <Table.Col>{getName(user)}</Table.Col>
                            </Table.Row>

                            <Table.Row>
                                <Table.Col>Number</Table.Col>
                                <Table.Col>{user.number}</Table.Col>
                            </Table.Row>

                            <Table.Row>
                                <Table.Col>Email</Table.Col>
                                <Table.Col>{user.email}</Table.Col>
                            </Table.Row>

                            <Table.Row>
                                <Table.Col>Phone</Table.Col>
                                <Table.Col>{user.phone}</Table.Col>
                            </Table.Row>

                            <Table.Row>
                                <Table.Col>Is active</Table.Col>
                                <Table.Col>{boolToYesNo(user.is_active)}</Table.Col>
                            </Table.Row>
                        </Table.Body>
                    </Table>
                </Dimmer>
                {isLoading === false && (
                    <Card.Footer>
                        <Text.Small>Created at {formatDateAndTime(user.created_at)}</Text.Small>
                    </Card.Footer>
                )}
            </>
        );
    }

    renderError () {
        return (
            <Card.Alert color='danger'>
                <Text.Small>{this.state.error}</Text.Small>
            </Card.Alert>
        );
    }

    render () {
        const { user, isLoading, error } = this.state;

        return (
            <Card statusColor={userStatusColor(user.is_verified)}>
                <Card.Header>
                    <Card.Title>Main user data</Card.Title>
                    {isLoading === false && <Card.Options>{userStatus(user.is_verified)}</Card.Options>}
                </Card.Header>

                {error ? this.renderError() : this.renderBody()}
            </Card>
        );
    }
}

MainData.propTypes = {
    userId: PropTypes.number.isRequired,
    fetchCallback: PropTypes.func.isRequired,
};

export default MainData;
