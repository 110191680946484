import React from 'react';
import { Form } from 'tabler-react';

const ActionListTab = ({ value, label, name = 'listType', onChange, selected }) => (
    <Form.SelectGroupItem
        label={label}
        name={name}
        value={value}
        checked={selected}
        onChange={onChange}
    />
);

export default ActionListTab;
