import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { Button, Card, Dimmer, Form, Grid } from 'tabler-react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createApiClient } from '../../../../services/api-client';
import ApiError from '../../../Common/ApiError';
import SiteWrapper from '../../../SiteWrapper';
import { toast } from 'react-toastify';
import './TermsAndConditionsCreateOrEdit.scss';
import { navigate } from '../../../../actions/navigate';
import { formatDate } from '../../../../helpers';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const TermsAndConditionsCreateOrEdit = ({ navigate, match }) => {
    const [inputValues, setInputValues] = useState({
        date: '',
        summary: '',
        user_type: 'private',
        text: '',
    });
    const [isLoading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const api = useMemo(() => createApiClient(), []);

    const { id } = match.params;

    const handleInputChange = (event) => {
        const { target } = event;

        setInputValues((prevInputValues) => ({ ...prevInputValues, [target.name]: target.value }));
    };

    const handleSummaryChange = (value) => {
        setInputValues((prevInputValues) => ({ ...prevInputValues, summary: value }));
    };

    const handleTextChange = (value) => {
        setInputValues((prevInputValues) => ({ ...prevInputValues, text: value }));
    };

    const onSubmit = useCallback(async () => {
        setLoading(true);
        setError(null);

        try {
            if (id !== undefined) {
                await api.put(`admin-user/agreements/${id}`, inputValues);
            } else {
                await api.post('admin-user/agreements', inputValues);
            }
            const toastStatusText = id === undefined ? 'created' : 'edited';

            toast.success(`Terms and Conditions ${toastStatusText} successfully`);
            navigate('/compliance/terms-conditions');
        } catch (error) {
            setError(error.data);
            setLoading(false);
        }
    }, [api, id, inputValues, navigate]);

    const getAgreement = useCallback(async () => {
        if (id === undefined) {
            return;
        }

        setLoading(true);

        try {
            const { data } = await api.get(`admin-user/agreements/${id}`);

            data.date = formatDate(data.date);
            setInputValues(data);
        } catch (error) {
            setError(error.data);
        } finally {
            setLoading(false);
        }
    }, [api, id]);

    const navigateToTermsAndConditionsList = useCallback(() => navigate('/compliance/terms-conditions'), [navigate]);

    useEffect(() => {
        getAgreement();
    }, [getAgreement]);

    return (
        <SiteWrapper title={`${id !== undefined ? 'Edit' : 'Create'} Terms & Conditions `}>
            <Dimmer loader active={isLoading}>
                <Card>
                    <Grid.Row>
                        <Grid.Col md={6}>
                            <Card.Body>
                                <Form.Group label='Date'>
                                    <Form.MaskedInput
                                        name='date'
                                        value={inputValues.date}
                                        mask={[/\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/]}
                                        placeholder='YYYY-MM-DD'
                                        onChange={handleInputChange}
                                    />
                                </Form.Group>
                                <Form.Group label='User type'>
                                    <Form.Radio
                                        checked={inputValues.user_type === 'private'}
                                        isInline
                                        label='Private'
                                        name='user_type'
                                        value='private'
                                        onChange={handleInputChange}
                                    />
                                    <Form.Radio
                                        isInline
                                        checked={inputValues.user_type === 'business'}
                                        label='Business'
                                        name='user_type'
                                        value='business'
                                        onChange={handleInputChange}
                                    />
                                </Form.Group>
                            </Card.Body>
                        </Grid.Col>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Col>
                            <Card.Body>
                                <label className='form-label'>Summary</label>
                                <ReactQuill theme='snow' value={inputValues.summary} onChange={handleSummaryChange} />
                            </Card.Body>
                        </Grid.Col>
                        <Grid.Col>
                            <Card.Body>
                                <label className='form-label'>Full text</label>
                                <ReactQuill theme='snow' value={inputValues.text} onChange={handleTextChange} className='textAreaSize' />
                            </Card.Body>
                        </Grid.Col>
                    </Grid.Row>
                    <Card.Footer>
                        <ApiError error={error} />
                        <div className='d-flex'>
                            <Button type='button' link onClick={navigateToTermsAndConditionsList}>
                                Cancel
                            </Button>

                            <Button type='submit' color='primary' className='ml-auto' onClick={onSubmit}>
                                Submit
                            </Button>
                        </div>
                    </Card.Footer>
                </Card>
            </Dimmer>
        </SiteWrapper>
    );
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            navigate,
        },
        dispatch,
    );

export default connect(null, mapDispatchToProps)(TermsAndConditionsCreateOrEdit);
