/* eslint-disable max-len */

export const WALLET_TYPES = {
    mano_bank: 'ManoBank',
};

export const TRANSACTION_PROVIDERS = {
    Centrolink: [
        'centrolink_sepa',
        'centrolink_sepa_return',
    ],
    'Satchel Pay': [
        'satchel_pay_sepa',
        'satchel_pay_incoming',
    ],
    Internal: ['charge', 'internal', 'refund', 'deposit', 'manual_swift'],
    'Currency Exchange': ['manual_currency_exchange'],
};

export const TRANSACTION_TYPES = {
    internal_transfer: 'Internal transfer',
    external_transfer: 'External transfer',
    charge: 'Charge',
    between_accounts: 'Between accounts',
    refund: 'Refund',
    transfer_return: 'Payment return',
    currency_buy: 'Currency Buy',
    chargeback: 'Chargeback',
    deposit: 'Deposit',
};

export const TRANSACTION_STATUSES = {
    created: 'Created',
    pending: 'Pending',
    manual_approval: 'Manual approval',
    processing: 'Processing',
    canceled: 'Canceled',
    failed: 'Failed',
    completed: 'Completed',
};

export const TRANSACTION_DOCUMENTATION_STATUS = {
    created: 'Requested',
    completed: 'Uploaded',
};

export const TRANSACTION_STATUS_MANUAL_APPROVAL = 'manual_approval';

export const CHARGE_STATUS = {
    new: 'Created',
    processing: 'Processing',
    waiting_funds: 'Waiting funds',
    completed: 'Completed',
};

export const TRANSACTION_DIRECTIONS = {
    outgoing: 'Outgoing',
    incoming: 'Incoming',
};

export const FILTER_MASKS = {
    date: {
        placeholder: '0000-00-00',
        mask: [/\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/],
    },
    transactionNumber: {
        placeholder: '0000000000',
        mask: [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/],
    },
};

export const KYC_SUCCESS_STATUSES = ['APPROVED', 'SUCCESSFUL'];

export const AUDIT_TYPES = {
    authentication: 'Authentication',
    transaction: 'Transaction',
    registration: 'Registration',
    verification: 'Verification',
    profile_management: 'Profile management',
};

export const ADMIN_AUDIT_FILTER_TYPES = {
    authentication: 'Authentication',
    transaction: 'Transaction',
    wallet: 'Wallets',
    profile_management: 'Profile management',
    settings: 'Configuration',
};

export const ADMIN_AUDIT_ALL_TYPES = {
    ...ADMIN_AUDIT_FILTER_TYPES,
    verification: 'Verification',
    document_verification: 'Document verification',
};

export const USER_VERIFICATION_TYPES = {
    identity_verification: 'User Verification',
    document_verification: 'User Verification',
    questionnaire_verification: 'User Verification',
};

export const EXTERNAL_TRANSFER = 'external_transfer';
export const INTERNAL_TRANSFER = 'internal_transfer';
export const CURRENCY_BUY = 'currency_buy';
export const REFUND = 'refund';
export const CHARGEBACK = 'chargeback';
export const DEPOSIT = 'deposit';
export const CARD_PAYMENT = 'card_payment';

export const OUTGOING_TRANSACTION = 'outgoing';
export const INCOMING_TRANSACTION = 'incoming';

export const TRANSACTION_SCORING_STATUS_PROCESSING = 'processing';
export const TRANSACTION_SCORING_STATUS_CREATED = 'created';

export const IDENTITY_VERIFICATION_STATUSES = {
    processing: 'Provided',
    confirmed: 'Confirmed',
    failed: 'Failed',
    expired: 'Expired',
    expires_soon: 'Expires soon',
};

export const DOCUMENT_VERIFICATION_STATUSES = {
    processing: 'Provided',
    confirmed: 'Confirmed',
};

export const QUESTIONNAIRE_VERIFICATION_STATUSES = {
    completed: 'Provided',
    confirmed: 'Confirmed',
    rejected: 'Rejected',
};

export const NAPIER_STATUS_COMPLETE = 'complete';

export const SCREENED_CLIENT_TYPE = {
    beneficial_owner: 'Beneficial owner',
    director: 'Director',
};

export const SCREENED_CLIENT_STATUS = {
    waiting_for_confirmation: 'Waiting for confirmation',
    confirmed: 'Confirmed',
};

export const SCREENED_CLIENT_STATUS_CONFIRMED = 'confirmed';

export const DOCUMENT_STATUS = {
    accepted: 'accepted',
    rejected: 'rejected',
    created: 'created',
};

export const DOCUMENT_TYPES = {
    additional: 'additional',
    mandatory: 'mandatory',
};

export const DOCUMENT_VERIFICATION_STATUS_CODE = {
    confirmed: 'confirmed',
    created: 'created',
    processing: 'processing',
};

export const FILTER_KEYS_WITH_TYPE_NUMBER = {
    related_sepa_message_id: 'related_sepa_message_id',
    requested_user_id: 'requested_user_id',
};

export const ACTION_LIST_TABS = [
    {
        value: 'pending-verification',
        label: 'KYC',
    },
    {
        value: 'pending-transactions',
        label: 'Pending transactions',
    },
    {
        value: 'transaction-documentation',
        label: 'Transaction documentation',
    },
];

export const BOOKKEEPING_ENTRY_TYPES = {
    debit: 'Debit',
    credit: 'Credit',
};

export const USER_TYPES = {
    business: 'Business',
    private: 'Private',
};

/* eslint-disable max-len */

export const CentrolinkTooltips = {
    FFCCTRNS: 'In this list, “FFCCTRNS” messages are displayed. FFCCTRNS  - message that includes initial incoming/outgoing transaction information such as sender, receiver, amount, etc. All the transactions displayed here, are duplicated in the general “Transactions” list. You can click “Cancel” button in order to cancel the transaction.  In case if transaction is in status “Accepted” > transaction will be canceled immediately and funds will be sent back to the sender. If status is “Settled” > cancellation request will be sent to the Beneficiary bank for its decision',
    CNRECP: 'In this list, “CNRECP” messages are displayed. The originator bank will send an inquiry message to investigate if and when the initial Credit Transfer has been executed. “Reject” and “Accept” buttons are available for such files. In the case of initial transaction received and executed, by clicking “Accept” button - RESINV message will be sent to the originator with a positive response. In case if initial transaction was not received or not executed, by clicking the button “Reject” RESINV message will be sent back to the originator with the selected rejection reason',
    REQMP: 'In this list, “REQMP” messages are displayed. Sender bank, after initial transaction initiation, could send the request to the Centrolink for settlement date value correction. In such case, we will receive initial transaction message, and request to settle the transaction at the specific date.  We are able to “Accept” and “Reject” such messages.  On accept or reject - sender bank will receive RESINV file',
    PRTRN: 'In this list, “PRTRN” messages are displayed. If outgoing transaction was settled, and cancellation request has been sent > beneficiary bank is able to respond us back with the PRTRN (payment return) message. In this case, sender wallet balance will be increased.  In case if there is an incoming cancellation request, we are able to accept it, so initial transaction will be sent back, and sender wallet balance will be reduced',
    FFPCRQST: 'In this list, “FFPCRQST” messages are displayed. A cancellation request can be generated from “Transactions” tab, by clicking “Cancel”.  You can “Accept” or “Reject” incoming cancellation requests. In case of acceptance - payment return (PRTRN) will be initiated, and receiver’s wallet balance reduced. In case of reject - no payment return will be initiated, and ROINV (Resolution of investigation) file will be sent back to the sender',
    FFPSRPRQ: 'In this list, “FFPSRPRQ” messages are displayed. Request for status update messages could be received in case if the sender didn’t get from us any positive/negative answer to the cancellation request. In order to reply to this message, you should accept/reject the incoming cancellation request',
    ROINV: 'In this list “ROINV” messages are displayed. ROINV message - negative response “answer” file to the previously received cancellation request (“Cancellation requests” tab). This file could be generated by clicking button “Reject” on the incoming cancellation request.',
};
