import React, { Component } from 'react';
import { isAmount } from '../../../services/input-validator';
import InputTextField from './InputTextField';
import isUndefined from 'lodash/isUndefined';
import { formatAmount } from '../../../helpers';

class AmountField extends Component {
    constructor (props) {
        super(props);

        this.state = {
            errorMessage: null,
        };

        this.onChange = this.onChange.bind(this);
        this.onBlur = this.onBlur.bind(this);
    }

    onBlur (event) {
        const { handleChange } = this.props;
        const { name } = event.target;
        let { value } = event.target;

        if (value !== '') {
            value = parseFloat(value).toFixed(2);
            handleChange({
                target: {
                    name,
                    value,
                },
            });
        }
    }

    onChange (event) {
        const { handleChange } = this.props;
        const { name, value } = event.target;

        if (value !== '' && !isAmount(value)) {
            return null;
        }

        this.validateAmount(value);

        handleChange({
            target: {
                name,
                value,
            },
        });
    }

    validateAmount (value) {
        const { maxAmount } = this.props;
        const { errorMessage } = this.state;

        if (!isUndefined(maxAmount) && value > maxAmount) {
            this.setState({
                errorMessage: `Maximum value: ${formatAmount(maxAmount)}`,
            });
        } else if (errorMessage !== null) {
            this.setState({
                errorMessage: null,
            });
        }
    }

    render () {
        const { errorMessage } = this.state;

        return (
            <InputTextField
                {...this.props}
                invalid={errorMessage !== null}
                feedback={errorMessage}
                handleChange={this.onChange}
                handleBlur={this.onBlur}
            />
        );
    }
}

export default AmountField;
