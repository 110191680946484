import React from 'react';
import { Card, Dimmer, Table } from 'tabler-react';
import PropTypes from 'prop-types';

const AdminInformation = ({ adminUser, adminFullName, adminRoles }) => {
    const renderBody = () => (
        <Dimmer loader>
            <Table className='small' striped={true} cards={true}>
                <Table.Body>
                    <Table.Row>
                        <Table.Col>Email</Table.Col>
                        <Table.Col>{adminUser.email}</Table.Col>
                    </Table.Row>

                    <Table.Row>
                        <Table.Col>Full name</Table.Col>
                        <Table.Col>{adminFullName}</Table.Col>
                    </Table.Row>
                    <Table.Row>
                        <Table.Col>Role</Table.Col>
                        <Table.Col>{adminRoles}</Table.Col>
                    </Table.Row>
                </Table.Body>
            </Table>
        </Dimmer>
    );

    return (
        <Card>
            <Card.Header>
                <Card.Title>General information</Card.Title>
            </Card.Header>
            {renderBody()}
        </Card>
    );
};

AdminInformation.propTypes = {
    adminUser: PropTypes.object.isRequired,
    adminFullName: PropTypes.string.isRequired,
    adminRoles: PropTypes.string.isRequired,
};

export default AdminInformation;
