import React, { Component } from 'react';
import SiteWrapper from '../../SiteWrapper';
import { Button } from 'tabler-react';
import { createApiClient as api } from '../../../services/api-client';
import { formatAmount, uppercase } from '../../../helpers';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import PaginatedFilteredList from '../../Common/PaginatedFilteredList';

class MonthlyFees extends Component {
    constructor (props) {
        super(props);

        this.state = {
            isLoading: false,
            items: [],
        };

        this.uri = '/admin-fee/monthly-fee-configurations';

        // this.filterFields = [
        //     {
        //         title: 'Provider',
        //         name: 'wallet_type',
        //         type: 'select',
        //         options: objectToOptions(WALLET_TYPES),
        //     },
        // ];

        this.columns = [
            {
                Header: 'Name',
                sortable: false,
                accessor: 'name',
            },
            {
                Header: 'Amount',
                accessor: 'amount',
                Cell: (row) => formatAmount(row.value),
            },
            {
                Header: 'Currency',
                accessor: 'currency',
                sortable: false,
                Cell: (row) => uppercase(row.value),
            },
            // {
            //     Header: 'Wallet',
            //     accessor: 'fee_wallet_relation',
            //     sortable: false,
            //     Cell: (row) => this.renderWalletColumn(row),
            // },
            // {
            //     Header: 'Provider',
            //     accessor: 'fee_wallet_relation',
            //     sortable: false,
            //     Cell: (row) => WALLET_TYPES[row.original.fee_wallet_relation.wallet_type] || null,
            // },
            {
                Header: 'Actions',
                id: 'actions',
                width: 130,
                sortable: false,
                headerClassName: 'column-actions',
                className: 'column-actions',
                Cell: (row) => (
                    <>
                        <Button.List>
                            <Link className='btn btn-sm btn-primary' to={{ pathname: `/fee/monthly-fees/edit/${row.original.id}` }}>
                                Edit
                            </Link>
                            <Button size='sm' onClick={() => this.deleteFee(row.original)} color='danger'>
                                Delete
                            </Button>
                        </Button.List>
                    </>
                ),
            },
        ];

        this.deleteFee = this.deleteFee.bind(this);
        this.updateItems = this.updateItems.bind(this);
    }

    // renderWalletColumn (row) {
    //     return (
    //         <>
    //             {row.original.fee_wallet_relation.name}
    //             &nbsp;
    //             <span className='text-muted-dark'>({row.original.fee_wallet_relation.wallet_number})</span>
    //         </>
    //     );
    // }

    deleteFee (item) {
        if (confirm('Are you sure?')) {
            this.setState({
                isLoading: true,
            });

            api()
                .delete(`/admin-fee/monthly-fee-configurations/${item.id}`)
                .then(() => {
                    toast.success('Deleted successful');
                    this.setState((prev) => ({
                        items: prev.items.filter((prevItem) => prevItem.id !== item.id),
                    }));
                })
                .catch((data) => {
                    toast.error(data.data.message);
                })
                .finally(() => {
                    this.setState({
                        isLoading: false,
                    });
                });
        }
    }

    renderOptions () {
        return (
            <Link to='/fee/monthly-fees/create'>
                <Button pill icon='plus' color='primary'>
                    Create new
                </Button>
            </Link>
        );
    }

    updateItems (items) {
        this.setState({
            items,
        });
    }

    render () {
        const { items } = this.state;

        return (
            <SiteWrapper title='Monthly fees' options={this.renderOptions()}>
                <PaginatedFilteredList
                    uri={this.uri}
                    columns={this.columns}
                    filterFields={this.filterFields}
                    updateItems={this.updateItems}
                    items={items}
                />
            </SiteWrapper>
        );
    }
}

export default MonthlyFees;
