import React, { Component } from 'react';
import './Dropzone.scss';
import PropTypes from 'prop-types';
import iconUpload from '../../../../images/baseline-cloud_upload-24px.svg';

class Dropzone extends Component {
    constructor (props) {
        super(props);

        this.state = {
            highlight: false,
        };

        this.fileInputRef = React.createRef();

        this.openFileDialog = this.openFileDialog.bind(this);
        this.onFilesAdded = this.onFilesAdded.bind(this);
        this.onDragOver = this.onDragOver.bind(this);
        this.onDragLeave = this.onDragLeave.bind(this);
        this.onDrop = this.onDrop.bind(this);
    }

    openFileDialog () {
        if (this.props.disabled) {
            return;
        }

        this.fileInputRef.current.click();
    }

    onFilesAdded (evt) {
        const { disabled, onFilesAdded } = this.props;

        if (disabled) {
            return;
        }

        const { files } = evt.target;

        if (onFilesAdded) {
            onFilesAdded(this.fileListToArray(files));
        }
    }

    onDragOver (event) {
        event.preventDefault();

        if (this.props.disabled) {
            return;
        }

        this.setState({ highlight: true });
    }

    onDragLeave () {
        this.setState({ highlight: false });
    }

    onDrop (event) {
        event.preventDefault();

        const { disabled, onFilesAdded } = this.props;

        if (disabled) {
            return;
        }

        if (onFilesAdded) {
            onFilesAdded(this.fileListToArray(event.dataTransfer.files));
        }

        this.setState({ highlight: false });
    }

    fileListToArray (list) {
        const array = [];
        for (let index = 0; index < list.length; index += 1) {
            array.push(list.item(index));
        }

        return array;
    }

    render () {
        const { highlight } = this.state;
        const { disabled } = this.props;

        return (
            <div
                className={`dropzone ${highlight ? 'highlight' : ''}`}
                onDragOver={this.onDragOver}
                onDragLeave={this.onDragLeave}
                onDrop={this.onDrop}
                onClick={this.openFileDialog}
                style={{ cursor: disabled ? 'default' : 'pointer' }}
            >
                <input ref={this.fileInputRef} className='fileInput' type='file' multiple onChange={this.onFilesAdded} />
                <img alt='upload' className='icon' src={iconUpload} />
                <span>Upload Files</span>
            </div>
        );
    }
}

Dropzone.propTypes = {
    disabled: PropTypes.bool,
    onFilesAdded: PropTypes.func.isRequired,
};

export default Dropzone;
