import React, { useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Icon, Button } from 'tabler-react';
import Modal from 'react-modal';
import { createApiClient as api } from '../../../services/api-client';
import ApiError from '../../Common/ApiError';
import SelectField from '../../Common/FilterFormFields/SelectField';
import InputTextField from '../../Common/FilterFormFields/InputTextField';

const REJECT_REASONS = {
    CUST: 'Requested by customer',
    LEGL: 'Legal decision',
    AC04: 'Closed Account Number',
    AM04: 'Insufficient Funds',
    ARDT: 'Already Returned Transacton',
    NOAS: 'No Answer from Customer',
    NOOR: 'No Original Transaction Received',
};

const ADDITIONAL_INFORMATION_REASONS = ['LEGL', 'CUST', 'ARDT', 'AM04', 'NOAS'];

const DEFAULT_FORM_VALUES = {
    reject_reason: '',
    additional_information: '',
};
const rejectOptions = Object.entries(REJECT_REASONS).map(([key, description]) => ({
    value: key,
    title: `${key} (${description})`,
}));

const RejectCancelRequestModal = ({ visible, onModalClose, onRejectPayment, selectedTransaction }) => {
    const [formValues, setFormValues] = useState(DEFAULT_FORM_VALUES);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const handleModalClose = useCallback(() => {
        onModalClose();
        setFormValues(DEFAULT_FORM_VALUES);
    }, [onModalClose]);

    const handleRejectPayment = useCallback(
        async (event) => {
            event.preventDefault();

            if (!selectedTransaction) {
                return;
            }

            setLoading(true);

            try {
                await api().put(`/admin-sepa-integration/cancellation-requests/${selectedTransaction.id}/reject`, {
                    code: formValues.reject_reason,
                    details: formValues.additional_information,
                });
                setLoading(false);
                onRejectPayment();
                setFormValues(DEFAULT_FORM_VALUES);
            } catch (error) {
                setError(error.data);
                setLoading(false);
            }
        },
        [formValues, onRejectPayment, selectedTransaction],
    );

    const handleChange = (event) => {
        const { target } = event;

        setFormValues((prev) => ({ ...prev, [target.name]: target.value }));
    };

    const isAdditionalInformationVisible = useMemo(() => {
        const hasFlag = ADDITIONAL_INFORMATION_REASONS.includes(formValues.reject_reason);

        if (!hasFlag) {
            setFormValues((prev) => ({ ...prev, additional_information: '' }));
        }

        return hasFlag;
    }, [formValues.reject_reason]);

    return (
        <Modal isOpen={visible} className='react-modal'>
            <Icon name='x-circle' className='w-4 react-modal__closeButton' onClick={handleModalClose} />

            <div className='react-modal__content'>
                <form onSubmit={handleRejectPayment}>
                    <ApiError error={error} />

                    <SelectField
                        name='reject_reason'
                        title='Rejection reason'
                        handleChange={handleChange}
                        options={rejectOptions}
                        value={formValues.reject_reason}
                    />

                    {isAdditionalInformationVisible && (
                        <InputTextField
                            name='additional_information'
                            title='Additional information'
                            handleChange={handleChange}
                            value={formValues.additional_information}
                        />
                    )}

                    <div className='buttonContainer'>
                        <Button onClick={handleModalClose} color='secondary' disabled={loading} loading={loading}>
                            Cancel
                        </Button>

                        <Button type='submit' color='primary' disabled={loading} loading={loading}>
                            Reject
                        </Button>
                    </div>
                </form>
            </div>
        </Modal>
    );
};

RejectCancelRequestModal.propTypes = {
    visible: PropTypes.bool.isRequired,
    onModalClose: PropTypes.func.isRequired,
    onRejectPayment: PropTypes.func.isRequired,
    selectedTransaction: PropTypes.object,
};

export default RejectCancelRequestModal;
