import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Icon, Button, Form } from 'tabler-react';
import Modal from 'react-modal';
import { toast } from 'react-toastify';
import { createApiClient as api } from '../../../../services/api-client';
import ApiError from '../../../Common/ApiError';
import CurrencySelectField from '../../../Common/FilterFormFields/CurrencySelectField';
import InputTextField from '../../../Common/FilterFormFields/InputTextField';

const CreateDefaultLimitModal = ({
    modalIsOpen,
    onModalClose,
    onLimitsChange,
}) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [data, setData] = useState({});

    const handleModalClose = () => {
        onModalClose();
    };

    const handleApplyClick = async () => {
        try {
            setLoading(true);

            await api().post('/admin-wallet-limit/default-wallet-limits', {
                user_type: data.user_type,
                currency: data.currency,
                daily_limit: parseFloat(data.daily_limit).toFixed(2),
                weekly_limit: parseFloat(data.weekly_limit).toFixed(2),
                monthly_limit: parseFloat(data.monthly_limit).toFixed(2),
            });

            toast.success('Default limits created');

            onLimitsChange();
            handleModalClose();
        } catch (error) {
            setError(error.data);
        } finally {
            setLoading(false);
        }
    };

    const onInputChange = (event) => {
        const { target } = event;

        setData((prev) => ({
            ...prev,
            [target.name]: target.value,
        }));
    };

    return (
        <Modal isOpen={modalIsOpen} className='react-modal'>
            <Icon name='x-circle' className='w-4 react-modal__closeButton' onClick={handleModalClose} />

            <div className='react-modal__content'>
                <ApiError error={error} />
                <h2 className='text-center'>Create default limits</h2>

                <Form.Group label='User type'>
                    <Form.Radio
                        isInline
                        label='Private'
                        name='user_type'
                        value='private'
                        onChange={onInputChange}
                    />
                    <Form.Radio
                        isInline
                        label='Business'
                        name='user_type'
                        value='business'
                        onChange={onInputChange}
                    />
                </Form.Group>

                <CurrencySelectField value={data.currency} onChange={onInputChange} />

                <InputTextField
                    name='daily_limit'
                    title='Daily:'
                    value={data.daily_limit}
                    handleChange={onInputChange}
                />
                <InputTextField
                    name='weekly_limit'
                    title='Weekly:'
                    value={data.weekly_limit}
                    handleChange={onInputChange}
                />
                <InputTextField
                    name='monthly_limit'
                    title='Monthly:'
                    value={data.monthly_limit}
                    handleChange={onInputChange}
                />
                <div className='buttonContainer'>
                    <Button onClick={handleModalClose} color='secondary' disabled={loading} loading={loading}>
                        Cancel
                    </Button>

                    <Button onClick={handleApplyClick} type='submit' color='primary' disabled={loading} loading={loading}>
                        Apply
                    </Button>
                </div>
            </div>
        </Modal>
    );
};

CreateDefaultLimitModal.propTypes = {
    modalIsOpen: PropTypes.bool.isRequired,
    onModalClose: PropTypes.func.isRequired,
    onLimitsChange: PropTypes.func.isRequired,
};

export default CreateDefaultLimitModal;
