import React, { useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'tabler-react';
import { createApiClient } from '../../../../../../services/api-client';
import { toast } from 'react-toastify';
import isEmpty from 'lodash/isEmpty';
import { DOCUMENT_VERIFICATION_STATUS_CODE } from '../../../../../../constants';

const RequestButton = ({ documentType, requestedDocumentTypes, userId, updateDocumentVerificationStatus, documentVerificationStatus }) => {
    const [isLoading, setLoading] = useState(false);
    const requestedDocumentType = requestedDocumentTypes.find(
        (requestedDocumentType) => requestedDocumentType.document_type_id === documentType.id,
    ) || {};

    const api = useMemo(() => createApiClient(), []);

    const isDocumentTypeRequestCreated = !!requestedDocumentType && !isEmpty(requestedDocumentType);

    const isDocumentTypeRequestActive = requestedDocumentType && requestedDocumentType.active;

    const shouldRequestDocumentType = () => {
        if (!isDocumentTypeRequestCreated) {
            return true;
        } else if (!isDocumentTypeRequestActive) {
            return true;
        }

        return false;
    };

    const createDocumentTypeRequest = useCallback(async () => {
        if (!confirm('Are you sure you want to request from the customer this document type?')) {
            return false;
        }

        setLoading(true);

        try {
            await api.post('admin-user-document-verification/document-type-requests', {
                document_type_id: documentType.id,
                user_id: userId,
            });
            await updateDocumentVerificationStatus(false, documentType);
        } catch (error) {
            toast.error(error.data.message);
        } finally {
            setLoading(false);
        }
    }, [api, documentType, updateDocumentVerificationStatus, userId]);

    const toggleDocumentTypeRequestActive = useCallback(async () => {
        if (!confirm('Are you sure you want to cancel request this document type request?')) {
            return false;
        }

        setLoading(true);

        try {
            await api.put(`/admin-user-document-verification/document-type-requests/${requestedDocumentType.id}`, {
                active: !isDocumentTypeRequestActive,
            });
            await updateDocumentVerificationStatus(false, documentType);
        } catch (error) {
            toast.error(error.data.message);
        } finally {
            setLoading(false);
        }
    }, [
        api,
        documentType,
        isDocumentTypeRequestActive,
        updateDocumentVerificationStatus,
        requestedDocumentType.id,
    ]);

    return (
        documentType.document_type !== 'mandatory' &&
        documentVerificationStatus !== DOCUMENT_VERIFICATION_STATUS_CODE.confirmed && (
            <Button
                size='sm'
                icon={shouldRequestDocumentType() ? 'plus' : 'x'}
                color={shouldRequestDocumentType() ? 'info' : 'secondary'}
                onClick={!isDocumentTypeRequestCreated ? createDocumentTypeRequest : toggleDocumentTypeRequestActive}
                loading={isLoading}
                disabled={isLoading}
            >
                {shouldRequestDocumentType() ? 'Request' : 'Cancel request'}
            </Button>
        )
    );
};

RequestButton.propTypes = {
    documentType: PropTypes.object.isRequired,
    requestedDocumentTypes: PropTypes.array.isRequired,
    userId: PropTypes.string.isRequired,
    updateDocumentVerificationStatus: PropTypes.func.isRequired,
    documentVerificationStatus: PropTypes.string.isRequired,
};

export default RequestButton;
