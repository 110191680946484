import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { fetchCountries } from '../../../actions/location/countries';
import { connect } from 'react-redux';
import SelectField from './SelectField';

class CountrySelectField extends Component {
    constructor (props) {
        super(props);

        this.state = {
            countries: [],
        };
    }

    componentDidMount () {
        this.props.fetchCountries().then((countries) => {
            this.setState({
                countries: this.buildCountriesOptions(countries),
            });
        });
    }

    buildCountriesOptions (countries) {
        const { status } = this.props;
        const options = [];

        let filteredCountries = countries;

        if (status !== '') {
            filteredCountries = filteredCountries.filter((country) => country.status === status);
        }

        filteredCountries.map((country) => {
            options.push({
                title: country.name,
                value: country.code,
            });

            return country;
        });

        return options;
    }

    render () {
        const { name, value, title, handleChange } = this.props;

        const { countries } = this.state;

        return <SelectField name={name} value={value} title={title} handleChange={handleChange} options={countries} />;
    }
}

CountrySelectField.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.string,
    title: PropTypes.string.isRequired,
    handleChange: PropTypes.func.isRequired,
    fetchCountries: PropTypes.func.isRequired,
    status: PropTypes.string.isRequired,
};

CountrySelectField.defaultProps = {
    value: '',
    status: '',
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            fetchCountries,
        },
        dispatch,
    );

export default connect(null, mapDispatchToProps)(CountrySelectField);
