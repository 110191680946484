import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'tabler-react';
import Modal from 'react-modal';
import { toast } from 'react-toastify';
import { createApiClient as api } from '../../../services/api-client';

const RemoveButton = ({ documentId, onSuccess }) => {
    const [removeModalOpen, setRemoveModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    const openRemoveModal = () => {
        setRemoveModalOpen(true);
    };

    const closeRemoveModal = () => {
        setRemoveModalOpen(false);
    };

    const handleDocumentRemove = async () => {
        setLoading(true);

        try {
            await api().delete(`/admin-transaction-document/documents/${documentId}`);

            closeRemoveModal();
            onSuccess();
        } catch (error) {
            toast.error(error.data.message);
        } finally {
            setLoading(false);
        }
    };

    const renderRemoveModal = () => (
        <Modal isOpen={removeModalOpen} className='react-modal'>
            <div className='react-modal__content'>
                <h5 className='text-center'>Do you really want to remove this document?</h5>
                <div className='buttonContainer'>
                    <Button onClick={closeRemoveModal} color='secondary' disabled={loading} loading={loading}>
                        Cancel
                    </Button>

                    <Button type='submit' color='primary' onClick={handleDocumentRemove} disabled={loading} loading={loading}>
                        Confirm
                    </Button>
                </div>
            </div>
        </Modal>
    );

    return (
        <>
            <Button
                size='sm'
                icon='trash'
                outline
                color='danger'
                onClick={openRemoveModal}
                loading={loading}
                disabled={loading}
            />
            {renderRemoveModal()}
        </>
    );
};

RemoveButton.propTypes = {
    documentId: PropTypes.number.isRequired,
    onSuccess: PropTypes.func.isRequired,
};

export default RemoveButton;
