import React, { useMemo, useEffect, useState, useCallback, useRef } from 'react';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import SiteWrapper from '../SiteWrapper';
import { Button, Badge } from 'tabler-react';
import PaginatedFilteredList from '../Common/PaginatedFilteredList';
import PropTypes from 'prop-types';
import { CENTROLINK_MESSAGE_TYPE, CENTROLINK_TAB } from './Centrolink';
import { formatDateAndTime } from '../../helpers';
import CancellationRequestModal from './Centrolink/CanellationRequestModal';
import CancelTransactionModal from './Centrolink/CancelTransactionModal';
import ReactTooltip from 'react-tooltip';
import { getProprietaryCode, isProprietaryCodeSuccessful } from '../../utils/proprietary-codes';
import AcceptReqmpModal from './Centrolink/AcceptReqmpModal';
import RejectReqmpModal from './Centrolink/RejectReqmpModal';
import RejectCancelRequestModal from './Centrolink/RejectCancelRequestModal';
import TransactionDetailsModal from './Centrolink/TransactionDetailsModal';
import RejectClaimRequestModal from './Centrolink/RejectClaimRequestModal';
import { createApiClient as api } from '../../services/api-client';

const CentrolinkMessageList = (props) => {
    const [items, setItems] = useState([]);
    const [error, setError] = useState(null);
    const [columns, setColumns] = useState([]);
    const [selectedTransaction, setSelectedTransaction] = useState(null);
    const [selectedRejectRequestTransaction, setSelectedRejectRequestTransaction] = useState(null);
    const [rejectTransactionRequestModalVisible, setRejectTransactionRequestModalVisible] = useState(false);
    const [selectedCancelTransaction, setSelectedCancelTransaction] = useState(null);
    const [cancelTransactionModalVisible, setCancelTransactionModalVisible] = useState(false);
    const [transactionDetailsModalVisible, setTransactionDetailsModalVisible] = useState(false);
    const transactionTable = useRef(null);
    const [cancellationModalVisible, setCancellationModalVisible] = useState(false);
    const [acceptReqmpModalVisible, setAcceptReqmpModalVisible] = useState(false);
    const [rejectReqmpModalVisible, setRejectReqmpModalVisible] = useState(false);
    const [loadingReportRequest, setLoadingReportRequest] = useState(null);

    const [selectedRejectRequestClaim, setSelectedRejectRequestClaim] = useState(null);
    const [rejectClaimRequestModalVisible, setRejectClaimRequestModalVisible] = useState(false);

    const {
        match: {
            params: { id: messageId, type: messageType },
        },
        fetchURI,
        onTabChange,
        tabsOptions,
    } = props;

    useEffect(() => {
        if (!fetchURI && !Object.values(CENTROLINK_MESSAGE_TYPE).includes(messageType)) {
            setError({ message: 'Invalid centrolink message type for list' });
        }
    }, [fetchURI, messageType, props]);

    const currentFetchURI = fetchURI || `/admin-sepa-integration/messages/${messageId}/${messageType}`;

    const isTypeMatchToTab = useCallback(
        (type) => currentFetchURI.indexOf(type) !== -1,
        [currentFetchURI],
    );

    const updateItems = useCallback((items) => {
        setItems(items);
    }, []);

    const updateColumns = useCallback((columns) => {
        setColumns(columns);
    }, []);

    const handleCancelTransaction = useCallback(() => {
        setCancelTransactionModalVisible(false);
        transactionTable.current.fetchData();
    }, []);

    const setCancelTransactionModalOpen = useCallback(
        (value, transaction) => () => {
            setSelectedCancelTransaction(transaction);
            setCancelTransactionModalVisible(value);
        },
        [],
    );

    const setCancellationModalOpen = useCallback(
        (visible, message) => () => {
            setSelectedCancelTransaction(message);
            setCancellationModalVisible(visible);
        },
        [],
    );

    const setAcceptReqmpModalOpen = useCallback(
        (visible, message) => () => {
            setSelectedTransaction(message);
            setAcceptReqmpModalVisible(visible);
        },
        [],
    );

    const setRejectReqmpModalOpen = useCallback(
        (visible, message) => () => {
            setSelectedTransaction(message);
            setRejectReqmpModalVisible(visible);
        },
        [],
    );

    const handleAcceptCancellationRequest = useCallback(() => {
        setCancellationModalVisible(false);
        transactionTable.current.fetchData();
    }, []);

    const handleAcceptReqmp = useCallback(() => {
        setAcceptReqmpModalVisible(false);
        transactionTable.current.fetchData();
    }, []);

    const handleRejectReqmp = useCallback(() => {
        setRejectReqmpModalVisible(false);
        transactionTable.current.fetchData();
    }, []);

    const renderAcceptReqmpAction = useCallback(
        (transaction) => {
            if (!transaction.is_acceptable || !isTypeMatchToTab(CENTROLINK_MESSAGE_TYPE.reqmp)) {
                return null;
            }

            return (
                <Button size='sm' color='success' onClick={setAcceptReqmpModalOpen(true, transaction)}>
                    Accept
                </Button>
            );
        },
        [isTypeMatchToTab, setAcceptReqmpModalOpen],
    );

    const renderRejectReqmpAction = useCallback(
        (transaction) => {
            if (!transaction.is_cancellable || !isTypeMatchToTab(CENTROLINK_MESSAGE_TYPE.reqmp)) {
                return null;
            }

            return (
                <Button size='sm' color='danger' onClick={setRejectReqmpModalOpen(true, transaction)}>
                    Reject
                </Button>
            );
        },
        [isTypeMatchToTab, setRejectReqmpModalOpen],
    );

    const handleRejectTransaction = useCallback(() => {
        setRejectTransactionRequestModalVisible(false);
        transactionTable.current.fetchData();
    }, []);

    const handleRejectClaim = useCallback(() => {
        setRejectClaimRequestModalVisible(false);
        transactionTable.current.fetchData();
    }, []);

    const setRejectTransactionModalOpen = useCallback(
        (value, transaction) => () => {
            setSelectedRejectRequestTransaction(transaction);
            setRejectTransactionRequestModalVisible(value);
        },
        [],
    );

    const setRejectClaimModalOpen = useCallback(
        (value, transaction) => () => {
            setSelectedRejectRequestClaim(transaction);
            setRejectClaimRequestModalVisible(value);
        },
        [],
    );

    const setTransactionDetailsModalOpen = useCallback((value, transaction) => () => {
        setSelectedTransaction(transaction);
        setTransactionDetailsModalVisible(value);
    }, []);

    const handleClaimAccept = useCallback(
        (transaction) => async () => {
            try {
                await api().put(`admin-sepa-integration/claims/${transaction.id}/accept`);
                transactionTable.current.fetchData();
            } catch (exception) {
                setError({ message: 'Something went wrong' });
                setTimeout(() => setError(null), 2000);
            }
        },
        []
    );

    const renderAcceptCancelRequest = useCallback(
        (transaction) => {
            if (
                !isTypeMatchToTab(CENTROLINK_MESSAGE_TYPE.ffpcrqst) ||
                transaction.status === 'accepted' ||
                transaction.direction !== 'incoming' ||
                transaction.status !== 'created'
            ) {
                return null;
            }

            return (
                <Button size='sm' color='success' onClick={setCancellationModalOpen(true, transaction)}>
                    Accept
                </Button>
            );
        },
        [isTypeMatchToTab, setCancellationModalOpen],
    );

    const renderViewTransactionAction = useCallback((transaction) => {
        if (!fetchURI || !tabsOptions) {
            return null;
        }

        const selectedTabLabel = tabsOptions.find((tab) => tab.value === fetchURI).label;

        if (selectedTabLabel !== CENTROLINK_TAB.TRANSACTIONS) {
            return null;
        }

        return (
            <Button size='sm' color='primary' onClick={setTransactionDetailsModalOpen(true, transaction)}>
                View
            </Button>
        );
    }, [fetchURI, setTransactionDetailsModalOpen, tabsOptions]);

    const renderCancelTransactionAction = useCallback(
        (transaction) => {
            if (!transaction.is_cancellable || !isTypeMatchToTab(CENTROLINK_MESSAGE_TYPE.ffcctrns)) {
                return null;
            }

            return (
                <Button size='sm' color='danger' onClick={setCancelTransactionModalOpen(true, transaction)}>
                    Cancel
                </Button>
            );
        },
        [setCancelTransactionModalOpen, isTypeMatchToTab],
    );

    const renderUpdateReportRequestAction = useCallback((transaction) => {
        if (transaction.is_report_requestable === undefined) {
            return null;
        }

        return (
            <Button
                disabled={!transaction.is_report_requestable || loadingReportRequest}
                loading={loadingReportRequest === transaction.id}
                size='sm'
                color='primary'
                onClick={handleRequestClick(transaction)}
            >
                Request for status update
            </Button>
        );
    }, [loadingReportRequest]);

    const renderRejectTransactionAction = useCallback(
        (transaction) => {
            if (!transaction.is_rejectable || !isTypeMatchToTab(CENTROLINK_MESSAGE_TYPE.ffcctrns)) {
                return null;
            }

            return (
                <Button size='sm' color='danger' onClick={setRejectTransactionModalOpen(true, transaction)}>
                    Reject
                </Button>
            );
        },
        [setRejectTransactionModalOpen, isTypeMatchToTab],
    );

    const renderAcceptClaimAction = useCallback(
        (transaction) => {
            if (!transaction.is_acceptable || !isTypeMatchToTab(CENTROLINK_MESSAGE_TYPE.cnrecp)) {
                return null;
            }

            return (
                <Button size='sm' color='success' onClick={handleClaimAccept(transaction)}>
                    Accept
                </Button>
            );
        },
        [isTypeMatchToTab, handleClaimAccept],
    );

    const renderRejectClaimAction = useCallback(
        (transaction) => {
            if (!transaction.is_cancellable || !isTypeMatchToTab(CENTROLINK_MESSAGE_TYPE.cnrecp)) {
                return null;
            }

            return (
                <Button size='sm' color='danger' onClick={setRejectClaimModalOpen(true, transaction)}>
                    Reject
                </Button>
            );
        },
        [isTypeMatchToTab, setRejectClaimModalOpen],
    );

    const autoMapOptions = useMemo(
        () => ({
            sortable: false,
            custom: {
                transaction_number: {
                    Header: 'Transaction Number',
                    accessor: 'transaction_number',
                    Cell: (row) => <Link to={{ pathname: `/transactions/${row.original.transaction_id}` }}>{row.value}</Link>,
                },
                created_at: {
                    Header: 'Created at',
                    accessor: 'created_at',
                    Cell: (row) => formatDateAndTime(row.value),
                },
                accepted_at: {
                    Header: 'Accepted at',
                    accessor: 'accepted_at',
                    Cell: (row) => formatDateAndTime(row.value),
                },
                rejected_at: {
                    Header: 'Rejected at',
                    accessor: 'rejected_at',
                    Cell: (row) => formatDateAndTime(row.value),
                },
                settled_at: {
                    Header: 'Settled at',
                    accessor: 'settled_at',
                    Cell: (row) => formatDateAndTime(row.value),
                },
                returned_at: {
                    Header: 'Returned at',
                    accessor: 'returned_at',
                    Cell: (row) => formatDateAndTime(row.value),
                },
                code: {
                    Header: 'Code',
                    accessor: 'code',
                    Cell: (row) => {
                        if (!row.value) {
                            return null;
                        }

                        if (!getProprietaryCode(row.value)) {
                            return row.value;
                        }

                        const badgeColor = isProprietaryCodeSuccessful(row.value) ? 'success' : 'danger';

                        return (
                            <div data-tip={getProprietaryCode(row.value)}>
                                <Badge color={badgeColor}>{row.value}</Badge>
                                <span className='icon-fail-reason' />

                                <ReactTooltip className='react-tooltip' />
                            </div>
                        );
                    },
                },
            },
            include: [
                {
                    Header: 'Actions',
                    id: 'actions',
                    sortable: false,
                    width: 300,
                    Cell: (row) => (
                        <Button.List>
                            {renderUpdateReportRequestAction(row.original)}
                            {renderAcceptCancelRequest(row.original)}
                            {renderRejectTransactionAction(row.original)}
                            {renderCancelTransactionAction(row.original)}
                            {renderAcceptReqmpAction(row.original)}
                            {renderRejectReqmpAction(row.original)}
                            {renderViewTransactionAction(row.original)}
                            {renderAcceptClaimAction(row.original)}
                            {renderRejectClaimAction(row.original)}
                        </Button.List>
                    ),
                },
            ],
            exclude: ['is_cancellable', 'is_acceptable', 'is_report_requestable', 'transaction_id'],
        }),
        [
            renderAcceptCancelRequest,
            renderRejectTransactionAction,
            renderCancelTransactionAction,
            renderViewTransactionAction,
            renderUpdateReportRequestAction,
            renderAcceptReqmpAction,
            renderRejectReqmpAction,
            renderAcceptClaimAction,
            renderRejectClaimAction,
        ],
    );

    const handleRequestClick = (transaction) => async () => {
        setLoadingReportRequest(transaction.id);

        try {
            await api().put(`/admin-sepa-integration/transactions/${transaction.id}/init-report-request`);
            transactionTable.current.fetchData();
        } catch (error) {
            setError(error);
        } finally {
            setLoadingReportRequest(null);
        }
    };

    const formattedHeader = useMemo(() => {
        if (!fetchURI) {
            return `Centrolink - ${messageType} - ${messageId}`;
        }

        const selectedTab = tabsOptions.find((tab) => tab.value === fetchURI);

        if (!selectedTab.label) {
            return 'Centrolink';
        }

        return `Centrolink - ${selectedTab.label}`;
    }, [fetchURI, messageId, messageType, tabsOptions]);

    const selectedTab = tabsOptions.find((tab) => tab.value === fetchURI);

    return (
        <SiteWrapper title={formattedHeader} tooltipId={selectedTab.tooltipId} titleDescription={selectedTab.titleDescription}>
            <PaginatedFilteredList
                ref={transactionTable}
                tabsOptions={tabsOptions}
                onTabChange={onTabChange}
                uri={currentFetchURI}
                columns={columns}
                updateItems={updateItems}
                items={items}
                parentError={error}
                updateColumns={updateColumns}
                autoMapOptions={autoMapOptions}
            />
            <CancelTransactionModal
                visible={cancelTransactionModalVisible}
                onModalClose={setCancelTransactionModalOpen(false, null)}
                onCancelTransaction={handleCancelTransaction}
                selectedTransaction={selectedCancelTransaction}
            />
            <CancellationRequestModal
                visible={cancellationModalVisible}
                onCloseModal={setCancellationModalOpen(false, null)}
                onAcceptCancellation={handleAcceptCancellationRequest}
                selectedTransaction={selectedCancelTransaction}
            />
            <AcceptReqmpModal
                visible={acceptReqmpModalVisible}
                onModalClose={setAcceptReqmpModalOpen(false, null)}
                onAcceptModification={handleAcceptReqmp}
                selectedTransaction={selectedTransaction}
            />
            <RejectReqmpModal
                visible={rejectReqmpModalVisible}
                onModalClose={setRejectReqmpModalOpen(false, null)}
                onRejectModification={handleRejectReqmp}
                selectedTransaction={selectedTransaction}
            />
            <RejectCancelRequestModal
                visible={rejectTransactionRequestModalVisible}
                onModalClose={setRejectTransactionModalOpen(false, null)}
                onRejectPayment={handleRejectTransaction}
                selectedTransaction={selectedRejectRequestTransaction}
            />
            <RejectClaimRequestModal
                visible={rejectClaimRequestModalVisible}
                onModalClose={setRejectClaimModalOpen(false, null)}
                onClaimReject={handleRejectClaim}
                selectedTransaction={selectedRejectRequestClaim}
            />
            <TransactionDetailsModal
                visible={transactionDetailsModalVisible}
                onModalClose={setTransactionDetailsModalOpen(false, null)}
                selectedTransaction={selectedTransaction}
            />
        </SiteWrapper>
    );
};

CentrolinkMessageList.propTypes = {
    match: PropTypes.object,
    fetchURI: PropTypes.string,
    tabsOptions: PropTypes.array,
    onTabChange: PropTypes.func,
};

export default withRouter(CentrolinkMessageList);
