import { createAction } from 'redux-actions';

export const FETCH_CURRENCIES_REQUEST = 'currency/FETCH_CURRENCIES_REQUEST';
export const FETCH_CURRENCIES_SUCCESS = 'currency/FETCH_CURRENCIES_SUCCESS';
export const FETCH_CURRENCIES_FAILURE = 'currency/FETCH_CURRENCIES_FAILURE';

const fetchCurrenciesRequest = createAction(FETCH_CURRENCIES_REQUEST);
const fetchCurrenciesSuccess = createAction(FETCH_CURRENCIES_SUCCESS);
const fetchCurrenciesFailure = createAction(FETCH_CURRENCIES_FAILURE);

export const fetchCurrencies = (filter = undefined) => (dispatch, getState, { api }) =>
    new Promise(async (resolve) => {
        try {
            if (filter === undefined && getState().currency.currencies.length !== 0) {
                resolve(getState().currency.currencies);
                return;
            }

            dispatch(fetchCurrenciesRequest());

            const result = await api.get('/admin-currency/currencies', {
                params: {
                    filter,
                },
            });

            dispatch(fetchCurrenciesSuccess(result.data));
            resolve(result.data);
        } catch (response) {
            dispatch(fetchCurrenciesFailure());
        }
    });
