import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Icon, Button } from 'tabler-react';
import Modal from 'react-modal';
import { toast } from 'react-toastify';
import { createApiClient as api } from '../../../../services/api-client';
import ApiError from '../../../Common/ApiError';
import InputTextField from '../../../Common/FilterFormFields/InputTextField';

const EditDefaultLimitModal = ({
    defaultData,
    modalIsOpen,
    onModalClose,
    onLimitsChange,
}) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [data, setData] = useState(defaultData);

    useEffect(() => {
        setData(defaultData);
    }, [defaultData, defaultData.id]);

    const handleModalClose = () => {
        setData(defaultData);

        onModalClose();
    };

    const handleApplyClick = async () => {
        try {
            setLoading(true);

            await api().put(`/admin-wallet-limit/default-wallet-limits/${data.id}`, {
                ...data,
                daily_limit: parseFloat(data.daily_limit).toFixed(2),
                weekly_limit: parseFloat(data.weekly_limit).toFixed(2),
                monthly_limit: parseFloat(data.monthly_limit).toFixed(2),
            });

            toast.success('Default limits changed');

            onLimitsChange();
            handleModalClose();
        } catch (error) {
            setError(error.data);
        } finally {
            setLoading(false);
        }
    };

    const onInputChange = (event) => {
        const { target } = event;

        setData((prev) => ({
            ...prev,
            [target.name]: target.value,
        }));
    };

    return (
        <Modal isOpen={modalIsOpen} className='react-modal'>
            <Icon name='x-circle' className='w-4 react-modal__closeButton' onClick={handleModalClose} />

            <div className='react-modal__content'>
                <ApiError error={error} />
                <h2 className='text-center'>Edit default transaction limits</h2>

                <InputTextField
                    name='daily_limit'
                    title='Daily:'
                    value={data.daily_limit}
                    handleChange={onInputChange}
                />
                <InputTextField
                    name='weekly_limit'
                    title='Weekly:'
                    value={data.weekly_limit}
                    handleChange={onInputChange}
                />
                <InputTextField
                    name='monthly_limit'
                    title='Monthly:'
                    value={data.monthly_limit}
                    handleChange={onInputChange}
                />
                <div className='buttonContainer'>
                    <Button onClick={handleModalClose} color='secondary' disabled={loading} loading={loading}>
                        Cancel
                    </Button>

                    <Button onClick={handleApplyClick} type='submit' color='primary' disabled={loading} loading={loading}>
                        Apply
                    </Button>
                </div>
            </div>
        </Modal>
    );
};

EditDefaultLimitModal.propTypes = {
    defaultData: PropTypes.object.isRequired,
    modalIsOpen: PropTypes.bool.isRequired,
    onModalClose: PropTypes.func.isRequired,
    onLimitsChange: PropTypes.func.isRequired,
};

export default EditDefaultLimitModal;
