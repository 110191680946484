import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { isLoading } from '../../../selectors/isLoading';
import { fetchCurrencies } from '../../../actions/currency/currencies';
import SelectField from './SelectField';

class CurrencySelectField extends Component {
    componentDidMount () {
        this.props.fetchCurrencies();
    }

    buildOptions () {
        const { currencies } = this.props;
        const output = [];

        currencies.forEach((currency) =>
            output.push({
                title: currency.code.toUpperCase(),
                value: currency.code,
            }),
        );

        return output;
    }

    render () {
        return (
            <SelectField
                {...this.props}
                name='currency'
                title='Currency'
                handleChange={this.props.onChange}
                options={this.buildOptions()}
            />
        );
    }
}

CurrencySelectField.propTypes = {
    currencies: PropTypes.array.isRequired,
    fetchCurrencies: PropTypes.func.isRequired,
    isFetchingCurrencies: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string,
};

const mapStateToProps = (state) => ({
    currencies: state.currency.currencies,
    isFetchingCurrencies: isLoading(state, 'currencies'),
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            fetchCurrencies,
        },
        dispatch,
    );

export default connect(mapStateToProps, mapDispatchToProps)(CurrencySelectField);
