const proprietaryCode = {
    am01: 'Zero Amount',
    am02: 'Not allowed amount',
    am04: 'There are insufficient funds on the account',
    ac01: 'Incorrect account number or it does not exist',
    ac04: 'The account is closed',
    ac06: 'The account is blocked; the reason is not specified',
    ag01: 'For this type of account credit transfers are forbidden',
    ag02: 'Invalid transaction code or invalid file format',
    be04: 'Missing Creditor Address',
    focr: 'Following Cancellation Request',
    md07: 'End Customer Deceased',
    ms02: 'Not Specified Reason Customer Generated',
    ms03: 'Not Specified Reason Agent Generated',
    rc01: 'Bank Identifier Incorrect',
    rr01: 'Regulatory reason. The Debtor account or identification data is not available',
    rr02: 'Regulatory reason. There is no full name or address of the debtor',
    rr03: 'Regulatory reason. There is no full name or address of the creditor',
    rr04: 'Regulatory reason',
    b00: 'FFCCTRNS/PRTRN/FFPCRQST/ROINVSTG is totally accepted',
    b01: 'FFCCTRNS/PRTRN/FFPCRQST/ROINVSTG is partially accepted',
    b03: 'FFCCTRNS/PRTRN/FFPCRQST/ROINVSTG is totally rejected,\
        as the calculated number of transactions does not match the value declared in the GrpHr',
    b05: 'FFCCTRNS/PRTRN istotally rejected, as the total calculated amount from the fields\
        ‘Interbank Settled Amount’ or ‘Returned Settlement amount’ of each transaction\
        does not match the TtlIntrBkSttlmAmt or TtlRtrdIntrBkSttlmAmt',
    b09: 'FFCCTRNS/PRTRN/FFPCRQST/ROINVSTG is totally rejected, as all transactions within the bulk are invalid',
    b10: 'FFCCTRNS/PRTRN is totally rejected, as the BIC of the Instructing Agent, present at the group header level,\
        does not match the Msg/Sender BIC, or the group header does not indicate the Instructing Agent BIC,\
        or it is indicated in an invalid format (only BIC consisting of 8 digits can be indicated).\
        The Instructing Agent BIC in the directory of participants must also be active on the settlement date',
    b11: 'FFCCTRNS/PRTRN is totally rejected, as the BIC of Instructed Agent is present at the group header level',
    b12: 'FFPCRQST/ROINVSTG is totally rejected because Assgnr/Assgne does not have the correct value',
    b13: 'FFPCRQST/ROINVSTG is totally rejected because TtlIntrBkSttlmAmt = 0',
    b14: 'FFCCTRNS/PRTRN/FFPCRQST/ROINVSTG is totally rejected, because MsgId is duplicated',
    b15: 'FFCCTRNS/PRTRN is totally rejected because the interbank settlement date (IntrBkSttlmDt)\
        is not in the allowed range (not current or next TARGET2 business day)',
    b23: 'FFCCTRNS/PRTRN is totally rejected because of too many consecutive rejected transactions',
    b24: 'These errors can only be received by a direct participant of STEP2\
        who uses the STEP2 participant connecting workstation to cancel the entire bulk',
    b30: 'These errors can only be received by a direct participant of STEP2\
        who uses the STEP2 participant connecting workstation to cancel the entire bulk',
    legl: 'For legal reasons, specifying the reason in clear text',
    cust: 'Refusal by creditor to return',
    noas: 'No answer is received from the Creditor',
    noor: 'The funds were not received',
    ardt: 'The funds have already been returned',
    am05: 'Duplicate',
    dt01: 'Invalid date',
    ed05: 'Settlement failed',
    py01: 'Unknown BIC in the directory of participants',
    xd19: 'Invalid IBAN format (not compliant with ISO 13616 format)',
    xt13: 'Unsupported XML field. The Prtry format is as follows: [CODE][1 space][XML tag not supported]',
    xt33: 'Invalid data format. The Prtry value format is as follows: [CODE][1 space][Incorrect XML tag]',
    xt73: 'Invalid country code (is not on the list of the codes of SWIFT countries)',
    xt74: 'Invalid original transaction status; action required',
    xt75: 'Invalid original transaction status\
        (original CT message was not processed in Bulk Processing Mode, was rejected); no action required',
    xt77: 'Original Amount mismatch (in the R-message)',
    xt81: 'Only SEPA fields are allowed',
    xt85: 'No settlement cycle available on the current business date for both Debtor and Creditor agents',
    xt86: 'Payment cancellation request rejected due to expired time limit for the credit transfer cancellation',
    xt87: 'R-msg transaction not following the same DP route /\
        sending DP not identical to the Instructing /\
        Instructed Agent of the original transaction',
    xt90: 'Invalid use of technical BIC',
    xt91: 'The country code in the CreditorAgentBIC is one of the following:‘PF’, ‘NC’ or ‘WF’,\
        while the country code in the DebtorAgentBIC is not one of the following:\
        ‘FR’, ‘GP’, ‘RE’, ‘MQ’, ‘GF’, ‘PM’, ‘PF’, ‘NC’, ‘WF’, ‘BL’, ‘MF’ or ‘YT’',
    cancl: 'Request for cancellation of the credit transfer received prior to its execution.The credit transfer was not settled',
    rcll: 'Request for cancellation of the credit transfer received after its execution. \
        The credit transfer was settled and the request for its cancellation has been transferred to the Creditor Agent',
};

const successfulCodes = ['b00'];

/**
 * Given a proprietary code returns if the status is successful
 *
 * @param {string} key - proprietary code
 */
export const isProprietaryCodeSuccessful = (key) => successfulCodes.includes(key.toLowerCase());

/**
 * Given a proprietary code returns the reason for it or null
 * if the key is not in the list.
 *
 * @param {string} key - proprietary code
 * @returns {string | null} - reason for code
 */
export const getProprietaryCode = (key) => {
    const formattedKey = key.toLowerCase();

    if (!hasProprietaryCode(formattedKey)) {
        return null;
    }

    return proprietaryCode[formattedKey];
};

/**
 * Given a proprietary code returns true or false
 * if the reason for code exists.
 *
 * @param {string} key - proprietary code
 */
const hasProprietaryCode = (key) => !!proprietaryCode[key];
