import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import { Button, Icon, Form } from 'tabler-react';
import { createApiClient as api } from '../../../services/api-client';
import ApiError from '../../Common/ApiError';
import AmountField from '../../Common/FilterFormFields/AmountField';

const CancellationRequestModal = ({ visible, onCloseModal, onAcceptCancellation, selectedTransaction }) => {
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [formInputs, setFormInputs] = useState({
        return_amount: '',
    });

    const resetFormInputs = () => {
        const inputKeys = Object.keys(formInputs);

        const newFormValues = inputKeys.map((key) => ({ [key]: '' }));
        setFormInputs(newFormValues);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!selectedTransaction) {
            return;
        }

        setLoading(true);

        try {
            await api().put(`admin-sepa-integration/cancellation-requests/${selectedTransaction.id}/accept`, {
                ...formInputs,
            });

            onAcceptCancellation();
            resetFormInputs();
            onCloseModal();
        } catch (error) {
            setError(error.data);
        } finally {
            setLoading(false);
        }
    };

    const onInputChange = (event) => {
        const { target } = event;

        setFormInputs((prev) => ({
            ...prev,
            [target.name]: target.value,
        }));
    };

    if (!selectedTransaction) {
        return null;
    }

    return (
        <Modal isOpen={visible} className='react-modal'>
            <Icon name='x-circle' className='w-4 react-modal__closeButton' onClick={onCloseModal} />
            <div className='react-modal__content'>
                <form onSubmit={handleSubmit}>
                    <ApiError error={error} />

                    <Form.Input
                        name='initialAmount'
                        label='Initial transaction amount'
                        value={selectedTransaction.original_amount}
                        disabled
                    />
                    <AmountField
                        name='return_amount'
                        title='Amount to return'
                        value={formInputs.return_amount}
                        handleChange={onInputChange}
                    />

                    <div className='buttonContainer'>
                        <Button onClick={onCloseModal} color='secondary' disabled={loading} loading={loading}>
                            Cancel
                        </Button>

                        <Button type='submit' color='primary' onClick={handleSubmit} disabled={loading} loading={loading}>
                            Accept
                        </Button>
                    </div>
                </form>
            </div>
        </Modal>
    );
};

CancellationRequestModal.propTypes = {
    visible: PropTypes.bool.isRequired,
    onCloseModal: PropTypes.func.isRequired,
    onAcceptCancellation: PropTypes.func.isRequired,
    selectedTransaction: PropTypes.object,
};

export default CancellationRequestModal;
