import React from 'react';
import PropTypes from 'prop-types';
import SidebarNavigation from '../../../../Common/SidebarNavigation';
import AdminInformation from './AdminInformation';

const Sidebar = ({ adminUser, components, currentComponent, handleComponentChange, adminFullName, adminRoles }) => (
    <>
        <AdminInformation adminUser={adminUser} adminFullName={adminFullName} adminRoles={adminRoles}/>
        <SidebarNavigation components={components} currentComponent={currentComponent} handleComponentChange={handleComponentChange} />
    </>
);

Sidebar.propTypes = {
    adminUser: PropTypes.object.isRequired,
    components: PropTypes.object.isRequired,
    currentComponent: PropTypes.string.isRequired,
    handleComponentChange: PropTypes.func.isRequired,
    adminFullName: PropTypes.string.isRequired,
    adminRoles: PropTypes.string.isRequired,
};

export default Sidebar;
