import React, { Component } from 'react';
import Dropzone from '../Dropzone/Dropzone';
import './Upload.scss';
import Progress from '../Progress/Progress';
import PropTypes from 'prop-types';
import { createApiClient as api } from '../../../../services/api-client';
import isUndefined from 'lodash/isUndefined';
import { Button, Icon } from 'tabler-react';
import ApiError from '../../ApiError';

class Upload extends Component {
    constructor (props) {
        super(props);

        this.state = {
            files: [],
            uploading: false,
            uploadProgress: {},
            uploadErrors: {},
            successfullyUploaded: false,
        };

        this.onFilesAdded = this.onFilesAdded.bind(this);
        this.uploadFiles = this.uploadFiles.bind(this);
        this.sendRequest = this.sendRequest.bind(this);
        this.renderActions = this.renderActions.bind(this);
    }

    onFilesAdded (files) {
        this.setState((prevState) => ({
            files: prevState.files.concat(files),
        }));
    }

    async uploadFiles () {
        const { onComplete } = this.props;
        const { files } = this.state;

        this.setState({
            uploadProgress: {},
            uploading: true,
        });

        await this.asyncForEach(files, async (file) => {
            await this.sendRequest(file);
        });

        this.setState({
            successfullyUploaded: true,
            uploading: false,
        });

        if (!isUndefined(onComplete)) {
            onComplete();
        }
    }

    async sendRequest (file) {
        const { url, fileParam, params } = this.props;

        return new Promise((resolve, reject) => {
            const formData = new FormData();
            formData.append(fileParam, file, file.name);

            if (!isUndefined(params)) {
                Object.keys(params).forEach((key) => formData.append(key, params[key]));
            }

            api()
                .post(url, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                    onUploadProgress: function (event) {
                        const uploadPercentage = (event.loaded / event.total) * 100;

                        this.setState((prevState) => ({
                            uploadProgress: {
                                ...prevState.uploadProgress,
                                [file.name]: {
                                    state: 'pending',
                                    percentage: uploadPercentage,
                                },
                            },
                        }));
                    }.bind(this),
                })
                .then((data) => {
                    this.setState((prevState) => ({
                        uploadProgress: {
                            ...prevState.uploadProgress,
                            [file.name]: {
                                state: 'done',
                                percentage: 100,
                            },
                        },
                    }));

                    resolve(data.data);
                })
                .catch((data) => {
                    this.setState((prevState) => ({
                        uploadProgress: {
                            ...prevState.uploadProgress,
                            [file.name]: {
                                state: 'error',
                                percentage: 0,
                            },
                        },
                        uploadErrors: {
                            ...prevState.uploadErrors,
                            [file.name]: data.data,
                        },
                    }));

                    reject(data.data);
                });
        });
    }

    renderProgress (file) {
        const { uploading, successfullyUploaded } = this.state;
        const uploadProgress = this.state.uploadProgress[file.name];
        const uploadErrors = this.state.uploadErrors[file.name];

        if ((uploading || successfullyUploaded) && !isUndefined(uploadProgress)) {
            return (
                <div className='ProgressWrapper'>
                    {isUndefined(uploadErrors) ? (
                        <Progress progress={uploadProgress ? uploadProgress.percentage : 0} />
                    ) : (
                        <ApiError error={uploadErrors} />
                    )}
                </div>
            );
        }
    }

    renderActions () {
        const { uploading, successfullyUploaded, files } = this.state;
        const { onClose } = this.props;

        return (
            <div className='actions'>
                <Button.List>
                    {!successfullyUploaded && (
                        <Button
                            onClick={this.uploadFiles}
                            size='sm'
                            icon='upload'
                            color='primary'
                            disabled={files.length <= 0 || uploading}
                            loading={uploading}
                        >
                            Upload
                        </Button>
                    )}

                    <Button onClick={onClose} size='sm' icon='x-circle' color='primary'>
                        Close
                    </Button>
                </Button.List>
            </div>
        );
    }

    async asyncForEach (array, callback) {
        for (let index = 0; index < array.length; index += 1) {
            await callback(array[index], index, array);
        }
    }

    render () {
        const { uploading, successfullyUploaded, files } = this.state;

        return (
            <div className='upload'>
                <div className='content'>
                    {!successfullyUploaded && <Dropzone onFilesAdded={this.onFilesAdded} disabled={uploading} />}
                    {files.length > 0 && (
                        <div className='files'>
                            {files.map((file) => (
                                <div key={file.name} className='file-wrapper'>
                                    <span className='filename'>
                                        <Icon name='file' className='mr-1' />
                                        {file.name}
                                    </span>
                                    {this.renderProgress(file)}
                                </div>
                            ))}
                        </div>
                    )}
                </div>
                {this.renderActions()}
            </div>
        );
    }
}

Upload.propTypes = {
    url: PropTypes.string.isRequired,
    fileParam: PropTypes.string.isRequired,
    params: PropTypes.object,
    onClose: PropTypes.func.isRequired,
    onComplete: PropTypes.func,
};

export default Upload;
