import React from 'react';
import { Form } from 'tabler-react';
import PropTypes from 'prop-types';
import {
    INTERNAL_TRANSFER,
    EXTERNAL_TRANSFER,
    REFUND,
    CHARGEBACK,
    DEPOSIT,
    CARD_PAYMENT,
} from '../../../../../constants';

const TransactionType = ({ value, onChange }) => (
    <Form.Group label='Transaction type'>
        <Form.Radio
            checked={value === INTERNAL_TRANSFER}
            isInline
            label='Internal'
            name='transaction_type'
            value={INTERNAL_TRANSFER}
            onChange={onChange}
        />
        <Form.Radio
            checked={value === EXTERNAL_TRANSFER}
            isInline
            label='External'
            name='transaction_type'
            value={EXTERNAL_TRANSFER}
            onChange={onChange}
        />
        <Form.Radio
            checked={value === DEPOSIT}
            isInline
            label='Deposit'
            name='transaction_type'
            value={DEPOSIT}
            onChange={onChange}
        />
        <Form.Radio
            checked={value === REFUND}
            isInline
            label='Refund'
            name='transaction_type'
            value={REFUND}
            onChange={onChange}
        />
        <Form.Radio
            checked={value === CHARGEBACK}
            isInline
            label='Chargeback'
            name='transaction_type'
            value={CHARGEBACK}
            onChange={onChange}
        />
        <Form.Radio
            checked={value === CARD_PAYMENT}
            isInline
            label='Card payment'
            name='transaction_type'
            value={CARD_PAYMENT}
            onChange={onChange}
        />
    </Form.Group>
);

TransactionType.propTypes = {
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string,
};

export default TransactionType;
