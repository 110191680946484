import { createAction } from 'redux-actions';
import isEmpty from 'lodash/isEmpty';

export const FETCH_MASTER_ACCOUNT_BALANCES_REQUEST = 'balances/FETCH_MASTER_ACCOUNT_BALANCES_REQUEST';
export const FETCH_MASTER_ACCOUNT_BALANCES_SUCCESS = 'balances/FETCH_MASTER_ACCOUNT_BALANCES_SUCCESS';
export const FETCH_MASTER_ACCOUNT_BALANCES_FAILURE = 'balances/FETCH_MASTER_ACCOUNT_BALANCES_FAILURE';

const fetchMasterAccountBalancesRequest = createAction(FETCH_MASTER_ACCOUNT_BALANCES_REQUEST);
const fetchMasterAccountBalancesSuccess = createAction(FETCH_MASTER_ACCOUNT_BALANCES_SUCCESS);
const fetchMasterAccountBalancesFailure = createAction(FETCH_MASTER_ACCOUNT_BALANCES_FAILURE);

export const fetchMasterAccountBalances = () => (dispatch, getState, { api }) =>
    new Promise(async (resolve) => {
        try {
            if (!isEmpty(getState().balances.masterAccount)) {
                resolve(getState().balances.masterAccount);

                return;
            }

            dispatch(fetchMasterAccountBalancesRequest());

            const { data } = await api.get('admin-transaction/provider-balances');

            dispatch(fetchMasterAccountBalancesSuccess(data));
            resolve(data);
        } catch (response) {
            dispatch(fetchMasterAccountBalancesFailure());
        }
    });
