import React, { useCallback, useState } from 'react';
import Modal from 'react-modal';
import PropTypes from 'prop-types';
import { Button, Icon } from 'tabler-react';
import InputTextField from '../../../../../../../Common/FilterFormFields/InputTextField';
import { DOCUMENT_STATUS } from '../../../../../../../../constants';

const RejectDocumentModal = ({ isModalOpen, closeModal, rejectDocument, rejectReason, rejectedBy, isDocumentAcceptedOrCreated }) => {
    const [reason, setReason] = useState('');

    const handleDeclineReasonChange = useCallback((event) => setReason(event.target.value), []);

    const renderRejectModalContent = () => {
        if (isDocumentAcceptedOrCreated) {
            return (
                <>
                    <InputTextField
                        name='reason'
                        title='Please input reason for declining transaction:'
                        value={reason}
                        handleChange={handleDeclineReasonChange}
                    />
                    <Button block color='danger' onClick={handleDocumentReject}>
                        Reject
                    </Button>
                </>
            );
        }

        return (
            <>
                <h1 className='text-danger'>Document reject reason</h1>
                <h5 className='pb-5'>Rejected by: {rejectedBy}</h5>
                <p>{rejectReason}</p>
            </>
        );
    };

    const handleDocumentReject = useCallback(() => {
        rejectDocument(DOCUMENT_STATUS.rejected, reason)();
        closeModal();
    }, [closeModal, reason, rejectDocument]);

    return (
        <Modal isOpen={isModalOpen} className='react-modal'>
            <Icon name='x-circle' className='w-4 react-modal__closeButton' onClick={closeModal} />

            <div className='react-modal__content text-center'>{renderRejectModalContent()}</div>
        </Modal>
    );
};

RejectDocumentModal.propTypes = {
    isModalOpen: PropTypes.bool.isRequired,
    closeModal: PropTypes.func.isRequired,
    rejectDocument: PropTypes.func.isRequired,
    rejectReason: PropTypes.string,
    rejectedBy: PropTypes.string,
    isDocumentAcceptedOrCreated: PropTypes.bool.isRequired,
};

export default RejectDocumentModal;
