import React, { Component } from 'react';
import { Alert, Card, Grid } from 'tabler-react';
import ReactTable from 'react-table';
import PropTypes from 'prop-types';

class TableBlock extends Component {
    render () {
        const { items, columns, isLoading, alert } = this.props;

        return (
            <>
                {alert !== '' && <Alert type='primary'>{alert}</Alert>}

                <Grid.Row>
                    <Grid.Col>
                        <Card>
                            <Card.Body>
                                <ReactTable
                                    manual
                                    data={items}
                                    columns={columns}
                                    className='-striped -highlight'
                                    multiSort={false}
                                    minRows={1}
                                    showPagination={false}
                                    loading={isLoading}
                                />
                            </Card.Body>
                        </Card>
                    </Grid.Col>
                </Grid.Row>
            </>
        );
    }
}

TableBlock.propTypes = {
    items: PropTypes.array,
    columns: PropTypes.array.isRequired,
    isLoading: PropTypes.bool,
    alert: PropTypes.string,
};

TableBlock.defaultProps = {
    alert: '',
};

export default TableBlock;
