import React, { useState, useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { fetchDocumentTypes } from '../../../../../actions/document-type/document-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Dimmer, Header } from 'tabler-react';
import { isLoading } from '../../../../../selectors/isLoading';
import { createApiClient } from '../../../../../services/api-client';
import groupBy from 'lodash/groupBy';
import DocumentType from './DocumentType/DocumentType';
import ConfirmButton from './ConfirmButton';
import { DOCUMENT_TYPES } from '../../../../../constants';

const Documents = ({
    user,
    documentTypes,
    fetchDocumentTypes,
    match: {
        params: { id },
    },
}) => {
    const [documentState, setDocumentState] = useState({
        mandatory: {
            documents: {},
            requestedDocumentTypes: [],
            documentVerificationStatus: null,
            documentVerificationId: null,
            documentVerificationType: '',
        },
        additional: {
            documents: {},
            requestedDocumentTypes: [],
            documentVerificationStatus: null,
            documentVerificationId: null,
            documentVerificationType: '',
        },
    });

    const [isLoading, setLoading] = useState({
        additional: true,
        mandatory: true,
    });

    const api = useMemo(() => createApiClient(), []);

    const renderToastError = (error) => toast.error(`Something went wrong: ${error}`);

    const fetchUserDocuments = useCallback(
        (documentVerification) => async () => {
            setLoading((prevLoading) => ({ ...prevLoading, [documentVerification.type]: true }));

            try {
                const response = await api.get(
                    `admin-user-document-verification/user-document-verifications/${documentVerification.id}/user-documents`,
                    {
                        type: documentVerification.type,
                    },
                );
                const documents = groupBy(response.data, (document) => document.document_type_id);

                setDocumentState((prevDocumentState) => ({
                    ...prevDocumentState,
                    [documentVerification.type]: {
                        ...prevDocumentState[documentVerification.type],
                        documents,
                    },
                }));
            } catch (error) {
                renderToastError(error.data.message);
            } finally {
                setLoading((prevLoading) => ({ ...prevLoading, [documentVerification.type]: false }));
            }
        },
        [api],
    );

    const fetchRequestedUserDocumentTypes = useCallback(
        async (documentVerification) => {
            setLoading((prevLoading) => ({ ...prevLoading, [documentVerification.type]: true }));

            try {
                const response = await api.get(`admin-user-document-verification/users/${id}/document-type-requests`);

                setDocumentState((prevDocumentState) => ({
                    ...prevDocumentState,
                    [documentVerification.type]: {
                        ...prevDocumentState[documentVerification.type],
                        requestedDocumentTypes: response.data,
                    },
                }));
            } catch (error) {
                renderToastError(error.data.message);
            } finally {
                setLoading((prevLoading) => ({ ...prevLoading, [documentVerification.type]: false }));
            }
        },
        [api, id],
    );

    const fetchUserDocumentVerification = useCallback(
        async (isLoadingBothTypes = true, documentVerificationType) => {
            if (isLoadingBothTypes) {
                setLoading({ additional: true, mandatory: true });
            } else {
                setLoading((prevLoading) => ({ ...prevLoading, [documentVerificationType]: true }));
            }

            try {
                const { data } = await api.get(`admin-user-document-verification/users/${id}/user-document-verifications`);
                const promiseWrapper = [];

                data.forEach((documentVerification) => {
                    promiseWrapper.push(fetchUserDocuments(documentVerification)());
                    promiseWrapper.push(fetchRequestedUserDocumentTypes(documentVerification));

                    setDocumentState((prevDocumentState) => ({
                        ...prevDocumentState,
                        [documentVerification.type]: {
                            ...prevDocumentState[documentVerification.type],
                            documentVerificationStatus: documentVerification.status,
                            documentVerificationId: documentVerification.id,
                            documentVerificationType: documentVerification.type,
                        },
                    }));
                });

                await Promise.all(promiseWrapper);
            } catch (error) {
                renderToastError(error.data.message);
            } finally {
                if (isLoadingBothTypes) {
                    setLoading({ additional: false, mandatory: false });
                } else {
                    setLoading((prevLoading) => ({ ...prevLoading, [documentVerificationType]: false }));
                }
            }
        },
        [api, fetchRequestedUserDocumentTypes, fetchUserDocuments, id],
    );

    const renderDocumentTypes = (documentVerificationType) =>
        documentState[documentVerificationType].documentVerificationStatus !== null &&
        documentTypes
            .filter((documentType) => documentType.document_type === documentVerificationType)
            .map((documentType) => (
                <DocumentType
                    userId={id}
                    key={documentType.id}
                    documentType={documentType}
                    documents={documentState[documentVerificationType].documents}
                    requestedDocumentTypes={documentState[documentVerificationType].requestedDocumentTypes}
                    updateDocuments={fetchUserDocuments({
                        id: documentState[documentVerificationType].documentVerificationId,
                        type: documentState[documentVerificationType].documentVerificationType,
                    })}
                    documentVerificationStatus={documentState[documentVerificationType].documentVerificationStatus}
                    documentVerificationId={documentState[documentVerificationType].documentVerificationId}
                    updateDocumentVerificationStatus={fetchUserDocumentVerification}
                />
            ));

    const renderDocuments = (documentVerificationType) => (
        <>
            <Header.H3 className='d-flex'>
                {documentVerificationType === DOCUMENT_TYPES.mandatory ? 'Documents' : 'Additional documents'}
                <ConfirmButton
                    userId={id}
                    documentVerificationStatus={documentState[documentVerificationType].documentVerificationStatus}
                    updateDocumentVerificationStatus={fetchUserDocumentVerification}
                    parentLoader={isLoading[documentVerificationType]}
                    documentsType={documentVerificationType}
                    documentVerificationId={documentState[documentVerificationType].documentVerificationId}
                />
            </Header.H3>
            {renderDocumentTypes(documentVerificationType)}
        </>
    );

    useEffect(() => {
        fetchDocumentTypes({
            user_type: user.type,
        });
        fetchUserDocumentVerification();
    }, [fetchDocumentTypes, fetchUserDocumentVerification, user.type]);

    return (
        <Dimmer loader active={isLoading.additional || isLoading.mandatory}>
            {renderDocuments(DOCUMENT_TYPES.mandatory)}
            {/*{renderDocuments(DOCUMENT_TYPES.additional)}*/}
        </Dimmer>
    );
};

Documents.propTypes = {
    match: PropTypes.object,
    fetchDocumentTypes: PropTypes.func.isRequired,
    documentTypes: PropTypes.array.isRequired,
    isFetchingDocumentTypes: PropTypes.bool.isRequired,
    user: PropTypes.object.isRequired,
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            fetchDocumentTypes,
        },
        dispatch,
    );

const mapStateToProps = (state) => ({
    documentTypes: state.documentType.documentTypes,
    isFetchingDocumentTypes: isLoading(state, 'documentTypes'),
});

export default connect(mapStateToProps, mapDispatchToProps)(Documents);
