import React, { useMemo, useState } from 'react';
import SiteWrapper from '../../SiteWrapper';
import PaginatedFilteredList from '../../Common/PaginatedFilteredList';
import { Link } from 'react-router-dom';

const bankAccountsURI = '/admin-bank-account/bank-accounts/paginated';

const BankAccounts = () => {
    const [items, setItems] = useState([]);

    const updateItems = (items) => {
        setItems(items);
    };

    const columns = useMemo(
        () => [
            {
                Header: 'Bank name',
                accessor: 'bank',
                sortable: false,
                Cell: (row) => (<span>{row.original.bank.name}</span>),
            },
            {
                Header: 'Account Number',
                accessor: 'account',
                sortable: false,
                Cell: (row) => (
                    <Link to={{ pathname: `/mano-bank/bank-accounts/${row.original.account.bank_account_id}/statement` }}>
                        {row.original.account.account}
                    </Link>
                ),
            },
            {
                Header: 'Status',
                accessor: 'account',
                sortable: false,
                Cell: (row) => (<span>{row.original.account.status}</span>),
            },
        ],
        [],
    );

    return (
        <SiteWrapper title='Bank Accounts'>
            <PaginatedFilteredList
                uri={bankAccountsURI}
                columns={columns}
                updateItems={updateItems}
                items={items}
            />
        </SiteWrapper>
    );
};

export default BankAccounts;
