import React, { useCallback, useEffect, useMemo, useState } from 'react';
import SiteWrapper from '../../../SiteWrapper';
import { Dimmer, Grid } from 'tabler-react';
import AdminAudit from './AdminAudit';
import Sidebar from './AdminUser/Sidebar';
import Actions from './AdminUser/Actions';
import { bindActionCreators } from 'redux';
import { navigate } from '../../../../actions/navigate';
import { connect } from 'react-redux';
import { createApiClient } from '../../../../services/api-client';
import ApiError from '../../../Common/ApiError';
import { capitalize } from '../../../../helpers';

const AdminUser = ({ currentAdminId, navigate, match }) => {
    const [isLoading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [adminUser, setAdminUser] = useState({
        first_name: '',
        last_name: '',
        email: '',
        id: null,
        permission_groups: [],
    });
    const [currentComponent, setCurrentComponent] = useState('actions');

    const api = useMemo(() => createApiClient(), []);

    const userAdminId = match.params.id;

    const sidebarNavigationComponents = {
        actions: {
            title: 'Actions',
            icon: 'user-check',
        },
        audit: {
            title: 'Audit',
            icon: 'grid',
        },
    };

    const handleComponentChange = useCallback((component) => () => setCurrentComponent(component), []);

    const renderAdminUserComponents = () => {
        switch (currentComponent) {
            case 'actions':
                return <Actions adminUser={adminUser} navigate={navigate} currentAdminId={currentAdminId} />;
            case 'audit':
                return <AdminAudit adminId={adminUser.id} />;
            default:
                return <Actions adminUser={adminUser} navigate={navigate} currentAdminId={currentAdminId} />;
        }
    };

    const adminFullName = `${capitalize(adminUser.first_name)} ${capitalize(adminUser.last_name)}`;

    const adminRoles = useMemo(() => {
        const rolesArray = [];

        adminUser.permission_groups.forEach((permissionGroup) => {
            rolesArray.push(permissionGroup.name);
        });

        return rolesArray.join(', ');
    }, [adminUser]);

    const fetchUserAdminInformation = useCallback(async () => {
        if (userAdminId === undefined) {
            return;
        }

        setLoading(true);

        try {
            const { data } = await api.get(`admin-user/admins/${userAdminId}`);

            setAdminUser({ ...data });
        } catch (error) {
            setError(error.data);
        } finally {
            setLoading(false);
        }
    }, [api, userAdminId]);

    useEffect(() => {
        fetchUserAdminInformation();
    }, [fetchUserAdminInformation]);

    return (
        <SiteWrapper title={isLoading ? 'Loading' : adminFullName}>
            <ApiError error={error} />
            <Dimmer active={isLoading} loader>
                <Grid.Row>
                    <Grid.Col md={3}>
                        <Sidebar
                            adminUser={adminUser}
                            components={sidebarNavigationComponents}
                            currentComponent={currentComponent}
                            handleComponentChange={handleComponentChange}
                            adminFullName={adminFullName}
                            adminRoles={adminRoles}
                        />
                    </Grid.Col>
                    <Grid.Col md={9}>{renderAdminUserComponents()}</Grid.Col>
                </Grid.Row>
            </Dimmer>
        </SiteWrapper>
    );
};

const mapStateToProps = (state) => ({
    currentAdminId: state.admin.information.id,
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            navigate,
        },
        dispatch,
    );

export default connect(mapStateToProps, mapDispatchToProps)(AdminUser);
