import React, { useCallback, useState, useMemo } from 'react';
import { Button } from 'tabler-react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { createApiClient } from '../../../../../../services/api-client';

const ResetTwoFAButton = ({ adminId, adminEmail, size, color, title }) => {
    const [isLoading, setLoading] = useState(false);

    const api = useMemo(() => createApiClient(), []);

    const reset2FA = useCallback(async () => {
        if (!confirm(`Are you sure you want to reset 2FA of ${adminEmail}?`)) {
            return false;
        }

        setLoading(true);

        try {
            await api.delete(`admin-authentication/admins/${adminId}/authentication`);

            toast.success(`You have successfully reset 2FA of ${adminEmail}`);
        } catch (error) {
            toast.error(error.data.message);
        } finally {
            setLoading(false);
        }
    }, [adminEmail, adminId, api]);

    return (
        <Button size={size} onClick={reset2FA} color={color} loading={isLoading} className='w-100'>
            {title}
        </Button>
    );
};

ResetTwoFAButton.propTypes = {
    adminId: PropTypes.number,
    adminEmail: PropTypes.string.isRequired,
    size: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
};

export default ResetTwoFAButton;
