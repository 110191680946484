import React, { useState, useEffect, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Icon, Button } from 'tabler-react';
import Modal from 'react-modal';
import { toast } from 'react-toastify';
import { createApiClient as api } from '../../../../../services/api-client';
import ApiError from '../../../../Common/ApiError';
import CurrencySelectField from '../../../../Common/FilterFormFields/CurrencySelectField';
import InputTextField from '../../../../Common/FilterFormFields/InputTextField';

const LimitsModal = ({
    wallet,
    user,
    modalIsOpen,
    onModalClose,
    defaultWalletLimits,
}) => {
    const [limitData, setLimitData] = useState({
        currency: '',
        daily_limit: 0,
        weekly_limit: 0,
        monthly_limit: 0,
    });
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const defaultUserWalletLimits =
        defaultWalletLimits?.length > 0
        && defaultWalletLimits.find((item) => item.user_type === user.type);

    const handleClose = () => {
        setError(null);

        onModalClose();
    };

    const formIsValid = useMemo(() =>
        limitData.currency
        && limitData.daily_limit
        && limitData.weekly_limit
        && limitData.monthly_limit
    , [
        limitData.currency,
        limitData.daily_limit,
        limitData.weekly_limit,
        limitData.monthly_limit,
    ]);

    const fetchWalletLimits = async () => {
        try {
            setLoading(true);

            const { data: limits } = await api().get(`/admin-wallet-limit/wallets/${wallet.id}/wallet-limits`);

            if (limits && limits.length > 0) {
                setLimitData(
                    limits.find((item) => item.currency === (limitData.currency || 'eur'))
                );
            } else if (defaultUserWalletLimits) {
                setLimitData(defaultUserWalletLimits);
            }
        } catch (error) {
            setError(error.data);
        } finally {
            setLoading(false);
        }
    };

    const updateLimit = async (limitId) => {
        try {
            setLoading(true);

            await api().put(`/admin-wallet-limit/wallet-limits/${limitId}`, {
                daily_limit: parseFloat(limitData.daily_limit).toFixed(2),
                weekly_limit: parseFloat(limitData.weekly_limit).toFixed(2),
                monthly_limit: parseFloat(limitData.monthly_limit).toFixed(2),
            });

            toast.success('Limits have been changed');
            handleClose();
        } catch (error) {
            setError(error.data);
        } finally {
            setLoading(false);
        }
    };

    const createLimit = async () => {
        try {
            setLoading(true);

            await api().post(`/admin-wallet-limit/wallets/${wallet.id}/wallet-limits`, {
                currency: limitData.currency,
                daily_limit: parseFloat(limitData.daily_limit).toFixed(2),
                weekly_limit: parseFloat(limitData.weekly_limit).toFixed(2),
                monthly_limit: parseFloat(limitData.monthly_limit).toFixed(2),
            });

            toast.success('Limits have been changed');
            handleClose();
        } catch (error) {
            setError(error.data);
        } finally {
            setLoading(false);
        }
    };

    const handleApplyClick = useCallback(() => {
        if (limitData.id && !limitData.user_type) {
            updateLimit(limitData.id);
        } else {
            createLimit();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [limitData]);

    useEffect(() => {
        if (modalIsOpen) {
            fetchWalletLimits();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [modalIsOpen]);

    const onInputChange = (event) => {
        const { target } = event;

        setLimitData((prev) => ({
            ...prev,
            [target.name]: target.value,
        }));
    };

    return (
        <Modal isOpen={modalIsOpen} className='react-modal'>
            <Icon name='x-circle' className='w-4 react-modal__closeButton' onClick={handleClose} />

            <div className='react-modal__content'>
                <ApiError error={error} />
                <h2 className='text-center'>Edit wallet limits</h2>

                <h5>Wallet number: {wallet.number}</h5>
                {
                    wallet.iban !== undefined && (
                        <h5 className='pb-4'>IBAN: {wallet.iban}</h5>
                    )
                }

                <CurrencySelectField value={limitData.currency} onChange={onInputChange} />

                <InputTextField
                    name='daily_limit'
                    title='Daily:'
                    value={limitData.daily_limit}
                    handleChange={onInputChange}
                />
                <InputTextField
                    name='weekly_limit'
                    title='Weekly:'
                    value={limitData.weekly_limit}
                    handleChange={onInputChange}
                />
                <InputTextField
                    name='monthly_limit'
                    title='Monthly:'
                    value={limitData.monthly_limit}
                    handleChange={onInputChange}
                />
                <div className='buttonContainer'>
                    <Button onClick={handleClose} color='secondary' disabled={loading} loading={loading}>
                        Cancel
                    </Button>

                    <Button onClick={handleApplyClick} type='submit' color='primary' disabled={!formIsValid || loading} loading={loading}>
                        Apply
                    </Button>
                </div>
            </div>
        </Modal>
    );
};

LimitsModal.propTypes = {
    user: PropTypes.object.isRequired,
    wallet: PropTypes.object.isRequired,
    modalIsOpen: PropTypes.bool.isRequired,
    onModalClose: PropTypes.func.isRequired,
    defaultWalletLimits: PropTypes.array.isRequired,
};

export default LimitsModal;
