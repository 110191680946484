import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Card, Dimmer, Table, Text, Button } from 'tabler-react';
import { capitalize, formatDateAndTime } from '../../../../../../helpers';
import { NAPIER_STATUS_COMPLETE } from '../../../../../../constants';
import CompletionNoteModal from './Napier/CompletionNoteModal';
import ExternalLink from '../../../../../Common/ExternalLink';

const Napier = ({
    userId,
    userHash,
    data,
    setData,
    error,
    loading,
}) => {
    const [isCompletionNoteModalOpen, setCompletionNoteModalOpen] = useState(false);
    const [napierCompletionError, setNapierCompletionError] = useState(null);

    const setNapierCompletionErrorCallback = (error) => setNapierCompletionError(error);

    const setCompletionNoteModalVisible = useCallback((value) => () => setCompletionNoteModalOpen(value), []);

    const updateNapierScoreCard = (data) => setData((prevScoreCardData) => ({ ...prevScoreCardData, ...data }));

    const napierCustomerUrl = useMemo(() => `https://napier.bigwallet.com/customers/system:${userHash}/details`, [userHash]);

    const renderCardAlert = () => (
        <Card.Alert color='secondary'>
            <Text.Small>{error}</Text.Small>
        </Card.Alert>
    );

    const renderCardError = () => (
        <Card.Alert color='danger'>
            <Text.Small>{napierCompletionError}</Text.Small>
        </Card.Alert>
    );

    return (
        <Card>
            <Dimmer active={loading} loader>
                <Card.Header>
                    <Card.Title>User scorecard</Card.Title>
                    <Card.Options>
                        {data.status !== NAPIER_STATUS_COMPLETE && error === null && (
                            <Button size='sm' color='success' onClick={setCompletionNoteModalVisible(true)}>
                                Complete
                            </Button>
                        )}
                    </Card.Options>
                </Card.Header>
                {error && renderCardAlert()}
                {napierCompletionError && renderCardError()}
                {!error && (
                    <Table className='small table-fixed' striped={true} cards={true}>
                        <Table.Body>
                            <Table.Row>
                                <Table.Col>Customer number</Table.Col>
                                <Table.Col>
                                    {data.scorecard_id !== null ? (
                                        <ExternalLink url={napierCustomerUrl}>{data.scorecard_id}</ExternalLink>
                                    ) : (
                                        '-'
                                    )}
                                </Table.Col>
                            </Table.Row>
                            <Table.Row>
                                <Table.Col>Status</Table.Col>
                                <Table.Col>{data.status !== null ? capitalize(data.status) : '-'}</Table.Col>
                            </Table.Row>
                            <Table.Row>
                                <Table.Col>Risk level</Table.Col>
                                <Table.Col>{data.risk_level !== null ? capitalize(data.risk_level) : '-'}</Table.Col>
                            </Table.Row>
                            <Table.Row>
                                <Table.Col>Risk score</Table.Col>
                                <Table.Col>{data.risk_score !== null ? data.risk_score : '-'}</Table.Col>
                            </Table.Row>
                            <Table.Row>
                                <Table.Col>Created at</Table.Col>
                                <Table.Col>{formatDateAndTime(data.created_at)}</Table.Col>
                            </Table.Row>
                            <Table.Row>
                                <Table.Col>Last sent at</Table.Col>
                                <Table.Col>{data.last_sent_at !== null ? formatDateAndTime(data.last_sent_at) : '-'}</Table.Col>
                            </Table.Row>
                        </Table.Body>
                        <CompletionNoteModal
                            userId={userId}
                            onModalClose={setCompletionNoteModalVisible(false)}
                            isModalOpen={isCompletionNoteModalOpen}
                            updateNapierScoreCard={updateNapierScoreCard}
                            setNapierCompletionError={setNapierCompletionErrorCallback}
                        />
                    </Table>
                )}
            </Dimmer>
        </Card>
    );
};

Napier.propTypes = {
    userId: PropTypes.number.isRequired,
    userHash: PropTypes.string.isRequired,
    data: PropTypes.object.isRequired,
    setData: PropTypes.func.isRequired,
    error: PropTypes.string,
    loading: PropTypes.bool.isRequired,
};

export default Napier;
