import React, { useState, useCallback } from 'react';
import pickBy from 'lodash/pickBy';
import { createApiClient as api } from '../../../services/api-client';
import Modal from 'react-modal';
import PropTypes from 'prop-types';
import { Button, Icon, Form } from 'tabler-react';
import InputTextField from '../../Common/FilterFormFields/InputTextField';
import { toast } from 'react-toastify';
import { transactionDeclineFilter } from '../../../services/input-filter';

const DeclineTransactionModal = ({ onModalClose, modalIsOpen, activeTransaction, updateTransaction }) => {
    const [inputValues, setInputValues] = useState({
        declineReasonForAdmin: '',
        declineReasonForUser: '',
    });
    const [loading, setLoading] = useState(false);
    const [isReasonForUserInputOpen, setDeclineReasonInputForUserOpen] = useState(false);

    const resetForm = useCallback(() => {
        setInputValues({ declineReasonForAdmin: '', declineReasonForUser: '' });
        setDeclineReasonInputForUserOpen(false);
    }, []);

    const handleModalClose = useCallback(() => {
        onModalClose();
        resetForm();
    }, [onModalClose, resetForm]);

    const declineTransaction = useCallback(async () => {
        setLoading(true);

        const params = {
            reason: inputValues.declineReasonForAdmin,
            reason_user: inputValues.declineReasonForUser,
        };

        try {
            const { data } = await api().put(`/admin-transaction/transactions/${activeTransaction.id}/decline`, pickBy(params));

            activeTransaction.status = data.status;
            activeTransaction.fail_reason = data.fail_reason;

            updateTransaction(activeTransaction);
        } catch (error) {
            toast.error(`Unable to decline user transaction: ${error.data.message}`);
        } finally {
            setLoading(false);
            handleModalClose();
        }
    }, [activeTransaction, handleModalClose, inputValues.declineReasonForAdmin, inputValues.declineReasonForUser, updateTransaction]);

    const handleDeclineReasonChange = (event) => {
        const {
            target: { name, value },
        } = event;

        setInputValues((prevInputValues) => ({ ...prevInputValues, [name]: value }));
    };

    const toggleUserDeclineReasonInput = useCallback(() => {
        setDeclineReasonInputForUserOpen((prevState) => !prevState);
    }, []);

    return (
        <Modal isOpen={modalIsOpen} className='react-modal'>
            <Icon name='x-circle' className='w-4 react-modal__closeButton' onClick={handleModalClose} />

            <div className='react-modal__content'>
                <InputTextField
                    name='declineReasonForAdmin'
                    title='Please input reason for declining transaction:'
                    value={inputValues.declineReasonForAdmin}
                    filter={[transactionDeclineFilter]}
                    handleChange={handleDeclineReasonChange}
                />
                <Form.Group>
                    <Form.Checkbox
                        checked={inputValues.declineReasonForAdmin.isReasonForUserInputVisible}
                        isInline
                        label='Reason for customer'
                        value={isReasonForUserInputOpen}
                        onChange={toggleUserDeclineReasonInput}
                    />
                </Form.Group>
                {isReasonForUserInputOpen && (
                    <InputTextField
                        name='declineReasonForUser'
                        title='Please input decline reason for customer:'
                        value={inputValues.declineReasonForUser}
                        filter={[transactionDeclineFilter]}
                        handleChange={handleDeclineReasonChange}
                    />
                )}
                <Button
                    block
                    color='danger'
                    onClick={declineTransaction}
                    disabled={!inputValues.declineReasonForAdmin || loading}
                    loading={loading}
                >
                    Decline
                </Button>
            </div>
        </Modal>
    );
};

DeclineTransactionModal.propTypes = {
    modalIsOpen: PropTypes.bool.isRequired,
    activeTransaction: PropTypes.object,
    onModalClose: PropTypes.func.isRequired,
    updateTransaction: PropTypes.func.isRequired,
};

export default DeclineTransactionModal;
