import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Card, Table } from 'tabler-react';
import isUndefined from 'lodash/isUndefined';
import Document from './Document/Document';
import RequestButton from './RequestButton';
import UploadButton from '../../../../../Common/Buttons/UploadButton';
import { DOCUMENT_VERIFICATION_STATUS_CODE } from '../../../../../../constants';

class DocumentType extends Component {
    renderDocuments () {
        const { documentType, documents, documentVerificationStatus } = this.props;

        if (isUndefined(documents[documentType.id])) {
            return null;
        }

        return (
            <Table cards={true} responsive={true} highlightRowOnHover={true}>
                <Table.Header>
                    <Table.Row>
                        <Table.ColHeader className='w-15'>Upload date</Table.ColHeader>
                        <Table.ColHeader>Document</Table.ColHeader>
                        <Table.ColHeader className='w-15'>Uploaded by</Table.ColHeader>
                        <Table.ColHeader className='w-20 text-center'>Actions</Table.ColHeader>
                        <Table.ColHeader className='w-20' />
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {documents[documentType.id].map((document) => (
                        <Document key={document.hash} document={document} documentVerificationStatus={documentVerificationStatus} />
                    ))}
                </Table.Body>
            </Table>
        );
    }

    render () {
        const {
            documentType,
            requestedDocumentTypes,
            userId,
            updateDocuments,
            documentVerificationId,
            documentVerificationStatus,
            updateDocumentVerificationStatus,
        } = this.props;

        const uploadUrl = `admin-user-document-verification/user-document-verifications/${documentVerificationId}/user-documents`;

        return (
            <Card key={documentType.id}>
                <Card.Header>
                    <Card.Title>
                        <span className='fs-14'>{documentType.name}</span>
                    </Card.Title>
                    <Card.Options>
                        <Button.List>
                            <RequestButton
                                userId={userId}
                                documentType={documentType}
                                requestedDocumentTypes={requestedDocumentTypes}
                                updateDocumentVerificationStatus={updateDocumentVerificationStatus}
                                documentVerificationStatus={documentVerificationStatus}
                                documentVerificationId={documentVerificationId}
                            />
                            {documentVerificationStatus !== DOCUMENT_VERIFICATION_STATUS_CODE.confirmed && (
                                <UploadButton
                                    updateDocuments={updateDocuments}
                                    uploadUrl={uploadUrl}
                                    params={{ document_type_id: documentType.id }}
                                />
                            )}
                        </Button.List>
                    </Card.Options>
                </Card.Header>
                {this.renderDocuments()}
            </Card>
        );
    }
}

DocumentType.propTypes = {
    documentType: PropTypes.object.isRequired,
    requestedDocumentTypes: PropTypes.array.isRequired,
    documents: PropTypes.object.isRequired,
    userId: PropTypes.string.isRequired,
    updateDocuments: PropTypes.func.isRequired,
    documentVerificationStatus: PropTypes.string.isRequired,
    updateDocumentVerificationStatus: PropTypes.func.isRequired,
    documentVerificationId: PropTypes.string.isRequired,
};

export default DocumentType;
