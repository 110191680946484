import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'tabler-react';
import { createApiClient as api } from '../../../../../../../services/api-client';
import { toast } from 'react-toastify';

class VerifyButton extends Component {
    constructor (props) {
        super(props);

        this.state = {
            isLoading: false,
        };

        this.verifyUser = this.verifyUser.bind(this);
    }

    verifyUser () {
        const { user, verificationData, updateUser } = this.props;

        this.setState({
            isLoading: true,
        });

        const data = {
            verified: true,
            pricing_group_id: verificationData.pricing_group_id,
            risk_level: verificationData.risk_level || user.risk_level,
            provider: verificationData.provider,
        };

        api()
            .put(`/admin-user/users/${user.id}/verify`, data)
            .then(() => {
                updateUser();

                toast.success('User verified successfully');
            })
            .catch((data) => {
                toast.error(data.data.message);

                this.setState({
                    isLoading: false,
                });
            });
    }

    isButtonDisabled () {
        const { user, verificationData } = this.props;

        return !verificationData.pricing_group_id || (!verificationData.risk_level && !user.risk_level) || !verificationData.provider;
    }

    render () {
        const { isLoading } = this.state;

        return (
            <Button
                disabled={this.isButtonDisabled()}
                loading={isLoading}
                size='sm'
                onClick={this.verifyUser}
                color='primary'
                className='w-100'
            >
                Verify and Activate
            </Button>
        );
    }
}

VerifyButton.propTypes = {
    user: PropTypes.object.isRequired,
    verificationData: PropTypes.object.isRequired,
    updateUser: PropTypes.func.isRequired,
};

export default VerifyButton;
