import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Card, Text, Dimmer } from 'tabler-react';
import ApiError from '../Common/ApiError';
import googlePlayButton from '../../images/google-play-light.png';
import appStoreButton from '../../images/app-store-light.png';

class TwoFAInstructions extends Component {
    render () {
        if (this.props.error) {
            return <ApiError error={this.props.error} />;
        }

        return (
            <Card>
                <Dimmer active={this.props.isLoading} loader>
                    <div className='second-factory-instructions'>
                        <Text.Small>
                            Download the free Google Authenticator app, click add, and then scan this barcode to set up your account.
                        </Text.Small>

                        <div className='buttons'>
                            <a
                                href='https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2'
                                rel='noopener noreferrer'
                                target='_blank'
                            >
                                <img src={googlePlayButton} alt='Google Authenticator' />
                            </a>

                            <a
                                href='https://apps.apple.com/us/app/google-authenticator/id388497605'
                                rel='noopener noreferrer'
                                target='_blank'
                            >
                                <img src={appStoreButton} alt='Google Authenticator' />
                            </a>
                        </div>

                        <img src={this.props.authenticator.url} alt='QR code' />
                    </div>
                </Dimmer>
            </Card>
        );
    }
}

TwoFAInstructions.propTypes = {
    authenticator: PropTypes.object.isRequired,
    isLoading: PropTypes.bool.isRequired,
    error: PropTypes.object,
};

export default TwoFAInstructions;
