import React, { useEffect, useRef, useState } from 'react';
import SiteWrapper from '../../SiteWrapper';
import PaginatedFilteredList from '../../Common/PaginatedFilteredList';
import { Button } from 'tabler-react';
import { formatAmount, uppercase } from '../../../helpers';
import MarkupCreateModal from './FXMarkupFees/MarkupCreateModal';
import MarkupUpdateModal from './FXMarkupFees/MarkupUpdateModal';
import { createApiClient as api } from '../../../services/api-client';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { fetchFeeWalletRelations } from '../../../actions/fee/wallets';

const FXMarkupFees = () => {
    const LIST_URI = '/admin-currency-exchange/markup-fee-configurations';

    const renderWalletColumn = (row) => (
        <>
            {row.value.name}
            &nbsp;
            <span className='text-muted-dark'>({row.value.wallet_number})</span>
        </>
    );

    const COLUMNS = [
        {
            Header: '#',
            accessor: 'id',
            width: 50,
        },
        {
            Header: 'From Currency',
            accessor: 'from_currency',
            Cell: (row) => uppercase(row.value),
        },
        {
            Header: 'To Currency',
            accessor: 'to_currency',
            Cell: (row) => uppercase(row.value),
        },
        {
            Header: 'Markup Amount',
            accessor: 'markup_amount',
            Cell: (row) => formatAmount(row.value),
        },
        {
            Header: 'Wallet',
            accessor: 'fee_wallet_relation',
            Cell: (row) => renderWalletColumn(row),
        },
        {
            Header: 'Actions',
            id: 'actions',
            width: 150,
            sortable: false,
            headerClassName: 'column-actions',
            className: 'column-actions',
            Cell: (row) => (
                <Button.List>
                    <Button className='btn btn-sm btn-primary' onClick={() => setEditableMarkupFee(row.original)}>
                        Edit
                    </Button>
                    <Button size='sm' onClick={() => onMarkupFeeDeleteClick(row.original)} color='danger'>
                        Delete
                    </Button>
                </Button.List>
            ),
        },
    ];

    const [items, setItems] = useState([]);
    const [creationModalOpen, setCreationModalOpen] = useState(false);
    const markupFeesTableRef = useRef(null);
    const [editableMarkupFee, setEditableMarkupFee] = useState(null);
    const feeWallets = useSelector((state) => state.fee.wallets);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchFeeWalletRelations());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const Controls = (
        <Button pill icon='plus' color='primary' onClick={() => setCreationModalOpen(true)}>
            Add markup fee
        </Button>
    );

    const onMarkupFeeUpdated = () => {
        if (markupFeesTableRef && markupFeesTableRef.current) {
            markupFeesTableRef.current.fetchData();
        }
    };

    const onMarkupFeeDeleteClick = ({ from_currency, to_currency, id }) => {
        if (confirm(`Remove Markup Fee ${from_currency.toUpperCase()} -> ${to_currency.toUpperCase()} ?`)) {
            api()
                .delete(`/admin-currency-exchange/markup-fee-configurations/${id}`)
                .then(() => {
                    onMarkupFeeUpdated();
                })
                .catch(() => {
                    toast.error('Error occurred. Try again');
                });
        }
    };

    return (
        <SiteWrapper title='FX Markup Fees' options={feeWallets.length > 0 && Controls}>
            <PaginatedFilteredList
                updateItems={(items) => setItems(items)}
                columns={COLUMNS}
                items={items}
                uri={LIST_URI}
                ref={markupFeesTableRef}
                sortBy='id'
                sortByDirectionDescending={false}
            />
            {
                creationModalOpen &&
                <MarkupCreateModal
                    onModalClose={() => setCreationModalOpen(false)}
                    onCreated={onMarkupFeeUpdated}
                    feeWallets={feeWallets}
                />
            }
            {
                editableMarkupFee !== null &&
                <MarkupUpdateModal
                    markupFee={editableMarkupFee}
                    onModalClose={() => setEditableMarkupFee(null)}
                    onUpdated={onMarkupFeeUpdated}
                />
            }
        </SiteWrapper>
    );
};

export default FXMarkupFees;
