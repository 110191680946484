import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { authenticateUser, confirmAuthentication } from '../actions/user/authenticate-user';
import PropTypes from 'prop-types';
import AuthenticationForm from './AuthenticationPage/AuthenticationForm';
import TwoFAForm from './AuthenticationPage/TwoFAForm';
import { getError } from '../selectors/getError';
import { StandaloneFormPage } from 'tabler-react';
import logo from '../images/logo.svg';

export class AuthenticationPage extends Component {
    constructor (props) {
        super(props);

        this.state = {
            step: 'authentication',
        };

        this.authenticateUser = this.authenticateUser.bind(this);
        this.confirmAuthentication = this.confirmAuthentication.bind(this);

        this.changeStep = this.changeStep.bind(this);
    }

    authenticateUser (username, password) {
        this.props.authenticateUser(username, password);
    }

    confirmAuthentication (code) {
        this.props.confirmAuthentication(code);
    }

    changeStep (step) {
        this.setState({
            step,
        });
    }

    renderAuthenticationStep () {
        if (this.state.step === 'authentication') {
            return <AuthenticationForm changeStep={this.changeStep} />;
        } else if (this.state.step === 'create_second_factory') {
            return <TwoFAForm changeStep={this.changeStep} hasAuthentication={false} />;
        } else if (this.state.step === 'second_factory') {
            return <TwoFAForm changeStep={this.changeStep} />;
        }
    }

    render () {
        return (
            <div className='authentication-page'>
                <StandaloneFormPage imageURL={logo}>{this.renderAuthenticationStep()}</StandaloneFormPage>
            </div>
        );
    }
}

AuthenticationPage.propTypes = {
    authenticateUser: PropTypes.func.isRequired,
    confirmAuthentication: PropTypes.func.isRequired,
    isAuthenticating: PropTypes.bool.isRequired,
    confirmError: PropTypes.object,
    authenticationError: PropTypes.object,
};

const mapStateToProps = (state) => ({
    isAuthenticating: state.authentication.isAuthenticating,
    confirmError: getError(state, 'authentication', 'confirm'),
    authenticationError: getError(state, 'authentication', 'authentication'),
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            authenticateUser,
            confirmAuthentication,
        },
        dispatch,
    );

export default connect(mapStateToProps, mapDispatchToProps)(AuthenticationPage);
