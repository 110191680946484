import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Card, Grid } from 'tabler-react';
import ReactTable from 'react-table';
import { toast } from 'react-toastify';
import { createApiClient } from '../../../../../../services/api-client';
import { capitalize } from '../../../../../../helpers';
import { SCREENED_CLIENT_STATUS, SCREENED_CLIENT_STATUS_CONFIRMED, SCREENED_CLIENT_TYPE } from '../../../../../../constants';
import { convertSelectValue } from '../../../../../../services/string-to-boolean';

const ClientScreening = ({ screenedClients, updateScreenedClients }) => {
    const [isLoading, setLoading] = useState(false);
    const [screenedClientsData, setScreenedClientsData] = useState(screenedClients);

    const api = useMemo(() => createApiClient(), []);

    const canConfirmScreening = useCallback(
        (screeningId) => {
            const indexOfScreenedClient = screenedClientsData.findIndex((clientsData) => clientsData.client_screening_id === screeningId);

            return (
                convertSelectValue(screenedClientsData[indexOfScreenedClient].is_pep) !== '' &&
                convertSelectValue(screenedClientsData[indexOfScreenedClient].is_media_adverse) !== '' &&
                convertSelectValue(screenedClientsData[indexOfScreenedClient].is_sanctioned) !== ''
            );
        },
        [screenedClientsData],
    );

    const handleScreeningSelect = useCallback(
        (event, screeningId) => {
            const {
                target: { name, value },
            } = event;
            const indexOfScreenedClient = screenedClientsData.findIndex((clientsData) => clientsData.client_screening_id === screeningId);
            const clientsDataStateCopy = [...screenedClientsData];
            clientsDataStateCopy[indexOfScreenedClient][name] = value;
            setScreenedClientsData(clientsDataStateCopy);
        },
        [screenedClientsData],
    );

    const renderScreeningColumn = useCallback(
        (name, value, screeningId, status) => {
            const convertedValue = convertSelectValue(
                screenedClientsData.find((screenedClient) => screenedClient.client_screening_id === screeningId)[name],
            );

            if (value === true && status === SCREENED_CLIENT_STATUS_CONFIRMED) {
                return 'Yes';
            } else if (value === false && status === SCREENED_CLIENT_STATUS_CONFIRMED) {
                return 'No';
            }

            return (
                <select
                    className='w-100'
                    name={name}
                    onChange={(event) => handleScreeningSelect(event, screeningId)}
                    value={convertedValue}
                >
                    <option value='' />
                    <option value={true}>Yes</option>
                    <option value={false}>No</option>
                </select>
            );
        },
        [handleScreeningSelect, screenedClientsData],
    );

    const renderClientType = (clientType) => {
        if (SCREENED_CLIENT_TYPE[clientType] !== undefined) {
            return SCREENED_CLIENT_TYPE[clientType];
        }

        return capitalize(clientType);
    };

    const renderScreenedClientStatus = (clientStatus) => {
        if (SCREENED_CLIENT_STATUS[clientStatus] !== undefined) {
            return SCREENED_CLIENT_STATUS[clientStatus];
        }

        return capitalize(clientStatus);
    };

    const confirmScreening = useCallback(
        (screeningId) => async () => {
            setLoading(true);

            const screenedClient = screenedClientsData.find((screenedClient) => screenedClient.client_screening_id === screeningId);
            const screeningInformation = {
                is_pep: convertSelectValue(screenedClient.is_pep),
                is_sanctioned: convertSelectValue(screenedClient.is_sanctioned),
                is_media_adverse: convertSelectValue(screenedClient.is_media_adverse),
            };

            try {
                await api.put(`admin-aml/client-screenings/${screeningId}/confirm`, screeningInformation);
                await updateScreenedClients(false);

                toast.success('Screening information confirmed');
            } catch (error) {
                toast.error(`Something went wrong: ${error.data.message}`);
            } finally {
                setLoading(false);
            }
        },
        [api, screenedClientsData, updateScreenedClients],
    );

    const columns = useMemo(
        () => [
            {
                Header: 'Client type',
                accessor: 'client_type',
                sortable: false,
                Cell: (row) => renderClientType(row.value),
            },
            {
                Header: 'Full name',
                accessor: 'full_name',
                sortable: false,
                Cell: (row) => capitalize(row.value),
            },
            {
                Header: 'PEP',
                accessor: 'is_pep',
                sortable: false,
                Cell: (row) => renderScreeningColumn(row.column.id, row.value, row.original.client_screening_id, row.original.status),
            },
            {
                Header: 'Sanctions list',
                accessor: 'is_sanctioned',
                sortable: false,
                Cell: (row) => renderScreeningColumn(row.column.id, row.value, row.original.client_screening_id, row.original.status),
            },
            {
                Header: 'Media found',
                accessor: 'is_media_adverse',
                sortable: false,
                Cell: (row) => renderScreeningColumn(row.column.id, row.value, row.original.client_screening_id, row.original.status),
            },
            {
                Header: 'Status',
                accessor: 'status',
                sortable: false,
                Cell: (row) => renderScreenedClientStatus(row.value),
            },
            {
                Header: 'Action',
                sortable: false,
                Cell: (row) =>
                    row.original.status !== 'confirmed' && (
                        <Button.List>
                            <Button
                                size='sm'
                                onClick={confirmScreening(row.original.client_screening_id)}
                                color='success'
                                disabled={!canConfirmScreening(row.original.client_screening_id)}
                            >
                                Confirm
                            </Button>
                        </Button.List>
                    ),
            },
        ],
        [canConfirmScreening, confirmScreening, renderScreeningColumn],
    );

    return (
        <Grid.Row>
            <Grid.Col>
                <Card>
                    <Card.Body>
                        <ReactTable
                            manual
                            data={screenedClients}
                            loading={isLoading}
                            columns={columns}
                            className='-striped -highlight'
                            multiSort={false}
                            minRows={2}
                            showPagination={false}
                        />
                    </Card.Body>
                </Card>
            </Grid.Col>
        </Grid.Row>
    );
};

ClientScreening.propTypes = {
    screenedClients: PropTypes.array.isRequired,
    updateScreenedClients: PropTypes.func.isRequired,
};

export default ClientScreening;
