import { Button, Card, Grid, Header, Table } from 'tabler-react';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo, useState } from 'react';
import ResetTwoFAButton from './Actions/ResetTwoFAButton';
import { toast } from 'react-toastify';
import { createApiClient } from '../../../../../services/api-client';

const Actions = ({ adminUser, navigate, currentAdminId }) => {
    const [isLoading, setLoading] = useState(false);

    const api = useMemo(() => createApiClient(), []);

    const navigateToAdminEdit = useCallback(() => navigate(`/configuration/admin-users/edit/${adminUser.id}`), [adminUser.id, navigate]);

    const deactivateAdmin = useCallback(async () => {
        if (!confirm(`Are you sure you want to deactivate ${adminUser.email}?`)) {
            return false;
        }

        setLoading(true);

        try {
            await api.put(`admin-user/admins/${adminUser.id}/deactivate`);

            toast.success(`Admin ${adminUser.email} deactivated successfully`);
            navigate('/configuration/admin-users');
        } catch (error) {
            toast.error(`Unable to deactivate admin: ${error.data.data}`);
        } finally {
            setLoading(false);
        }
    }, [adminUser.email, adminUser.id, api, navigate]);

    return (
        <>
            <Header.H3>Actions</Header.H3>
            <Grid.Row>
                <Grid.Col width={5}>
                    <Card>
                        <Table className='table-fixed' striped={true} cards={true}>
                            <Table.Body>
                                <Table.Row>
                                    <Table.Col>Reset 2FA</Table.Col>
                                    <Table.Col>
                                        <ResetTwoFAButton
                                            color='warning'
                                            title='Reset'
                                            adminEmail={adminUser.email}
                                            adminId={adminUser.id}
                                            size='sm'
                                        />
                                    </Table.Col>
                                </Table.Row>

                                <Table.Row>
                                    <Table.Col className='align-middle'>Edit role</Table.Col>
                                    <Table.Col>
                                        <Button
                                            color='primary'
                                            className='w-100 mt-2 mb-2'
                                            size='sm'
                                            onClick={navigateToAdminEdit}
                                        >
                                            Edit
                                        </Button>
                                    </Table.Col>
                                </Table.Row>

                                {currentAdminId !== adminUser.id && (
                                    <Table.Row>
                                        <Table.Col>Deactivate admin</Table.Col>
                                        <Table.Col>
                                            <Button
                                                color='danger'
                                                className='w-100'
                                                size='sm'
                                                onClick={deactivateAdmin}
                                                loading={isLoading}
                                            >
                                                Deactivate
                                            </Button>
                                        </Table.Col>
                                    </Table.Row>
                                )}
                            </Table.Body>
                        </Table>
                    </Card>
                </Grid.Col>
            </Grid.Row>
        </>
    );
};

Actions.propTypes = {
    navigate: PropTypes.func.isRequired,
    currentAdminId: PropTypes.number.isRequired,
    adminUser: PropTypes.object.isRequired,
};

export default Actions;
