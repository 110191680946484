import React, { useMemo, useState } from 'react';
import SiteWrapper from '../../../SiteWrapper';
import PropTypes from 'prop-types';
import ApiError from '../../../Common/ApiError';
import { Button, Card, Dimmer, Grid } from 'tabler-react';
import { createApiClient } from '../../../../services/api-client';
import { bindActionCreators } from 'redux';
import { navigate } from '../../../../actions/navigate';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import InputTextField from '../../../Common/FilterFormFields/InputTextField';

const FeeWalletsCreate = ({ navigate }) => {
    const [isLoading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [feeWallet, setFeeWallet] = useState({
        name: '',
        wallet_number: '',
    });

    const api = useMemo(() => createApiClient(), []);

    const handleChange = (event) => {
        const {
            target: { name, value },
        } = event;

        setFeeWallet((prevFeeWallet) => ({ ...prevFeeWallet, [name]: value.length > 0 ? value : '' }));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        setLoading(true);
        setError(null);

        try {
            await api.post('/admin-fee/fee-wallet-relations', feeWallet);

            toast.success('Fee wallet created successfully');
            navigate('/configuration/fee-wallets');
        } catch (error) {
            setError(error.data);
        } finally {
            setLoading(false);
        }
    };

    return (
        <SiteWrapper title='Create fee wallet'>
            <Grid.Row>
                <Grid.Col md={4} sm={6} xs={12}>
                    <Card>
                        <form onSubmit={handleSubmit}>
                            <Dimmer active={isLoading} loader>
                                <Card.Body>
                                    <InputTextField name='name' title='Name' value={feeWallet.name} handleChange={handleChange} />
                                    <InputTextField
                                        name='wallet_number'
                                        title='Wallet number'
                                        value={feeWallet.wallet_number}
                                        handleChange={handleChange}
                                    />
                                    <ApiError error={error} />
                                </Card.Body>
                                <Card.Footer className='text-center'>
                                    <Button type='submit' color='primary' onClick={handleSubmit}>
                                        Submit
                                    </Button>
                                </Card.Footer>
                            </Dimmer>
                        </form>
                    </Card>
                </Grid.Col>
            </Grid.Row>
        </SiteWrapper>
    );
};

FeeWalletsCreate.propTypes = {
    navigate: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            navigate,
        },
        dispatch,
    );

export default connect(null, mapDispatchToProps)(FeeWalletsCreate);
