import React, { useState, useEffect, useCallback } from 'react';
import SiteWrapper from '../../../SiteWrapper';
import { Grid } from 'tabler-react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import Sidebar from './Common/Sidebar';
import Audit from './Audit/Audit';
import Report from './Report/Report';
import Identity from './Identity/Identity';
import Documents from './Documents/Documents';
import Questionnaire from './Questionnaire/Questionnaire';
import Comments from '../../../Common/Comments';
import { createApiClient as api } from '../../../../services/api-client';
import { getName, getNumberHash } from '../../../../selectors/user/get-participants-information';
import Wallets from '../../Users/Wallets/Wallets';

const User = (props) => {
    const [user, setUser] = useState({
        first_name: null,
        last_name: null,
        company_name: null,
        created_at: null,
        email: null,
        id: null,
        hash: null,
        is_active: null,
        is_verified: null,
        number: null,
        phone: null,
        type: null,
        risk_level: null,
    });
    const [isLoading, setLoading] = useState(true);
    const [currentComponent, setCurrentComponent] = useState('identity');
    const [isCommentsModalOpen, setCommentsModalOpen] = useState(false);

    const components = {
        identity: {
            title: 'Identity',
            icon: 'user-check',
        },
        questionnaire: {
            title: 'Questionnaire',
            icon: 'book-open',
        },
        documents: {
            title: 'Documents',
            icon: 'file-text',
        },
        wallets: {
            title: 'Wallets',
            icon: 'credit-card',
        },
        report: {
            title: 'Report',
            icon: 'bar-chart',
        },
        audit: {
            title: 'Audit log',
            icon: 'grid',
        },
        comment: {
            title: 'Comment',
            icon: 'message-circle',
        },
    };

    const fetchUser = useCallback(async () => {
        const { id } = props.match.params;

        setLoading(true);

        try {
            const response = await api().get(`/admin-user/users/${id}`);

            setUser(response.data);
        } catch (error) {
            toast.error(error.data.message);
        } finally {
            setLoading(false);
        }
    }, [props.match.params]);

    const updateUser = (name, value) => setUser((prevUser) => ({ ...prevUser, [name]: value }));

    const renderUserComponents = () => {
        switch (currentComponent) {
            case 'questionnaire':
                return <Questionnaire {...props} user={user} />;
            case 'report':
                return <Report {...props} user={user} />;
            case 'audit':
                return <Audit {...props} user={user} />;
            case 'documents':
                return <Documents {...props} user={user} />;
            case 'wallets':
                return <Wallets {...props} isChargeModalButtonVisible={false} user={user} />;
            default:
                return <Identity {...props} user={user} updateUser={fetchUser} updateUserState={updateUser} />;
        }
    };

    const renderLoading = () => <SiteWrapper title='Loading' />;

    const setCommentsModalVisible = useCallback((value) => () => setCommentsModalOpen(value), []);

    const handleComponentChange = useCallback(
        (page) => () => {
            const hasQueryString = window.location.href.includes('?');

            if (hasQueryString) {
                window.history.replaceState(null, null, window.location.pathname);
            }

            if (page === 'comment') {
                setCommentsModalOpen(true);

                return;
            }
            setCurrentComponent(page);
        },
        [],
    );

    useEffect(() => {
        fetchUser();
    }, [fetchUser]);

    return isLoading ? (
        renderLoading()
    ) : (
        <SiteWrapper title={getName(user)} subTitle={getNumberHash(user)}>
            <Grid.Row>
                <Grid.Col md={3}>
                    <Sidebar
                        user={user}
                        components={components}
                        currentComponent={currentComponent}
                        handleComponentChange={handleComponentChange}
                    />
                </Grid.Col>
                <Grid.Col md={9}>{renderUserComponents()}</Grid.Col>
            </Grid.Row>
            <Comments
                relationType={'user'}
                relationId={user.id}
                isModalOpen={isCommentsModalOpen}
                setCommentsModalVisible={setCommentsModalVisible}
            />
        </SiteWrapper>
    );
};

User.propTypes = {
    match: PropTypes.object,
};

export default User;
