import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import SiteWrapper from '../../../SiteWrapper';
import { Button, Card, Dimmer, Form, Grid } from 'tabler-react';
import { createApiClient as api } from '../../../../services/api-client';
import PropTypes from 'prop-types';
import { fetchCurrencies } from '../../../../actions/currency/currencies';
import ApiError from '../../../Common/ApiError';
import { isLoading } from '../../../../selectors/isLoading';
import { navigate } from '../../../../actions/navigate';
import { toast } from 'react-toastify';
import AmountField from '../../../Common/FilterFormFields/AmountField';
import InputTextField from '../../../Common/FilterFormFields/InputTextField';

const MonthlyFeesEdit = (props) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [fee, setFee] = useState({
        name: '',
        amount: '',
        currency: '',
        wallet_type: '',
    });

    const { navigate, match, isFetchingCurrencies } = props;

    const fetchMonthlyFeeConfiguration = useCallback(async () => {
        const { id } = match.params;

        setLoading(true);
        setError(null);

        try {
            const { data } = await api().get(`/admin-fee/monthly-fee-configurations/${id}`);

            setFee({
                name: data.name,
                amount: data.amount === null ? '0' : data.amount,
                currency: data.currency,
                wallet_type: data.wallet_type,
            });
        } catch (error) {
            setError(error.data);
        } finally {
            setLoading(false);
        }
    }, [match.params]);

    useEffect(() => {
        fetchMonthlyFeeConfiguration();
    }, [fetchMonthlyFeeConfiguration]);

    const handleSubmit = useCallback(
        async (event) => {
            event.preventDefault();

            const { id } = match.params;

            setLoading(true);
            setError(null);

            try {
                await api().put(`/admin-fee/monthly-fee-configurations/${id}`, {
                    ...fee,
                });

                setLoading(false);
                toast.success('Edit successful');
                navigate('/fee/monthly-fees');
            } catch (error) {
                setError(error.data);
                setLoading(false);
            }
        },
        [fee, match.params, navigate],
    );

    const handleSubmitCreate = useCallback(
        async (event) => {
            event.preventDefault();

            setLoading(true);
            setError(null);

            try {
                await api().post('/admin-fee/monthly-fee-configurations', {
                    ...fee,
                });

                setLoading(false);
                toast.success('Create successful');
                navigate('/fee/monthly-fees');
            } catch (error) {
                setError(error.data);
                setLoading(false);
            }
        },
        [fee, navigate],
    );

    const handleChange = (event) => {
        const { target } = event;
        const targetValue = target.value.length > 0 ? target.value : '';

        setFee((prev) => ({ ...prev, [target.name]: targetValue }));
    };

    const isLoading = useMemo(() => loading || isFetchingCurrencies, [
        isFetchingCurrencies,
        loading,
    ]);

    return (
        <SiteWrapper title='Edit monthly fee'>
            <Grid.Row>
                <Grid.Col md={4} sm={6} xs={12}>
                    <Card>
                        <form onSubmit={handleSubmit}>
                            <Dimmer active={isLoading} loader>
                                <Card.Body>
                                    <InputTextField name='name' title='Name' value={fee.name} handleChange={handleChange} />
                                    <AmountField
                                        name='amount'
                                        title='Amount'
                                        value={fee.amount}
                                        handleChange={handleChange}
                                        maxAmount={10000}
                                    />

                                    <Form.Select
                                        name='wallet_type'
                                        label='Wallet Type'
                                        value={fee.wallet_type}
                                        onChange={handleChange}
                                    >
                                        <option></option>
                                        <option key='mano_bank' value='mano_bank'>
                                            ManoBank
                                        </option>
                                    </Form.Select>

                                    <ApiError error={error} />
                                </Card.Body>
                                <Card.Footer className='text-center'>
                                    <div className='d-flex'>
                                        <Button type='button' link onClick={() => navigate('/fee/monthly-fees')}>
                                                Cancel
                                        </Button>

                                        <Button type='submit' color='primary' className='ml-auto' onClick={handleSubmit}>
                                                Update
                                        </Button>
                                        <Button type='submit' color='primary' className='ml-auto' onClick={handleSubmitCreate}>
                                                Create new
                                        </Button>
                                    </div>
                                </Card.Footer>
                            </Dimmer>
                        </form>
                    </Card>
                </Grid.Col>
            </Grid.Row>
        </SiteWrapper>
    );
};

MonthlyFeesEdit.propTypes = {
    navigate: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,

    currencies: PropTypes.array.isRequired,
    fetchCurrencies: PropTypes.func.isRequired,
    isFetchingCurrencies: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
    currencies: state.currency.currencies,
    isFetchingCurrencies: isLoading(state, 'currencies'),
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            fetchCurrencies,
            navigate,
        },
        dispatch,
    );

export default connect(mapStateToProps, mapDispatchToProps)(MonthlyFeesEdit);
