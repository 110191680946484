import React, { Component } from 'react';
import PropTypes from 'prop-types';
import String from '../String';
import Country from '../Country';
import { Table } from 'tabler-react';

class AccountsInBanks extends Component {
    renderAccount (account, key) {
        return (
            <Table.Row key={key}>
                <Table.Col>
                    <String answer={account.name_of_bank} />
                </Table.Col>
                <Table.Col>
                    <Country answer={account.country} />
                </Table.Col>
            </Table.Row>
        );
    }

    render () {
        const { answer } = this.props;

        return (
            <Table>
                <Table.Header>
                    <Table.Row>
                        <Table.ColHeader>Name of the financial institution</Table.ColHeader>
                        <Table.ColHeader>Country of the financial institution</Table.ColHeader>
                    </Table.Row>
                </Table.Header>
                <Table.Body>{answer.map((account, key) => this.renderAccount(account, key))}</Table.Body>
            </Table>
        );
    }
}

AccountsInBanks.propTypes = {
    answer: PropTypes.array,
    field: PropTypes.object.isRequired,
};

export default AccountsInBanks;
