import { handleActions } from 'redux-actions';

import {
    AUTHENTICATE_USER_FAILURE,
    AUTHENTICATE_USER_SUCCESS,
    CONFIRM_AUTHENTICATION_SUCCESS,
    CONFIRM_AUTHENTICATION_FAILURE,
    USER_AUTHENTICATED,
} from '../../actions/user/authenticate-user';
import { FETCH_CURRENT_ADMIN_INFORMATION_FAILURE, FETCH_CURRENT_ADMIN_INFORMATION_REQUEST } from '../../actions/user/admin-information';

export default handleActions(
    {
        [AUTHENTICATE_USER_FAILURE]: () => false,
        [AUTHENTICATE_USER_SUCCESS]: () => false,
        [CONFIRM_AUTHENTICATION_SUCCESS]: () => false,
        [CONFIRM_AUTHENTICATION_FAILURE]: () => false,
        [FETCH_CURRENT_ADMIN_INFORMATION_REQUEST]: () => true,
        [FETCH_CURRENT_ADMIN_INFORMATION_FAILURE]: () => false,
        [USER_AUTHENTICATED]: () => false,
    },
    false,
);
