import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { fetchMonthlyFeeConfigurations } from '../../../../../actions/fee/monthly';
import { monthlyFeesOptions, selectedMonthlyFees } from '../services/monthly-fee';
import { multiSelectValues } from '../services/pricing-group';

class MonthlyFee extends Component {
    constructor (props) {
        super(props);

        this.onChange = this.onChange.bind(this);
    }

    componentDidMount () {
        this.props.fetchMonthlyFeeConfigurations();
    }

    onChange (selected) {
        this.props.onChange({
            target: {
                name: 'monthly',
                value: multiSelectValues(selected),
            },
        });
    }

    render () {
        const { monthlyFeeConfigurations, value, disabled } = this.props;

        return (
            <div className='form-group'>
                <label className='form-label'>Monthly fee</label>
                <Select
                    isMulti={true}
                    isSearchable={true}
                    className='multi-select'
                    value={selectedMonthlyFees(monthlyFeeConfigurations, value)}
                    onChange={this.onChange}
                    options={monthlyFeesOptions(monthlyFeeConfigurations)}
                    isDisabled={disabled}
                />
            </div>
        );
    }
}

MonthlyFee.propTypes = {
    monthlyFeeConfigurations: PropTypes.array.isRequired,
    fetchMonthlyFeeConfigurations: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.array.isRequired,
    disabled: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
    monthlyFeeConfigurations: state.fee.monthly,
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            fetchMonthlyFeeConfigurations,
        },
        dispatch,
    );

MonthlyFee.defaultProps = {
    disabled: false,
};

export default connect(mapStateToProps, mapDispatchToProps)(MonthlyFee);
