export const download = (filename, blob) => {
    const url = window.URL.createObjectURL(new Blob([blob]));
    const link = window.document.createElement('a');

    link.href = url;
    link.setAttribute('download', filename);
    window.document.body.appendChild(link);
    link.click();
    link.remove();
};
