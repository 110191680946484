import React, { useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import 'react-table/react-table.css';
import ReactTable from 'react-table';
import SiteWrapper from '../../SiteWrapper';
import { Button, Card, Grid } from 'tabler-react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchFeeWalletRelations } from '../../../actions/fee/wallets';
import { isLoading } from '../../../selectors/isLoading';
import { WALLET_TYPES } from '../../../constants';
import { createApiClient } from '../../../services/api-client';
import { toast } from 'react-toastify';
import { CentrolinkFeeCollection } from './FeeWallets/CentrolinkFeeCollection';

const FeeWallets = ({ isLoading, feeWallets, fetchFeeWalletRelations }) => {
    const api = useMemo(() => createApiClient(), []);

    const renderOptions = useMemo(
        () => (
            <Link to='/configuration/fee-wallets/create'>
                <Button pill icon='plus' color='primary'>
                    Create new
                </Button>
            </Link>
        ),
        [],
    );

    const handleDeleteWallet = useCallback(
        (wallet) => async () => {
            if (confirm('Are you sure?')) {
                try {
                    await api.delete(`/admin-fee/fee-wallet-relation/${wallet.id}`);
                    toast.success('Fee wallet deleted successfully');

                    await fetchFeeWalletRelations();
                } catch (error) {
                    toast.error(`Failed to delete fee wallet: ${error.data.message}`);
                }
            }
        },
        [api, fetchFeeWalletRelations],
    );

    const columns = useMemo(
        () => [
            {
                Header: 'Fee wallet name',
                accessor: 'name',
                sortable: false,
                Cell: (row) => row.value,
            },
            {
                Header: 'Owner name',
                accessor: 'owner_name',
                sortable: false,
                Cell: (row) => row.value,
            },
            {
                Header: 'Wallet number',
                accessor: 'wallet_number',
                sortable: false,
                Cell: (row) => row.value,
            },
            {
                Header: 'Provider',
                accessor: 'type',
                sortable: false,
                Cell: (row) => WALLET_TYPES[row.value] || null,
            },
            {
                Header: 'Actions',
                id: 'actions',
                sortable: false,
                width: 100,
                Cell: (row) => (
                    <Button.List>
                        <Button size='sm' color='danger' onClick={handleDeleteWallet(row.original)}>
                            Delete
                        </Button>
                    </Button.List>
                ),
            },
        ],
        [handleDeleteWallet],
    );

    useEffect(() => {
        fetchFeeWalletRelations();
    }, [fetchFeeWalletRelations]);

    return (
        <SiteWrapper
            title='Fee wallets'
            options={renderOptions}
            titleDescription='Setup corporate fee wallet for for monthly /
                transaction / card or any other fee collection. After setup,
                wallet will be found under the "Fees" menu'
        >
            <Grid.Row>
                <Grid.Col>
                    <Card>
                        <Card.Body>
                            <ReactTable
                                manual
                                data={feeWallets}
                                loading={isLoading}
                                columns={columns}
                                className='-striped -highlight'
                                multiSort={false}
                                minRows={2}
                                showPagination={false}
                            />
                        </Card.Body>
                    </Card>
                </Grid.Col>
            </Grid.Row>
            <CentrolinkFeeCollection />
        </SiteWrapper>
    );
};

FeeWallets.propTypes = {
    fetchFeeWalletRelations: PropTypes.func.isRequired,
    feeWallets: PropTypes.array.isRequired,
    isLoading: PropTypes.bool,
};

const mapStateToProps = (state) => ({
    feeWallets: state.fee.wallets,
    isLoading: isLoading(state, 'feeWallets'),
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            fetchFeeWalletRelations,
        },
        dispatch,
    );

export default connect(mapStateToProps, mapDispatchToProps)(FeeWallets);
