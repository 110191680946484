import { uppercase } from '../../../../../helpers';

export const buildMonthlyFeeLabel = (fee) => `${fee.name} | ${fee.amount} ${uppercase(fee.currency)}`;

export const selectedMonthlyFees = (monthlyFeeConfigurations, selectedMonthlyItems) => {
    const selectedOptions = [];

    if (selectedMonthlyItems.length > 0) {
        selectedMonthlyItems.forEach((monthlyItemId) => {
            const monthlyFee = monthlyFeeConfigurations.filter((fee) => fee.id === monthlyItemId);
    
            selectedOptions.push({
                value: monthlyFee[0].id,
                label: buildMonthlyFeeLabel(monthlyFee[0]),
            });
        });
    }

    return selectedOptions;
};

export const monthlyFeesOptions = (monthlyFeeConfigurations) => {
    const options = [];

    monthlyFeeConfigurations.map((fee) => {
        options.push({
            value: fee.id,
            label: buildMonthlyFeeLabel(fee),
        });

        return fee;
    });

    return options;
};
