import React, { useCallback, useMemo, useState } from 'react';
import { Card, Dimmer, Icon, Table } from 'tabler-react';
import PropTypes from 'prop-types';
import {
    capitalize,
    formatAmountAndCurrency,
    formatDateAndTime,
    transactionDirection,
    transactionStatus,
    transactionType,
} from '../../../../helpers';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { createApiClient } from '../../../../services/api-client';

import './TransactionInformation.scss';

const TransactionInformation = ({ transactionData, isLoading, fetchTransaction }) => {
    const [editingProviderKey, setEditingProviderKey] = useState(false);
    const [newProviderKey, setNewProviderKey] = useState('');
    const [changingProviderKey, setChangingProviderKey] = useState(false);

    const api = useMemo(() => createApiClient(), []);

    const changeTransactionProviderKey = useCallback(async () => {
        setChangingProviderKey(true);

        try {
            await api.put(`admin-transaction/transactions/${transactionData.id}/change-provider`, {
                provider_key: newProviderKey,
            });
            fetchTransaction();
            setNewProviderKey('');
            setEditingProviderKey(false);
        } catch (error) {
            toast.error(`Something went wrong: ${error.data.message}`);
        } finally {
            setChangingProviderKey(false);
        }
    }, [newProviderKey, fetchTransaction, transactionData.id, api]);

    const onProviderKeySaveClick = () => {
        if (!changingProviderKey) {
            changeTransactionProviderKey();
        }
    };

    const onProviderKeyEditClick = () => {
        setEditingProviderKey(true);
    };

    const onProviderKeyEditCancelClick = () => {
        setEditingProviderKey(false);
    };

    const renderProviderKeyEdit = () => (
        <div>
            <input type='text' onChange={({ target }) => setNewProviderKey(target.value)} value={newProviderKey}/>
            {
                !changingProviderKey && <div>
                    <Icon className='icon' name='x' onClick={onProviderKeyEditCancelClick}/>
                    <Icon className='icon submit-icon' name='chevrons-right' onClick={onProviderKeySaveClick}/>
                </div>
            }
        </div>
    );

    const renderAmounts = () => {
        if (transactionData.exchange_amount) {
            return (
                <>
                    <Table.Row>
                        <Table.Col>Amount</Table.Col>
                        <Table.Col>
                            {formatAmountAndCurrency(transactionData.exchange_amount, transactionData.exchange_currency)}{' '}
                            ({formatAmountAndCurrency(transactionData.amount, transactionData.currency)})
                        </Table.Col>
                    </Table.Row>
                    <Table.Row>
                        <Table.Col>Exchange Rate</Table.Col>
                        <Table.Col>{transactionData.exchange_rate}</Table.Col>
                    </Table.Row>
                </>
            );
        }

        return (
            <Table.Row>
                <Table.Col>Amount</Table.Col>
                <Table.Col>{formatAmountAndCurrency(transactionData.amount, transactionData.currency)}</Table.Col>
            </Table.Row>
        );
    };

    return (
        <Card className='TransactionInformation'>
            <Dimmer active={isLoading} loader>
                <Card.Header>
                    <Card.Title>Transaction information</Card.Title>
                    <Card.Options>{transactionStatus(transactionData.status)}</Card.Options>
                </Card.Header>
                <Table className='small' striped={true} cards={true}>
                    <Table.Body>
                        <Table.Row>
                            <Table.Col>Id</Table.Col>
                            <Table.Col>{transactionData.id}</Table.Col>
                        </Table.Row>
                        {
                            transactionData.refund_transaction_id && <Table.Row>
                                <Table.Col>Refund transaction id</Table.Col>
                                <Table.Col>{transactionData.refund_transaction_id}</Table.Col>
                            </Table.Row>
                        }
                        <Table.Row>
                            <Table.Col>Number</Table.Col>
                            <Table.Col>{transactionData.number}</Table.Col>
                        </Table.Row>
                        <Table.Row>
                            <Table.Col>Sender</Table.Col>
                            <Table.Col>
                                <Link to={{ pathname: `/users/${transactionData.sender_user_id}` }}>
                                    {capitalize(transactionData.sender_name)}
                                </Link>
                            </Table.Col>
                        </Table.Row>
                        <Table.Row>
                            <Table.Col>Sender account</Table.Col>
                            <Table.Col>{transactionData.sender_account}</Table.Col>
                        </Table.Row>
                        <Table.Row>
                            <Table.Col>Receiver</Table.Col>
                            <Table.Col>
                                {transactionData.receiver_user_id !== null ? (
                                    <Link
                                        to={{ pathname: `/users/${transactionData.receiver_user_id}` }}>
                                        {transactionData.receiver_name}
                                    </Link>
                                ) : (
                                    transactionData.receiver_name
                                )}
                            </Table.Col>
                        </Table.Row>
                        <Table.Row>
                            <Table.Col>Receiver account</Table.Col>
                            <Table.Col>{transactionData.receiver_account}</Table.Col>
                        </Table.Row>
                        <Table.Row>
                            <Table.Col>Receiver address</Table.Col>
                            <Table.Col>{transactionData.receiver_address || '-'}</Table.Col>
                        </Table.Row>
                        {renderAmounts()}
                        <Table.Row>
                            <Table.Col>Fee amount</Table.Col>
                            <Table.Col>{formatAmountAndCurrency(transactionData.fee_amount, transactionData.currency)}</Table.Col>
                        </Table.Row>
                        <Table.Row>
                            <Table.Col>Details</Table.Col>
                            <Table.Col>{transactionData.details}</Table.Col>
                        </Table.Row>
                        <Table.Row>
                            <Table.Col>Direction</Table.Col>
                            <Table.Col>{transactionDirection(transactionData.direction)}</Table.Col>
                        </Table.Row>
                        <Table.Row>
                            <Table.Col>Type</Table.Col>
                            <Table.Col>{transactionType(transactionData.type)}</Table.Col>
                        </Table.Row>
                        <Table.Row>
                            <Table.Col>Provider Key</Table.Col>
                            <Table.Col>
                                {transactionData.provider_key}
                                {!editingProviderKey && <Icon className='icon edit-icon' name='edit' onClick={onProviderKeyEditClick}/>}
                                {
                                    editingProviderKey && renderProviderKeyEdit()
                                }
                            </Table.Col>
                        </Table.Row>
                        <Table.Row>
                            <Table.Col>Created at:</Table.Col>
                            <Table.Col>{formatDateAndTime(transactionData.created_at)}</Table.Col>
                        </Table.Row>
                        {transactionData.completed_at !== null &&
                        <Table.Row>
                            <Table.Col>Completed at:</Table.Col>
                            <Table.Col>{formatDateAndTime(transactionData.completed_at)}</Table.Col>
                        </Table.Row>
                        }
                    </Table.Body>
                </Table>
            </Dimmer>
        </Card>
    );
};

TransactionInformation.propTypes = {
    fetchTransaction: PropTypes.func.isRequired,
    transactionData: PropTypes.shape({
        id: PropTypes.string,
        receiver_address: PropTypes.string,
        currency: PropTypes.string,
        created_at: PropTypes.string,
        provider_key: PropTypes.string,
        status: PropTypes.string,
        number: PropTypes.string,
        sender_name: PropTypes.string,
        sender_user_id: PropTypes.number,
        sender_account: PropTypes.string,
        receiver_user_id: PropTypes.number,
        receiver_name: PropTypes.string,
        receiver_account: PropTypes.string,
        amount: PropTypes.string,
        fee_amount: PropTypes.string,
        details: PropTypes.string,
        direction: PropTypes.string,
        type: PropTypes.string,
        completed_at: PropTypes.oneOfType([PropTypes.oneOf([null]), PropTypes.string]),
        refund_transaction_id: PropTypes.number,
        exchange_rate: PropTypes.string,
        exchange_amount: PropTypes.string,
        exchange_currency: PropTypes.string,
    }),
    isLoading: PropTypes.bool.isRequired,
};

export default TransactionInformation;
