import { useEffect, useRef, useState, useCallback } from 'react';

export const useDidUpdate = (callback, deps) => {
    const hasMount = useRef(false);

    useEffect(() => {
        if (hasMount.current) {
            callback();
        } else {
            hasMount.current = true;
        }
    }, [callback, deps]);
};

export const usePrevious = (value) => {
    const ref = useRef(null);
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
};

export const useTabs = (initialURI, options, fetchFunc) => {
    const [fetchURI, setFetchURI] = useState(initialURI);
    const [didMount, setDidMount] = useState(false);

    const handleTabChange = useCallback((value) => {
        if (value === fetchURI) {
            return;
        }
        
        setFetchURI(value);
    }, [fetchURI]);

    useEffect(() => {
        if (!didMount) {
            setDidMount(true);
            return;
        }

        if (!fetchFunc) {
            return;
        }
        
        fetchFunc();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fetchURI]);

    return [handleTabChange, fetchURI];
};
