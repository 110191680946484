import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import SiteWrapper from '../../../SiteWrapper';
import { Button, Card, Dimmer, Form, Grid } from 'tabler-react';
import { createApiClient as api } from '../../../../services/api-client';
import PropTypes from 'prop-types';
import { fetchCurrencies } from '../../../../actions/currency/currencies';
import ApiError from '../../../Common/ApiError';
import AmountField from '../../../Common/FilterFormFields/AmountField';
import { isLoading } from '../../../../selectors/isLoading';
import { navigate } from '../../../../actions/navigate';
import { toast } from 'react-toastify';
import InputTextField from '../../../Common/FilterFormFields/InputTextField';

class MonthlyFeesCreate extends Component {
    constructor (props) {
        super(props);

        this.state = {
            isLoading: false,
            error: null,
            fee: {
                name: '',
                amount: '',
                currency: '',
                wallet_type: null,
            },
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount () {
        this.props.fetchCurrencies().then((currencies) => {
            if (currencies.length > 0) {
                this.setState((state) => ({
                    fee: {
                        ...state.fee,
                        currency: currencies[0].code,
                    },
                }));
            }
        });

        // this.props.fetchFeeWalletRelations().then((feeWallets) => {
        //     if (feeWallets.length > 0) {
        //         this.setState((state) => ({
        //             fee: {
        //                 ...state.fee,
        //                 fee_wallet_relation_id: feeWallets[0].id,
        //             },
        //         }));
        //     }
        // });
    }

    handleSubmit (event) {
        event.preventDefault();

        this.setState({
            isLoading: true,
            error: null,
        });

        api()
            .post('/admin-fee/monthly-fee-configurations', {
                ...this.state.fee,
                // fee_wallet_relation_id: toNumber(this.state.fee.fee_wallet_relation_id),
            })
            .then(() => {
                toast.success('Created successful');
                this.props.navigate('/fee/monthly-fees');
            })
            .catch((data) => {
                this.setState({
                    error: data.data,
                    isLoading: false,
                });
            });
    }

    handleChange (event) {
        const { target } = event;

        this.setState((prevState) => ({
            fee: {
                ...prevState.fee,
                [target.name]: target.value.length > 0 ? target.value : '',
            },
        }));
    }

    isLoading () {
        return this.state.isLoading || this.props.isFetchingCurrencies;
    }

    render () {
        const { fee, error } = this.state;
        const { currencies, navigate } = this.props;

        return (
            <SiteWrapper title='Create monthly fee'>
                <Grid.Row>
                    <Grid.Col md={4} sm={6} xs={12}>
                        <Card>
                            <form onSubmit={this.handleSubmit}>
                                <Dimmer active={this.isLoading()} loader>
                                    <Card.Body>
                                        <InputTextField name='name' title='Name' value={fee.name} handleChange={this.handleChange} />
                                        <AmountField
                                            name='amount'
                                            title='Amount'
                                            value={fee.amount}
                                            handleChange={this.handleChange}
                                            maxAmount={10000}
                                        />
                                        <Form.Select name='currency' label='Currency' value={fee.currency} onChange={this.handleChange}>
                                            {currencies.map((currency) => (
                                                <option key={currency.code} value={currency.code}>
                                                    {currency.code.toUpperCase()}
                                                </option>
                                            ))}
                                        </Form.Select>
                                        <Form.Select
                                            name='wallet_type'
                                            label='Wallet Type'
                                            value={fee.wallet_type}
                                            onChange={this.handleChange}
                                        >
                                            <option></option>
                                            <option key='mano_bank' value='mano_bank'>
                                                ManoBank
                                            </option>
                                        </Form.Select>
                                        {/*<Form.Select*/}
                                        {/*    name='fee_wallet_relation_id'*/}
                                        {/*    label='Collect to'*/}
                                        {/*    value={fee.fee_wallet_relation_id}*/}
                                        {/*    onChange={this.handleChange}*/}
                                        {/*>*/}
                                        {/*    {feeWallets.map((feeWallet) => (*/}
                                        {/*        <option key={feeWallet.id} value={feeWallet.id}>*/}
                                        {/*            {feeWallet.name} ({feeWallet.wallet_number}, {feeWallet.owner_name})*/}
                                        {/*        </option>*/}
                                        {/*    ))}*/}
                                        {/*</Form.Select>*/}
                                        <ApiError error={error} />
                                    </Card.Body>
                                    <Card.Footer className='text-center'>
                                        <div className='d-flex'>
                                            <Button type='button' link onClick={() => navigate('/fee/monthly-fees')}>
                                                Cancel
                                            </Button>

                                            <Button type='submit' color='primary' className='ml-auto' onClick={this.handleSubmit}>
                                                Submit
                                            </Button>
                                        </div>
                                    </Card.Footer>
                                </Dimmer>
                            </form>
                        </Card>
                    </Grid.Col>
                </Grid.Row>
            </SiteWrapper>
        );
    }
}

MonthlyFeesCreate.propTypes = {
    navigate: PropTypes.func.isRequired,

    currencies: PropTypes.array.isRequired,
    fetchCurrencies: PropTypes.func.isRequired,
    isFetchingCurrencies: PropTypes.bool.isRequired,

    // feeWallets: PropTypes.array.isRequired,
    // fetchFeeWalletRelations: PropTypes.func.isRequired,
    // isFetchingFeeWallets: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
    currencies: state.currency.currencies,
    isFetchingCurrencies: isLoading(state, 'currencies'),
    // feeWallets: state.fee.wallets,
    // isFetchingFeeWallets: isLoading(state, 'feeWallets'),
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            fetchCurrencies,
            // fetchFeeWalletRelations,
            navigate,
        },
        dispatch,
    );

export default connect(mapStateToProps, mapDispatchToProps)(MonthlyFeesCreate);
