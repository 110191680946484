export const convertSelectValue = (value) => {
    switch (value) {
        case 'true':
            return true;
        case 'false':
            return false;
        case null:
            return '';
        default:
            return value;
    }
};
