import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import ReactTable from 'react-table';
import { Card, Icon } from 'tabler-react';
import './ScoringRulesTable.scss';

const ScoringRulesTables = ({ data, isLoading }) => {
    const columns = useMemo(
        () => [
            {
                Header: 'Rules',
                accessor: 'rule',
                className: 'align-self-center',
                sortable: false,
                Cell: (row) => row.value,
            },
            {
                Header: 'Triggered',
                accessor: 'isTriggered',
                sortable: false,
                className: 'text-center triggeredRules text-danger',
                width: 150,
                Cell: (row) => row.value === true && <Icon name='alert-triangle' />,
            },
        ],
        [],
    );

    return (
        <Card>
            <Card.Header>
                <Card.Title>Scoring rules</Card.Title>
            </Card.Header>
            <Card.Body>
                <ReactTable
                    manual
                    data={data}
                    loading={isLoading}
                    columns={columns}
                    className='-striped -highlight'
                    multiSort={false}
                    minRows={1}
                    showPagination={false}
                />
            </Card.Body>
        </Card>
    );
};

ScoringRulesTables.propTypes = {
    data: PropTypes.array.isRequired,
    isLoading: PropTypes.bool.isRequired,
};

export default ScoringRulesTables;
