import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'tabler-react';
import isUndefined from 'lodash/isUndefined';

class Checkboxes extends Component {
    resolveName (answer) {
        const { field } = this.props;

        if (!isUndefined(field.values) && !isUndefined(field.values[answer])) {
            return field.values[answer];
        }

        return answer;
    }

    render () {
        const { answer } = this.props;

        return answer.map((key) => (
            <div key={key}>
                <Icon prefix='fe' name='check-square' /> {this.resolveName(key)}
            </div>
        ));
    }
}

Checkboxes.propTypes = {
    answer: PropTypes.oneOfType([PropTypes.array]),
    field: PropTypes.object.isRequired,
};

export default Checkboxes;
