import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Icon, Button } from 'tabler-react';
import Modal from 'react-modal';
import { createApiClient as api } from '../../../services/api-client';
import ApiError from '../../Common/ApiError';
import SelectField from '../../Common/FilterFormFields/SelectField';
import InputTextField from '../../Common/FilterFormFields/InputTextField';

const ACCEPT_REQMP_REASONS = {
    CVAA: 'Rejected Value Date Adjustment',
    RJVA: 'Rejected Value Date Adjustment',
};

const DEFAULT_PROPRIETARY_VALUE = 'ST2';
const DEFAULT_FORM_VALUES = {
    investigation_status: '',
    proprietary: '',
};

const reasonOptions = Object.entries(ACCEPT_REQMP_REASONS).map(([key, description]) => ({ value: key, title: `${key} (${description})` }));

const RejectReqmpModal = ({ visible, onModalClose, selectedTransaction, onRejectModification }) => {
    const [formValues, setFormValues] = useState(DEFAULT_FORM_VALUES);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const handleModalClose = useCallback(() => {
        onModalClose();
        setFormValues(DEFAULT_FORM_VALUES);
    }, [onModalClose]);

    const handleRejectModification = useCallback(
        async (event) => {
            event.preventDefault();

            if (!selectedTransaction) {
                return;
            }

            setLoading(true);

            try {
                await api().put(`/admin-sepa-integration/payment-modification-requests/${selectedTransaction.id}/reject`, formValues);
                setLoading(false);
                onRejectModification();
                setFormValues(DEFAULT_FORM_VALUES);
            } catch (error) {
                setError(error.data);
                setLoading(false);
            }
        },
        [formValues, onRejectModification, selectedTransaction],
    );

    const handleChange = (event) => {
        const { target } = event;

        if (
            target.name === 'investigation_status' &&
            target.value &&
            target.value.length > 0 &&
            !formValues.investigation_status.length > 0
        ) {
            setFormValues((prev) => ({ ...prev, proprietary: DEFAULT_PROPRIETARY_VALUE }));
        }

        setFormValues((prev) => ({ ...prev, [target.name]: target.value }));
    };

    return (
        <Modal isOpen={visible} className='react-modal'>
            <Icon name='x-circle' className='w-4 react-modal__closeButton' onClick={handleModalClose} />

            <div className='react-modal__content'>
                <form onSubmit={handleRejectModification}>
                    <ApiError error={error} />

                    <SelectField
                        name='investigation_status'
                        title='The status of investigation'
                        handleChange={handleChange}
                        options={reasonOptions}
                        value={formValues.investigation_status}
                    />

                    {formValues.investigation_status.length > 0 && (
                        <InputTextField name='proprietary' title='Proprietary' handleChange={handleChange} value={formValues.proprietary} />
                    )}

                    <div className='buttonContainer'>
                        <Button onClick={handleModalClose} color='secondary' disabled={loading} loading={loading}>
                            Close
                        </Button>

                        <Button type='submit' color='primary' disabled={loading} loading={loading}>
                            Reject
                        </Button>
                    </div>
                </form>
            </div>
        </Modal>
    );
};

RejectReqmpModal.propTypes = {
    visible: PropTypes.bool.isRequired,
    onModalClose: PropTypes.func.isRequired,
    onRejectModification: PropTypes.func.isRequired,
    selectedTransaction: PropTypes.object,
};

export default RejectReqmpModal;
