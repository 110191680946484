import React, { Component } from 'react';
import { Form } from 'tabler-react';
import PropTypes from 'prop-types';

class Type extends Component {
    render () {
        const { value, onChange } = this.props;

        return (
            <Form.Group>
                <Form.SelectGroup>
                    <Form.SelectGroupItem
                        label='Fixed fee'
                        name='type'
                        value='fixed'
                        checked={value === 'fixed'}
                        onChange={onChange}
                    />
                    <Form.SelectGroupItem
                        label='Percent fee'
                        name='type'
                        value='percent'
                        checked={value === 'percent'}
                        onChange={onChange}
                    />
                    <Form.SelectGroupItem
                        label='Combined fee'
                        name='type'
                        value='combined'
                        checked={value === 'combined'}
                        onChange={onChange}
                    />
                </Form.SelectGroup>
            </Form.Group>
        );
    }
}

Type.propTypes = {
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string,
};

export default Type;
