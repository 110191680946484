import { Form } from 'tabler-react';
import React, { Component } from 'react';
import PropTypes from 'prop-types';

class ReturnFee extends Component {
    render () {
        const { value, onChange } = this.props;

        return (
            <Form.Checkbox
                checked={value === true}
                isInline
                label='Return fee on transaction refund/chargeback'
                value={value}
                name={'return_fee'}
                onChange={onChange}
            />
        );
    }
}

ReturnFee.propTypes = {
    onChange: PropTypes.func.isRequired,
    value: PropTypes.bool,
};

export default ReturnFee;
