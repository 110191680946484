import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Button } from 'tabler-react';
import { toast } from 'react-toastify';
import { riskLevelUpdate } from '../../../../../../../actions/user/risk-level';
import { isLoading } from '../../../../../../../selectors/isLoading';

import './RiskLevelSelect.scss';

const RiskLevelSelect = ({ user, onChange, riskLevel, updateUserState, riskLevelUpdate, isUpdatingRiskLevel }) => {
    const [riskChangeReason, setRiskChangeReason] = useState('');

    const isUpdateRiskLevelButtonAvailable = user.risk_level !== riskLevel && riskLevel && user.is_verified;

    const data = {
        risk_level: riskLevel,
        risk_change_reason: riskChangeReason,
    };

    const updateRiskLevel = useCallback(async () => {
        try {
            const response = await riskLevelUpdate(user.id, data);

            updateUserState('risk_level', response.risk_level);
            setRiskChangeReason('');
            toast.success('User risk level updated successfully');
        } catch (error) {
            toast.error(error.data.message);
        }
    }, [riskLevelUpdate, user.id, data, updateUserState]);

    return (
        <div className='RiskLevelSelect'>
            <select className='w-100' name='risk_level' onChange={onChange} value={riskLevel || ''}>
                {((riskLevel === null && user.risk_level === null) || user.is_verified === false) && <option />}
                <option value='low'>Low</option>
                <option value='medium'>Medium</option>
                <option value='high'>High</option>
            </select>

            {
                isUpdateRiskLevelButtonAvailable
                && <div>
                    <textarea
                        rows={5}
                        placeholder='Change reason'
                        className='w-100 mt-1'
                        minLength={5}
                        maxLength={150}
                        onChange={({ target }) => setRiskChangeReason(target.value)}
                        value={riskChangeReason}
                    />
                    <Button
                        disabled={riskChangeReason.length < 5}
                        loading={isUpdatingRiskLevel}
                        size='sm'
                        onClick={updateRiskLevel}
                        color='primary'
                        className='w-100'
                    >
                        Update
                    </Button>
                </div>
            }
        </div>
    );
};

RiskLevelSelect.propTypes = {
    user: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    riskLevel: PropTypes.string,
    updateUserState: PropTypes.func.isRequired,
    riskLevelUpdate: PropTypes.func.isRequired,
    isUpdatingRiskLevel: PropTypes.bool,
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            riskLevelUpdate,
        },
        dispatch,
    );

const mapStateToProps = (state) => ({
    isUpdatingRiskLevel: isLoading(state, 'riskLevel'),
});

export default connect(mapStateToProps, mapDispatchToProps)(RiskLevelSelect);
