import React, { Component } from 'react';
import { Card, Dimmer, Table, Text } from 'tabler-react';
import PropTypes from 'prop-types';
import { formatDateAndTime, userStatus, userStatusColor } from '../../../../../../helpers';

class MainData extends Component {
    renderBody () {
        const { user } = this.props;

        return (
            <>
                <Dimmer loader>
                    <Table className='small' striped={true} cards={true}>
                        <Table.Body>
                            <Table.Row>
                                <Table.Col>Email</Table.Col>
                                <Table.Col>{user.email}</Table.Col>
                            </Table.Row>

                            <Table.Row>
                                <Table.Col>Phone</Table.Col>
                                <Table.Col>{user.phone}</Table.Col>
                            </Table.Row>

                            <Table.Row>
                                <Table.Col>Type</Table.Col>
                                <Table.Col className='capitalize-first-letter'>{user.type}</Table.Col>
                            </Table.Row>
                        </Table.Body>
                    </Table>
                </Dimmer>
                <Card.Footer>
                    <Text.Small>Created at {formatDateAndTime(user.created_at)}</Text.Small>
                </Card.Footer>
            </>
        );
    }

    render () {
        const { user } = this.props;

        return (
            <Card statusColor={userStatusColor(user.is_verified)}>
                <Card.Header>
                    <Card.Title>General information</Card.Title>
                    <Card.Options>
                        {userStatus(user.is_verified)}
                    </Card.Options>
                </Card.Header>
                {this.renderBody()}
            </Card>
        );
    }
}

MainData.propTypes = {
    user: PropTypes.object.isRequired,
};

export default MainData;
