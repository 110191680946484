import React, { useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Icon, Button } from 'tabler-react';
import Modal from 'react-modal';
import { createApiClient as api } from '../../../services/api-client';
import ApiError from '../../Common/ApiError';
import SelectField from '../../Common/FilterFormFields/SelectField';
import InputTextField from '../../Common/FilterFormFields/InputTextField';

const CANCEL_TRANSACTION_REASONS = {
    CUST: 'Requested By Customer',
    DUPL: 'Duplicate Payment',
    AC03: 'Wrong IBAN',
    AM09: 'Wrong Amount',
    FRAD: 'Fraudulent Original Credit or Debit Transfer',
    TECH: 'Technical Problem',
};
const ADDITIONAL_INFORMATION_REASONS = ['CUST', 'FRAD', 'AM09', 'AC03'];

const DEFAULT_FORM_VALUES = {
    reason_code: '',
    additional_information: '',
};

const reasonOptions = Object.entries(CANCEL_TRANSACTION_REASONS).map(([key, description]) => ({
    value: key,
    title: `${key} (${description})`,
}));

const CancelTransactionModal = ({ visible, onModalClose, onCancelTransaction, selectedTransaction }) => {
    const [formValues, setFormValues] = useState(DEFAULT_FORM_VALUES);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const handleModalClose = useCallback(() => {
        onModalClose();
        setFormValues(DEFAULT_FORM_VALUES);
    }, [onModalClose]);

    const handleCancelTransaction = useCallback(
        async (event) => {
            event.preventDefault();

            if (!selectedTransaction) {
                return;
            }

            setLoading(true);

            try {
                await api().put(`/admin-sepa-integration/transactions/${selectedTransaction.id}/cancel`, formValues);
                setLoading(false);
                onCancelTransaction();
                setFormValues(DEFAULT_FORM_VALUES);
            } catch (error) {
                setError(error.data);
                setLoading(false);
            }
        },
        [formValues, onCancelTransaction, selectedTransaction],
    );

    const handleChange = (event) => {
        const { target } = event;

        setFormValues((prev) => ({ ...prev, [target.name]: target.value }));
    };

    const isAdditionalInformationVisible = useMemo(() => {
        const hasFlag = ADDITIONAL_INFORMATION_REASONS.includes(formValues.reason_code);

        if (!hasFlag) {
            setFormValues((prev) => ({ ...prev, additional_information: DEFAULT_FORM_VALUES.additional_information }));
        }

        return hasFlag;
    }, [formValues.reason_code]);

    return (
        <Modal isOpen={visible} className='react-modal'>
            <Icon name='x-circle' className='w-4 react-modal__closeButton' onClick={handleModalClose} />

            <div className='react-modal__content'>
                <form onSubmit={handleCancelTransaction}>
                    <ApiError error={error} />

                    <SelectField
                        name='reason_code'
                        title='Reason'
                        handleChange={handleChange}
                        options={reasonOptions}
                        value={formValues.reason_code}
                    />

                    {isAdditionalInformationVisible && (
                        <InputTextField
                            name='additional_information'
                            title='Additional information'
                            handleChange={handleChange}
                            value={formValues.additional_information}
                        />
                    )}

                    <div className='buttonContainer'>
                        <Button onClick={handleModalClose} color='secondary' disabled={loading} loading={loading}>
                            Close
                        </Button>

                        <Button type='submit' color='primary' disabled={loading} loading={loading}>
                            Submit
                        </Button>
                    </div>
                </form>
            </div>
        </Modal>
    );
};

CancelTransactionModal.propTypes = {
    visible: PropTypes.bool.isRequired,
    onModalClose: PropTypes.func.isRequired,
    onCancelTransaction: PropTypes.func.isRequired,
    selectedTransaction: PropTypes.object,
};

export default CancelTransactionModal;
