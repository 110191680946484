import React, { Component } from 'react';
import PropTypes from 'prop-types';
import AmountFields from '../../../../Common/FilterFormFields/AmountField';

class FixedFeeFields extends Component {
    render () {
        const { value, onChange } = this.props;

        return <AmountFields name='amount' title='Amount' value={value} handleChange={onChange} maxAmount={10000} />;
    }
}

FixedFeeFields.propTypes = {
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string,
};

export default FixedFeeFields;
