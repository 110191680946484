import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { createApiClient } from '../../../../../services/api-client';
import isUndefined from 'lodash/isUndefined';
import Group from './Group';

class Groups extends Component {
    constructor (props) {
        super(props);

        this.state = {
            permissions: [],
        };

        this.api = createApiClient();

        this.onCheckboxChange = this.onCheckboxChange.bind(this);
    }

    componentDidMount () {
        const { permissions } = this.state;

        if (permissions.length !== 0) {
            this.fetchPermissions();
        }
    }

    onCheckboxChange (groupId, permission) {
        const { groups } = this.props;
        const group = groups.find((group) => group.id === groupId);
        const permissionInGroup = group.permissions.find((groupPermissions) => groupPermissions.name === permission.name);

        if (isUndefined(permissionInGroup)) {
            group.permissions.push(permission);
        } else {
            group.permissions = group.permissions.filter((groupPermissions) => groupPermissions.name !== permission.name);
        }

        this.setState({
            group,
        });
    }

    render () {
        const { groups } = this.props;
        const { permissions } = this.props;

        return groups.map((group, key) => (
            <Group key={key} group={group} onCheckboxChange={this.onCheckboxChange} permissions={permissions} />
        ));
    }
}

Groups.propTypes = {
    groups: PropTypes.array.isRequired,
    permissions: PropTypes.array.isRequired,
};

export default Groups;
