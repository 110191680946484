import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import { Button, Icon } from 'tabler-react';
import { createApiClient as api } from '../../../../services/api-client';
import ApiError from '../../../Common/ApiError';
import { toast } from 'react-toastify';
import InputTextField from '../../../Common/FilterFormFields/InputTextField';

const EditBankAccountModal = ({ visible, onCloseModal, onBankAccountEdit, selectedValue }) => {
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [formInputs, setFormInputs] = useState({
        account_holder_name: '',
        iban: '',
        bic: '',
        purpose: '',
    });

    useEffect(() => {
        if (selectedValue) {
            setFormInputs(selectedValue);
        }
    }, [selectedValue]);

    const handleSubmit = async (event) => {
        event.preventDefault();

        setLoading(true);

        try {
            await api().put(`/admin-fee/external-fee-accounts/${selectedValue.id}`, formInputs);
            toast.success('Bank account edited successfully');

            onBankAccountEdit();
            onCloseModal();
        } catch (error) {
            setError(error.data);
        } finally {
            setLoading(false);
        }
    };

    const handleClose = () => {
        onCloseModal();
    };

    const onInputChange = (event) => {
        const { target } = event;

        setFormInputs((prev) => ({
            ...prev,
            [target.name]: target.value,
        }));
    };

    return (
        <Modal isOpen={visible} className='react-modal'>
            <Icon name='x-circle' className='w-4 react-modal__closeButton' onClick={onCloseModal} />
            <div className='react-modal__content'>
                <form onSubmit={handleSubmit}>
                    <ApiError error={error} />

                    <InputTextField
                        name='account_holder_name'
                        title='Account holder name'
                        value={formInputs.account_holder_name}
                        handleChange={onInputChange}
                        required
                    />

                    <InputTextField name='iban' title='IBAN' value={formInputs.iban} handleChange={onInputChange} required />

                    <InputTextField name='bic' title='BIC' value={formInputs.bic} handleChange={onInputChange} required />

                    <InputTextField name='purpose' title='Purpose' value={formInputs.purpose} handleChange={onInputChange} required />

                    <div className='buttonContainer'>
                        <Button onClick={handleClose} color='secondary' disabled={loading} loading={loading}>
                            Cancel
                        </Button>

                        <Button type='submit' color='primary' onClick={handleSubmit} disabled={loading} loading={loading}>
                            Save
                        </Button>
                    </div>
                </form>
            </div>
        </Modal>
    );
};

EditBankAccountModal.propTypes = {
    visible: PropTypes.bool.isRequired,
    onCloseModal: PropTypes.func.isRequired,
    onBankAccountEdit: PropTypes.func.isRequired,
    selectedValue: PropTypes.object,
};

export default EditBankAccountModal;
