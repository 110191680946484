import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Card, Table } from 'tabler-react';
import VerifyButton from './Actions/VerifyButton';
import PricingGroupSelect from './Actions/PricingGroupSelect';
import RiskLevelSelect from './Actions/RiskLevelSelect';
import ProviderSelect from './Actions/ProviderSelect';
import { formatDateAndTime } from '../../../../../../helpers';

// TODO: refactor this component
class Actions extends Component {
    constructor (props) {
        super(props);

        this.state = {
            verificationData: {
                risk_level: null,
                pricing_group_id: null,
                provider: null,
            },
        };

        this.updateVerificationData = this.updateVerificationData.bind(this);
    }

    updateVerificationData (event) {
        const { name, value } = event.target;

        this.setState((prevState) => ({
            verificationData: {
                ...prevState.verificationData,
                [name]: value,
            },
        }));
    }

    componentDidUpdate (prevProps) {
        const { user, napierData } = this.props;

        if (
            user.risk_level !== prevProps.user.risk_level
            || napierData.risk_level !== prevProps.napierData.risk_level
        ) {
            this.setState((prevState) => ({
                verificationData: {
                    ...prevState.verificationData,
                    risk_level: user.risk_level || napierData.risk_level,
                },
            }));
        }
    }

    renderVerification () {
        const { verificationData } = this.state;
        const { user, updateUser, updateUserState } = this.props;

        return (
            <>
                <Table.Row>
                    <Table.Col>Risk level</Table.Col>
                    <Table.Col>
                        <RiskLevelSelect
                            onChange={this.updateVerificationData}
                            user={user}
                            riskLevel={verificationData.risk_level || user.risk_level}
                            updateUserState={updateUserState}
                        />
                    </Table.Col>
                </Table.Row>

                {
                    user.last_risk_level_audit_record !== null
                    && <Table.Row>
                        <Table.Col>
                            Last risk level audit
                        </Table.Col>
                        <Table.Col>
                            <div className='font-weight-bold'>
                                {formatDateAndTime(user.last_risk_level_audit_record.created_at)}
                            </div>
                            {user.last_risk_level_audit_record.details}
                        </Table.Col>
                    </Table.Row>
                }

                <Table.Row>
                    <Table.Col>Pricing</Table.Col>
                    <Table.Col>
                        <PricingGroupSelect disabled={user.is_verified} onChange={this.updateVerificationData} user={user} />
                    </Table.Col>
                </Table.Row>

                {!user.is_verified &&
                <Table.Row>
                    <Table.Col>Provider</Table.Col>
                    <Table.Col>
                        <ProviderSelect onChange={this.updateVerificationData}/>
                    </Table.Col>
                </Table.Row>
                }

                {!user.is_verified &&
                <Table.Row>
                    <Table.Col>Verify</Table.Col>
                    <Table.Col>
                        <VerifyButton user={user} verificationData={verificationData} updateUser={updateUser} />
                    </Table.Col>
                </Table.Row>
                }
            </>
        );
    }

    render () {
        return (
            <Card>
                <Card.Header>
                    <Card.Title>Actions</Card.Title>
                </Card.Header>
                <Table className='small table-fixed' striped={true} cards={true}>
                    <Table.Body>
                        {this.renderVerification()}
                    </Table.Body>
                </Table>
            </Card>
        );
    }
}

Actions.propTypes = {
    user: PropTypes.object.isRequired,
    updateUser: PropTypes.func.isRequired,
    updateUserState: PropTypes.func.isRequired,
    napierData: PropTypes.object.isRequired,
};

export default Actions;
