import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { Route, Switch } from 'react-router-dom';
import SiteWrapper from '../../SiteWrapper';
import { Grid } from 'tabler-react';
import PropTypes from 'prop-types';
import Wallets from './Wallets/Wallets';
import MainData from './User/MainData';

import { getNumberHash } from '../../../selectors/user/get-participants-information';

class User extends Component {
    constructor (props) {
        super(props);

        this.state = {
            title: 'User',
            subTitle: false,
            user: {},
        };

        this.pages = {
            ['/users/:id']: {
                component: Wallets,
                title: 'Wallets',
                icon: 'credit-card',
            },
        };

        this.fetchCallback = this.fetchCallback.bind(this);
    }

    fetchCallback (user) {
        this.setState({
            subTitle: getNumberHash(user),
            user,
        });
    }

    renderRoutedPages () {
        return Object.keys(this.pages).map((path) => <Route key={path} exact path={path} component={this.pages[path].component} />);
    }

    renderPage () {
        return <Switch>{this.renderRoutedPages()}</Switch>;
    }

    render () {
        const { id } = this.props.match.params;
        const { title, subTitle, user } = this.state;

        return (
            <SiteWrapper title={title} subTitle={subTitle}>
                <Grid.Row>
                    <Grid.Col md={3}>
                        <MainData userId={Number(id)} fetchCallback={this.fetchCallback} />
                    </Grid.Col>
                    <Grid.Col md={9}>
                        <Wallets user={user} isChargeModalButtonVisible={true} />
                    </Grid.Col>
                </Grid.Row>
            </SiteWrapper>
        );
    }
}

User.propTypes = {
    match: PropTypes.object,
};

export default withRouter(User);
