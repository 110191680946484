import React, { Component } from 'react';
import SiteWrapper from '../../SiteWrapper';
import { Button } from 'tabler-react';
import { createApiClient } from '../../../services/api-client';

class OndatoTest extends Component {
    constructor (props) {
        super(props);

        this.state = {
            loading: false,
            url: null,
        };

        this.api = createApiClient();

        this.generateOndatoLink = this.generateOndatoLink.bind(this);
    }

    async generateOndatoLink () {
        this.setState({
            loading: true,
            url: null,
        });

        const response = await this.api.get('/admin-ondato-integration/generate-identity-link');

        this.setState({
            loading: false,
            url: response.data.url,
        });
    }

    render () {
        const { loading, url } = this.state;

        return (
            <SiteWrapper title='Ondato test'>
                <Button disabled={loading} onClick={this.generateOndatoLink}>
                    Generate ondato link
                </Button>
                <div>Link will be generated here: </div>
                {url !== null && (
                    <a href={url} rel='noopener noreferrer' target='_blank'>
                        {url}
                    </a>
                )}
            </SiteWrapper>
        );
    }
}

export default OndatoTest;
