import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { selectedTransactionFees, transactionFeeOptions } from '../services/transaction-fee';
import { CHARGEBACK, DEPOSIT, EXTERNAL_TRANSFER, OUTGOING_TRANSACTION, REFUND } from '../../../../../constants';
import { multiSelectValues } from '../services/pricing-group';

class TransactionFees extends Component {
    constructor (props) {
        super(props);

        this.onChange = this.onChange.bind(this);
        this.formatTransactionNaming = this.formatTransactionNaming.bind(this);
        this.formatTargetName = this.formatTargetName.bind(this);
    }

    onChange (selected, action) {
        const { onChange } = this.props;

        onChange({
            target: {
                name: this.formatTargetName(),
                value: this.getValueOnChange(selected, action),
            },
        });
    }

    formatTargetName () {
        const { transactionDirection, feeTransactionType } = this.props;

        if (transactionDirection) {
            return `${transactionDirection}_${feeTransactionType}`;
        }

        return feeTransactionType;
    }

    getValueOnChange (selected) {
        if (selected === null) {
            return [];
        }

        return multiSelectValues(selected);
    }

    formatTransactionNaming () {
        const { transactionDirection, feeTransactionType, transactionTypes } = this.props;

        if (feeTransactionType === EXTERNAL_TRANSFER) {
            return 'External transaction fees';
        }

        if (transactionTypes.includes(REFUND)) {
            return 'Internal transaction refund fees';
        }

        if (transactionTypes.includes(CHARGEBACK)) {
            return 'Internal transaction chargeback fees';
        }

        if (transactionTypes.includes(DEPOSIT)) {
            return 'Deposit transaction fees';
        }

        if (transactionDirection === OUTGOING_TRANSACTION) {
            return 'Outgoing internal transaction fees';
        }

        return 'Incoming internal transaction fees';
    }

    render () {
        const { transactionsFeeConfigurations, value, transactionTypes, transactionDirection, isMulti } = this.props;

        return (
            <div className='form-group'>
                <label className='form-label'>{this.formatTransactionNaming()}</label>
                <Select
                    isSearchable={true}
                    className='multi-select'
                    isMulti={isMulti}
                    isClearable={true}
                    value={selectedTransactionFees(transactionsFeeConfigurations, value)}
                    onChange={this.onChange}
                    options={transactionFeeOptions(transactionsFeeConfigurations, transactionTypes, transactionDirection)}
                />
            </div>
        );
    }
}

TransactionFees.propTypes = {
    transactionsFeeConfigurations: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.array.isRequired,
    transactionTypes: PropTypes.array.isRequired,
    feeTransactionType: PropTypes.string.isRequired,
    isMulti: PropTypes.bool.isRequired,
    transactionDirection: PropTypes.string,
};

export default TransactionFees;
