import React, { useCallback, useState, useMemo, useEffect } from 'react';
import Modal from 'react-modal';
import PropTypes from 'prop-types';
import { Dimmer, Card, Header, Icon, Table, Button } from 'tabler-react';
import { createApiClient } from '../../../services/api-client';
import Document from './Document';
import UploadButton from '../../Common/Buttons/UploadButton';
import ApiError from '../../Common/ApiError';
import DocumentRequestButton from './Misc/DocumentRequestButton';
import { TRANSACTION_STATUS_MANUAL_APPROVAL } from '../../../constants';

const TransactionDocuments = ({ onModalClose, isModal, isModalOpen, transactionId, title, transactionStatus }) => {
    const [isLoading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [documents, setDocuments] = useState([]);

    const api = useMemo(() => createApiClient(), []);

    const fetchTransactionDocuments = useCallback(async () => {
        setLoading(true);
        setError(null);

        if (!isModalOpen && isModal && transactionId === null) {
            return;
        }

        try {
            const { data } = await api.get(`admin-transaction-document/transactions/${transactionId}/documents`);

            setDocuments(data);
        } catch (error) {
            setError(error.data);
        } finally {
            setLoading(false);
        }
    }, [api, isModal, isModalOpen, transactionId]);

    const resetOnClose = useCallback(() => {
        setError(null);
        setDocuments([]);
        onModalClose();
    }, [onModalClose]);

    useEffect(() => {
        fetchTransactionDocuments();
    }, [fetchTransactionDocuments]);

    const renderUploadButton = () => (
        <Button.List>
            <UploadButton
                updateDocuments={fetchTransactionDocuments}
                uploadUrl={`admin-transaction-document/transactions/${transactionId}/documents`}
            />
            {transactionStatus === TRANSACTION_STATUS_MANUAL_APPROVAL && (
                <DocumentRequestButton transactionId={transactionId} isParentLoading={isLoading} />
            )}
        </Button.List>
    );

    const renderDocumentTable = () => (
        <Table cards={true} responsive={true} highlightRowOnHover={true}>
            <Table.Header>
                <Table.Row>
                    <Table.ColHeader className='w-15 align-middle'>Upload date</Table.ColHeader>
                    <Table.ColHeader className='align-middle'>Document</Table.ColHeader>
                    <Table.ColHeader className='w-15 align-middle'>Uploaded by</Table.ColHeader>
                    <Table.ColHeader className='w-15 text-right align-middle'>Actions</Table.ColHeader>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {documents.map((document) => (
                    <Document
                        key={document.hash}
                        document={document}
                        transactionStatus={transactionStatus}
                        onDocumentRemove={fetchTransactionDocuments}
                        downloadUrl={`admin-transaction-document/documents/${document.id}/download`}
                    />
                ))}
            </Table.Body>
        </Table>
    );

    const renderDocumentsArea = () => (
        <Card>
            <Card.Header>
                <Card.Title>{title}</Card.Title>
                {documents.length > 0 && !isLoading && (
                    <Card.Options>
                        <Button.List>{renderUploadButton()}</Button.List>
                    </Card.Options>
                )}
            </Card.Header>
            <ApiError error={error} />
            <Dimmer active={isLoading} loader>
                {!documents.length && !isLoading ? (
                    <Card.Body>
                        <div className='text-center'>
                            <Header.H3>No documents attached to this transaction</Header.H3>
                            {renderUploadButton()}
                        </div>
                    </Card.Body>
                ) : (
                    renderDocumentTable()
                )}
            </Dimmer>
        </Card>
    );

    const renderModal = () => (
        <Modal color='warning' isOpen={isModalOpen} className='react-modal'>
            <Icon name='x-circle' className='w-4 react-modal__closeButton' onClick={resetOnClose} />
            <div className='transactionModal'>{renderDocumentsArea()}</div>
        </Modal>
    );

    return isModal ? renderModal() : renderDocumentsArea();
};

TransactionDocuments.propTypes = {
    isModalOpen: PropTypes.bool,
    transactionId: PropTypes.number,
    onModalClose: PropTypes.func,
    isModal: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    transactionStatus: PropTypes.string,
};

export default TransactionDocuments;
