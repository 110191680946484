import React, { Component } from 'react';
import PropTypes from 'prop-types';
import String from '../String';
import Country from '../Country';
import { Table } from 'tabler-react';

class FinancialInstitutions extends Component {
    renderInstitution (institution, key) {
        return (
            <Table.Row key={key}>
                <Table.Col>
                    <String answer={institution.financial_institution_name} />
                </Table.Col>
                <Table.Col>
                    <Country answer={institution.financial_institution_country} />
                </Table.Col>
            </Table.Row>
        );
    }

    render () {
        const { answer } = this.props;

        return (
            <Table>
                <Table.Header>
                    <Table.Row>
                        <Table.ColHeader>Name</Table.ColHeader>
                        <Table.ColHeader>Country</Table.ColHeader>
                    </Table.Row>
                </Table.Header>
                <Table.Body>{answer.map((institution, key) => this.renderInstitution(institution, key))}</Table.Body>
            </Table>
        );
    }
}

FinancialInstitutions.propTypes = {
    answer: PropTypes.array,
    field: PropTypes.object.isRequired,
};

export default FinancialInstitutions;
