import { createStore, compose, applyMiddleware } from 'redux';
import reduxImmutableStateInvariant from 'redux-immutable-state-invariant';
import thunk from 'redux-thunk';
import createBrowserHistory from 'history/createBrowserHistory';
import { routerMiddleware } from 'connected-react-router';

import createRootReducer from '../reducers';
import { createApiClient, createApiMiddleware } from '../services/api-client';

export const history = createBrowserHistory();

const configureStoreProd = function (initialState, extraArgument) {
    const reactRouterMiddleware = routerMiddleware(history);
    const middlewares = [createApiMiddleware(extraArgument.api), thunk.withExtraArgument(extraArgument), reactRouterMiddleware];

    return createStore(createRootReducer(history), initialState, compose(applyMiddleware(...middlewares)));
};

const configureStoreDev = function (initialState, extraArgument) {
    const reactRouterMiddleware = routerMiddleware(history);
    const middlewares = [
        reduxImmutableStateInvariant(),
        createApiMiddleware(extraArgument.api),
        thunk.withExtraArgument(extraArgument),
        reactRouterMiddleware,
    ];

    let composeEnhancers;

    if (typeof window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ !== 'undefined') {
        composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ trace: true }) || compose; // add support for Redux dev tools
    } else {
        composeEnhancers = compose; // add support for Redux dev tools
    }

    return createStore(createRootReducer(history), initialState, composeEnhancers(applyMiddleware(...middlewares)));
};

const configureStore = process.env.NODE_ENV === 'production' ? configureStoreProd : configureStoreDev;

export default configureStore(undefined, { api: createApiClient() });
