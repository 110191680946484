import axios from 'axios';

import {
    AUTHENTICATE_USER_SUCCESS,
    CONFIRM_AUTHENTICATION_SUCCESS,
    CREATE_AUTHENTICATION_SUCCESS,
} from '../actions/user/authenticate-user';
import { LOGOUT_USER_SUCCESS/* , logoutUser*/ } from '../actions/user/logout-user';
import { clear, getAccessToken, getRefreshToken, setAccessToken, setRefreshToken } from './authentication-storage';
// import store from '../store/configure-store';
import { toast } from 'react-toastify';
import { config } from '../utils/config';

export const buildAuthorizationHeader = (token) => `Bearer ${token}`;

export const buildApiConfig = () => ({
    baseURL: `${config.apiUrl}`,
    timeout: 10000,
    headers: {
        'Content-Type': 'application/json',
    },
});

export const createApiClient = () => {
    const instance = axios.create(buildApiConfig());

    const access_token = getAccessToken();

    instance.interceptors.response.use(
        (config) => config,
        async (error) => {
            const { response } = error;

            if (typeof response === 'undefined') {
                throw {
                    data: {
                        code: 'unknown_error',
                        message: 'Unknown error',
                    },
                };
            }

            const currentRefreshToken = getRefreshToken();

            if (error.code !== 'ECONNABORTED' && response.status === 401 && currentRefreshToken) {
                try {
                    const newClient = axios.create(buildApiConfig());
                    const response = await newClient
                        .post('/admin-authentication/refresh-token', {
                            refresh_token: currentRefreshToken,
                        })
                        .catch((error) => {
                            if (error.response.status === 400) {
                                // Circular Dependency
                                // store.dispatch(logoutUser());

                                toast.error('Your session has been terminated.');
                            }
                        });

                    const { access_token, refresh_token } = response.data;

                    setAccessToken(access_token);
                    setRefreshToken(refresh_token);

                    instance.defaults.headers.common.Authorization = buildAuthorizationHeader(access_token);

                    const { config } = error;
                    config.headers.Authorization = buildAuthorizationHeader(access_token);
                    delete config.baseURL;

                    return axios(error.config);
                } catch (exception) {
                    delete instance.defaults.headers.common.Authorization;
                    clear();
                }
            }

            throw response;
        },
    );

    if (access_token) {
        instance.defaults.headers.common.Authorization = buildAuthorizationHeader(access_token);
    }

    return instance;
};

export const createApiMiddleware = (instance) => () => (next) => (action) => {
    if (
        action.type === AUTHENTICATE_USER_SUCCESS ||
        action.type === CREATE_AUTHENTICATION_SUCCESS ||
        action.type === CONFIRM_AUTHENTICATION_SUCCESS
    ) {
        const { access_token, refresh_token } = action.payload;

        setAccessToken(access_token);
        setRefreshToken(refresh_token);

        instance.defaults.headers.common.Authorization = buildAuthorizationHeader(access_token);
    } else if (action.type === LOGOUT_USER_SUCCESS) {
        delete instance.defaults.headers.common.Authorization;
        clear();
    }

    return next(action);
};
