import { createAction } from 'redux-actions';
import { push } from 'connected-react-router';
import { buildApiConfig, buildAuthorizationHeader } from '../../services/api-client';
import { getAccessToken } from '../../services/authentication-storage';

export const LOGOUT_USER_SUCCESS = 'user/LOGOUT_USER_SUCCESS';
export const LOGOUT_USER_FAILURE = 'user/LOGOUT_USER_FAILURE';

const logoutUserSuccess = createAction(LOGOUT_USER_SUCCESS);
const logoutUserFailure = createAction(LOGOUT_USER_FAILURE);

export const logoutUser = () => async (dispatch, getState, { api }) => {
    const config = buildApiConfig();
    config.headers.Authorization = buildAuthorizationHeader(getAccessToken());

    dispatch(logoutUserSuccess());

    try {
        await api.post('/admin-authentication/logout', {}, config);
    } catch (error) {
        dispatch(logoutUserFailure(error.data));
    }

    dispatch(push('/'));
};
