import toNumber from 'lodash/toNumber';
import { numberToDecimalString } from '../../../../../services/amount';

export const buildSubmitData = (fee, type) => {
    const data = {
        name: fee.name,
        direction: fee.direction,
        transaction_type: fee.transaction_type,
        currency: fee.currency,
        fee_wallet_relation_id: toNumber(fee.fee_wallet_relation_id),
        countries: fee.countries,
        return_fee: fee.return_fee,
        wallet_type: fee.wallet_type === '' ? null : fee.wallet_type,
    };

    if (type === 'fixed') {
        data.amount = numberToDecimalString(fee.amount);
    } else if (type === 'percent') {
        data.percent = numberToDecimalString(fee.percent);

        const minimum = toNumber(fee.minimum);
        const maximum = toNumber(fee.maximum);

        if (minimum > 0) {
            data.minimum = numberToDecimalString(minimum);
        }

        if (maximum > 0) {
            data.maximum = numberToDecimalString(maximum);
        }
    } else if (type === 'combined') {
        data.amount = numberToDecimalString(fee.amount);
        data.percent = numberToDecimalString(fee.percent);

        const minimum = toNumber(fee.minimum);
        const maximum = toNumber(fee.maximum);

        if (minimum > 0) {
            data.minimum = numberToDecimalString(minimum);
        }

        if (maximum > 0) {
            data.maximum = numberToDecimalString(maximum);
        }
    }

    return data;
};

export const resolveFeeTypeByAmount = (amount, percent) => {
    if (toNumber(amount) > 0 && toNumber(percent) > 0) {
        return 'combined';
    }

    if (toNumber(amount) > 0) {
        return 'fixed';
    }

    return 'percent';
};
