import React, { Component } from 'react';
import { Grid } from 'tabler-react';
import PropTypes from 'prop-types';
import AmountFields from '../../../../Common/FilterFormFields/AmountField';

class PercentFeeFields extends Component {
    render () {
        const { percent, minimum, maximum, onChange } = this.props;

        return (
            <Grid.Row>
                <Grid.Col>
                    <AmountFields name='percent' title='Percent, %' value={percent} handleChange={onChange} maxAmount={100} />
                </Grid.Col>
                <Grid.Col>
                    <AmountFields name='minimum' title='Minimum' value={minimum} handleChange={onChange} maxAmount={10000} />
                </Grid.Col>
                <Grid.Col>
                    <AmountFields name='maximum' title='Maximum' value={maximum} handleChange={onChange} maxAmount={10000} />
                </Grid.Col>
            </Grid.Row>
        );
    }
}

PercentFeeFields.propTypes = {
    onChange: PropTypes.func.isRequired,
    percent: PropTypes.string,
    minimum: PropTypes.string,
    maximum: PropTypes.string,
};

export default PercentFeeFields;
