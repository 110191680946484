import React, { useState, useCallback, useEffect } from 'react';
import { Button, Icon, Form, Dimmer } from 'tabler-react';
import Modal from 'react-modal';
import PropTypes from 'prop-types';
import ApiError from './ApiError';
import { createApiClient as api } from '../../services/api-client';
import { formatDateAndTime } from '../../helpers';
import cx from 'classnames';

const Comments = (props) => {
    const { relationType, relationId, isModalOpen, setCommentsModalVisible } = props;
    const [error, setError] = useState(null);
    const [isLoading, setLoading] = useState(false);
    const [commentInput, setCommentInput] = useState('');
    const [comments, setComments] = useState([]);

    const onCommentInputChange = (event) => setCommentInput(event.target.value);

    const fetchComments = useCallback(async () => {
        setLoading(true);

        try {
            const response = await api().get('admin-comment/comments', {
                params: {
                    relation_type: relationType,
                    relation_id: relationId,
                },
            });

            setComments(response.data);
        } catch (error) {
            setError(error.data.message);
        } finally {
            setLoading(false);
        }
    }, [relationId, relationType]);

    const handleSubmit = async (event) => {
        event.preventDefault();

        setLoading(true);
        setError(null);

        try {
            await api().post('admin-comment/comments', {
                comment: commentInput,
                relation_type: relationType,
                relation_id: relationId,
            });
            await fetchComments();

            setCommentInput('');
        } catch (error) {
            setError(error.data.message);
        } finally {
            setLoading(false);
        }
    };

    const renderComments = () => (
        <div className={cx('comment', comments.length === 0 ? 'empty' : '')}>
            {comments.map((comment) => (
                <div className='block' key={comment.id}>
                    <div className='heading'>
                        <span>
                            {comment.full_name} ({comment.email}){' '}
                        </span>
                        <span>{formatDateAndTime(comment.created_at)}</span>
                    </div>
                    <div>
                        {comment.comment.split('\n').map((item, key) => (
                            <span key={key}>
                                {item}
                                <br />
                            </span>
                        ))}
                    </div>
                </div>
            ))}
        </div>
    );

    useEffect(() => {
        if (isModalOpen) {
            fetchComments();
        }
    }, [fetchComments, isModalOpen]);

    return (
        <Modal isOpen={isModalOpen} className='react-modal'>
            <Icon name='x-circle' className='w-4 react-modal__closeButton' onClick={setCommentsModalVisible(false)} />

            <div className='react-modal__content comments-container'>
                <form onSubmit={handleSubmit}>
                    <Dimmer active={isLoading} loader>
                        {renderComments()}
                    </Dimmer>

                    <ApiError error={error} />

                    <Form.Textarea name='comment' onChange={onCommentInputChange}>
                        {commentInput}
                    </Form.Textarea>

                    <div>
                        <Button type='button' onClick={setCommentsModalVisible(false)} color='secondary'>
                            Close
                        </Button>

                        <Button type='submit' color='primary' disabled={isLoading || commentInput === ''} loading={isLoading}>
                            Comment
                        </Button>
                    </div>
                </form>
            </div>
        </Modal>
    );
};

Comments.propTypes = {
    relationType: PropTypes.string.isRequired,
    relationId: PropTypes.number.isRequired,
    showButton: PropTypes.bool,
    isModalOpen: PropTypes.bool,
    setCommentsModalVisible: PropTypes.func,
};

export default Comments;
