import { createAction } from 'redux-actions';

export const FETCH_PERMISSION_GROUPS_REQUEST = 'permission-groups/FETCH_GROUPS_REQUEST';
export const FETCH_PERMISSION_GROUPS_SUCCESS = 'permission-groups/FETCH_GROUPS_SUCCESS';
export const FETCH_PERMISSION_GROUPS_FAILURE = 'permission-groups/FETCH_GROUPS_FAILURE';

const fetchPermissionGroupsRequest = createAction(FETCH_PERMISSION_GROUPS_REQUEST);
const fetchPermissionGroupsSuccess = createAction(FETCH_PERMISSION_GROUPS_SUCCESS);
const fetchPermissionGroupsFailure = createAction(FETCH_PERMISSION_GROUPS_FAILURE);

export const fetchPermissionGroups = () => (dispatch, getState, { api }) =>
    new Promise(async (resolve) => {
        try {
            dispatch(fetchPermissionGroupsRequest());

            const result = await api.get('/admin-permission/permission-groups');

            dispatch(fetchPermissionGroupsSuccess(result.data));

            resolve();
        } catch (response) {
            dispatch(fetchPermissionGroupsFailure());
        }
    });
