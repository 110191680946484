import React, { Component } from 'react';
import SiteWrapper from '../SiteWrapper';
import { capitalize, formatDateAndTime, userStatus } from '../../helpers';
import PaginatedFilteredList from '../Common/PaginatedFilteredList';
import { FILTER_MASKS } from '../../constants';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { fetchPricingGroups } from '../../actions/fee/pricing';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

class Users extends Component {
    constructor (props) {
        super(props);

        this.state = {
            items: [],
            error: null,
            isLoading: false,
        };

        this.uri = '/admin-user/users';

        this.columns = [
            {
                Header: 'Created at',
                accessor: 'created_at',
                Cell: (row) => formatDateAndTime(row.value),
                width: 130,
            },
            {
                Header: 'Name',
                sortable: false,
                Cell: (row) => <Link to={{ pathname: `/users/${row.original.id}` }}>{this.renderNameCell(row)}</Link>,
            },
            {
                Header: 'Status',
                accessor: 'is_verified',
                Cell: (row) => userStatus(row.value),
                width: 100,
            },
            {
                Header: 'Type',
                accessor: 'type',
                sortable: false,
                Cell: (row) => capitalize(row.value),
                width: 100,
            },
            {
                Header: 'Email',
                accessor: 'email',
                sortable: false,
            },
            {
                Header: 'Phone',
                accessor: 'phone',
                sortable: false,
                width: 140,
            },
            {
                Header: 'Risk level',
                accessor: 'risk_level',
                sortable: false,
                Cell: (row) => this.renderRiskLevelCell(row),
                width: 100,
            },
            {
                Header: 'Pricing Group',
                accessor: 'pricing_group',
                sortable: false,
                Cell: (row) => this.renderPricingGroupCell(row),
            },
        ];

        this.filterFields = [
            {
                title: 'Created from',
                name: 'created_at_from',
                type: 'text',
                placeholder: FILTER_MASKS.date.placeholder,
                mask: FILTER_MASKS.date.mask,
            },
            {
                title: 'Company name',
                name: 'company_name',
                type: 'text',
            },
            {
                title: 'First name',
                name: 'first_name',
                type: 'text',
            },
            {
                title: 'Last name',
                name: 'last_name',
                type: 'text',
            },
            {
                title: 'Email',
                name: 'email',
                type: 'text',
            },
            {
                title: 'Type',
                name: 'type',
                type: 'select',
                options: [
                    {
                        title: 'Business',
                        value: 'business',
                    },
                    {
                        title: 'Private',
                        value: 'private',
                    },
                ],
            },
            {
                title: 'Risk level',
                name: 'risk_level',
                type: 'select',
                options: [
                    {
                        title: 'Low',
                        value: 'low',
                    },
                    {
                        title: 'Medium',
                        value: 'medium',
                    },
                    {
                        title: 'High',
                        value: 'high',
                    },
                ],
            },
            {
                title: 'Verified',
                name: 'is_verified',
                type: 'select',
                options: [
                    {
                        title: 'Yes',
                        value: 1,
                    },
                    {
                        title: 'No',
                        value: 0,
                    },
                ],
            },
        ];

        this.updateItems = this.updateItems.bind(this);
    }

    componentDidMount () {
        this.props.fetchPricingGroups();
    }

    renderNameCell (row) {
        if (row.original.type === 'business') {
            return row.original.company_name;
        }

        return `${row.original.first_name} ${row.original.last_name}`;
    }

    renderRiskLevelCell (row) {
        if (row.original.is_verified) {
            return capitalize(row.original.risk_level);
        }

        return '-';
    }

    renderPricingGroupCell (row) {
        if (row.original.is_verified) {
            const pricingGroup = this.props.pricingGroups.find((pricingGroup) => pricingGroup.id === row.original.pricing_group_id);

            return pricingGroup ? pricingGroup.name : '-';
        }

        return '-';
    }

    updateItems (items) {
        this.setState({
            items,
        });
    }

    render () {
        return (
            <SiteWrapper title='Users'>
                <PaginatedFilteredList
                    uri={this.uri}
                    columns={this.columns}
                    filterFields={this.filterFields}
                    updateItems={this.updateItems}
                    items={this.state.items}
                    isLoading={this.state.isLoading}
                    parentError={this.state.error}
                />
            </SiteWrapper>
        );
    }
}

Users.propTypes = {
    fetchPricingGroups: PropTypes.func.isRequired,
    pricingGroups: PropTypes.array.isRequired,
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            fetchPricingGroups,
        },
        dispatch,
    );

const mapStateToProps = (state) => ({
    pricingGroups: state.fee.pricing,
});

export default connect(mapStateToProps, mapDispatchToProps)(Users);
