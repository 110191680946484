import React from 'react';
import PropTypes from 'prop-types';
import MainData from './Sidebar/MainData';
import SidebarNavigation from '../../../../Common/SidebarNavigation';

const Sidebar = ({ user, components, currentComponent, handleComponentChange }) => (
    <>
        <MainData user={user} />
        <SidebarNavigation components={components} currentComponent={currentComponent} handleComponentChange={handleComponentChange} />
    </>
);

Sidebar.propTypes = {
    user: PropTypes.object.isRequired,
    components: PropTypes.object.isRequired,
    currentComponent: PropTypes.string.isRequired,
    handleComponentChange: PropTypes.func.isRequired,
};

export default Sidebar;
