import React, { Component } from 'react';
import SiteWrapper from '../../SiteWrapper';
import { Button, Card, Grid } from 'tabler-react';
import { createApiClient as api } from '../../../services/api-client';
import { Link } from 'react-router-dom';
import ReactTable from 'react-table';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import { isLoading } from '../../../selectors/isLoading';
import { fetchPricingGroups } from '../../../actions/fee/pricing';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

class PricingGroups extends Component {
    constructor (props) {
        super(props);

        this.state = {
            isLoading: false,
        };

        this.columns = [
            {
                Header: 'Name',
                accessor: 'name',
                sortable: false,
            },
            {
                Header: 'Actions',
                id: 'actions',
                width: 130,
                sortable: false,
                headerClassName: 'column-actions',
                className: 'column-actions',
                Cell: (row) => (
                    <>
                        <Button.List>
                            <Link className='btn btn-sm btn-primary' to={{ pathname: `/fee/pricing-groups/edit/${row.original.id}` }}>
                                Edit
                            </Link>
                            <Button size='sm' onClick={() => this.deletePricingGroup(row.original)} color='danger'>
                                Delete
                            </Button>
                        </Button.List>
                    </>
                ),
            },
        ];

        this.deletePricingGroup = this.deletePricingGroup.bind(this);
        this.updateItems = this.updateItems.bind(this);
    }

    componentDidMount () {
        this.props.fetchPricingGroups();
    }

    deletePricingGroup (item) {
        if (confirm('Are you sure?')) {
            this.setState({
                isLoading: true,
            });

            api()
                .delete(`admin-fee/pricing-groups/${item.id}`)
                .then(() => {
                    toast.success('Deleted successful');
                    this.props.fetchPricingGroups();
                })
                .catch((data) => {
                    toast.error(data.data.message);
                })
                .finally(() => {
                    this.setState({
                        isLoading: false,
                    });
                });
        }
    }

    renderOptions () {
        return (
            <Link to='/fee/pricing-groups/create'>
                <Button pill icon='plus' color='primary'>
                    Create new
                </Button>
            </Link>
        );
    }

    updateItems (items) {
        this.setState({
            items,
        });
    }

    render () {
        const { isFetchingPricingGroups, pricingGroups } = this.props;
        const { isLoading } = this.state;

        return (
            <SiteWrapper title='Pricing groups' options={this.renderOptions()}>
                <Grid.Row>
                    <Grid.Col>
                        <Card>
                            <Card.Body>
                                <ReactTable
                                    manual
                                    data={pricingGroups}
                                    loading={isFetchingPricingGroups || isLoading}
                                    columns={this.columns}
                                    onFetchData={this.changeTable}
                                    className='-striped -highlight'
                                    minRows={2}
                                    showPagination={false}
                                />
                            </Card.Body>
                        </Card>
                    </Grid.Col>
                </Grid.Row>
            </SiteWrapper>
        );
    }
}

PricingGroups.propTypes = {
    pricingGroups: PropTypes.array.isRequired,
    fetchPricingGroups: PropTypes.func.isRequired,
    isFetchingPricingGroups: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
    pricingGroups: state.fee.pricing,
    isFetchingPricingGroups: isLoading(state, 'pricingGroups'),
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            fetchPricingGroups,
        },
        dispatch,
    );

export default connect(mapStateToProps, mapDispatchToProps)(PricingGroups);
