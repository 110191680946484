import React, { useState, useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Button, Card, Dimmer, Table } from 'tabler-react';
import { createApiClient } from '../../../../../../services/api-client';
import ApiError from '../../../../../Common/ApiError';
import { toast } from 'react-toastify';
import { capitalize } from '../../../../../../helpers';
import { SCREENED_CLIENT_STATUS_CONFIRMED, SCREENED_CLIENT_STATUS } from '../../../../../../constants';
import { convertSelectValue } from '../../../../../../services/string-to-boolean';

const UserScreening = ({ identityId }) => {
    const [isLoading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [screenedUserData, setScreenedUserData] = useState({});

    const api = useMemo(() => createApiClient(), []);

    const canConfirmScreening =
        convertSelectValue(screenedUserData.is_pep) !== '' &&
        convertSelectValue(screenedUserData.is_media_adverse) !== '' &&
        convertSelectValue(screenedUserData.is_sanctioned) !== '';

    const handleScreeningSelect = useCallback((event) => {
        const {
            target: { name, value },
        } = event;

        setScreenedUserData((prevScreenedUserData) => ({ ...prevScreenedUserData, [name]: value }));
    }, []);

    const renderScreeningColumn = useCallback(
        (name, value, status) => {
            const convertedValue = convertSelectValue(value);

            if (convertedValue === true && status === SCREENED_CLIENT_STATUS_CONFIRMED) {
                return 'Yes';
            } else if (convertedValue === false && status === SCREENED_CLIENT_STATUS_CONFIRMED) {
                return 'No';
            }

            return (
                <select className='w-100' name={name} onChange={handleScreeningSelect} value={convertedValue}>
                    <option value='' />
                    <option value={true}>Yes</option>
                    <option value={false}>No</option>
                </select>
            );
        },
        [handleScreeningSelect],
    );

    const renderScreenedClientStatus = (clientStatus) => {
        if (SCREENED_CLIENT_STATUS[clientStatus] !== undefined) {
            return SCREENED_CLIENT_STATUS[clientStatus];
        }

        if (clientStatus === 'failed') {
            return (
                <div>
                    {capitalize(clientStatus)}
                    <Button color='warning' size='sm' className='btn-right' onClick={restartScreening}>Restart</Button>
                </div>
            );
        }

        return capitalize(clientStatus);
    };

    const fetchClientScreening = useCallback(async () => {
        if (identityId === null) {
            return;
        }

        setLoading(true);

        try {
            const { data } = await api.get(`/admin-user-identity-verification/user-identities/${identityId}/client-screening`);

            setScreenedUserData(data);
        } catch (error) {
            setError(error.data);
        } finally {
            setLoading(false);
        }
    }, [api, identityId]);

    const confirmScreening = useCallback(async () => {
        setLoading(true);

        try {
            const { data } = await api.put(`admin-aml/client-screenings/${screenedUserData.client_screening_id}/confirm`, {
                is_pep: convertSelectValue(screenedUserData.is_pep),
                is_sanctioned: convertSelectValue(screenedUserData.is_sanctioned),
                is_media_adverse: convertSelectValue(screenedUserData.is_media_adverse),
            });

            setScreenedUserData((prevScreenedUserData) => ({ ...prevScreenedUserData, ...data }));
            toast.success('Screening information confirmed');
        } catch (error) {
            toast.error(`Screening confirmation failed: ${error.data.message}`);
        } finally {
            setLoading(false);
        }
    }, [
        api,
        screenedUserData.client_screening_id,
        screenedUserData.is_pep,
        screenedUserData.is_sanctioned,
        screenedUserData.is_media_adverse,
    ]);

    const restartScreening = useCallback(async () => {
        setLoading(true);

        try {
            const { data } = await api.put(`admin-aml/client-screenings/${screenedUserData.client_screening_id}/restart`);

            setScreenedUserData((prevScreenedUserData) => ({ ...prevScreenedUserData, ...data }));
            toast.success('Screening restart requested. Waiting for the screening provider response');
        } catch (error) {
            toast.error(`Screening restart failed: ${error.data.message}`);
        } finally {
            setLoading(false);
        }
    }, [
        api,
        screenedUserData.client_screening_id,
        screenedUserData.is_pep,
        screenedUserData.is_sanctioned,
        screenedUserData.is_media_adverse,
    ]);

    useEffect(() => {
        fetchClientScreening();
    }, [fetchClientScreening]);

    return (
        <Card>
            <Dimmer active={isLoading} loader>
                <Card.Header>
                    <Card.Title>Identity screening</Card.Title>
                    {screenedUserData.status !== SCREENED_CLIENT_STATUS_CONFIRMED && !error && (
                        <Card.Options>
                            <Button color='success' size='sm' onClick={confirmScreening} disabled={!canConfirmScreening}>
                                Confirm
                            </Button>
                        </Card.Options>
                    )}
                </Card.Header>
                <ApiError error={error} className='mb-0' />
                {!error && (
                    <Table className='small' striped={true} cards={true}>
                        <Table.Body>
                            <Table.Row>
                                <Table.Col>PEP</Table.Col>
                                <Table.Col>{renderScreeningColumn('is_pep', screenedUserData.is_pep, screenedUserData.status)}</Table.Col>
                            </Table.Row>
                            <Table.Row>
                                <Table.Col>Sanctions list</Table.Col>
                                <Table.Col>
                                    {renderScreeningColumn('is_sanctioned', screenedUserData.is_sanctioned, screenedUserData.status)}
                                </Table.Col>
                            </Table.Row>
                            <Table.Row>
                                <Table.Col>Media found</Table.Col>
                                <Table.Col>
                                    {renderScreeningColumn('is_media_adverse', screenedUserData.is_media_adverse, screenedUserData.status)}
                                </Table.Col>
                            </Table.Row>
                            <Table.Row>
                                <Table.Col>Status</Table.Col>
                                <Table.Col>{renderScreenedClientStatus(screenedUserData.status)}</Table.Col>
                            </Table.Row>
                        </Table.Body>
                    </Table>
                )}
            </Dimmer>
        </Card>
    );
};

UserScreening.propTypes = {
    identityId: PropTypes.string.isRequired,
};

export default UserScreening;
