import React, { useState, useEffect, useCallback } from 'react';
import { Card, Table, Icon, Dimmer } from 'tabler-react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { createApiClient as api } from '../../../../../../services/api-client';
import { userStatus } from '../../../../../../helpers';

const OndatoDuplicate = ({ userId }) => {
    const [identicalUsers, setIdenticalUsers] = useState([]);
    const [isLoading, setLoading] = useState(true);

    const fetchIdenticalUsers = useCallback(async () => {
        setLoading(true);

        try {
            const result = await api().get(`admin-user/users/${userId}/identical`);

            setIdenticalUsers(result.data);
        } catch (error) {
            toast.error(error.data.message);
        } finally {
            setLoading(false);
        }
    }, [userId]);

    useEffect(() => {
        fetchIdenticalUsers();
    }, [fetchIdenticalUsers]);

    return !identicalUsers.length ? null : (
        <Card>
            <Card.Header>
                <Card.Title>Ondato identical users</Card.Title>
            </Card.Header>
            <Dimmer active={isLoading} loader>
                <Table className='small' striped={true} cards={true}>
                    <Table.Body>
                        {identicalUsers.map((identicalUser, index) => (
                            <Table.Row key={index}>
                                <Table.Col>
                                    <Link to={`/compliance/users/${identicalUser.user_id}`}>
                                        <Icon name='users' className='mr-1' />
                                        {identicalUser.first_name} {identicalUser.last_name} ({identicalUser.email})
                                    </Link>
                                </Table.Col>
                                <Table.Col className='align-middle'>{userStatus(identicalUser.verified)}</Table.Col>
                            </Table.Row>
                        ))}
                    </Table.Body>
                </Table>
            </Dimmer>
        </Card>
    );
};

OndatoDuplicate.propTypes = {
    userId: PropTypes.number.isRequired,
};

export default OndatoDuplicate;
