import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Card } from 'tabler-react';
import PaginatedFilteredList from '../../../../Common/PaginatedFilteredList';
import { capitalize, chargeStatus, formatAmount, formatDateAndTime } from '../../../../../helpers';

const ChargeList = ({ walletId, isChargeListOpen, toggleChargeList }) => {
    const [items, setItems] = useState([]);

    const uri = useMemo(() => `admin-wallet/wallets/${walletId}/charges`, [walletId]);

    const columns = useMemo(
        () => [
            {
                Header: 'Created at',
                accessor: 'created_at',
                sortable: false,
                Cell: (row) => formatDateAndTime(row.value),
            },
            {
                Header: 'Charge description',
                accessor: 'details',
                sortable: false,
                Cell: (row) => row.value,
            },
            {
                Header: 'Amount',
                accessor: 'amount',
                sortable: false,
                Cell: (row) => formatAmount(row.value),
            },
            {
                Header: 'Type',
                accessor: 'type',
                sortable: false,
                Cell: (row) => capitalize(row.value),
            },
            {
                Header: 'Charge status',
                accessor: 'status',
                sortable: false,
                Cell: (row) => chargeStatus(row.value),
            },
        ],
        [],
    );

    const updateItems = (items) => setItems(items);

    return (
        <Card>
            <Card.Header className='filterHeader'>
                <div onClick={toggleChargeList}>
                    <span>Applied charges</span>
                    <i className={`fe fe-chevrons-${isChargeListOpen ? 'up' : 'down'}`} />
                </div>
            </Card.Header>
            {isChargeListOpen && <PaginatedFilteredList uri={uri} columns={columns} updateItems={updateItems} items={items} />}
        </Card>
    );
};

ChargeList.propTypes = {
    walletId: PropTypes.number.isRequired,
    isChargeListOpen: PropTypes.bool.isRequired,
    toggleChargeList: PropTypes.func.isRequired,
};

export default ChargeList;
