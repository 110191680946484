import { createAction } from 'redux-actions';

export const FETCH_PRICING_GROUPS_REQUEST = 'fee/FETCH_PRICING_GROUPS_REQUEST';
export const FETCH_PRICING_GROUPS_SUCCESS = 'fee/FETCH_PRICING_GROUPS_SUCCESS';
export const FETCH_PRICING_GROUPS_FAILURE = 'fee/FETCH_PRICING_GROUPS_FAILURE';

const fetchPricingGroupsRequest = createAction(FETCH_PRICING_GROUPS_REQUEST);
const fetchPricingGroupsSuccess = createAction(FETCH_PRICING_GROUPS_SUCCESS);
const fetchPricingGroupsFailure = createAction(FETCH_PRICING_GROUPS_FAILURE);

export const fetchPricingGroups = () => (dispatch, getState, { api }) =>
    new Promise(async (resolve) => {
        try {
            dispatch(fetchPricingGroupsRequest());

            const result = await api.get('admin-fee/pricing-groups');

            dispatch(fetchPricingGroupsSuccess(result.data));
            resolve(result.data);
        } catch (response) {
            dispatch(fetchPricingGroupsFailure());
        }
    });
