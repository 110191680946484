import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Card, Table, Dimmer, Button } from 'tabler-react';
import DeclineTransactionModal from '../DeclineTransactionModal';
import ErrorTransactionModal from '../ErrorTransactionModal';
import { formatDateAndTime, transactionStatus } from '../../../../helpers';
import { createApiClient } from '../../../../services/api-client';
import { TRANSACTION_SCORING_STATUS_CREATED, TRANSACTION_SCORING_STATUS_PROCESSING } from '../../../../constants';

const TransactionStatusUpdates = ({ isLoading, updateTransactionState, transactionData, transactionScoring, setLoadingParent }) => {
    const { status, fail_reason, created_at, id, direction, refund_transaction_id } = transactionData;

    const [isUpdateTransactionErrorModalOpen, setUpdateTransactionErrorModalOpen] = useState(false);
    const [isDeclineTransactionModalOpen, setDeclineTransactionModalOpen] = useState(false);
    const [updateTransactionError, setUpdateTransactionError] = useState(null);

    const api = useMemo(() => createApiClient(), []);

    const setDeclineTransactionModalVisible = useCallback((value) => () => setDeclineTransactionModalOpen(value), []);

    const setUpdateTransactionErrorModalVisible = useCallback((value) => () => setUpdateTransactionErrorModalOpen(value), []);

    const isDisabled =
        transactionScoring !== null
        && (
            transactionScoring.status === TRANSACTION_SCORING_STATUS_CREATED
            || transactionScoring.status === TRANSACTION_SCORING_STATUS_PROCESSING
        );

    const approveTransaction = useCallback(async () => {
        setLoadingParent(true);

        try {
            const { data } = await api.put(`/admin-transaction/transactions/${id}/approve`);
            const updatedTransaction = transactionData;

            updatedTransaction.status = data.status;

            updateTransactionState(updatedTransaction);
        } catch (error) {
            setUpdateTransactionError(error.data);
            setUpdateTransactionErrorModalOpen(true);
        } finally {
            setLoadingParent(false);
        }
    }, [setLoadingParent, api, id, transactionData, updateTransactionState]);

    const cancelTransactionWithConfirm = async () => {
        if (window.confirm('Cancel not yet processed transaction?')) {
            await cancelTransaction();
        }
    };

    const refundTransactionWithConfirm = async () => {
        if (window.confirm('Refund rejected in provider transaction?')) {
            await refundTransaction();
        }
    };

    const cancelTransaction = useCallback(async () => {
        setLoadingParent(true);

        try {
            const { data } = await api.put(`/admin-transaction/transactions/${id}/cancel`);
            const updatedTransaction = transactionData;

            updatedTransaction.status = data.status;

            updateTransactionState(updatedTransaction);
        } catch (error) {
            setUpdateTransactionError(error.data);
            setUpdateTransactionErrorModalOpen(true);
        } finally {
            setLoadingParent(false);
        }
    }, [setLoadingParent, api, id, transactionData, updateTransactionState]);

    const refundTransaction = useCallback(async () => {
        setLoadingParent(true);

        try {
            const { data } = await api.put(`/admin-transaction/transactions/${id}/refund`);
            const updatedTransaction = transactionData;

            updatedTransaction.status = data.status;
            updatedTransaction.refund_transaction_id = data.refund_transaction_id;

            updateTransactionState(updatedTransaction);
        } catch (error) {
            setUpdateTransactionError(error.data);
            setUpdateTransactionErrorModalOpen(true);
        } finally {
            setLoadingParent(false);
        }
    }, [setLoadingParent, api, id, transactionData, updateTransactionState]);

    return (
        <Card>
            <Card.Header>
                <Card.Title>Transaction updates</Card.Title>
            </Card.Header>
            <Dimmer active={isLoading} loader>
                <Table className='small' cards={true}>
                    <Table.Body>
                        <Table.Row>
                            <Table.Col>Status</Table.Col>
                            <Table.Col>
                                {transactionStatus(status)}
                                {refund_transaction_id !== null && <span className='ml-1'>{transactionStatus('refunded')}</span>}
                            </Table.Col>
                        </Table.Row>
                        {fail_reason && (
                            <Table.Row>
                                <Table.Col className='align-middle'>Decline reason</Table.Col>
                                <Table.Col>{fail_reason}</Table.Col>
                            </Table.Row>
                        )}
                        <Table.Row>
                            <Table.Col>Date</Table.Col>
                            <Table.Col>{formatDateAndTime(created_at)}</Table.Col>
                        </Table.Row>
                    </Table.Body>
                </Table>
            </Dimmer>

            {status === 'manual_approval' && (
                <Card.Footer>
                    <Button.List>
                        <Button
                            size='sm'
                            color='success'
                            onClick={approveTransaction}
                            loading={isLoading}
                            disabled={isLoading || isDisabled}
                        >
                            Approve
                        </Button>
                        {direction !== 'incoming' && (
                            <Button
                                size='sm'
                                color='danger'
                                onClick={setDeclineTransactionModalVisible(true)}
                                loading={isLoading}
                                disabled={isLoading}
                            >
                                Decline
                            </Button>
                        )}
                    </Button.List>
                </Card.Footer>
            )}

            {status === 'pending' && (
                <Card.Footer>
                    <Button.List>
                        {direction !== 'incoming' && (
                            <Button
                                size='sm'
                                color='danger'
                                onClick={cancelTransactionWithConfirm}
                                loading={isLoading}
                                disabled={isLoading}
                            >
                                Cancel
                            </Button>
                        )}
                    </Button.List>
                </Card.Footer>
            )}

            {status === 'completed' && direction !== 'incoming' && refund_transaction_id === null && (
                <Card.Footer>
                    <Button.List>
                        <Button
                            size='sm'
                            color='danger'
                            onClick={refundTransactionWithConfirm}
                            loading={isLoading}
                            disabled={isLoading}
                        >
                            Refund
                        </Button>
                    </Button.List>
                </Card.Footer>
            )}

            <DeclineTransactionModal
                modalIsOpen={isDeclineTransactionModalOpen}
                onModalClose={setDeclineTransactionModalVisible(false)}
                updateTransaction={updateTransactionState}
                activeTransaction={transactionData}
            />
            <ErrorTransactionModal
                error={updateTransactionError}
                modalIsOpen={isUpdateTransactionErrorModalOpen}
                onModalClose={setUpdateTransactionErrorModalVisible(false)}
            />
        </Card>
    );
};

TransactionStatusUpdates.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    updateTransactionState: PropTypes.func.isRequired,
    transactionData: PropTypes.object.isRequired,
    setLoadingParent: PropTypes.func.isRequired,
    transactionScoring: PropTypes.object,
};

export default TransactionStatusUpdates;
