import React, { Component } from 'react';
import 'react-table/react-table.css';
import PropTypes from 'prop-types';
import SiteWrapper from '../../../SiteWrapper';
import { Button, Card, Dimmer, Grid } from 'tabler-react';
import { bindActionCreators } from 'redux';
import { navigate } from '../../../../actions/navigate';
import { connect } from 'react-redux';
import { createApiClient } from '../../../../services/api-client';
import { fetchPermissionGroups } from '../../../../actions/permission-groups/groups';
import { toast } from 'react-toastify';
import ApiError from '../../../Common/ApiError';
import Groups from './Misc/GroupsSelectBox';
import InputTextField from '../../../Common/FilterFormFields/InputTextField';

class CreateAdminUser extends Component {
    constructor (props) {
        super(props);

        this.state = {
            error: null,
            isLoading: false,
            first_name: '',
            last_name: '',
            email: '',
            permission_groups: [],
        };

        this.api = createApiClient();

        this.onFormSubmit = this.onFormSubmit.bind(this);
        this.onInputChange = this.onInputChange.bind(this);
        this.navigateToList = this.navigateToList.bind(this);
    }

    componentDidMount () {
        const { fetchPermissionGroups } = this.props;

        fetchPermissionGroups();
    }

    onInputChange (event) {
        const { name, value } = event.target;

        this.setState({
            [name]: value,
        });
    }

    onFormSubmit (event) {
        this.setState({
            isLoading: true,
        });

        event.preventDefault();

        const { first_name, last_name, email, permission_groups } = this.state;

        this.api
            .post('/admin-user/admins', { first_name, last_name, email, permission_groups })
            .then(() => {
                this.setState({
                    isLoading: false,
                });

                toast.success(`Admin ${email} has been created successfully.`);

                this.navigateToList();
            })
            .catch((error) => {
                this.setState({
                    isLoading: false,
                    error: error.data,
                });
            });
    }

    navigateToList () {
        const { navigate } = this.props;

        navigate('/configuration/admin-users');
    }

    render () {
        const { isLoading, error, first_name, last_name, email, permission_groups } = this.state;

        return (
            <SiteWrapper title='Create admin user'>
                <Grid.Row>
                    <Grid.Col md={4} sm={6} xs={12}>
                        <Card>
                            <form onSubmit={this.onFormSubmit}>
                                <Dimmer active={isLoading} loader>
                                    <Card.Body>
                                        <InputTextField
                                            name='first_name'
                                            title='First name'
                                            value={first_name}
                                            handleChange={this.onInputChange}
                                        />
                                        <InputTextField
                                            name='last_name'
                                            title='Last name'
                                            value={last_name}
                                            handleChange={this.onInputChange}
                                        />
                                        <InputTextField name='email' title='Email' value={email} handleChange={this.onInputChange} />

                                        <Groups onInputChange={this.onInputChange} assignedGroups={permission_groups} />
                                        <ApiError error={error} />
                                    </Card.Body>
                                    <Card.Footer className='text-center'>
                                        <div className='d-flex'>
                                            <Button type='button' link onClick={this.navigateToList}>
                                                Cancel
                                            </Button>

                                            <Button type='submit' color='primary' className='ml-auto'>
                                                Submit
                                            </Button>
                                        </div>
                                    </Card.Footer>
                                </Dimmer>
                            </form>
                        </Card>
                    </Grid.Col>
                </Grid.Row>
            </SiteWrapper>
        );
    }
}

CreateAdminUser.propTypes = {
    navigate: PropTypes.func.isRequired,
    fetchPermissionGroups: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            navigate,
            fetchPermissionGroups,
        },
        dispatch,
    );

export default connect(null, mapDispatchToProps)(CreateAdminUser);
