import React from 'react';
import Modal from 'react-modal';
import PropTypes from 'prop-types';
import { Button, Icon } from 'tabler-react';

const ErrorTransactionModal = ({ modalIsOpen, onModalClose, error }) => (
    <Modal color='warning' isOpen={modalIsOpen} className='react-modal'>
        <Icon name='x-circle' className='w-4 react-modal__closeButton' onClick={onModalClose} />

        <div className='react-modal__content'>
            <h3>{error ? error.message : 'Unknown Error'}</h3>
            <Button block onClick={onModalClose} color='primary' size='sm'>
                Ok
            </Button>
        </div>
    </Modal>
);

ErrorTransactionModal.propTypes = {
    modalIsOpen: PropTypes.bool.isRequired,
    onModalClose: PropTypes.func.isRequired,
    error: PropTypes.object,
};

export default ErrorTransactionModal;
