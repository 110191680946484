import Modal from 'react-modal';
import pickBy from 'lodash/pickBy';
import { Button, Form, Icon } from 'tabler-react';
import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { createApiClient as api } from '../../../services/api-client';

const CreateTransactionModal = ({ modalIsOpen, onModalClose, bankAccountTransaction, updateTransaction }) => {
    const [inputValues, setInputValues] = useState({
        walletNumber: '',
    });

    const [loading, setLoading] = useState(false);

    const resetForm = useCallback(() => {
        setInputValues({ walletNumber: '' });
    }, []);

    const handleModalClose = useCallback(() => {
        onModalClose();
        resetForm();
    }, [onModalClose, resetForm]);

    const createDeposit = useCallback(async () => {
        setLoading(true);

        const params = {
            wallet_number: inputValues.walletNumber,
        };

        try {
            const { data } = await api().put(
                `/admin-bank-account/bank-account-transactions/${bankAccountTransaction.id}/create-deposit`,
                pickBy(params)
            );

            bankAccountTransaction.is_related = data.is_related;
            bankAccountTransaction.status = data.status;
            bankAccountTransaction.transaction_id = data.transaction_id;
            bankAccountTransaction.transaction_number = data.transaction_number;

            updateTransaction(bankAccountTransaction);
        } catch (error) {
            toast.error(`Unable to create transaction: ${error.data.message}`);
        } finally {
            setLoading(false);
            handleModalClose();
        }
    }, [bankAccountTransaction, handleModalClose, inputValues.walletNumber, updateTransaction]);

    const handleDeclineReasonChange = (event) => {
        const {
            target: { name, value },
        } = event;

        setInputValues((prevInputValues) => ({ ...prevInputValues, [name]: value }));
    };

    return (
        <Modal isOpen={modalIsOpen} className='react-modal'>
            <Icon name='x-circle' className='w-4 react-modal__closeButton' onClick={handleModalClose} />

            <div className='react-modal__content'>
                <Form.Group label='Please enter wallet number for credit'>
                    <Form.MaskedInput
                        name='walletNumber'
                        mask={['W', '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/]}
                        placeholder='W-000-000'
                        value={inputValues.walletNumber}
                        onChange={handleDeclineReasonChange}
                        autoFocus
                    />
                </Form.Group>
                <Button
                    block
                    color='success'
                    onClick={createDeposit}
                    disabled={!inputValues.walletNumber || loading}
                    loading={loading}
                >
                    Credit funds
                </Button>
            </div>
        </Modal>
    );
};

CreateTransactionModal.propTypes = {
    modalIsOpen: PropTypes.bool.isRequired,
    bankAccountTransaction: PropTypes.object,
    onModalClose: PropTypes.func.isRequired,
    updateTransaction: PropTypes.func.isRequired,
};

export default CreateTransactionModal;
