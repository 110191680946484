import { handleActions } from 'redux-actions';

import { FETCH_CURRENT_ADMIN_INFORMATION_SUCCESS } from '../../actions/user/admin-information';

export default handleActions(
    {
        [FETCH_CURRENT_ADMIN_INFORMATION_SUCCESS]: (state, action) => Object.assign({}, action.payload),
    },
    {},
);
