import React, { Component } from 'react';
import SiteWrapper from '../../SiteWrapper';
import 'react-table/react-table.css';
import { Button, Card, Form, Grid } from 'tabler-react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { navigate } from '../../../actions/navigate';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ReactTable from 'react-table';
import { fetchDocumentTypes } from '../../../actions/document-type/document-types';
import { isLoading } from '../../../selectors/isLoading';
import { capitalize } from '../../../helpers';

class DocumentTypes extends Component {
    constructor (props) {
        super(props);

        this.state = {
            filter: {
                user_type: 'private',
            },
            isLoading: false,
        };

        this.columns = [
            {
                Header: 'Name',
                accessor: 'name',
                sortable: false,
                Cell: (row) => row.value,
            },
            {
                Header: 'Document type',
                accessor: 'document_type',
                sortable: false,
                Cell: (row) => capitalize(row.value),
                width: 150,
            },
            {
                Header: 'Actions',
                id: 'actions',
                width: 130,
                sortable: false,
                headerClassName: 'column-actions',
                className: 'column-actions',
                Cell: (row) => (
                    <>
                        <Button.List>
                            <Button size='sm' onClick={() => this.edit(row.original)} color='primary'>
                                Edit
                            </Button>
                        </Button.List>
                    </>
                ),
            },
        ];

        this.changeFilter = this.changeFilter.bind(this);
    }

    componentDidMount () {
        this.props.fetchDocumentTypes(this.state.filter);
    }

    renderOptions () {
        return (
            <Link to='/compliance/document-types/create'>
                <Button pill icon='plus' color='primary'>
                    Create new
                </Button>
            </Link>
        );
    }

    edit (item) {
        this.props.navigate(`/compliance/document-types/edit/${item.id}`);
    }

    changeFilter (event) {
        const { name, value } = event.target;

        this.setState(
            (prevState) => ({
                filter: {
                    ...prevState.filter,
                    [name]: value,
                },
            }),
            () => {
                this.props.fetchDocumentTypes(this.state.filter);
            },
        );
    }

    renderFilter () {
        const { filter } = this.state;

        return (
            <Grid.Row>
                <Grid.Col width={3}>
                    <Form.Group>
                        <Form.SelectGroup>
                            <Form.SelectGroupItem
                                label='Private'
                                name='user_type'
                                value='private'
                                checked={filter.user_type === 'private'}
                                onChange={this.changeFilter}
                            />
                            <Form.SelectGroupItem
                                label='Business'
                                name='user_type'
                                value='business'
                                checked={filter.user_type === 'business'}
                                onChange={this.changeFilter}
                            />
                        </Form.SelectGroup>
                    </Form.Group>
                </Grid.Col>
            </Grid.Row>
        );
    }

    renderList () {
        const { isLoading } = this.state;
        const { documentTypes, isFetchingDocumentTypes } = this.props;

        return (
            <Grid.Row>
                <Grid.Col>
                    <Card>
                        <Card.Body>
                            <ReactTable
                                manual
                                data={documentTypes}
                                loading={isLoading || isFetchingDocumentTypes}
                                columns={this.columns}
                                className='-striped -highlight'
                                multiSort={false}
                                minRows={2}
                                showPagination={false}
                            />
                        </Card.Body>
                    </Card>
                </Grid.Col>
            </Grid.Row>
        );
    }

    render () {
        return (
            <SiteWrapper
                title='Document types'
                options={this.renderOptions()}
                titleDescription='Create document types for Private and Business users. Document type created as
                    "Mandatory" will be displayed for each Private/Business user under verification
                    settings. Document type created as "Additional" will not be displayed under user
                    verification settings until it will be requested from -  Admin > Compliance
                    > Customers list > %user > Documents > Button "Request"'
            >
                {this.renderFilter()}
                {this.renderList()}
            </SiteWrapper>
        );
    }
}

DocumentTypes.propTypes = {
    navigate: PropTypes.func.isRequired,
    fetchDocumentTypes: PropTypes.func.isRequired,
    documentTypes: PropTypes.array.isRequired,
    isFetchingDocumentTypes: PropTypes.bool.isRequired,
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            navigate,
            fetchDocumentTypes,
        },
        dispatch,
    );

const mapStateToProps = (state) => ({
    documentTypes: state.documentType.documentTypes,
    isFetchingDocumentTypes: isLoading(state, 'documentTypes'),
});

export default connect(mapStateToProps, mapDispatchToProps)(DocumentTypes);
