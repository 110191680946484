import React, { Component } from 'react';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import isUndefined from 'lodash/isUndefined';
import queryString from 'query-string';
import InputTextField from './FilterFormFields/InputTextField';
import SelectField from './FilterFormFields/SelectField';
import { Card, Button, Grid, Dimmer } from 'tabler-react';
import ApiError from './ApiError';
import './FilterForm.scss';
import CountrySelectField from './FilterFormFields/CountrySelectField';

class FilterForm extends Component {
    constructor (props) {
        super(props);

        this.state = {
            show: this.props.show,
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.toggleFilter = this.toggleFilter.bind(this);
    }

    toggleFilter () {
        this.setState((prev) => ({
            show: !prev.show,
        }));
    }

    componentDidMount () {
        const fields = {};

        const filter = queryString.parse(this.props.location.search);

        this.props.fields.forEach((field) => {
            if (!isUndefined(filter[field.name])) {
                fields[field.name] = filter[field.name];
            } else {
                fields[field.name] = !isUndefined(field.value) ? field.value : '';
            }
        });

        this.setState(fields);
    }

    handleField = (valueType) => (event) => {
        const { target } = event;
        const { onFilterChange } = this.props;
        let value = target.value || '';

        if (valueType === 'integer' && value) {
            value = parseInt(target.value, 10);
        }

        this.setState({
            [target.name]: value,
        });

        onFilterChange !== undefined && onFilterChange(target.name, value);
    };

    handleSubmit (event) {
        event.preventDefault();
        const { submitCallback } = this.props;
        submitCallback(this.state);
    }

    renderBody () {
        const { fields, error, csvCallback } = this.props;

        return (
            <Card.Body>
                <Grid.Row deck={true}>
                    {fields.map((field) => {
                        if (field.type === 'text' || field.type === 'number') {
                            return (
                                <Grid.Col key={field.name} lg={2} md={3} sm={4} xs={6}>
                                    <InputTextField
                                        name={field.name}
                                        title={field.title}
                                        handleChange={this.handleField()}
                                        mask={field.mask}
                                        placeholder={field.placeholder}
                                        invalid={field.invalid}
                                        feedback={field.feedback}
                                        value={this.state[field.name]}
                                        filter={field.filter}
                                        isNumber={field.type === 'number'}
                                    />
                                </Grid.Col>
                            );
                        }

                        if (field.type === 'select') {
                            return (
                                <Grid.Col key={field.name} lg={2} md={3} sm={4} xs={6}>
                                    <SelectField
                                        name={field.name}
                                        title={field.title}
                                        handleChange={this.handleField(field.valueType)}
                                        options={field.options}
                                        value={this.state[field.name]}
                                    />
                                </Grid.Col>
                            );
                        }

                        if (field.type === 'countries') {
                            return (
                                <Grid.Col key={field.name} lg={2} md={3} sm={4} xs={6}>
                                    <CountrySelectField
                                        name={field.name}
                                        title={field.title}
                                        handleChange={this.handleField()}
                                        options={field.options}
                                        value={this.state[field.name]}
                                        status={field.status}
                                    />
                                </Grid.Col>
                            );
                        }

                        return null;
                    })}

                    <Button type='submit' color='primary' onClick={this.handleSubmit} size='sm' className='filterButton'>
                        Search
                    </Button>
                    {csvCallback !== undefined && (
                        <Button type='button' color='secondary' onClick={csvCallback} size='sm' className='filterButton'>
                            Export .csv
                        </Button>
                    )}
                </Grid.Row>
                <ApiError error={error} />
            </Card.Body>
        );
    }

    render () {
        const { show } = this.state;

        return (
            <Card>
                <Dimmer active={false} loader>
                    <form onSubmit={this.handleSubmit}>
                        <Card.Header className='filterHeader'>
                            <div onClick={this.toggleFilter}>
                                <span>Filter</span>
                                <i className={`fe fe-chevrons-${show ? 'up' : 'down'}`} />
                            </div>
                        </Card.Header>
                        {show && this.renderBody()}
                    </form>
                </Dimmer>
            </Card>
        );
    }
}

FilterForm.propTypes = {
    fields: PropTypes.array.isRequired,
    error: PropTypes.object,
    submitCallback: PropTypes.func.isRequired,
    location: PropTypes.object,
    show: PropTypes.bool,
    onFilterChange: PropTypes.func,
    csvCallback: PropTypes.func,
};

FilterForm.defaultProps = {
    show: false,
};

export default withRouter(FilterForm);
