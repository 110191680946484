import React, { useState } from 'react';
import SiteWrapper from '../../../SiteWrapper';
import ApiError from '../../../Common/ApiError';
import { Button, Card, Dimmer, Form, Grid } from 'tabler-react';
import { createApiClient as api } from '../../../../services/api-client';
import { bindActionCreators } from 'redux';
import { navigate } from '../../../../actions/navigate';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import moment from 'moment';
import PropTypes from 'prop-types';
import { FILTER_MASKS } from '../../../../constants';

const BankHolidaysCreate = (props) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [holiday, setHoliday] = useState({
        holiday_date: moment().format('YYYY-MM-DD'),
    });

    const handleChange = (event) => {
        const { name, value } = event.target;

        setHoliday({ [name]: value });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        setLoading(true);
        setError(null);

        try {
            await api().post('/admin-sepa-integration/bank-holidays', holiday);
            setLoading(false);
            toast.success('Bank holiday created successfully');
            props.navigate('/configuration/bank-holidays');
        } catch (error) {
            setError(error.data);
            setLoading(false);
        }
    };

    return (
        <SiteWrapper title='Create bank holiday'>
            <Grid.Row>
                <Grid.Col md={4} sm={6} xs={12}>
                    <Card>
                        <form onSubmit={handleSubmit}>
                            <Dimmer active={loading} loader>
                                <Card.Body>
                                    <Form.MaskedInput
                                        mask={FILTER_MASKS.date.mask}
                                        name='holiday_date'
                                        label='Holiday date'
                                        value={holiday.name}
                                        placeholder='YYYY-MM-DD'
                                        onChange={handleChange}
                                    />
                                    <ApiError error={error} />
                                </Card.Body>
                                <Card.Footer className='text-center'>
                                    <Button type='submit' color='primary' onClick={handleSubmit}>
                                        Submit
                                    </Button>
                                </Card.Footer>
                            </Dimmer>
                        </form>
                    </Card>
                </Grid.Col>
            </Grid.Row>
        </SiteWrapper>
    );
};

BankHolidaysCreate.propTypes = {
    navigate: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            navigate,
        },
        dispatch,
    );

export default connect(null, mapDispatchToProps)(BankHolidaysCreate);
