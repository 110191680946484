import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'tabler-react';
import Modal from 'react-modal';
import Upload from '../Upload/Upload/Upload';

const UploadButton = ({ uploadUrl, params, updateDocuments }) => {
    const [isUploadModalOpen, setUploadModalOpen] = useState(false);

    const toggleModal = useCallback(async () => {
        setUploadModalOpen(!isUploadModalOpen);
    }, [isUploadModalOpen]);

    const renderUploadModal = () => (
        <Modal isOpen={isUploadModalOpen} className='react-modal'>
            <Upload
                url={uploadUrl}
                fileParam='file'
                params={params}
                onClose={toggleModal}
                onComplete={updateDocuments}
            />
        </Modal>
    );

    return (
        <>
            <Button onClick={toggleModal} size='sm' icon='upload' color='primary'>
                Upload
            </Button>
            {renderUploadModal()}
        </>
    );
};

UploadButton.propTypes = {
    uploadUrl: PropTypes.string.isRequired,
    params: PropTypes.object,
    updateDocuments: PropTypes.func.isRequired,
};

UploadButton.defaultProps = {
    params: {},
};

export default UploadButton;
