export const findPricingItemsByType = (pricingItems, type) => pricingItems.filter((pricingItem) => pricingItem.type === type);

export const pricingItemsIds = (pricingItems) => {
    const ids = [];

    if (pricingItems !== null) {
        pricingItems.forEach((pricingItem) => {
            ids.push(pricingItem.relation_id);
        });
    }

    return ids;
};

export const multiSelectValues = (selectedItems) => {
    const values = [];

    if (selectedItems !== null) {
        selectedItems.forEach((selectedItem) => {
            values.push(selectedItem.value);
        });
    }

    return values;
};

export const filterPricingItemsByTransactionTypes = (pricingItems, transactionsFeeConfigurations, transactionTypes) => {
    const ids = [];

    transactionsFeeConfigurations.forEach((fee) => {
        if (transactionTypes.indexOf(fee.transaction_type) !== -1) {
            pricingItems.forEach((id) => {
                if (fee.id === id) {
                    ids.push(id);
                }
            });
        }
    });

    return ids;
};

export const filterPricingItemsByTransactionTypeAndDirection = (
    pricingItems,
    transactionsFeeConfigurations,
    transactionType,
    transactionDirection,
) => {
    const pricingId = [];

    transactionsFeeConfigurations.forEach((fee) => {
        if (fee.transaction_type === transactionType && fee.direction === transactionDirection) {
            pricingItems.forEach((id) => {
                if (fee.id === id) {
                    pricingId.push(id);
                }
            });
        }
    });

    return pricingId;
};
