import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { fetchFeeWalletRelations } from '../../../../../actions/fee/wallets';
import { isLoading } from '../../../../../selectors/isLoading';
import SelectField from '../../../../Common/FilterFormFields/SelectField';

class FeeWallets extends Component {
    componentDidMount () {
        this.props.fetchFeeWalletRelations();
    }

    buildOptions () {
        const { feeWallets } = this.props;
        const output = [];

        feeWallets.forEach((feeWallet) =>
            output.push({
                title: `${feeWallet.name} (${feeWallet.wallet_number}, ${feeWallet.owner_name})`,
                value: feeWallet.id,
            }),
        );

        return output;
    }

    render () {
        return <SelectField {...this.props} title='Collect to' handleChange={this.props.onChange} options={this.buildOptions()} />;
    }
}

FeeWallets.propTypes = {
    feeWallets: PropTypes.array.isRequired,
    fetchFeeWalletRelations: PropTypes.func.isRequired,
    isFetchingFeeWallets: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string,
    name: PropTypes.string,
};

FeeWallets.defaultProps = {
    name: 'fee_wallet_relation_id',
};

const mapStateToProps = (state) => ({
    feeWallets: state.fee.wallets,
    isFetchingFeeWallets: isLoading(state, 'feeWallets'),
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            fetchFeeWalletRelations,
        },
        dispatch,
    );

export default connect(mapStateToProps, mapDispatchToProps)(FeeWallets);
