import React, { Component } from 'react';
import PropTypes from 'prop-types';
import String from '../String';
import { Table } from 'tabler-react';
import Country from '../Country';

class BusinessPartners extends Component {
    renderOwner (owner, key) {
        return (
            <Table.Row key={key}>
                <Table.Col>
                    <String answer={owner.company_name} />
                </Table.Col>
                <Table.Col>
                    <Country answer={owner.country} />
                </Table.Col>
                <Table.Col>
                    <String answer={owner.activity} />
                </Table.Col>
            </Table.Row>
        );
    }

    render () {
        const { answer } = this.props;

        return (
            <Table>
                <Table.Header>
                    <Table.Row>
                        <Table.ColHeader>Company name</Table.ColHeader>
                        <Table.ColHeader>Country</Table.ColHeader>
                        <Table.ColHeader>Activity</Table.ColHeader>
                    </Table.Row>
                </Table.Header>
                <Table.Body>{answer.map((owner, key) => this.renderOwner(owner, key))}</Table.Body>
            </Table>
        );
    }
}

BusinessPartners.propTypes = {
    answer: PropTypes.array,
    field: PropTypes.object.isRequired,
};

export default BusinessPartners;
