import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Dimmer, Grid, Header } from 'tabler-react';
import { createApiClient as api } from '../../../../../services/api-client';
import { fetchCountries } from '../../../../../actions/location/countries';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { groups as privateGroups, fields as privateFields } from './Private/fields';
import { groups as businessGroups, fields as businessFields } from './Business/fields';
import Groups from './Common/Groups';
import ConfirmButton from './ConfirmButton';
import ClientScreening from './Business/ClientScreening';

class Questionnaire extends Component {
    constructor (props) {
        super(props);

        this.state = {
            isLoading: true,
            error: null,
            answers: {},
            previousAnswers: {},
            status: null,
            id: null,
            checksum: null,
            screenedClients: [],
        };

        this.fields = props.user.type === 'private' ? privateFields : businessFields;
        this.groups = props.user.type === 'private' ? privateGroups : businessGroups;

        this.fetchQuestionnaire = this.fetchQuestionnaire.bind(this);
        this.updateStatus = this.updateStatus.bind(this);
    }

    async componentDidMount () {
        this.props.fetchCountries();
        await this.fetchQuestionnaire();
        await this.fetchScreenedClients();
    }

    async fetchQuestionnaire () {
        this.setState({
            isLoading: true,
        });

        await api()
            .get(`/admin-user-questionnaire-verification/users/${this.props.match.params.id}/verifications/current`)
            .then((response) => {
                this.setState({
                    answers: response.data.answers,
                    status: response.data.status,
                    id: response.data.id,
                    checksum: response.data.checksum,
                    isLoading: false,
                    error: null,
                });

                if (response.data.previous_questionnaire_id !== null && response.data.status !== 'confirmed') {
                    this.fetchPreviousQuestionnaire(response.data.previous_questionnaire_id);
                }
            })
            .catch((data) => {
                this.setState({
                    error: data.data,
                    isLoading: false,
                });
            });
    }

    fetchScreenedClients = async (isLoading = true) => {
        const { user: { type } } = this.props;
        const { id } = this.state;

        if (type === 'private' || id === null) {
            return;
        }

        if (isLoading) {
            this.setState({ isLoading: true });
        }


        try {
            const { data } = await api().get(`/admin-user-questionnaire-verification/verifications/${id}/client-screenings`);

            this.setState({ screenedClients: data });
        } catch (error) {
            this.setState({ error: error.data });
        } finally {
            this.setState({ isLoading: false });
        }
    };

    isUnconfirmedScreenedClients = () => this.state.screenedClients.some((screenedClient) => screenedClient.status !== 'confirmed');

    async fetchPreviousQuestionnaire (id) {
        this.setState({
            isLoading: true,
        });

        await api()
            .get(`/admin-user-questionnaire-verification/verifications/${id}`)
            .then((response) => {
                this.setState({
                    previousAnswers: response.data.answers,
                    isLoading: false,
                });
            })
            .catch((data) => {
                this.setState({
                    error: data.data,
                    isLoading: false,
                });
            });
    }

    updateStatus (status) {
        this.setState({ status });
    }

    render () {
        const { isLoading, answers, previousAnswers, status, checksum, id, screenedClients } = this.state;
        const { user } = this.props;

        return (
            <>
                <Header.H3>
                    Questionnaire
                    <ConfirmButton
                        id={id}
                        status={status}
                        checksum={checksum}
                        updateStatus={this.updateStatus}
                        fetchQuestionnaire={this.fetchQuestionnaire}
                        isUnconfirmedScreenedClients={this.isUnconfirmedScreenedClients()}
                        isParentLoading={isLoading}
                    />
                </Header.H3>

                <Dimmer active={isLoading} loader>
                    <Grid.Row>
                        <Grid.Col>
                            {user.type === 'business' && screenedClients.length > 0 && (
                                <ClientScreening
                                    isParentLoading={isLoading}
                                    screenedClients={screenedClients}
                                    updateScreenedClients={this.fetchScreenedClients}
                                />
                            )}
                            {isLoading === false && status !== null ? (
                                <Groups fields={this.fields} previousAnswers={previousAnswers} answers={answers} groups={this.groups} />
                            ) : null}
                            {isLoading === false && status === null && 'Questionnaire not provided'}
                        </Grid.Col>
                    </Grid.Row>
                </Dimmer>
            </>
        );
    }
}

Questionnaire.propTypes = {
    fetchCountries: PropTypes.func.isRequired,
    countries: PropTypes.array.isRequired,
    match: PropTypes.object,
    user: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    countries: state.location.countries,
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            fetchCountries,
        },
        dispatch,
    );

export default connect(mapStateToProps, mapDispatchToProps)(Questionnaire);
