import React, { useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'tabler-react';
import { toast } from 'react-toastify';
import { createApiClient } from '../../../../../services/api-client';
import { DOCUMENT_VERIFICATION_STATUS_CODE } from '../../../../../constants';

const ConfirmButton = ({
    userId,
    documentVerificationStatus,
    updateDocumentVerificationStatus,
    parentLoader,
    documentsType,
    documentVerificationId,
}) => {
    const [isLoading, setLoading] = useState(false);

    const api = useMemo(() => createApiClient(), []);

    const onDocumentVerificationUpdate = useCallback((status) => async () => {
        setLoading(true);

        try {
            await api.put(`admin-user-document-verification/user-document-verifications/${documentVerificationId}`, {
                status,
            });
            await updateDocumentVerificationStatus(false, documentsType);
        } catch (error) {
            toast.error(error.data.message);
        } finally {
            setLoading(false);
        }
    }, [api, documentVerificationId, documentsType, updateDocumentVerificationStatus]);

    const onCreateDocuments = useCallback(async () => {
        setLoading(true);

        try {
            await api.post(`admin-user-document-verification/users/${userId}/user-document-verifications`, {
                type: documentsType,
            });
            await updateDocumentVerificationStatus(false, documentsType);
        } catch (error) {
            toast.error(error.data.message);
        } finally {
            setLoading(false);
        }
    }, [api, documentsType, updateDocumentVerificationStatus, userId]);

    const renderCreateButton = () => (
        <Button
            className='ml-3'
            size='sm'
            icon='plus-circle'
            color='primary'
            onClick={onCreateDocuments}
            loading={parentLoader || isLoading}
            disabled={isLoading}
        >
            Create documents
        </Button>
    );

    const renderConfirmButton = () => (
        <Button.List className='ml-3'>
            <Button
                size='sm'
                icon='check'
                color='success'
                onClick={onDocumentVerificationUpdate(DOCUMENT_VERIFICATION_STATUS_CODE.confirmed)}
                loading={parentLoader || isLoading}
                disabled={documentVerificationStatus === DOCUMENT_VERIFICATION_STATUS_CODE.confirmed || isLoading || parentLoader}
            >
                {documentVerificationStatus === DOCUMENT_VERIFICATION_STATUS_CODE.confirmed ? 'Documents confirmed' : 'Confirm documents'}
            </Button>
            {documentVerificationStatus === DOCUMENT_VERIFICATION_STATUS_CODE.confirmed && (
                <Button
                    className='ml-3'
                    size='sm'
                    icon='x'
                    color='secondary'
                    loading={parentLoader || isLoading}
                    onClick={onDocumentVerificationUpdate(DOCUMENT_VERIFICATION_STATUS_CODE.processing)}
                >
                    Cancel confirmation
                </Button>
            )}
        </Button.List>
    );

    return documentVerificationStatus === null ? renderCreateButton() : renderConfirmButton();
};

ConfirmButton.propTypes = {
    userId: PropTypes.string.isRequired,
    updateDocumentVerificationStatus: PropTypes.func.isRequired,
    documentVerificationStatus: PropTypes.string,
    parentLoader: PropTypes.bool.isRequired,
    documentsType: PropTypes.string.isRequired,
};

export default ConfirmButton;
