import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Card, Dimmer, Icon } from 'tabler-react';
import { createApiClient } from '../../../../../services/api-client';
import Permissions from './Permissions';
import '../PermissionGroups.scss';

class Group extends Component {
    constructor (props) {
        super(props);

        this.state = {
            isLoading: false,
            isChanged: false,
        };

        this.api = createApiClient();

        this.isChecked = this.isChecked.bind(this);
        this.onCheckboxChange = this.onCheckboxChange.bind(this);
    }

    isChecked (group, permission) {
        const equivalent = group.permissions.filter((desired) => desired.name === permission.name);

        return equivalent.length !== 0;
    }

    savePermissions (group) {
        this.setState({
            isLoading: true,
        });

        this.api
            .put(`/admin-permission/permission-groups/${group.id}`, {
                name: group.name,
                permissions: group.permissions.map((permission) => permission.id),
            })
            .then(() => {
                this.setState({
                    isLoading: false,
                    isChanged: false,
                });
            });
    }

    onCheckboxChange (groupId, permission) {
        const { onCheckboxChange } = this.props;

        this.setState({
            isChanged: true,
        });

        onCheckboxChange(groupId, permission);
    }

    renderGroupOptions (group) {
        const { isChanged } = this.state;

        if (!isChanged) {
            return null;
        }

        return (
            <Button color='primary' size='sm' onClick={() => this.savePermissions(group)}>
                Save permissions
            </Button>
        );
    }

    renderGroupTitle () {
        const { group, permissions } = this.props;
        const groupPermissionsCount = group.permissions.length;

        return (
            <>
                {group.name}
                <div className='stars'>{permissions.map((permission, key) => this.renderStar(key, groupPermissionsCount))}</div>
            </>
        );
    }

    renderStar (key, groupPermissionsCount) {
        return <Icon key={key} prefix='fe' name='star' className={key + 1 <= groupPermissionsCount ? 'starActive' : 'starInactive'} />;
    }

    render () {
        const { group, permissions } = this.props;
        const { isLoading } = this.state;

        return (
            <Card key={group.name} title={this.renderGroupTitle()} options={this.renderGroupOptions(group)} className='role' isCollapsible>
                <Card.Body className='permissions'>
                    <Dimmer active={isLoading} loader>
                        <div className='permission'>
                            <Permissions
                                onCheckboxChange={this.onCheckboxChange}
                                isChecked={this.isChecked}
                                group={group}
                                permissions={permissions}
                            />
                        </div>
                    </Dimmer>
                </Card.Body>
            </Card>
        );
    }
}

Group.propTypes = {
    group: PropTypes.object.isRequired,
    permissions: PropTypes.array.isRequired,
    onCheckboxChange: PropTypes.func.isRequired,
};

export default Group;
