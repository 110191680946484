import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'tabler-react';
import '../PermissionGroups.scss';

class Permissions extends Component {
    renderPermissions (permission, key) {
        const { group, onCheckboxChange, isChecked } = this.props;

        const isPermissionChecked = isChecked(group, permission);

        return (
            <div
                key={key}
                onClick={() => onCheckboxChange(group.id, permission)}
                className={isPermissionChecked ? 'permission-on' : 'permission-off'}
            >
                <Icon prefix='fe' name={isPermissionChecked ? 'eye' : 'eye-off'} />
                {permission.name}
            </div>
        );
    }

    render () {
        const { permissions } = this.props;

        return permissions.map((permission, key) => this.renderPermissions(permission, key));
    }
}

Permissions.propTypes = {
    group: PropTypes.object.isRequired,
    permissions: PropTypes.array.isRequired,
    onCheckboxChange: PropTypes.func.isRequired,
    isChecked: PropTypes.func.isRequired,
};

export default Permissions;
