import React, { useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Button, Table } from 'tabler-react';
import { formatDateAndTime, getFileExtension } from '../../../../../../../helpers';
import { createApiClient } from '../../../../../../../services/api-client';
import { useDidUpdate, usePrevious } from '../../../../../../../services/custom-hooks';
import { toast } from 'react-toastify';
import Comments from '../../../../../../Common/Comments';
import FilePreview from '../../../../../../Common/FilePreview';
import DownloadButton from '../../../../../../Common/Buttons/DownloadButton';
import RejectDocumentModal from './Misc/RejectDocumentModal';
import { DOCUMENT_STATUS, DOCUMENT_VERIFICATION_STATUS_CODE } from '../../../../../../../constants';

const Document = ({ document, documentVerificationStatus }) => {
    const [documentLoading, setDocumentLoading] = useState({
        isDeleting: false,
        isDeleted: false,
        isPerformingAction: false,
    });
    const [documentState, setDocumentState] = useState(document);
    const [isCommentsModalOpen, setCommentsModalOpen] = useState(false);
    const [isDocumentRejectModalOpen, setDocumentRejectModalOpen] = useState(false);
    const prevDocument = usePrevious(document);

    const { isDeleting, isDeleted, isPerformingAction } = documentLoading;

    const api = useMemo(() => createApiClient(), []);

    const componentDidUpdate = useCallback(() => {
        if (prevDocument !== document) {
            setDocumentState((prevDocumentState) => ({ ...prevDocumentState, status: document.status }));
        }
    }, [document, prevDocument]);

    const onDelete = useCallback(async () => {
        if (!confirm('Are you sure?')) {
            return false;
        }

        setDocumentLoading((prevDocumentLoading) => ({ ...prevDocumentLoading, isDeleting: true }));

        try {
            await api.delete(`admin-user-document-verification/user-documents/${document.id}`);
            setDocumentLoading((prevDocumentLoading) => ({ ...prevDocumentLoading, isDeleted: true }));
            toast.success('Deleted successfully');
        } catch (error) {
            toast.error(error.data.message);
        } finally {
            setDocumentLoading((prevDocumentLoading) => ({ ...prevDocumentLoading, isDeleting: false }));
        }
    }, [api, document.id]);

    const handleAction = useCallback(
        (status, reason = '') => async () => {
            setDocumentLoading((prevDocumentLoading) => ({ ...prevDocumentLoading, isPerformingAction: true }));

            try {
                const { data } = await api.put(`admin-user-document-verification/user-documents/${document.id}`, {
                    status,
                    reason,
                });

                setDocumentState((prevDocument) => ({ ...prevDocument, ...data }));
            } catch (error) {
                toast.error(error.data.message);
            } finally {
                setDocumentLoading((prevDocumentLoading) => ({ ...prevDocumentLoading, isPerformingAction: false }));
            }
        },
        [api, document.id],
    );

    const isDocumentAcceptedOrCreated =
        documentState.status === DOCUMENT_STATUS.accepted || documentState.status === DOCUMENT_STATUS.created;

    const renderActions = () => {
        if (
            documentVerificationStatus === DOCUMENT_VERIFICATION_STATUS_CODE.created ||
            documentVerificationStatus === DOCUMENT_VERIFICATION_STATUS_CODE.processing
        ) {
            return (
                <Button.List>
                    <Button
                        size='sm'
                        icon='thumbs-up'
                        color='success'
                        onClick={handleAction(DOCUMENT_STATUS.accepted)}
                        loading={isPerformingAction}
                        disabled={isPerformingAction || documentState.status === 'accepted'}
                        name='accepted'
                    >
                        {documentState.status === DOCUMENT_STATUS.accepted ? 'Accepted' : 'Accept'}
                    </Button>
                    <Button
                        size='sm'
                        icon={isDocumentAcceptedOrCreated ? 'thumbs-down' : 'eye'}
                        color='danger'
                        onClick={setRejectDocumentModalVisible(true)}
                        loading={isPerformingAction}
                        disabled={isPerformingAction}
                    >
                        {isDocumentAcceptedOrCreated ? 'Reject' : 'Rejected'}
                    </Button>
                </Button.List>
            );
        }

        return (
            <Button
                color={isDocumentAcceptedOrCreated ? 'success' : 'danger'}
                icon={documentState.status === DOCUMENT_STATUS.rejected ? 'eye' : ''}
                disabled={isDocumentAcceptedOrCreated}
                size='sm'
                onClick={documentState.status === DOCUMENT_STATUS.rejected ? setRejectDocumentModalVisible(true) : undefined}
            >
                {isDocumentAcceptedOrCreated ? 'Accepted' : 'Rejected'}
            </Button>
        );
    };

    const setCommentsModalVisible = useCallback((value) => () => setCommentsModalOpen(value), []);

    const setRejectDocumentModalVisible = useCallback((value) => () => setDocumentRejectModalOpen(value), []);

    const downloadUrl = `admin-user-document-verification/user-documents/${document.id}/download`;

    useDidUpdate(() => {
        componentDidUpdate();
    });

    return (
        !isDeleted && (
            <Table.Row key={document.id} className='small'>
                <Table.Col>{formatDateAndTime(document.created_at)}</Table.Col>
                <Table.Col>{document.name ?? document.file_name}</Table.Col>
                <Table.Col className='text-capitalize'>
                    {document.uploaded_by_admin ? `Admin (${document.uploader_name})` : 'User'}
                </Table.Col>
                <Table.Col className='text-center'>{renderActions()}</Table.Col>
                <Table.Col className='text-right'>
                    <Button.List>
                        <DownloadButton downloadUrl={downloadUrl} fileName={document.file_name} />

                        <FilePreview downloadUrl={downloadUrl} fileType={getFileExtension(document.file_name)} />

                        <Button outline size='sm' icon='message-circle' color='info' onClick={setCommentsModalVisible(true)} />

                        <Comments
                            relationType='document'
                            relationId={document.id}
                            isModalOpen={isCommentsModalOpen}
                            setCommentsModalVisible={setCommentsModalVisible}
                        />

                        <RejectDocumentModal
                            isModalOpen={isDocumentRejectModalOpen}
                            closeModal={setRejectDocumentModalVisible(false)}
                            rejectDocument={handleAction}
                            documentStatus={documentState.status}
                            rejectReason={documentState.reason}
                            rejectedBy={documentState.rejected_by}
                            isDocumentAcceptedOrCreated={isDocumentAcceptedOrCreated}
                        />

                        {document.uploaded_by_admin && documentState.status !== DOCUMENT_STATUS.accepted && (
                            <Button
                                size='sm'
                                icon='trash'
                                outline
                                color='danger'
                                onClick={onDelete}
                                loading={isDeleting}
                                disabled={isDeleting}
                            />
                        )}
                    </Button.List>
                </Table.Col>
            </Table.Row>
        )
    );
};

Document.propTypes = {
    document: PropTypes.object.isRequired,
    documentVerificationStatus: PropTypes.string.isRequired,
};

export default Document;
