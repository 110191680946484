import React, { useState } from 'react';
import { Button, Dimmer, Form, Icon } from 'tabler-react';
import Modal from 'react-modal';
import PropTypes from 'prop-types';
import AmountField from '../../../Common/FilterFormFields/AmountField';
import { createApiClient as api } from '../../../../services/api-client';
import { toast } from 'react-toastify';
import toNumber from 'lodash/toNumber';

const MarkupCreateModal = ({ onModalClose, onCreated, feeWallets }) => {
    const FROM_CURRENCIES = ['eur'];
    const TO_CURRENCIES = ['usd', 'gbp'];

    const handleCreationSubmit = (event) => {
        event.preventDefault();

        setIsloading(true);

        api()
            .post('/admin-currency-exchange/markup-fee-configurations', {
                from_currency: fromCurrency,
                to_currency: toCurrency,
                markup_amount: markupAmount,
                fee_wallet_relation_id: toNumber(feeWalletRelationId),
            })
            .then(() => {
                toast.success('Created successful');
                onCreated();
            })
            .catch(({ data }) => {
                toast.error(data.message);
            })
            .finally(() => {
                onModalClose();
            });
    };

    const [fromCurrency, setFromCurrency] = useState(FROM_CURRENCIES[0]);
    const [toCurrency, setToCurrency] = useState(TO_CURRENCIES[0]);
    const [markupAmount, setMarkupAmount] = useState(0);
    const [feeWalletRelationId, setFeeWalletRelationId] = useState(feeWallets[0].id);
    const [isLoading, setIsloading] = useState(false);

    return (
        <Modal isOpen className='react-modal w-450'>
            <div className='react-modal__content'>
                <Icon name='x-circle' className='w-4 react-modal__closeButton' onClick={onModalClose}/>
                <Form onSubmit={handleCreationSubmit}>
                    <Dimmer active={isLoading} loader>
                        <Form.Select
                            name='from_currency'
                            label='From Currency'
                            value={fromCurrency}
                            onChange={({ target }) => setFromCurrency(target.value)}
                        >
                            {
                                FROM_CURRENCIES.map(
                                    (currency) => (
                                        <option key={currency} value={currency} label={currency.toUpperCase()}/>
                                    ),
                                )
                            }
                        </Form.Select>
                        <Form.Select
                            name='to_currency'
                            label='To Currency'
                            value={toCurrency}
                            onChange={({ target }) => setToCurrency(target.value)}
                        >
                            {
                                TO_CURRENCIES.map(
                                    (currency) => (
                                        <option key={currency} value={currency} label={currency.toUpperCase()}/>
                                    ),
                                )
                            }
                        </Form.Select>
                        <Form.Select
                            name='fee_wallet_relation_id'
                            label='Collect to'
                            value={feeWalletRelationId}
                            onChange={({ target }) => setFeeWalletRelationId(target.value)}
                        >
                            {feeWallets.map((feeWallet) => (
                                <option key={feeWallet.id} value={feeWallet.id}>
                                    {feeWallet.name} ({feeWallet.wallet_number}, {feeWallet.owner_name})
                                </option>
                            ))}
                        </Form.Select>
                        <AmountField
                            name='markup_amount'
                            title='Markup Amount'
                            value={markupAmount}
                            handleChange={({ target }) => setMarkupAmount(target.value)}
                            maxAmount={5}
                        />
                        <Button type='submit' color='primary' className='w-100' disabled={markupAmount < 0.01}>
                            Create
                        </Button>
                    </Dimmer>
                </Form>
            </div>
        </Modal>
    );
};

MarkupCreateModal.propTypes = {
    onModalClose: PropTypes.func,
    onCreated: PropTypes.func,
    feeWallets: PropTypes.array,
};

export default MarkupCreateModal;
