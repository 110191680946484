export const countriesCodes = (countries) => {
    const codes = [];

    if (countries !== null) {
        countries.map((country) => {
            codes.push(country.value);

            return country;
        });
    }

    return codes;
};

export const countriesOptions = (countries) => {
    const options = [];

    countries.map((country) => {
        options.push({
            value: country.code,
            label: country.name,
        });

        return country;
    });

    return options;
};

export const selectedCountries = (countries, selected) => {
    const selectedCountries = [];

    countries.map((country) => {
        if (selected.includes(country.code)) {
            selectedCountries.push({
                value: country.code,
                label: country.name,
            });
        }

        return country;
    });

    return selectedCountries;
};
