import React, { useState, useEffect, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { Badge, Card, Text, Grid } from 'tabler-react';
import { createApiClient as api } from '../../../../../../services/api-client';
import { formatDateAndTime } from '../../../../../../helpers';
import { formatOndatoStatus, getOndatoStatusColor } from '../../../../../../services/ondato/status-helper';
import SelectField from '../../../../../Common/FilterFormFields/SelectField';
import DocumentData from './Documents/DocumentData';
import OndatoDuplicate from './OndatoDuplicate';
import UserScreening from './UserScreening';

const Document = ({ userId, userType }) => {
    const [kycState, setKycState] = useState({
        ondato_status: null,
        status: null,
        document_data: {},
        identification_url: null,
    });
    const [identityState, setIdentityState] = useState({
        identityId: null,
        identitiesSelect: [],
        identities: [],
    });
    const [error, setError] = useState(null);
    const [isLoading, setLoading] = useState(false);
    const mounted = useRef(false);

    const fetchUserRelation = useCallback(async (identityId) => {
        if (identityId === null) {
            return;
        }

        setLoading(true);

        try {
            const response = await api().get(`/admin-user-identity-verification/user-identities/${identityId}/kyc-user-relation`);

            setKycState(response.data);
        } catch (error) {
            setError(error.data.message);
        } finally {
            setLoading(false);
        }
    }, []);

    const fetchKycUserRelations = useCallback(async () => {
        setLoading(true);

        try {
            const response = await api().get(`/admin-user-identity-verification/users/${userId}/user-identities`);

            const identitiesWithDocumentData = response.data.filter((identity) => identity.has_document_data === true);
            const identityId = identitiesWithDocumentData.length !== 0 ? identitiesWithDocumentData[0].id.toString() : null;

            setIdentityState((prevIdentityState) => ({
                ...prevIdentityState,
                identitiesSelect: identitiesWithDocumentData.map((identity) => ({
                    title: formatDateAndTime(identity.created_at),
                    value: identity.id,
                })),
                identityId,
                identities: identitiesWithDocumentData,
            }));

            await fetchUserRelation(identityId);
        } catch (error) {
            setError(error.data.message);
        } finally {
            setLoading(false);
        }
    }, [fetchUserRelation, userId]);

    const renderError = () =>
        error !== null && (
            <Card.Alert color='danger'>
                <Text.Small>{error}</Text.Small>
            </Card.Alert>
        );

    const renderFailReason = () =>
        kycState.fail_reason !== undefined && (
            <Card.Alert color='danger'>
                <Text.Small>{kycState.fail_reason}</Text.Small>
            </Card.Alert>
        );

    const renderIdentityFailReason = () =>
        getIdentityFailReasonById() !== null && (
            <Card.Alert color='danger'>
                <Text.Small>{getIdentityFailReasonById(identityState.identityId)}</Text.Small>
            </Card.Alert>
        );

    const handleRelationChange = useCallback(
        async (event) => {
            const target = event.currentTarget;

            setIdentityState((prevRelationState) => ({
                ...prevRelationState,
                identityId: target.value,
            }));
            await fetchUserRelation(target.value);
        },
        [fetchUserRelation],
    );

    const getIdentityFailReasonById = () =>
        identityState.identityId !== null &&
        identityState.identities.find((identity) => identity.id === Number(identityState.identityId)).fail_reason;

    useEffect(() => {
        if (!mounted.current) {
            fetchKycUserRelations();
        }

        mounted.current = true;
    }, [fetchKycUserRelations]);

    return (
        <>
            {/*{userType === 'business' && (*/}
            {/*    <Grid.Row>*/}
            {/*        <UserCompanyScreening userId={userId} />*/}
            {/*    </Grid.Row>*/}
            {/*)}*/}
            {identityState.identityId && userType === 'private' && (
                <Grid.Row>
                    <UserScreening identityId={identityState.identityId} />
                </Grid.Row>
            )}
            <Grid.Row>
                <Card>
                    <Card.Header>
                        <Card.Title>Identity</Card.Title>
                        <Card.Options>
                            <Badge color={getOndatoStatusColor(kycState.ondato_status)}>{formatOndatoStatus(kycState.ondato_status)}</Badge>
                        </Card.Options>
                    </Card.Header>

                    {renderIdentityFailReason()}
                    {renderFailReason()}
                    {renderError()}

                    <DocumentData
                        isLoading={isLoading}
                        documentData={kycState.document_data}
                        relation={identityState.identityId}
                    />

                    {identityState.identitiesSelect.length !== 0 && (
                        <Card.Footer>
                            <SelectField
                                name='relation'
                                handleChange={handleRelationChange}
                                options={identityState.identitiesSelect}
                                value={identityState.identityId}
                                onChange={fetchUserRelation}
                                includeEmptyOption={false}
                            />
                        </Card.Footer>
                    )}
                </Card>

                {userType !== 'business' && identityState.identitiesSelect !== null && <OndatoDuplicate userId={userId} />}
            </Grid.Row>
        </>
    );
};

Document.propTypes = {
    userId: PropTypes.number.isRequired,
    userType: PropTypes.string.isRequired,
};

export default Document;
