import { handleActions } from 'redux-actions';

import {
    AUTHENTICATE_USER_FAILURE,
    AUTHENTICATE_USER_REQUEST,
    AUTHENTICATE_USER_SUCCESS,
    CONFIRM_AUTHENTICATION_FAILURE,
    CONFIRM_AUTHENTICATION_REQUEST,
    CONFIRM_AUTHENTICATION_SUCCESS,
    CREATE_AND_GET_AUTHENTICATION_FAILURE,
    CREATE_AND_GET_AUTHENTICATION_REQUEST,
    CREATE_AND_GET_AUTHENTICATION_SUCCESS,
} from '../actions/user/authenticate-user';
import { FETCH_CURRENCIES_FAILURE, FETCH_CURRENCIES_REQUEST, FETCH_CURRENCIES_SUCCESS } from '../actions/currency/currencies';
import {
    FETCH_FEE_WALLETS_RELATIONS_FAILURE,
    FETCH_FEE_WALLETS_RELATIONS_REQUEST,
    FETCH_FEE_WALLETS_RELATIONS_SUCCESS,
} from '../actions/fee/wallets';
import {
    FETCH_MONTHLY_FEE_CONFIGURATIONS_FAILURE,
    FETCH_MONTHLY_FEE_CONFIGURATIONS_REQUEST,
    FETCH_MONTHLY_FEE_CONFIGURATIONS_SUCCESS,
} from '../actions/fee/monthly';
import { FETCH_COUNTRIES_FAILURE, FETCH_COUNTRIES_REQUEST, FETCH_COUNTRIES_SUCCESS } from '../actions/location/countries';
import {
    FETCH_TRANSACTION_FEE_CONFIGURATIONS_FAILURE,
    FETCH_TRANSACTION_FEE_CONFIGURATIONS_REQUEST,
    FETCH_TRANSACTION_FEE_CONFIGURATIONS_SUCCESS,
} from '../actions/fee/transactions';
import { FETCH_PRICING_GROUPS_FAILURE, FETCH_PRICING_GROUPS_REQUEST, FETCH_PRICING_GROUPS_SUCCESS } from '../actions/fee/pricing';
import {
    FETCH_DOCUMENT_TYPES_FAILURE,
    FETCH_DOCUMENT_TYPES_REQUEST,
    FETCH_DOCUMENT_TYPES_SUCCESS,
} from '../actions/document-type/document-types';
import {
    FETCH_DEFAULT_WALLET_LIMITS_REQUEST,
    FETCH_DEFAULT_WALLET_LIMITS_SUCCESS,
    FETCH_DEFAULT_WALLET_LIMITS_FAILURE,
} from '../actions/limits/default-wallet';
import {
    RISK_LEVEL_UPDATE_REQUEST,
    RISK_LEVEL_UPDATE_SUCCESS,
    RISK_LEVEL_UPDATE_FAILURE,
} from '../actions/user/risk-level';

const setIsLoading = (state, entity, isLoading) => ({ ...state, [entity]: isLoading });

export default handleActions(
    {
        [AUTHENTICATE_USER_REQUEST]: (state) => setIsLoading(state, 'authenticate', true),
        [AUTHENTICATE_USER_SUCCESS]: (state) => setIsLoading(state, 'authenticate', false),
        [AUTHENTICATE_USER_FAILURE]: (state) => setIsLoading(state, 'authenticate', false),

        [CONFIRM_AUTHENTICATION_REQUEST]: (state) => setIsLoading(state, 'confirmAuthentication', true),
        [CONFIRM_AUTHENTICATION_SUCCESS]: (state) => setIsLoading(state, 'confirmAuthentication', false),
        [CONFIRM_AUTHENTICATION_FAILURE]: (state) => setIsLoading(state, 'confirmAuthentication', false),

        [CREATE_AND_GET_AUTHENTICATION_REQUEST]: (state) => setIsLoading(state, 'createAndGetAuthentication', true),
        [CREATE_AND_GET_AUTHENTICATION_SUCCESS]: (state) => setIsLoading(state, 'createAndGetAuthentication', false),
        [CREATE_AND_GET_AUTHENTICATION_FAILURE]: (state) => setIsLoading(state, 'createAndGetAuthentication', false),

        [FETCH_CURRENCIES_REQUEST]: (state) => setIsLoading(state, 'currencies', true),
        [FETCH_CURRENCIES_SUCCESS]: (state) => setIsLoading(state, 'currencies', false),
        [FETCH_CURRENCIES_FAILURE]: (state) => setIsLoading(state, 'currencies', false),

        [FETCH_FEE_WALLETS_RELATIONS_REQUEST]: (state) => setIsLoading(state, 'feeWallets', true),
        [FETCH_FEE_WALLETS_RELATIONS_SUCCESS]: (state) => setIsLoading(state, 'feeWallets', false),
        [FETCH_FEE_WALLETS_RELATIONS_FAILURE]: (state) => setIsLoading(state, 'feeWallets', false),

        [FETCH_MONTHLY_FEE_CONFIGURATIONS_REQUEST]: (state) => setIsLoading(state, 'monthlyFees', true),
        [FETCH_MONTHLY_FEE_CONFIGURATIONS_SUCCESS]: (state) => setIsLoading(state, 'monthlyFees', false),
        [FETCH_MONTHLY_FEE_CONFIGURATIONS_FAILURE]: (state) => setIsLoading(state, 'monthlyFees', false),

        [FETCH_COUNTRIES_REQUEST]: (state) => setIsLoading(state, 'countries', true),
        [FETCH_COUNTRIES_SUCCESS]: (state) => setIsLoading(state, 'countries', false),
        [FETCH_COUNTRIES_FAILURE]: (state) => setIsLoading(state, 'countries', false),

        [FETCH_TRANSACTION_FEE_CONFIGURATIONS_REQUEST]: (state) => setIsLoading(state, 'transactionsFees', true),
        [FETCH_TRANSACTION_FEE_CONFIGURATIONS_SUCCESS]: (state) => setIsLoading(state, 'transactionsFees', false),
        [FETCH_TRANSACTION_FEE_CONFIGURATIONS_FAILURE]: (state) => setIsLoading(state, 'transactionsFees', false),

        [FETCH_PRICING_GROUPS_REQUEST]: (state) => setIsLoading(state, 'pricingGroups', true),
        [FETCH_PRICING_GROUPS_SUCCESS]: (state) => setIsLoading(state, 'pricingGroups', false),
        [FETCH_PRICING_GROUPS_FAILURE]: (state) => setIsLoading(state, 'pricingGroups', false),

        [FETCH_DOCUMENT_TYPES_REQUEST]: (state) => setIsLoading(state, 'documentTypes', true),
        [FETCH_DOCUMENT_TYPES_SUCCESS]: (state) => setIsLoading(state, 'documentTypes', false),
        [FETCH_DOCUMENT_TYPES_FAILURE]: (state) => setIsLoading(state, 'documentTypes', false),

        [RISK_LEVEL_UPDATE_REQUEST]: (state) => setIsLoading(state, 'riskLevel', true),
        [RISK_LEVEL_UPDATE_SUCCESS]: (state) => setIsLoading(state, 'riskLevel', false),
        [RISK_LEVEL_UPDATE_FAILURE]: (state) => setIsLoading(state, 'riskLevel', false),

        [FETCH_DEFAULT_WALLET_LIMITS_REQUEST]: (state) => setIsLoading(state, 'defaultWalletLimits', true),
        [FETCH_DEFAULT_WALLET_LIMITS_SUCCESS]: (state) => setIsLoading(state, 'defaultWalletLimits', false),
        [FETCH_DEFAULT_WALLET_LIMITS_FAILURE]: (state) => setIsLoading(state, 'defaultWalletLimits', false),
    },
    {},
);
