import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FixedFeeFields from './FixedFeeFields';
import PercentFeeFields from './PercentFeeFields';

class TypeFields extends Component {
    render () {
        const { type, fee, onChange } = this.props;

        if (type === 'fixed') {
            return <FixedFeeFields value={fee.amount} onChange={onChange} />;
        } else if (type === 'percent') {
            return <PercentFeeFields percent={fee.percent} minimum={fee.minimum} maximum={fee.maximum} onChange={onChange} />;
        } else if (type === 'combined') {
            return (
                <div>
                    <FixedFeeFields value={fee.amount} onChange={onChange} />
                    <PercentFeeFields percent={fee.percent} minimum={fee.minimum} maximum={fee.maximum} onChange={onChange} />
                </div>
            );
        }
    }
}

TypeFields.propTypes = {
    onChange: PropTypes.func.isRequired,
    type: PropTypes.string.isRequired,
    fee: PropTypes.object.isRequired,
};

export default TypeFields;
