import React, { useState, useMemo, useCallback } from 'react';
import SiteWrapper from '../../SiteWrapper';
import { Button } from 'tabler-react';
import PaginatedFilteredList from '../../Common/PaginatedFilteredList';
import { Link } from 'react-router-dom';
import { formatDateAndTime } from '../../../helpers';
import { createApiClient as api } from '../../../services/api-client';
import { toast } from 'react-toastify';

const bankHolidaysUri = '/admin-sepa-integration/bank-holidays';

const BankHolidays = () => {
    const [items, setItems] = useState([]);
    const [error, setError] = useState(null);
    const [columns, setColumns] = useState([]);

    const updateItems = useCallback((items) => {
        setItems(items);
    }, []);

    const updateColumns = useCallback((columns) => {
        setColumns(columns);
    }, []);

    const handleDeleteHoliday = useCallback(
        (holiday) => async () => {
            if (confirm('Are you sure?')) {
                try {
                    await api().delete(`/admin-sepa-integration/bank-holidays/${holiday.id}`);
                    toast.success('Bank holiday deleted successfully');
                    setItems((prev) => prev.filter((item) => item.id !== holiday.id));
                } catch (error) {
                    setError(error.data);
                }
            }
        },
        [],
    );

    const renderOptions = () => (
        <Link to='/configuration/bank-holidays/create'>
            <Button pill icon='plus' color='primary'>
                Create new
            </Button>
        </Link>
    );

    const autoMapOptions = useMemo(
        () => ({
            sortable: false,
            include: [
                {
                    Header: 'Actions',
                    id: 'actions',
                    sortable: false,
                    width: 100,
                    Cell: (row) => (
                        <Button.List>
                            <Button size='sm' color='danger' onClick={handleDeleteHoliday(row.original)}>
                                Delete
                            </Button>
                        </Button.List>
                    ),
                },
            ],
            custom: {
                created_at: {
                    Header: 'Created at',
                    accessor: 'created_at',
                    Cell: (row) => formatDateAndTime(row.value),
                },
            },
        }),
        [handleDeleteHoliday],
    );

    return (
        <SiteWrapper
            title='Bank Holidays'
            options={renderOptions()}
            titleDescription='All transactions created during the Bank holiday,
                 will be processed the next working day after the Bank holiday'
        >
            <PaginatedFilteredList
                uri={bankHolidaysUri}
                columns={columns}
                updateItems={updateItems}
                items={items}
                parentError={error}
                updateColumns={updateColumns}
                autoMapOptions={autoMapOptions}
            />
        </SiteWrapper>
    );
};

export default BankHolidays;
