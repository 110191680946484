import { combineReducers } from 'redux';

import pricing from './pricing';
import wallets from './wallets';
import monthly from './monthly';
import transactions from './transactions';

export default combineReducers({
    wallets,
    monthly,
    transactions,
    pricing,
});
