import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createApiClient } from '../../../services/api-client';
import { Button, Card, Grid } from 'tabler-react';
import ReactTable from 'react-table';
import { toast } from 'react-toastify';
import { navigate } from '../../../actions/navigate';
import SiteWrapper from '../../SiteWrapper';
import { capitalize, formatDate } from '../../../helpers';
import { Link } from 'react-router-dom';

const TermsAndConditions = ({ navigate }) => {
    const [isLoading, setLoading] = useState(false);
    const [agreements, setAgreements] = useState([]);

    const api = useMemo(() => createApiClient(), []);

    const getAgreements = useCallback(async () => {
        setLoading(true);

        try {
            const { data } = await api.get('admin-user/agreements');

            setAgreements(data);
        } catch (error) {
            toast.error(`Something went wrong: ${error.data.message}`);
        } finally {
            setLoading(false);
        }
    }, [api]);

    const deleteAgreement = useCallback(
        (agreementId) => async () => {
            if (!confirm('Are you sure?')) {
                return false;
            }

            setLoading(true);

            try {
                await api.delete(`admin-user/agreements/${agreementId}`);
                await getAgreements();

                toast.success('User agreement has been successfully deleted');
            } catch (error) {
                toast.error(`User agreement deletion failed: ${error.data.message}`);
            } finally {
                setLoading(false);
            }
        },
        [api, getAgreements],
    );

    const editUserAgreement = useCallback((agreementId) => () => navigate(`/compliance/terms-conditions/edit/${agreementId}`), [navigate]);

    const renderCreateTermsAndConditionsButton = () => (
        <Link to='terms-conditions/create'>
            <Button pill icon='plus' color='primary'>
                Create new
            </Button>
        </Link>
    );

    const columns = useMemo(
        () => [
            {
                Header: 'Date/Version',
                accessor: 'date',
                sortable: false,
                Cell: (row) => formatDate(row.value),
            },
            {
                Header: 'User type',
                accessor: 'user_type',
                sortable: false,
                Cell: (row) => capitalize(row.value),
            },
            {
                Header: 'Actions',
                id: 'actions',
                width: 130,
                sortable: false,
                headerClassName: 'column-actions',
                className: 'column-actions',
                Cell: (row) => (
                    <Button.List>
                        <Button size='sm' onClick={editUserAgreement(row.original.id)} color='primary'>
                            Edit
                        </Button>
                        <Button size='sm' onClick={deleteAgreement(row.original.id)} color='danger'>
                            Delete
                        </Button>
                    </Button.List>
                ),
            },
        ],
        [deleteAgreement, editUserAgreement],
    );

    useEffect(() => {
        getAgreements();
    }, [getAgreements]);

    return (
        <SiteWrapper title='Terms & Conditions' options={renderCreateTermsAndConditionsButton()}>
            <Grid.Row>
                <Grid.Col>
                    <Card>
                        <Card.Body>
                            <ReactTable
                                manual
                                loading={isLoading}
                                data={agreements}
                                columns={columns}
                                className='-striped -highlight'
                                multiSort={false}
                                minRows={1}
                                showPagination={false}
                            />
                        </Card.Body>
                    </Card>
                </Grid.Col>
            </Grid.Row>
        </SiteWrapper>
    );
};

TermsAndConditions.propTypes = {
    navigate: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            navigate,
        },
        dispatch,
    );

export default connect(null, mapDispatchToProps)(TermsAndConditions);
