import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { fetchCountries } from '../../../../../../../actions/location/countries';
import { connect } from 'react-redux';
import { getLabelByCountryCode } from '../../../../../../../selectors/location/countries';
import { Icon } from 'tabler-react';

class Country extends Component {
    componentDidMount () {
        this.props.fetchCountries();
    }

    render () {
        const { answer, countries } = this.props;

        return (
            <>
                <Icon prefix='fe' name='globe' /> {getLabelByCountryCode(countries, answer)}
            </>
        );
    }
}

Country.propTypes = {
    answer: PropTypes.string.isRequired,
    countries: PropTypes.array.isRequired,
    fetchCountries: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    countries: state.location.countries,
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            fetchCountries,
        },
        dispatch,
    );

export default connect(mapStateToProps, mapDispatchToProps)(Country);
