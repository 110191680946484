import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Grid, Card, Header } from 'tabler-react';
import { toast } from 'react-toastify';
import { createApiClient } from '../../../services/api-client';
import SiteWrapper from '../../SiteWrapper';
import TransactionDocuments from './TransactionDocuments';
import TransactionAudit from './Transaction/TransactionAudit';
import TransactionInformation from './Transaction/TransactionInformations';
import TransactionStatusUpdates from './Transaction/TransactionStatusUpdates';
import ClientScreening from '../../Common/ClientScreening';
import { SCREENED_CLIENT_STATUS_CONFIRMED } from '../../../constants';
import TransactionScoring from './Transaction/TransactionScoring';
import ScoringRulesTables from './Transaction/ScoringRulesTable';

import './Transaction.scss';

const Transaction = ({ match }) => {
    const [isLoading, setLoading] = useState(false);
    const [isTransactionAuditOpen, setTransactionAuditOpen] = useState(false);
    const [transactionData, setTransactionData] = useState({
        id: null,
        sender_user_id: null,
        receiver_user_id: null,
        number: '',
        sender_name: '',
        sender_account: '',
        receiver_name: '',
        receiver_account: '',
        amount: '',
        fee_amount: '',
        details: '',
        direction: '',
        type: '',
        created_at: null,
        status: '',
        provider_key: '',
        refund_transaction_id: null,
    });
    const [screenedClients, setScreenedClients] = useState([]);
    const [transactionScoring, setTransactionScoring] = useState(null);

    const api = useMemo(() => createApiClient(), []);

    const {
        params: { id },
    } = useMemo(() => match, [match]);

    const fetchScoringData = useCallback(
        async (id) => {
            setLoading(true);

            try {
                const { data } = await api.get(`/admin-transaction-scoring/transactions/${id}/scoring`);

                setTransactionScoring(data);
            } catch (error) {
                toast.error(`Something went wrong: ${error.data.message}`);
            } finally {
                setLoading(false);
            }
        },
        [api],
    );

    const fetchScreenedClients = useCallback(
        async (transactionData) => {
            setLoading(true);

            try {
                const { data } = await api.get(`/admin-transaction-client-screening/transactions/${transactionData.id}/client-screenings`);

                const clientsConfirmed =
                    data.length > 0
                    && data.findIndex((item) => item.status !== SCREENED_CLIENT_STATUS_CONFIRMED) === -1;

                if (clientsConfirmed) {
                    await fetchScoringData(transactionData.id);
                }

                setScreenedClients(data);
            } catch (error) {
                toast.error(`Something went wrong: ${error.data.message}`);
            } finally {
                setLoading(false);
            }
        },
        [api, fetchScoringData],
    );

    const fetchTransaction = useCallback(async () => {
        setLoading(true);

        try {
            const { data } = await api.get(`/admin-transaction/transactions/${id}`);

            await fetchScreenedClients(data);

            setTransactionData(data);
        } catch (error) {
            toast.error(`Something went wrong: ${error.data.message}`);
        } finally {
            setLoading(false);
        }
    }, [api, fetchScreenedClients, id]);

    const toggleTransactionAudit = () => setTransactionAuditOpen((prevTransactionAuditOpen) => !prevTransactionAuditOpen);

    const updateTransactionState = (transactionState) => {
        setTransactionData((prevTransactionData) => ({ ...prevTransactionData, ...transactionState }));
    };

    const updateScreenedClients = useCallback((data) => () => fetchScreenedClients(data), [fetchScreenedClients]);

    const setLoadingParent = (value) => setLoading(value);

    const mappedScoringRules = useCallback(() =>
        transactionScoring !== null &&
        transactionScoring.rule_groups !== null &&
        transactionScoring.rule_groups.reduce((accumulator, current) => {
            const scoringRule = {
                rule: current.desc,
                isTriggered:
                    transactionScoring.triggered_rules !== null &&
                    transactionScoring.triggered_rules.includes(current.id),
            };

            return [...accumulator, scoringRule];
        }, []),
    [transactionScoring]);

    useEffect(() => {
        fetchTransaction();
    }, [fetchTransaction]);

    return (
        <SiteWrapper title='Transaction'>
            <Grid.Row>
                <Grid.Col md='3'>
                    <TransactionInformation transactionData={transactionData} isLoading={isLoading} fetchTransaction={fetchTransaction} />
                </Grid.Col>

                <Grid.Col md='9'>
                    <Header.H3>Status updates</Header.H3>
                    <Card>
                        <Card.Header>
                            <Card.Title>{transactionData.number}</Card.Title>
                        </Card.Header>
                        <Card.Body>
                            <Grid.Row>
                                <Grid.Col md='4'>
                                    <TransactionStatusUpdates
                                        updateTransactionState={updateTransactionState}
                                        transactionData={transactionData}
                                        transactionScoring={transactionScoring}
                                        isLoading={isLoading}
                                        setLoadingParent={setLoadingParent}
                                    />
                                </Grid.Col>

                                <Grid.Col>
                                    {transactionData.id !== null && (
                                        <TransactionDocuments
                                            isModal={false}
                                            transactionId={transactionData.id}
                                            title='Supporting documentation'
                                            transactionStatus={transactionData.status}
                                        />
                                    )}
                                </Grid.Col>
                            </Grid.Row>
                        </Card.Body>
                    </Card>

                    {transactionScoring !== null && (
                        <Grid.Row>
                            <Grid.Col lg={4} md={3} sm={4} xs={6}>
                                <TransactionScoring data={transactionScoring} isLoading={isLoading} />
                            </Grid.Col>

                            {mappedScoringRules().length > 0 && (
                                <Grid.Col>
                                    <ScoringRulesTables
                                        data={mappedScoringRules().sort(
                                            (elementOne, elementTwo) => elementTwo.isTriggered - elementOne.isTriggered,
                                        )}
                                        isLoading={isLoading}
                                    />
                                </Grid.Col>
                            )}
                        </Grid.Row>
                    )}

                    {screenedClients.length > 0 && (
                        <ClientScreening
                            updateScreenedClients={updateScreenedClients(transactionData)}
                            screenedClients={screenedClients}
                            screeningType='transaction'
                        />
                    )}

                    <Card>
                        <Card.Header>
                            <Card.Header className='filterHeader transactionLog'>
                                <div onClick={toggleTransactionAudit}>
                                    <span>Transaction logs</span>
                                    <i className={`fe fe-chevrons-${isTransactionAuditOpen ? 'up' : 'down'}`} />
                                </div>
                            </Card.Header>
                        </Card.Header>
                        {isTransactionAuditOpen && (
                            <TransactionAudit transactionId={transactionData.id} />
                        )}
                    </Card>
                </Grid.Col>
            </Grid.Row>
        </SiteWrapper>
    );
};

export default Transaction;
