import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect, Provider } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import ReactModal from 'react-modal';
import { redirectIfAuthenticated } from '../hoc/authentication';
import { bindActionCreators } from 'redux';
import { toast } from 'react-toastify';
import AuthenticationPage from './AuthenticationPage';
import { fetchCurrentAdminInformation } from '../actions/user/admin-information';
import { routes } from '../services/routes';
import { resolvePermissions } from '../services/permissions';

import '../styles.global.scss';
import 'react-toastify/dist/ReactToastify.css';

const AuthAwareAuthenticationPage = redirectIfAuthenticated(AuthenticationPage);

ReactModal.setAppElement('#app');
toast.configure();

class App extends Component {
    componentDidMount () {
        this.props.fetchCurrentAdminInformation();
    }

    render () {
        const { store, history, adminInformation } = this.props;

        const availableRoutes = resolvePermissions(routes, adminInformation.permissions);

        return (
            <Provider store={store}>
                <ConnectedRouter history={history}>
                    <Switch>
                        <Route exact path='/'>
                            <Redirect to='/authentication' />
                        </Route>
                        <Route path='/authentication' exact component={AuthAwareAuthenticationPage} />

                        {availableRoutes.map((route, idx) => (
                            <Route key={idx} path={route.path} exact={route.exact} component={route.component} />
                        ))}
                    </Switch>
                </ConnectedRouter>
            </Provider>
        );
    }
}

App.propTypes = {
    store: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    adminInformation: PropTypes.object.isRequired,
    fetchCurrentAdminInformation: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            fetchCurrentAdminInformation,
        },
        dispatch,
    );

const mapStateToProps = (state) => ({
    adminInformation: state.admin.information,
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
