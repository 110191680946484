import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import { Button, Icon } from 'tabler-react';
import CurrencySelectField from '../../Common/FilterFormFields/CurrencySelectField';
import SelectField from '../../Common/FilterFormFields/SelectField';
import { createApiClient as api } from '../../../services/api-client';
import ApiError from '../../Common/ApiError';
import { toast } from 'react-toastify';

const CreateWalletModal = ({ visible, onCloseModal, onWalletCreate, selectedUserId }) => {
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [formInputs, setFormInputs] = useState({
        provider: 'centrolink',
        currency: 'eur',
    });
    const [providerOptions, setProviderOptions] = useState([]);

    useEffect(() => {
        (async () => {
            try {
                const { data } = await api().get('/admin-wallet/providers');
                const formattedOptions = data.reduce((accumulator, current) => {
                    if (!current.supports_wallet_creation) {
                        return accumulator;
                    }

                    const provider = { title: current.name, value: current.id };

                    return [...accumulator, provider];
                }, []);

                setProviderOptions(formattedOptions);
            } catch (error) {
                setError(error.data);
            }
        })();
    }, []);

    const resetFormInputs = () => {
        const inputKeys = Object.keys(formInputs);

        const newFormValues = inputKeys.map((key) => ({ [key]: '' }));
        setFormInputs(newFormValues);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        setLoading(true);

        try {
            await api().post('/admin-wallet/wallets', {
                user_id: selectedUserId,
                type: formInputs.provider,
                currency: formInputs.currency,
            });
            toast.success('Wallet created successfully');

            onWalletCreate();
            resetFormInputs();
            onCloseModal();
        } catch (error) {
            setError(error.data);
        } finally {
            setLoading(false);
        }
    };

    const handleClose = () => {
        resetFormInputs();
        onCloseModal();
    };

    const onInputChange = (event) => {
        const { target } = event;

        setFormInputs((prev) => ({
            ...prev,
            [target.name]: target.value,
        }));
    };

    return (
        <Modal isOpen={visible} className='react-modal'>
            <Icon name='x-circle' className='w-4 react-modal__closeButton' onClick={onCloseModal} />
            <div className='react-modal__content'>
                <form onSubmit={handleSubmit}>
                    <ApiError error={error} />

                    <SelectField
                        title='Provider'
                        name='provider'
                        value={formInputs.provider}
                        handleChange={onInputChange}
                        options={providerOptions}
                    />

                    <CurrencySelectField value={formInputs.currency} onChange={onInputChange} />

                    <div>
                        <Button onClick={handleClose} color='secondary' disabled={loading} loading={loading}>
                            Cancel
                        </Button>

                        <Button type='submit' color='primary' onClick={handleSubmit} disabled={loading} loading={loading}>
                            Create wallet
                        </Button>
                    </div>
                </form>
            </div>
        </Modal>
    );
};

CreateWalletModal.propTypes = {
    visible: PropTypes.bool.isRequired,
    onCloseModal: PropTypes.func.isRequired,
    onWalletCreate: PropTypes.func.isRequired,
    selectedUserId: PropTypes.number.isRequired,
};

export default CreateWalletModal;
