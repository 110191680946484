import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ModalSkeleton from '../../../../Common/ModalSkeleton';
import { Button } from 'tabler-react';
import { createApiClient } from '../../../../../services/api-client';
import { toast } from 'react-toastify';
import '../PermissionGroups.scss';
import InputTextField from '../../../../Common/FilterFormFields/InputTextField';

class CreateGroupModal extends Component {
    constructor (props) {
        super(props);

        this.state = {
            name: '',
            isModalOpen: false,
            error: null,
        };

        this.api = createApiClient();

        this.referenceModalSkeleton = React.createRef();

        this.saveRole = this.saveRole.bind(this);
        this.onInputChange = this.onInputChange.bind(this);
    }

    initialBody () {
        return (
            <Button pill icon='plus' color='primary'>
                Create new
            </Button>
        );
    }

    content () {
        const { name } = this.state;

        return (
            <>
                <InputTextField name='name' title='Role name:' value={name} handleChange={this.onInputChange} />

                <Button color='primary' onClick={this.saveRole} className='submit-role-name-button'>
                    Create new role
                </Button>
            </>
        );
    }

    saveRole () {
        const { name } = this.state;

        this.api
            .post('admin-permission/permission-groups', {
                name,
                permissions: [],
            })
            .then(() => {
                this.referenceModalSkeleton.current.closeModal();

                this.setState({
                    error: null,
                    name: '',
                });

                toast.success(`Role ${name} has been created successfully`);

                const { successCallback } = this.props;

                successCallback();
            })
            .catch((data) => {
                this.setState({
                    error: data.data,
                });
            });
    }

    onInputChange (event) {
        const { target } = event;

        this.setState({
            [target.name]: target.value,
        });
    }

    render () {
        const { error } = this.state;

        return <ModalSkeleton ref={this.referenceModalSkeleton} content={this.content()} error={error} initialBody={this.initialBody()} />;
    }
}

CreateGroupModal.propTypes = {
    successCallback: PropTypes.func.isRequired,
};

export default CreateGroupModal;
