import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TableBlock from '../../../../Common/TableBlock';
import FilterForm from '../../../../Common/FilterForm';
import moment from 'moment';
import isUndefined from 'lodash/isUndefined';
import { FILTER_MASKS } from '../../../../../constants';
import { Grid, Header } from 'tabler-react';
import { createApiClient as api } from '../../../../../services/api-client';
import { getLabelByCountryCode } from '../../../../../selectors/location/countries';
import { fetchCountries } from '../../../../../actions/location/countries';
import { getLabelForAmountRange, getLabelFromDirectionAndType } from '../../../../../selectors/get-label';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { formatDateAndTime } from '../../../../../helpers';

class Report extends Component {
    constructor (props) {
        super(props);

        this.uri = `/admin-reporting/users/${props.match.params.id}/report`;

        this.state = {
            date_from: moment()
                .startOf('month')
                .format('YYYY-MM-DD'),
            date_to: moment()
                .endOf('month')
                .format('YYYY-MM-DD'),
            isLoading: true,
            error: null,
            blocks: [
                {
                    key: 'turnovers',
                    columns: [
                        {
                            Header: 'Incoming/Outgoing turnover (EUR):',
                            accessor: 'direction',
                            headerClassName: 'text-left',
                            width: 550,
                            className: 'capitalize-first-letter',
                            sortable: false,
                        },
                        {
                            Header: 'Total',
                            accessor: 'amount',
                            headerClassName: 'text-left',
                            sortable: false,
                        },
                    ],
                    items: [],
                },
                {
                    key: 'totals_by_direction_and_type',
                    columns: [
                        {
                            Header: 'Totals',
                            accessor: 'direction_and_type_label',
                            headerClassName: 'text-left',
                            width: 550,
                            className: 'capitalize-first-letter',
                            sortable: false,
                        },
                        {
                            Header: 'Total',
                            accessor: 'count',
                            headerClassName: 'text-left',
                            sortable: false,
                        },
                    ],
                    items: [],
                },
                {
                    key: 'total_number_outgoing_external',
                    columns: [
                        {
                            Header: 'Total number of outgoing external transactions by amount (EUR)',
                            accessor: 'amount_range_label',
                            headerClassName: 'text-left',
                            width: 550,
                            sortable: false,
                        },
                        {
                            Header: 'Total',
                            accessor: 'count',
                            headerClassName: 'text-left',
                            sortable: false,
                        },
                    ],
                    items: [],
                },
                {
                    key: 'total_number_incoming_external',
                    columns: [
                        {
                            Header: 'Total number of incoming external transactions by amount (EUR)',
                            accessor: 'amount_range_label',
                            headerClassName: 'text-left',
                            width: 550,
                            sortable: false,
                        },
                        {
                            Header: 'Total',
                            accessor: 'count',
                            headerClassName: 'text-left',
                            sortable: false,
                        },
                    ],
                    items: [],
                },
                {
                    key: 'total_number_outgoing_internal',
                    columns: [
                        {
                            Header: 'Total number of outgoing internal transactions by amount (EUR)',
                            accessor: 'amount_range_label',
                            headerClassName: 'text-left',
                            width: 550,
                            sortable: false,
                        },
                        {
                            Header: 'Total',
                            accessor: 'count',
                            headerClassName: 'text-left',
                            sortable: false,
                        },
                    ],
                    items: [],
                },
                {
                    key: 'total_number_incoming_internal',
                    columns: [
                        {
                            Header: 'Total number of incoming internal transactions by amount (EUR)',
                            accessor: 'amount_range_label',
                            headerClassName: 'text-left',
                            width: 550,
                            sortable: false,
                        },
                        {
                            Header: 'Total',
                            accessor: 'count',
                            headerClassName: 'text-left',
                            sortable: false,
                        },
                    ],
                    items: [],
                },
                {
                    key: 'most_common_recipients',
                    columns: [
                        {
                            Header: 'The most common recipients',
                            accessor: 'receiver_name',
                            headerClassName: 'text-left',
                            sortable: false,
                        },
                        {
                            Header: 'Account number',
                            accessor: 'receiver_iban',
                            headerClassName: 'text-left',
                            sortable: false,
                        },
                        {
                            Header: 'Number of transactions',
                            accessor: 'count',
                            headerClassName: 'text-left',
                            sortable: false,
                        },
                        {
                            Header: 'Total amount (EUR)',
                            accessor: 'amount',
                            headerClassName: 'text-left',
                            sortable: false,
                        },
                    ],
                    items: [],
                    alert: (() => {
                        const date = moment()
                            .tz('UTC')
                            .startOf('day')
                            .hour(0)
                            .minute(5)
                            .unix();

                        return `The most common recipients report is re-calculated every night. 
                            At the moment contains data only before ${formatDateAndTime(date)}`;
                    })(),
                },
                {
                    key: 'external_outgoing_by_country',
                    columns: [
                        {
                            Header: 'Total number of outgoing external transactions by country',
                            accessor: 'country_label',
                            headerClassName: 'text-left',
                            width: 550,
                            sortable: false,
                        },
                        {
                            Header: 'Total',
                            accessor: 'count',
                            headerClassName: 'text-left',
                            sortable: false,
                        },
                    ],
                    items: [],
                },
            ],
        };

        this.filterFields = [
            {
                title: 'From',
                name: 'date_from',
                type: 'text',
                placeholder: FILTER_MASKS.date.placeholder,
                mask: FILTER_MASKS.date.mask,
                value: this.state.date_from,
            },
            {
                title: 'To',
                name: 'date_to',
                type: 'text',
                placeholder: FILTER_MASKS.date.placeholder,
                mask: FILTER_MASKS.date.mask,
                value: this.state.date_to,
            },
        ];

        this.setDates = this.setDates.bind(this);
        this.fetchReports = this.fetchReports.bind(this);
    }

    async componentDidMount () {
        await this.props.fetchCountries();

        this.fetchReports({
            date_from: this.state.date_from,
            date_to: this.state.date_to,
        });
    }

    setDates (dates) {
        this.setState({
            dates,
        });
    }

    async fetchReports (params) {
        this.setState({
            isLoading: true,
        });

        await api()
            .get(this.uri, {
                params: {
                    date_from: params.date_from,
                    date_to: params.date_to,
                },
            })
            .then((response) => {
                this.formatItems(response);

                this.setState({
                    isLoading: false,
                    error: null,
                });
            })
            .catch((data) => {
                this.setState({
                    error: data.data,
                    isLoading: false,
                });
            });
    }

    formatItems (res) {
        const items = [];

        for (const [key, value] of Object.entries(res.data)) {
            items[key] = value.map((item) => {
                const amountRangeLabel = getLabelForAmountRange(item.amount_from, item.amount_to);

                if (amountRangeLabel) {
                    item.amount_range_label = amountRangeLabel;
                }

                const directionAndTypeLabel = getLabelFromDirectionAndType(item.type, item.direction);

                if (directionAndTypeLabel) {
                    item.direction_and_type_label = directionAndTypeLabel;
                }

                if (item.country_code) {
                    item.country_label = getLabelByCountryCode(this.props.countries, item.country_code);
                }

                return item;
            });
        }

        const blocks = this.state.blocks.map((block) => {
            block.items = items[block.key];

            return block;
        });

        this.setState({
            blocks,
        });
    }

    renderBlocks () {
        const { isLoading } = this.state;

        return this.state.blocks.map((block, key) => {
            const props = {
                isLoading,
                items: block.items,
                columns: block.columns,
            };

            if (!isUndefined(block.alert)) {
                props.alert = block.alert;
            }

            return <TableBlock key={key} {...props}></TableBlock>;
        });
    }

    render () {
        return (
            <>
                <Header.H3>Report</Header.H3>

                <Grid.Row>
                    <Grid.Col>
                        <FilterForm
                            fields={this.filterFields}
                            submitCallback={this.fetchReports}
                            setDates={this.setDates}
                            dates={this.state.dates}
                            error={this.state.error}
                            show={true}
                        />
                    </Grid.Col>
                </Grid.Row>

                <Grid.Row>
                    <Grid.Col>{this.renderBlocks()}</Grid.Col>
                </Grid.Row>
            </>
        );
    }
}

Report.propTypes = {
    fetchCountries: PropTypes.func.isRequired,
    countries: PropTypes.array.isRequired,
    match: PropTypes.object,
};

const mapStateToProps = (state) => ({
    countries: state.location.countries,
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            fetchCountries,
        },
        dispatch,
    );

export default connect(mapStateToProps, mapDispatchToProps)(Report);
