import React, { Component } from 'react';
import './Progress.scss';
import PropTypes from 'prop-types';

class Progress extends Component {
    render () {
        const { progress } = this.props;

        return (
            <div className='progressBar'>
                <div className='progress' style={{ width: `${progress} %` }} />
            </div>
        );
    }
}

Progress.propTypes = {
    progress: PropTypes.number.isRequired,
};

export default Progress;
