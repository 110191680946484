import { createAction } from 'redux-actions';
import { getAccessToken } from '../../services/authentication-storage';
import { userAuthenticated } from './authenticate-user';

export const FETCH_CURRENT_ADMIN_INFORMATION_REQUEST = 'user/FETCH_CURRENT_ADMIN_INFORMATION_REQUEST';
export const FETCH_CURRENT_ADMIN_INFORMATION_SUCCESS = 'user/FETCH_CURRENT_ADMIN_INFORMATION_SUCCESS';
export const FETCH_CURRENT_ADMIN_INFORMATION_FAILURE = 'user/FETCH_CURRENT_ADMIN_INFORMATION_FAILURE';

const fetchCurrentAdminInformationRequest = createAction(FETCH_CURRENT_ADMIN_INFORMATION_REQUEST);
const fetchCurrentAdminInformationSuccess = createAction(FETCH_CURRENT_ADMIN_INFORMATION_SUCCESS);
const fetchCurrentAdminInformationFailure = createAction(FETCH_CURRENT_ADMIN_INFORMATION_FAILURE);

export const fetchCurrentAdminInformation = () => async (dispatch, getState, { api }) => {
    const accessToken = getAccessToken();

    if (!accessToken) {
        return;
    }

    try {
        dispatch(fetchCurrentAdminInformationRequest());

        const { data } = await api.get('/admin-user/admins/current/details');

        await dispatch(fetchCurrentAdminInformationSuccess(data));

        dispatch(userAuthenticated());
    } catch (response) {
        dispatch(fetchCurrentAdminInformationFailure());
    }
};
