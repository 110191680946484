import React from 'react';
import { capitalize, formatDateAndTime, titleCase, uppercase } from '../../helpers';
import { Icon } from 'tabler-react';
import ReactTooltip from 'react-tooltip';

export const auditColumns = [
    {
        Header: 'Date/time',
        accessor: 'audited_at',
        Cell: (row) => formatDateAndTime(row.value),
        width: 150,
    },
    {
        Header: 'Type',
        accessor: 'type',
        sortable: false,
        Cell: (row) => capitalize(row.value),
        width: 150,
    },
    {
        Header: 'Activity',
        accessor: 'activity',
        sortable: false,
    },
    {
        Header: 'IP',
        accessor: 'ip',
        sortable: false,
        width: 120,
    },
    {
        Header: 'Browser',
        accessor: 'browser',
        sortable: false,
        width: 150,
    },
    {
        Header: 'OS',
        accessor: 'os',
        sortable: false,
        width: 150,
    },
    {
        Header: 'Admin name',
        accessor: 'admin_name',
        sortable: false,
        width: 150,
        Cell: (row) => (row.value !== null ? titleCase(row.value) : '-'),
    },
    {
        Header: 'Country',
        accessor: 'country',
        sortable: false,
        Cell: (row) => (
            <div
                data-tip={`${row.value ? uppercase(row.original.country) : 'N / A'} <br /> 
                        ${row.original.ip} <br /> 
                        ${row.original.browser} <br /> 
                        ${row.original.os}`}
            >
                {row.value ? <Icon prefix='flag' name={row.value} /> : 'N / A'}
                <ReactTooltip className='react-tooltip' multiline={true}/>
            </div>
        ),
        width: 150,
    },
];
