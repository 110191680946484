import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import SiteWrapper from '../../../SiteWrapper';
import { Button, Card, Dimmer, Grid } from 'tabler-react';
import { createApiClient as api } from '../../../../services/api-client';
import PropTypes from 'prop-types';
import ApiError from '../../../Common/ApiError';
import { navigate } from '../../../../actions/navigate';
import { toast } from 'react-toastify';
import TransactionFees from './FormFields/TransactionFees';
import isString from 'lodash/isString';
import MonthlyFee from './FormFields/MonthlyFee';
import {
    EXTERNAL_TRANSFER,
    INTERNAL_TRANSFER,
    OUTGOING_TRANSACTION,
    INCOMING_TRANSACTION,
    CURRENCY_BUY, REFUND, CHARGEBACK, DEPOSIT,
} from '../../../../constants';
import { isLoading } from '../../../../selectors/isLoading';
import toNumber from 'lodash/toNumber';
import { fetchTransactionFeeConfigurations } from '../../../../actions/fee/transactions';
import InputTextField from '../../../Common/FilterFormFields/InputTextField';

class PricingGroupCreate extends Component {
    constructor (props) {
        super(props);

        this.state = {
            isLoading: false,
            error: null,
            pricingGroup: {
                name: '',
                monthly: [],
                external_transfer: [],
                outgoing_internal_transfer: [],
                incoming_internal_transfer: [],
                refund: [],
                chargeback: [],
                deposit: [],
            },
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount () {
        this.props.fetchTransactionFeeConfigurations();
    }

    handleSubmit (event) {
        event.preventDefault();

        const { pricingGroup } = this.state;

        this.setState({
            isLoading: true,
            error: null,
        });

        api()
            .post('admin-fee/pricing-groups', {
                name: pricingGroup.name,
            })
            .then((data) => {
                this.assignFees(data.data.id).then(() => {
                    toast.success('Created successful');
                    this.props.navigate('/fee/pricing-groups');
                });
            })
            .catch((data) => {
                this.setState({
                    error: data.data,
                    isLoading: false,
                });
            });
    }

    async assignFees (pricingId) {
        const { pricingGroup } = this.state;
        const transactions = pricingGroup.external_transfer
            .concat(pricingGroup.incoming_internal_transfer)
            .concat(pricingGroup.outgoing_internal_transfer)
            .concat(pricingGroup.refund)
            .concat(pricingGroup.chargeback)
            .concat(pricingGroup.deposit)
        ;

        if (pricingGroup.monthly.length > 0) {
            await this.asyncForEach(pricingGroup.monthly, async (monthlyFee) => {
                await this.assignFee(pricingId, monthlyFee, 'monthly');
            });
        }

        if (transactions.length > 0) {
            await this.asyncForEach(transactions, async (transaction) => {
                await this.assignFee(pricingId, transaction, 'transaction');
            });
        }
    }

    async assignFee (pricing_group_id, relation_id, type) {
        await api()
            .post('admin-fee/pricing-items', {
                pricing_group_id,
                relation_id: toNumber(relation_id),
                type,
            })
            .catch((data) => {
                toast.error(data.data.message);
            });
    }

    async asyncForEach (array, callback) {
        for (let index = 0; index < array.length; index += 1) {
            await callback(array[index], index, array);
        }
    }

    handleChange (event) {
        const { target } = event;

        this.setState((prevState) => ({
            pricingGroup: {
                ...prevState.pricingGroup,
                [target.name]: target.value.length > 0 || !isString(target.value) ? target.value : '',
            },
        }));
    }

    isLoading () {
        return this.state.isLoading || this.props.isFetchingMonthlyFeeConfigurations || this.props.isFetchingTransactionsFeeConfigurations;
    }

    render () {
        const { pricingGroup, error } = this.state;
        const { navigate, transactionsFeeConfigurations } = this.props;

        return (
            <SiteWrapper title='Create pricing group'>
                <Grid.Row>
                    <Grid.Col md={4} sm={6} xs={12}>
                        <Card>
                            <form onSubmit={this.handleSubmit}>
                                <Dimmer active={this.isLoading()} loader>
                                    <Card.Body>
                                        <InputTextField
                                            name='name'
                                            title='Group name'
                                            value={pricingGroup.name}
                                            handleChange={this.handleChange}
                                        />

                                        <MonthlyFee value={pricingGroup.monthly} onChange={this.handleChange} />

                                        <TransactionFees
                                            value={pricingGroup.external_transfer}
                                            onChange={this.handleChange}
                                            isMulti={true}
                                            transactionTypes={[EXTERNAL_TRANSFER, CURRENCY_BUY]}
                                            transactionsFeeConfigurations={transactionsFeeConfigurations}
                                            feeTransactionType={EXTERNAL_TRANSFER}
                                        />

                                        <TransactionFees
                                            value={pricingGroup.outgoing_internal_transfer}
                                            onChange={this.handleChange}
                                            isMulti={true}
                                            transactionTypes={[INTERNAL_TRANSFER]}
                                            transactionDirection={OUTGOING_TRANSACTION}
                                            transactionsFeeConfigurations={transactionsFeeConfigurations}
                                            feeTransactionType={INTERNAL_TRANSFER}
                                        />

                                        <TransactionFees
                                            value={pricingGroup.incoming_internal_transfer}
                                            onChange={this.handleChange}
                                            isMulti={true}
                                            transactionTypes={[INTERNAL_TRANSFER]}
                                            transactionDirection={INCOMING_TRANSACTION}
                                            transactionsFeeConfigurations={transactionsFeeConfigurations}
                                            feeTransactionType={INTERNAL_TRANSFER}
                                        />

                                        <TransactionFees
                                            value={pricingGroup.refund}
                                            onChange={this.handleChange}
                                            isMulti={true}
                                            transactionTypes={[REFUND]}
                                            transactionsFeeConfigurations={transactionsFeeConfigurations}
                                            feeTransactionType={REFUND}
                                        />

                                        <TransactionFees
                                            value={pricingGroup.chargeback}
                                            onChange={this.handleChange}
                                            isMulti={true}
                                            transactionTypes={[CHARGEBACK]}
                                            transactionsFeeConfigurations={transactionsFeeConfigurations}
                                            feeTransactionType={CHARGEBACK}
                                        />

                                        <TransactionFees
                                            value={pricingGroup.deposit}
                                            onChange={this.handleChange}
                                            isMulti={true}
                                            transactionTypes={[DEPOSIT]}
                                            transactionsFeeConfigurations={transactionsFeeConfigurations}
                                            feeTransactionType={DEPOSIT}
                                        />

                                        <ApiError error={error} />
                                    </Card.Body>
                                    <Card.Footer>
                                        <div className='d-flex'>
                                            <Button type='button' link onClick={() => navigate('/fee/pricing-groups')}>
                                                Cancel
                                            </Button>

                                            <Button type='submit' color='primary' className='ml-auto' onClick={this.handleSubmit}>
                                                Submit
                                            </Button>
                                        </div>
                                    </Card.Footer>
                                </Dimmer>
                            </form>
                        </Card>
                    </Grid.Col>
                </Grid.Row>
            </SiteWrapper>
        );
    }
}

PricingGroupCreate.propTypes = {
    navigate: PropTypes.func.isRequired,
    isFetchingMonthlyFeeConfigurations: PropTypes.bool.isRequired,
    isFetchingTransactionsFeeConfigurations: PropTypes.bool.isRequired,
    transactionsFeeConfigurations: PropTypes.array.isRequired,
    fetchTransactionFeeConfigurations: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    transactionsFeeConfigurations: state.fee.transactions,
    isFetchingTransactionsFeeConfigurations: isLoading(state, 'transactionsFees'),
    isFetchingMonthlyFeeConfigurations: isLoading(state, 'monthlyFees'),
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            fetchTransactionFeeConfigurations,
            navigate,
        },
        dispatch,
    );

export default connect(mapStateToProps, mapDispatchToProps)(PricingGroupCreate);
