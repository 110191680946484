import React, { useState, useCallback, useEffect, useMemo } from 'react';
import ReactTable from 'react-table';
import { Button, Card, Grid } from 'tabler-react';
import { createApiClient as api } from '../../../../services/api-client';
import ApiError from '../../../Common/ApiError';
import CreateBankAccountModal from './CreateBankAccountModal';
import EditBankAccountModal from './EditBankAccountModal';
import SiteWrapper from '../../../SiteWrapper';

export const CentrolinkFeeCollection = () => {
    const [externalFeeAccounts, setExternalFeeAccounts] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [createBankAccountModalOpen, setCreateBankAccountModalOpen] = useState(false);
    const [editBankAccountModalOpen, setEditBankAccountModalOpen] = useState(false);
    const [selectedBankAccount, setSelectedBankAccount] = useState(null);

    const setEditBankAccountModalVisible = useCallback(
        (value, selectedValue) => () => {
            if (selectedValue) {
                setSelectedBankAccount(selectedValue);
            }

            setEditBankAccountModalOpen(value);
        },
        [],
    );

    const columns = useMemo(() => [
        {
            Header: 'Account holder\'s name',
            accessor: 'account_holder_name',
            sortable: false,
            Cell: (row) => row.value,
        },
        {
            Header: 'IBAN',
            accessor: 'iban',
            sortable: false,
            Cell: (row) => row.value,
        },
        {
            Header: 'BIC',
            accessor: 'bic',
            sortable: false,
            Cell: (row) => row.value,
        },
        {
            Header: 'Purpose',
            accessor: 'purpose',
            sortable: false,
            Cell: (row) => row.value,
        },
        {
            Header: 'Actions',
            id: 'actions',
            sortable: false,
            width: 100,
            Cell: (row) => (
                <Button.List>
                    <Button className='btn btn-sm btn-primary' onClick={setEditBankAccountModalVisible(true, row.original)}>
                        Edit
                    </Button>
                </Button.List>
            ),
        },
    ], [setEditBankAccountModalVisible]);

    const fetchExternalFeeAccounts = useCallback(async () => {
        setLoading(true);

        try {
            const { data } = await api().get('/admin-fee/external-fee-accounts');

            setExternalFeeAccounts(data);
        } catch (error) {
            setError(error.data);
        } finally {
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        fetchExternalFeeAccounts();
    }, [fetchExternalFeeAccounts]);

    const setCreateBankAccountModalVisible = useCallback((value) => () => setCreateBankAccountModalOpen(value), []);

    const renderOptions = useCallback(() => {
        if (externalFeeAccounts.length > 0) {
            return null;
        }

        return (
            <Button pill icon='plus' color='primary' onClick={setCreateBankAccountModalVisible(true)}>
                Create new
            </Button>
        );
    }, [externalFeeAccounts.length, setCreateBankAccountModalVisible]);

    return (
        <SiteWrapper
            title='Bank account for fees'
            options={renderOptions()}
            titleDescription="Company's Bank account used in order to automatically
            send funds to it at the end of each day, from predefined Fee Wallet"
            isMainWrapper={false}
        >
            <Grid.Row>
                <Grid.Col>
                    <Card>
                        <ApiError error={error} />
                        <Card.Body>
                            <ReactTable
                                manual
                                data={externalFeeAccounts}
                                loading={loading}
                                columns={columns}
                                className='-striped -highlight'
                                multiSort={false}
                                minRows={2}
                                showPagination={false}
                            />
                        </Card.Body>
                    </Card>
                </Grid.Col>
            </Grid.Row>
            <CreateBankAccountModal
                visible={createBankAccountModalOpen}
                onCloseModal={setCreateBankAccountModalVisible(false)}
                onBankAccountCreate={fetchExternalFeeAccounts}
            />
            <EditBankAccountModal
                visible={editBankAccountModalOpen}
                onCloseModal={setEditBankAccountModalVisible(false, null)}
                onBankAccountEdit={fetchExternalFeeAccounts}
                selectedValue={selectedBankAccount}
            />
        </SiteWrapper>
    );
};
