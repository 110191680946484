import React, { Component } from 'react';
import { Error400Page } from 'tabler-react';

class NotFoundPage extends Component {
    render () {
        return <Error400Page />;
    }
}

export default NotFoundPage;
