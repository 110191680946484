import React, { Component } from 'react';
import { createApiClient as api } from '../../../../../services/api-client';
import Modal from 'react-modal';
import PropTypes from 'prop-types';
import { Alert, Button, Form, Grid, Icon } from 'tabler-react';
import InputTextField from '../../../../Common/FilterFormFields/InputTextField';
import ApiError from '../../../../Common/ApiError';
import FeeWallets from '../../../Fees/TransactionFees/FormFields/FeeWallets';
import CurrencySelectField from '../../../../Common/FilterFormFields/CurrencySelectField';
import isString from 'lodash/isString';
import toNumber from 'lodash/toNumber';
import { transactionDetailsFilter } from '../../../../../services/input-filter';
import AmountField from '../../../../Common/FilterFormFields/AmountField';
import { toast } from 'react-toastify';
import { getName } from '../../../../../selectors/user/get-participants-information';
import { getWalletName } from '../../../../../selectors/wallet/get-wallet-information';

const DEFAULT_STATE = {
    isLoading: false,
    error: null,
    charge: {
        fee_wallet_id: '',
        details: '',
        amount: '',
        currency: '',
    },
};

class ChargeModal extends Component {
    constructor (props) {
        super(props);

        this.state = DEFAULT_STATE;

        this.closeModal = this.closeModal.bind(this);
        this.onInputChange = this.onInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    closeModal () {
        this.setState(DEFAULT_STATE);

        this.props.onModalClose();
    }

    onInputChange (event) {
        const { target } = event;

        this.setState((prevState) => ({
            charge: {
                ...prevState.charge,
                [target.name]: target.value.length > 0 || !isString(target.value) ? target.value : '',
            },
        }));
    }

    async handleSubmit (event) {
        event.preventDefault();

        const { charge } = this.state;
        const { wallet, user, closeChargeList } = this.props;

        this.setState({
            isLoading: true,
        });

        const data = {
            ...charge,
            sender_wallet_id: wallet.id,
            user_id: toNumber(user.id),
            fee_wallet_id: charge.fee_wallet_id !== '' ? toNumber(charge.fee_wallet_id) : '',
        };

        api()
            .post('/admin-charges/create', data)
            .then(() => {
                toast.success('Charge created successfully');

                this.closeModal();
                closeChargeList();
            })
            .catch((data) => {
                this.setState({
                    error: data.data,
                    isLoading: false,
                });
            });
    }

    render () {
        const { modalIsOpen, user, wallet } = this.props;
        const { charge, isLoading, error } = this.state;

        return (
            <Modal isOpen={modalIsOpen} className='react-modal'>
                <Icon name='x-circle' className='w-4 react-modal__closeButton' onClick={this.closeModal} />

                <div className='react-modal__content'>
                    <Alert type='warning' icon='alert-triangle'>
                        Please note, that you are trying to apply manual fee charge.
                        <br />
                        Be careful with that!
                    </Alert>
                    <form onSubmit={this.handleSubmit}>
                        <ApiError error={error} />
                        <hr />
                        <Grid.Row>
                            <Grid.Col width={4}>
                                <Form.Label>Account number:</Form.Label>
                            </Grid.Col>
                            <Grid.Col>{getWalletName(wallet)}</Grid.Col>
                        </Grid.Row>

                        <Grid.Row>
                            <Grid.Col width={4}>
                                <Form.Label>Account holder:</Form.Label>
                            </Grid.Col>
                            <Grid.Col>{getName(user)}</Grid.Col>
                        </Grid.Row>
                        <hr />

                        <Grid.Row>
                            <Grid.Col>
                                <AmountField
                                    name='amount'
                                    title='Amount:'
                                    value={charge.amount}
                                    handleChange={this.onInputChange}
                                    maxAmount={10000}
                                />
                            </Grid.Col>
                            <Grid.Col>
                                <CurrencySelectField value={charge.currency} onChange={this.onInputChange} />
                            </Grid.Col>
                        </Grid.Row>

                        <InputTextField
                            name='details'
                            title='Reason (details):'
                            value={charge.details}
                            handleChange={this.onInputChange}
                            filter={[transactionDetailsFilter]}
                        />

                        <FeeWallets name='fee_wallet_id' onChange={this.onInputChange} value={charge.fee_wallet_id} />

                        <Button block color='primary' onClick={this.handleSubmit} disabled={isLoading} loading={isLoading}>
                            Charge
                        </Button>
                    </form>
                </div>
            </Modal>
        );
    }
}

ChargeModal.propTypes = {
    modalIsOpen: PropTypes.bool.isRequired,
    onModalClose: PropTypes.func.isRequired,
    closeChargeList: PropTypes.func.isRequired,
    wallet: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
};

export default ChargeModal;
