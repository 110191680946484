import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Card } from 'tabler-react';
import isUndefined from 'lodash/isUndefined';
// import { STEP_OCCUPATION } from '../Private/fields';
import Answers from './Answers';

class Groups extends Component {
    renderGroup (groupKey) {
        const { answers, previousAnswers, fields, groups } = this.props;

        // if (isUndefined(answers[groupKey]) && groupKey === STEP_OCCUPATION) {
        //     return null;
        // }

        return (
            <Card key={groupKey} title={groups[groupKey]} isCollapsible>
                <Card.Body>
                    {!isUndefined(answers[groupKey]) ? <Answers
                        fields={fields[groupKey]}
                        answers={answers[groupKey]}
                        previousAnswers={!isUndefined(previousAnswers[groupKey]) ? previousAnswers[groupKey] : {}}
                    /> : 'No answers provided' }
                </Card.Body>
            </Card>
        );
    }

    render () {
        const { groups } = this.props;

        return Object.keys(groups).map((groupKey) => this.renderGroup(groupKey));
    }
}

Groups.propTypes = {
    fields: PropTypes.object.isRequired,
    groups: PropTypes.object.isRequired,
    answers: PropTypes.object.isRequired,
    previousAnswers: PropTypes.object.isRequired,
};

export default Groups;
