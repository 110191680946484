import React, { Component } from 'react';
import PropTypes from 'prop-types';
import isArray from 'lodash/isArray';
import { Alert, List, Text } from 'tabler-react';

class ApiError extends Component {
    renderAdditionalError () {
        const { error } = this.props;

        if (isArray(error.additional_data) === false) {
            return false;
        }

        return (
            <List>
                {error.additional_data.map((additionalError) => (
                    <List.Item key={`${additionalError.field}_${additionalError.code}`}>
                        <Text.Small>{additionalError.message}</Text.Small>
                    </List.Item>
                ))}
            </List>
        );
    }

    render () {
        const { error, className } = this.props;

        if (!error) {
            return null;
        }

        return (
            <Alert type='danger' icon='alert-triangle' className={className}>
                <strong>{error.message}</strong>
                {this.renderAdditionalError()}
            </Alert>
        );
    }
}

ApiError.propTypes = {
    error: PropTypes.object,
    className: PropTypes.string,
};

export default ApiError;
