import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import SiteWrapper from '../../../SiteWrapper';
import { Button, Card, Dimmer, Grid } from 'tabler-react';
import { createApiClient as api } from '../../../../services/api-client';
import PropTypes from 'prop-types';
import ApiError from '../../../Common/ApiError';
import { navigate } from '../../../../actions/navigate';
import { fetchTransactionFeeConfigurations } from '../../../../actions/fee/transactions';
import { toast } from 'react-toastify';
import TransactionFees from './FormFields/TransactionFees';
import {
    findPricingItemsByType,
    pricingItemsIds,
    filterPricingItemsByTransactionTypes,
    filterPricingItemsByTransactionTypeAndDirection,
} from './services/pricing-group';
import {
    INTERNAL_TRANSFER,
    EXTERNAL_TRANSFER,
    OUTGOING_TRANSACTION,
    INCOMING_TRANSACTION,
    CURRENCY_BUY,
    REFUND, CHARGEBACK, DEPOSIT,
} from '../../../../constants';
import isString from 'lodash/isString';
import { isLoading } from '../../../../selectors/isLoading';
import MonthlyFee from './FormFields/MonthlyFee';
import toNumber from 'lodash/toNumber';
import InputTextField from '../../../Common/FilterFormFields/InputTextField';

class PricingGroupEdit extends Component {
    constructor (props) {
        super(props);

        this.state = {
            isLoading: false,
            error: null,
            pricingGroup: {
                name: '',
                monthly: [],
                external_transfer: [],
                outgoing_internal_transfer: [],
                incoming_internal_transfer: [],
                refund: [],
                chargeback: [],
                deposit: [],
            },
            currentPricingItems: [],
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount () {
        this.fetchPricingGroup();
    }

    async fetchPricingGroup () {
        await this.props.fetchTransactionFeeConfigurations();

        const { id } = this.props.match.params;
        const { transactionsFeeConfigurations } = this.props;

        this.setState({
            isLoading: true,
            error: null,
        });

        api()
            .get(`/admin-fee/pricing-groups/${id}`)
            .then((data) => {
                const monthlyFees = pricingItemsIds(findPricingItemsByType(data.data.pricing_items, 'monthly'));
                const pricingItemIds = pricingItemsIds(findPricingItemsByType(data.data.pricing_items, 'transaction'));

                this.setState({
                    pricingGroup: {
                        name: data.data.pricing_group.name,
                        monthly: monthlyFees,
                        external_transfer: filterPricingItemsByTransactionTypes(
                            pricingItemIds,
                            transactionsFeeConfigurations,
                            [EXTERNAL_TRANSFER, CURRENCY_BUY],
                        ),
                        outgoing_internal_transfer: filterPricingItemsByTransactionTypeAndDirection(
                            pricingItemIds,
                            transactionsFeeConfigurations,
                            INTERNAL_TRANSFER,
                            OUTGOING_TRANSACTION,
                        ),
                        incoming_internal_transfer: filterPricingItemsByTransactionTypeAndDirection(
                            pricingItemIds,
                            transactionsFeeConfigurations,
                            INTERNAL_TRANSFER,
                            INCOMING_TRANSACTION,
                        ),
                        refund: filterPricingItemsByTransactionTypeAndDirection(
                            pricingItemIds,
                            transactionsFeeConfigurations,
                            REFUND,
                            OUTGOING_TRANSACTION,
                        ),
                        chargeback: filterPricingItemsByTransactionTypeAndDirection(
                            pricingItemIds,
                            transactionsFeeConfigurations,
                            CHARGEBACK,
                            OUTGOING_TRANSACTION,
                        ),
                        deposit: filterPricingItemsByTransactionTypeAndDirection(
                            pricingItemIds,
                            transactionsFeeConfigurations,
                            DEPOSIT,
                            INCOMING_TRANSACTION,
                        ),
                    },
                    currentPricingItems: data.data.pricing_items,
                    isLoading: false,
                });
            })
            .catch((data) => {
                this.setState({
                    error: data.data,
                    isLoading: false,
                });
            });
    }

    handleSubmit (event) {
        event.preventDefault();

        const { id } = this.props.match.params;
        const { pricingGroup } = this.state;

        this.setState({
            isLoading: true,
            error: null,
        });

        api()
            .put(`admin-fee/pricing-groups/${id}`, {
                name: pricingGroup.name,
            })
            .then(() => {
                this.updateFees().then(() => {
                    toast.success('Updated successful');
                    this.props.navigate('/fee/pricing-groups');
                });
            })
            .catch((data) => {
                this.setState({
                    error: data.data,
                    isLoading: false,
                });
            });
    }

    feeConfigurationExists (currentPricingItem, transactions) {
        let exists = false;

        if (Array.isArray(transactions)) {
            transactions.map((feeConfiguration) => {
                if (feeConfiguration === currentPricingItem.relation_id) {
                    exists = true;
                }

                return feeConfiguration;
            });
        }

        return exists;
    }

    isNewConfiguration (id, type) {
        const { currentPricingItems } = this.state;

        let isNew = true;

        if (currentPricingItems.length > 0) {
            currentPricingItems.map((pricingItem) => {
                if (pricingItem.type === type && pricingItem.relation_id === id) {
                    isNew = false;
                }

                return pricingItem;
            });
        }

        return isNew;
    }

    async updateFees () {
        const { currentPricingItems, pricingGroup } = this.state;
        const { id } = this.props.match.params;
        const transactions = pricingGroup.external_transfer
            .concat(pricingGroup.incoming_internal_transfer)
            .concat(pricingGroup.outgoing_internal_transfer)
            .concat(pricingGroup.refund)
            .concat(pricingGroup.chargeback)
            .concat(pricingGroup.deposit)
        ;

        const monthlyFees = Array.isArray(pricingGroup.monthly) ? pricingGroup.monthly : [pricingGroup.monthly];

        const toRemove = [];
        const toAssign = [];
        const toAssignMonthly = [];

        // Find deleted
        currentPricingItems.map((pricingItem) => {
            if (pricingItem.type === 'transaction') {
                if (!this.feeConfigurationExists(pricingItem, transactions)) {
                    toRemove.push(pricingItem.id);
                }
            }

            if (pricingItem.type === 'monthly') {
                if (!this.feeConfigurationExists(pricingItem, monthlyFees)) {
                    toRemove.push(pricingItem.id);
                }
            }

            return pricingItem;
        });

        if (toRemove.length > 0) {
            await this.asyncForEach(toRemove, async (pricingItemId) => {
                await this.removeFee(pricingItemId);
            });
        }

        // Find new
        if (Array.isArray(transactions)) {
            transactions.map((pricingItem) => {
                if (this.isNewConfiguration(pricingItem, 'transaction')) {
                    toAssign.push(pricingItem);
                }

                return pricingItem;
            });
        }

        monthlyFees.map((pricingItem) => {
            if (this.isNewConfiguration(pricingItem, 'monthly')) {
                toAssignMonthly.push(pricingItem);
            }

            return pricingItem;
        });

        if (toAssign.length > 0) {
            await this.asyncForEach(toAssign, async (transactionFeeConfigurationId) => {
                await this.assignFee(parseInt(id, 10), transactionFeeConfigurationId, 'transaction');
            });
        }

        if (toAssignMonthly.length > 0) {
            await this.asyncForEach(toAssignMonthly, async (transactionFeeConfigurationId) => {
                await this.assignFee(parseInt(id, 10), transactionFeeConfigurationId, 'monthly');
            });
        }
    }

    async assignFee (pricing_group_id, relation_id, type) {
        await api()
            .post('admin-fee/pricing-items', {
                pricing_group_id,
                relation_id: toNumber(relation_id),
                type,
            })
            .catch((data) => {
                toast.error(data.data.message);
            });
    }

    async removeFee (pricingItemId) {
        await api()
            .delete(`admin-fee/pricing-items/${pricingItemId}`)
            .catch((data) => {
                toast.error(data.data.message);
            });
    }

    async asyncForEach (array, callback) {
        for (let index = 0; index < array.length; index += 1) {
            await callback(array[index], index, array);
        }
    }

    handleChange (event) {
        const { target } = event;

        this.setState((prevState) => ({
            pricingGroup: {
                ...prevState.pricingGroup,
                [target.name]: target.value.length > 0 || !isString(target.value) ? target.value : '',
            },
        }));
    }

    isLoading () {
        return this.state.isLoading || this.props.isFetchingMonthlyFeeConfigurations || this.props.isFetchingTransactionsFeeConfigurations;
    }

    render () {
        const { pricingGroup, error } = this.state;
        const { navigate, transactionsFeeConfigurations } = this.props;

        return (
            <SiteWrapper title='Edit pricing group'>
                <Grid.Row>
                    <Grid.Col md={4} sm={6} xs={12}>
                        <Card>
                            <form onSubmit={this.handleSubmit}>
                                <Dimmer active={this.isLoading()} loader>
                                    <Card.Body>
                                        <InputTextField
                                            name='name'
                                            title='Group name'
                                            value={pricingGroup.name}
                                            handleChange={this.handleChange}
                                        />

                                        <MonthlyFee value={pricingGroup.monthly} onChange={this.handleChange} />

                                        <TransactionFees
                                            value={pricingGroup.external_transfer}
                                            onChange={this.handleChange}
                                            isMulti={true}
                                            transactionTypes={[EXTERNAL_TRANSFER, CURRENCY_BUY]}
                                            transactionsFeeConfigurations={transactionsFeeConfigurations}
                                            feeTransactionType={EXTERNAL_TRANSFER}
                                        />

                                        <TransactionFees
                                            value={pricingGroup.outgoing_internal_transfer}
                                            onChange={this.handleChange}
                                            isMulti={true}
                                            transactionTypes={[INTERNAL_TRANSFER]}
                                            transactionDirection={OUTGOING_TRANSACTION}
                                            transactionsFeeConfigurations={transactionsFeeConfigurations}
                                            feeTransactionType={INTERNAL_TRANSFER}
                                        />

                                        <TransactionFees
                                            value={pricingGroup.incoming_internal_transfer}
                                            onChange={this.handleChange}
                                            isMulti={true}
                                            transactionTypes={[INTERNAL_TRANSFER]}
                                            transactionDirection={INCOMING_TRANSACTION}
                                            transactionsFeeConfigurations={transactionsFeeConfigurations}
                                            feeTransactionType={INTERNAL_TRANSFER}
                                        />

                                        <TransactionFees
                                            value={pricingGroup.refund}
                                            onChange={this.handleChange}
                                            isMulti={true}
                                            transactionTypes={[REFUND]}
                                            transactionsFeeConfigurations={transactionsFeeConfigurations}
                                            feeTransactionType={REFUND}
                                        />

                                        <TransactionFees
                                            value={pricingGroup.chargeback}
                                            onChange={this.handleChange}
                                            isMulti={true}
                                            transactionTypes={[CHARGEBACK]}
                                            transactionsFeeConfigurations={transactionsFeeConfigurations}
                                            feeTransactionType={CHARGEBACK}
                                        />

                                        <TransactionFees
                                            value={pricingGroup.deposit}
                                            onChange={this.handleChange}
                                            isMulti={true}
                                            transactionTypes={[DEPOSIT]}
                                            transactionsFeeConfigurations={transactionsFeeConfigurations}
                                            feeTransactionType={DEPOSIT}
                                        />

                                        <ApiError error={error} />
                                    </Card.Body>
                                    <Card.Footer>
                                        <div className='d-flex'>
                                            <Button link onClick={() => navigate('/fee/pricing-groups')}>
                                                Cancel
                                            </Button>

                                            <Button type='submit' color='primary' className='ml-auto' onClick={this.handleSubmit}>
                                                Submit
                                            </Button>
                                        </div>
                                    </Card.Footer>
                                </Dimmer>
                            </form>
                        </Card>
                    </Grid.Col>
                </Grid.Row>
            </SiteWrapper>
        );
    }
}

PricingGroupEdit.propTypes = {
    match: PropTypes.object,
    navigate: PropTypes.func.isRequired,
    isFetchingMonthlyFeeConfigurations: PropTypes.bool.isRequired,
    isFetchingTransactionsFeeConfigurations: PropTypes.bool.isRequired,
    transactionsFeeConfigurations: PropTypes.array.isRequired,
    fetchTransactionFeeConfigurations: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    transactionsFeeConfigurations: state.fee.transactions,
    isFetchingTransactionsFeeConfigurations: isLoading(state, 'transactionsFees'),
    isFetchingMonthlyFeeConfigurations: isLoading(state, 'monthlyFees'),
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            fetchTransactionFeeConfigurations,
            navigate,
        },
        dispatch,
    );

export default connect(mapStateToProps, mapDispatchToProps)(PricingGroupEdit);
