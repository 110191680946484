import React, { useMemo, useState, useCallback, useRef } from 'react';
import SiteWrapper from '../SiteWrapper';
import { Button, Badge } from 'tabler-react';
import PaginatedFilteredList from '../Common/PaginatedFilteredList';
import PropTypes from 'prop-types';
import { numberFilter } from '../../services/input-filter';
import { formatDateAndTime, objectToOptions } from '../../helpers';
import CentrolinkMessageList from './CentrolinkMessageList';
import { useTabs } from '../../services/custom-hooks';
import ReactTooltip from 'react-tooltip';
import { getProprietaryCode, isProprietaryCodeSuccessful } from '../../utils/proprietary-codes';
import { CentrolinkTooltips, TRANSACTION_DIRECTIONS } from '../../constants';

const centrolinkMessagesUri = '/admin-sepa-integration/messages';

export const CENTROLINK_MESSAGE_TYPE = {
    ffcctrns: 'transactions',
    ffpsrprt: 'relations',
    ffpcrqst: 'cancellation-requests',
    prtrn: 'payment-returns',
    cnrecp: 'claims',
    reqmp: 'payment-modification-requests',
    roinvstg: 'investigations',
    dictionr: 'parts-participants',
    ffpsrprq: 'report-requests',
};

export const CENTROLINK_TAB = {
    ALL_FILES: 'All files',
    TRANSACTIONS: 'Transactions',
    CLAIM_NON_RECEIPT: 'Claims for Non Receipt',
    REQUEST_TO_MODIFY_PAYMENT: 'Request to Modify Payment',
    PAYMENT_RETURNS: 'Payment returns',
    CANCELLATION_REQUESTS: 'Cancellation requests',
    REPORT_REQUESTS: 'Requests for Status Update',
    RESOLUTION_OF_INVESTIGATION: 'Resolution of investigation',
};

const tabsOptions = [
    {
        value: centrolinkMessagesUri,
        label: CENTROLINK_TAB.ALL_FILES,
    },
    {
        value: '/admin-sepa-integration/transactions',
        label: CENTROLINK_TAB.TRANSACTIONS,
        titleDescription: CentrolinkTooltips.FFCCTRNS,
        tooltipId: 'centrolink-transactions-tooltip' },
    {
        value: '/admin-sepa-integration/claims',
        label: CENTROLINK_TAB.CLAIM_NON_RECEIPT, titleDescription: CentrolinkTooltips.CNRECP,
        tooltipId: 'claims-tooltip',
    },
    {
        value: '/admin-sepa-integration/payment-modification-requests',
        label: CENTROLINK_TAB.REQUEST_TO_MODIFY_PAYMENT,
        titleDescription: CentrolinkTooltips.REQMP,
        tooltipId: 'payment-modification-tooltip' },
    {
        value: '/admin-sepa-integration/payment-returns',
        label: CENTROLINK_TAB.PAYMENT_RETURNS, titleDescription: CentrolinkTooltips.PRTRN,
        tooltipId: 'payment-returns-tooltip',
    },
    {
        value: '/admin-sepa-integration/cancellation-requests',
        label: CENTROLINK_TAB.CANCELLATION_REQUESTS, titleDescription: CentrolinkTooltips.FFPCRQST,
        tooltipId: 'cancellation-requests-tooltip',
    },
    {
        value: '/admin-sepa-integration/report-requests',
        label: CENTROLINK_TAB.REPORT_REQUESTS,
        titleDescription: CentrolinkTooltips.FFPSRPRQ,
        tooltipId: 'report-requests-tooltip' },
    {
        value: '/admin-sepa-integration/resolution-of-investigations',
        titleDescription: CentrolinkTooltips.ROINV,
        tooltipId: 'roinv-tooltip',
        label: CENTROLINK_TAB.RESOLUTION_OF_INVESTIGATION,
    },
];

const filterFields = [
    {
        title: 'Message type',
        name: 'message_type',
        type: 'text',
    },
    {
        title: 'Direction',
        name: 'direction',
        type: 'select',
        options: objectToOptions(TRANSACTION_DIRECTIONS),
    },
    {
        title: 'Related sepa message id',
        name: 'related_sepa_message_id',
        type: 'number',
        filter: [numberFilter],
    },
];

const Centrolink = () => {
    const [items, setItems] = useState([]);
    const [columns, setColumns] = useState([]);
    const sepaFileTable = useRef(null);
    const sepaDetailsTable = useRef(null);

    const currentFetchFunction = useMemo(() => {
        if (sepaDetailsTable && sepaDetailsTable.current) {
            return sepaDetailsTable.current.fetchData();
        }

        if (sepaFileTable && sepaFileTable.current) {
            return sepaFileTable.current.fetchData();
        }

        return null;
    }, [sepaDetailsTable, sepaFileTable]);
    const [handleTabChange, fetchURI] = useTabs(centrolinkMessagesUri, tabsOptions, currentFetchFunction);

    const fileHasDetails = (file) => !!(file.message_type && file.message_id);

    const autoMapOptions = {
        sortable: false,
        custom: {
            related_sepa_message_id: {
                Header: 'Related Sepa Message Id',
                accessor: 'related_sepa_message_id',
                Cell: (row) => {
                    if (!CENTROLINK_MESSAGE_TYPE[row.original.related_sepa_message_type]) {
                        return null;
                    }

                    return (
                        <Button.List>
                            <Button className='btn-left' size='sm' link onClick={viewSepaMessageList(row.original, true)}>
                                {row.value}
                            </Button>
                        </Button.List>
                    );
                },
            },
            created_at: {
                Header: 'Created at',
                accessor: 'created_at',
                Cell: (row) => formatDateAndTime(row.value),
            },
            id: {
                Header: 'Id',
                accessor: 'id',
                width: 50,
            },
            code: {
                Header: 'Code',
                accessor: 'code',
                width: 50,
                Cell: (row) => {
                    if (!row.value) {
                        return null;
                    }

                    if (!getProprietaryCode(row.value)) {
                        return row.value;
                    }

                    const badgeColor = isProprietaryCodeSuccessful(row.value) ? 'success' : 'danger';

                    return (
                        <div data-tip={getProprietaryCode(row.value)}>
                            <Badge color={badgeColor}>{row.value}</Badge>
                            {!isProprietaryCodeSuccessful && <span className='icon-fail-reason' />}

                            <ReactTooltip className='react-tooltip' />
                        </div>
                    );
                },
            },
        },
        include: [
            {
                Header: 'Actions',
                id: 'actions',
                sortable: false,
                width: 100,
                Cell: (row) => (
                    fileHasDetails(row.original) && (
                        <Button.List>
                            <Button size='sm' color='primary' onClick={viewSepaMessageList(row.original)}>
                                View
                            </Button>
                        </Button.List>
                    )
                ),
            },
        ],
        exclude: ['related_sepa_message_type'],
    };

    const viewSepaMessageList = useCallback(
        (/* message, bySepa */) => () => {
            // if (bySepa) {
            //     const sepaMessageListType = CENTROLINK_MESSAGE_TYPE[message.related_sepa_message_type];

            //     if (!sepaMessageListType) {
            //         return;
            //     }

            //     history.push(`/centrolink/${message.related_sepa_message_id}/${sepaMessageListType}`);
            //     return;
            // }
            // const messageListType = CENTROLINK_MESSAGE_TYPE[message.message_type];

            // if (!messageListType) {
            //     return;
            // }

            // history.push(`/centrolink/${message.id}/${messageListType}`);
        },
        [/* history */],
    );

    const updateItems = useCallback((items) => {
        setItems(items);
    }, []);

    const updateColumns = useCallback((columns) => {
        setColumns(columns);
    }, []);

    const CentrolinkDetailsTable = React.forwardRef(() => (
        <CentrolinkMessageList
            fetchURI={fetchURI}
            tabsOptions={tabsOptions}
            onTabChange={handleTabChange}
        />
    ));

    if (fetchURI !== centrolinkMessagesUri) {
        return <CentrolinkDetailsTable ref={sepaDetailsTable} />;
    }

    return (
        <SiteWrapper title='Centrolink'>
            <PaginatedFilteredList
                ref={sepaFileTable}
                tabsOptions={tabsOptions}
                onTabChange={handleTabChange}
                uri={fetchURI}
                columns={columns}
                updateItems={updateItems}
                items={items}
                updateColumns={updateColumns}
                autoMapOptions={autoMapOptions}
                filterFields={filterFields}
            />
        </SiteWrapper>
    );
};

Centrolink.propTypes = {
    history: PropTypes.object.isRequired,
};

export default Centrolink;
