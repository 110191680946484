import React, { useState, useCallback, useMemo } from 'react';
import { createApiClient } from '../../../../../../../services/api-client';
import Modal from 'react-modal';
import PropTypes from 'prop-types';
import { Button, Icon } from 'tabler-react';
import InputTextField from '../../../../../../Common/FilterFormFields/InputTextField';
import { toast } from 'react-toastify';

const CompletionNoteModal = ({ onModalClose, isModalOpen, updateNapierScoreCard, userId, setNapierCompletionError }) => {
    const [completionNote, setCompletionNote] = useState('');
    const [isLoading, setLoading] = useState(false);

    const api = useMemo(() => createApiClient(), []);

    const completeNapier = useCallback(async () => {
        setLoading(true);

        try {
            const { data } = await api.put(`admin-napier-integration/users/${userId}/scorecard/complete`, {
                completion_note: completionNote,
            });

            updateNapierScoreCard(data);
            toast.success('You have successfully completed scorecard');
        } catch (error) {
            if (error.data.code === 'bad_request') {
                setNapierCompletionError('Scorecard completion failed, please try again.');

                return;
            }

            toast.error(`Failed to complete Napier: ${error.data.message}`);
        } finally {
            setLoading(false);
            setCompletionNote('');
            onModalClose();
        }
    }, [api, completionNote, onModalClose, setNapierCompletionError, updateNapierScoreCard, userId]);

    const handleCompletionNoteChange = (event) => setCompletionNote(event.target.value);

    return (
        <Modal isOpen={isModalOpen} className='react-modal'>
            <Icon name='x-circle' className='w-4 react-modal__closeButton' onClick={onModalClose} />

            <div className='react-modal__content'>
                <InputTextField
                    name='reason'
                    title='Please enter a completion note:'
                    value={completionNote}
                    handleChange={handleCompletionNoteChange}
                />
                <Button block color='success' onClick={completeNapier} disabled={!completionNote || isLoading} loading={isLoading}>
                    Complete
                </Button>
            </div>
        </Modal>
    );
};

CompletionNoteModal.propTypes = {
    isModalOpen: PropTypes.bool.isRequired,
    onModalClose: PropTypes.func.isRequired,
    updateNapierScoreCard: PropTypes.func.isRequired,
    userId: PropTypes.number.isRequired,
    setNapierCompletionError: PropTypes.func.isRequired,
};

export default CompletionNoteModal;
