import React, { useState } from 'react';
import PropTypes from 'prop-types';
import 'react-table/react-table.css';
import ReactTable from 'react-table';
import SiteWrapper from '../../SiteWrapper';
import { Button, Card, Grid } from 'tabler-react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { USER_TYPES } from '../../../constants';
import { fetchDefaultWalletLimits } from '../../../actions/limits/default-wallet';
import { isLoading } from '../../../selectors/isLoading';
import EditDefaultLimitModal from './DefaultWalletLimits/EditDefaultLimitModal';
import CreateDefaultLimitModal from './DefaultWalletLimits/CreateDefaultLimitModal';
import { useDidMount } from '../../../hooks/useDidMount';
import { createApiClient as api } from '../../../services/api-client';
import { toast } from 'react-toastify';

const DefaultWalletLimits = ({
    fetchDefaultWalletLimitsAction,
    defaultWalletLimits,
    isFetchingDefaultWalletLimits,
}) => {
    const [modalOpened, setModalOpened] = useState(false);
    const [selectedLimits, setSelectedLimits] = useState(null);
    const [isCreateModalVisible, setIsCreateModalVisible] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    useDidMount(() => {
        fetchDefaultWalletLimitsAction();
    });

    const columns = [
        {
            Header: 'Type',
            accessor: 'user_type',
            sortable: false,
            Cell: (row) => USER_TYPES[row.value] || row.value,
        },
        {
            Header: 'Currency',
            accessor: 'currency',
            sortable: false,
            Cell: (row) => row.value,
        },
        {
            Header: 'Daily',
            accessor: 'daily_limit',
            sortable: false,
            Cell: (row) => row.value,
        },
        {
            Header: 'Weekly',
            accessor: 'weekly_limit',
            sortable: false,
            Cell: (row) => row.value,
        },
        {
            Header: 'Monthly',
            accessor: 'monthly_limit',
            sortable: false,
            Cell: (row) => row.value,
        },
        {
            Header: 'Actions',
            id: 'actions',
            accessor: 'id',
            sortable: false,
            Cell: (row) => (
                <Button.List>
                    <Button
                        size='sm'
                        color='primary'
                        onClick={() => handleEditLimitClick(row.value)}
                    >
                        Edit
                    </Button>
                    <Button size='sm' color='danger' onClick={() => handleDeleteLimitClick(row.value)}>
                        Delete
                    </Button>
                </Button.List>
            ),
        },
    ];

    const toggleModal = () => {
        setModalOpened((prevState) => !prevState);
    };

    const handleEditLimitClick = (limitsId) => {
        const limitsData = defaultWalletLimits.find((item) => item.id === limitsId);

        setSelectedLimits(limitsData);
        toggleModal();
    };

    const handleDeleteLimitClick = async (limitsId) => {
        if (confirm('Are you sure?')) {
            try {
                setIsLoading(true);

                await api().delete(`/admin-wallet-limit/default-wallet-limits/${limitsId}`);

                toast.success('Default limits deleted');

                fetchDefaultWalletLimitsAction();
            } catch (error) {
                toast.error(error.data.message);
            } finally {
                setIsLoading(false);
            }
        }
    };

    const renderOptions = () => (
        <Button pill icon='plus' color='primary' onClick={() => setIsCreateModalVisible(true)}>
            Create new
        </Button>
    );

    return (
        <SiteWrapper
            title='Default wallet limits'
            options={renderOptions()}
        >
            <Grid.Row>
                <Grid.Col>
                    <Card>
                        <Card.Body>
                            <ReactTable
                                manual
                                data={defaultWalletLimits}
                                loading={isFetchingDefaultWalletLimits || isLoading}
                                columns={columns}
                                className='-striped -highlight'
                                multiSort={false}
                                minRows={2}
                                showPagination={false}
                            />
                        </Card.Body>
                    </Card>
                </Grid.Col>
            </Grid.Row>
            {
                selectedLimits !== null && (
                    <EditDefaultLimitModal
                        defaultData={selectedLimits}
                        modalIsOpen={modalOpened}
                        onModalClose={toggleModal}
                        onLimitsChange={fetchDefaultWalletLimitsAction}
                    />
                )
            }
            {
                isCreateModalVisible === true && (
                    <CreateDefaultLimitModal
                        modalIsOpen={isCreateModalVisible}
                        onModalClose={() => setIsCreateModalVisible(false)}
                        onLimitsChange={fetchDefaultWalletLimitsAction}
                    />
                )
            }
        </SiteWrapper>
    );
};

DefaultWalletLimits.propTypes = {
    fetchDefaultWalletLimitsAction: PropTypes.func.isRequired,
    defaultWalletLimits: PropTypes.array.isRequired,
    isFetchingDefaultWalletLimits: PropTypes.bool.isRequired,
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            fetchDefaultWalletLimitsAction: fetchDefaultWalletLimits,
        },
        dispatch,
    );

const mapStateToProps = (state) => ({
    defaultWalletLimits: state.limits.defaultWallet,
    isFetchingDefaultWalletLimits: isLoading(state, 'defaultWalletLimits'),
});

export default connect(mapStateToProps, mapDispatchToProps)(DefaultWalletLimits);
