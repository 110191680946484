import React, { Component } from 'react';
import SiteWrapper from '../SiteWrapper';
import Bb from './Dashboard/Bb';

class Dashboard extends Component {
    render () {
        return (
            <SiteWrapper title='Dashboard'>
                <h1 className='d-flex justify-content-center'>Welcome to the future</h1>
                <Bb />
            </SiteWrapper>
        );
    }
}

export default Dashboard;
