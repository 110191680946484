import React, { useMemo, useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'tabler-react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import SiteWrapper from '../SiteWrapper';
import { createApiClient } from '../../services/api-client';
import 'react-table/react-table.css';
import { navigate } from '../../actions/navigate';
import { toast } from 'react-toastify';
import PaginatedFilteredList from '../Common/PaginatedFilteredList';

const CompanyTypes = ({ navigate }) => {
    const [items, setItems] = useState([]);
    const [isDeleting, setIsDeleting] = useState(false);

    const uri = useMemo(() => 'admin-company-type/company-types', []);

    const api = useMemo(() => createApiClient(), []);

    const renderOptions = () => (
        <Link to='/company-types/create'>
            <Button pill icon='plus' color='primary'>
                Create new
            </Button>
        </Link>
    );

    const updateItems = (items) => setItems(items);

    const edit = useCallback((id) => () => navigate(`/company-types/edit/${id}`), [navigate]);

    const deleteCompanyType = useCallback(
        (id) => async () => {
            if (!confirm('Are you sure?')) {
                return null;
            }

            setIsDeleting(true);

            try {
                await api.delete(`/admin-company-type/company-types/${id}`);
                setItems((prevItems) => prevItems.filter((item) => item.id !== id));

                toast.success('Company type deleted successfully');
            } catch ({ data }) {
                toast.error(data.message);
            } finally {
                setIsDeleting(false);
            }
        },
        [api],
    );

    const columns = useMemo(
        () => [
            {
                Header: 'Name',
                accessor: 'name',
                sortable: true,
                Cell: (row) => row.value,
            },
            {
                Header: 'Code',
                accessor: 'code',
                sortable: true,
                Cell: (row) => row.value,
            },
            {
                Header: 'Actions',
                id: 'actions',
                width: 130,
                sortable: false,
                headerClassName: 'column-actions',
                className: 'column-actions',
                Cell: (row) => (
                    <>
                        <Button.List>
                            <Button size='sm' onClick={edit(row.original.id)} color='primary'>
                                Edit
                            </Button>
                            <Button size='sm' onClick={deleteCompanyType(row.original.id)} color='danger'>
                                Delete
                            </Button>
                        </Button.List>
                    </>
                ),
            },
        ],
        [deleteCompanyType, edit],
    );

    return (
        <SiteWrapper
            title='Company types'
            options={renderOptions()}
            titleDescription='Company types are created according to
            "NACE" (EVRK) official code list. This company types are displayed in Business questionnaire,
            in order for business to define it&apos;s activity type'
        >
            <PaginatedFilteredList
                uri={uri}
                columns={columns}
                updateItems={updateItems}
                items={items}
                sortBy='code'
                isLoading={isDeleting}
                sortByDirectionDescending={false}
            />
        </SiteWrapper>
    );
};

CompanyTypes.propTypes = {
    navigate: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            navigate,
        },
        dispatch,
    );

export default connect(null, mapDispatchToProps)(CompanyTypes);
