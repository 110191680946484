import { capitalize, uppercase } from '../../../../../helpers';

export const transactionFeeOptions = (transactionsFeeConfigurations, transactionTypes, transactionDirection) => {
    const options = [];
    let feeConfigurations = transactionsFeeConfigurations;

    if (transactionDirection) {
        feeConfigurations = transactionsFeeConfigurations.filter((fee) => fee.direction === transactionDirection);
    }

    feeConfigurations.forEach((fee) => {
        if (transactionTypes.indexOf(fee.transaction_type) !== -1) {
            options.push({
                value: fee.id,
                label: buildTransactionFeeLabel(fee),
            });
        }
    });

    return options;
};

export const selectedTransactionFees = (transactionsFeeConfigurations, selectedPricingItems) => {
    const selectedOptions = [];

    if (selectedPricingItems.length > 0) {
        selectedPricingItems.forEach((selectedPricingItemId) => {
            const transactionFee = transactionsFeeConfigurations.filter((fee) => fee.id === selectedPricingItemId);

            selectedOptions.push({
                value: transactionFee[0].id,
                label: buildTransactionFeeLabel(transactionFee[0]),
            });
        });
    }

    return selectedOptions;
};

export const buildTransactionFeeLabel = (fee) => {
    let label = `${fee.name} | `;

    if (fee.amount > 0) {
        label += `${fee.amount}`;
    } else {
        label += `${fee.percent} %`;
    }

    label += ` | ${uppercase(fee.currency)} | ${capitalize(fee.direction)}`;

    if (Array.isArray(fee.countries)) {
        label += ` | ${uppercase(fee.countries.join(', '))}`;
    }

    return label;
};
