import { createAction } from 'redux-actions';

export const FETCH_DEFAULT_WALLET_LIMITS_REQUEST = 'limits/FETCH_DEFAULT_WALLET_LIMITS_REQUEST';
export const FETCH_DEFAULT_WALLET_LIMITS_SUCCESS = 'limits/FETCH_DEFAULT_WALLET_LIMITS_SUCCESS';
export const FETCH_DEFAULT_WALLET_LIMITS_FAILURE = 'limits/FETCH_DEFAULT_WALLET_LIMITS_FAILURE';

const fetchDefaultWalletLimitsRequest = createAction(FETCH_DEFAULT_WALLET_LIMITS_REQUEST);
const fetchDefaultWalletLimitsSuccess = createAction(FETCH_DEFAULT_WALLET_LIMITS_SUCCESS);
const fetchDefaultWalletLimitsFailure = createAction(FETCH_DEFAULT_WALLET_LIMITS_FAILURE);

export const fetchDefaultWalletLimits = () => (dispatch, getState, { api }) =>
    new Promise(async (resolve) => {
        try {
            dispatch(fetchDefaultWalletLimitsRequest());

            const result = await api.get('/admin-wallet-limit/default-wallet-limits');

            dispatch(fetchDefaultWalletLimitsSuccess(result.data));
            resolve(result.data);
        } catch (response) {
            dispatch(fetchDefaultWalletLimitsFailure());
        }
    });
