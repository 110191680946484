import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'tabler-react';
import isUndefined from 'lodash/isUndefined';

class Radio extends Component {
    render () {
        const { answer, field } = this.props;

        let name = answer;

        if (!isUndefined(field.values) && !isUndefined(field.values[answer])) {
            name = field.values[answer];
        }

        return (
            <>
                <Icon prefix='fe' name='check-circle' />
                {'  '}
                {name}
            </>
        );
    }
}

Radio.propTypes = {
    answer: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    field: PropTypes.object.isRequired,
};

export default Radio;
