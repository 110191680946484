export const STEP_LEVEL1 = 'level1';
// export const STEP_LEVEL2 = 'level2';
// export const STEP_OCCUPATION = 'occupation';
// export const STEP_FUNDS_AND_ACTIVITY = 'funds_and_activity';
// export const STEP_PLANNED_MONTHLY_ACTIVITY = 'planned_monthly_activity';
// export const STEP_PARTICIPATION_IN_POLITICS = 'participation_in_politics';

export const groups = {
    [STEP_LEVEL1]: 'Level 1',
    // [STEP_LEVEL2]: 'Level 2',
    // [STEP_OCCUPATION]: 'Information on the Client\'s occupation',
    // [STEP_FUNDS_AND_ACTIVITY]: 'Information about client funds and monthly account activity',
    // [STEP_PLANNED_MONTHLY_ACTIVITY]: 'Planned client\'s monthly activity in the account',
    // [STEP_PARTICIPATION_IN_POLITICS]: 'Information about participation in politics',
};

export const fields = {
    [STEP_LEVEL1]: {
        email_address: {
            title: 'Email Address',
        },
        gender: {
            title: 'Gender',
        },
        address_country: {
            title: 'Country',
        },
        address_street_number: {
            title: 'Street and Number',
        },
        address_floor_flat: {
            title: 'Floor/Flat or similar',
            hideIfEmpty: true,
        },
        address_city: {
            title: 'City',
        },
        address_zip: {
            title: 'ZIP',
        },
        citizenship: {
            title: 'Citizenship',
            type: 'country-list',
        },
        us_citizen: {
            title: 'Are you a United Stated citizen (also applies to dual citizenship) or Green Card owner?',
        },
        us_tax_resident: {
            title: 'Are you liable to pay taxes in the United States?',
        },
        origin_of_assets: {
            title: 'Principle origin of the assets used for the Tantum account',
            type: 'checkboxes',
            values: {
                income: 'Employment relationship (income)',
                pension: 'Pension',
                wealth: 'Wealth',
                insurance: 'Insurance',
                social_welfare: 'Social Welfare',
            },
        },
        source_of_wealth: {
            title: 'Source of wealth',
            type: 'radio',
            hideIfEmpty: true,
            values: {
                inheritance: 'Inheritance',
                sale_of_company: 'Sale of company',
                sale_of_property: 'Sale of property',
                company_profits: 'Company profits/dividends',
                crypto_business: 'Crypto business',
                gambling: 'Gambling',
                sale_of_shares: 'Sale of shares or other investment',
                other: 'Other business transactions/savings',
            },
        },
        volume_of_wealth: {
            title: 'Volume of wealth',
            type: 'radio',
            hideIfEmpty: true,
            values: {
                '0-20000': '0 - 20,000.00 Euro',
                '20000-50000': '20,000.00 - 50,000.00 Euro',
                '50000-100000': '50,000.00 - 100,000.00 Euro',
                '100000-250000': '100,000 - 250,000.00 Euro',
                '250000-500000': '250,000 - 500,000.00 Euro',
                '500000-1000000': '500,000 - 1,000,000.00 Euro',
            },
        },
        country_of_wealth: {
            title: 'Country of source of funds',
            type: 'country-list',
        },
        monthly_spending: {
            title: 'Expected monthly spending volume?',
            type: 'radio',
            values: {
                '0-100': '0 - 100.00 Euro',
                '100-300': '100 - 300.00 Euro',
                '300-500': '300 - 500.00 Euro',
                '500-1000': '500 - 1,000.00 Euro',
                '>1000': '1,000.00 Euro and more',
            },
        },
        intention_of_using: {
            title: 'Intention of using your Tantum account',
            type: 'radio',
            values: {
                payments: 'Payments for online services and products',
                others: 'Others',
            },
        },
        employment_status: {
            title: 'Employment Status',
            type: 'radio',
            values: {
                full_time: 'Full-time employment',
                part_time: 'Part-time employment',
                self_employed: 'Self-employed',
                freelancer: 'Freelancer',
                unemployed: 'Unemployed',
                retired: 'Retired',
                student: 'Student',
            },
        },
        professional_occupation: {
            title: 'Professional occupation',
        },
        industry: {
            title: 'Industry',
            type: 'radio',
            values: {
                agriculture_forestry:	'Agriculture or forestry',
                bank_insurance:	'Bank or insurance',
                construction:	'Construction',
                education_science: 'Education or science',
                energy_utilities:	'Energy, utilities or telecommunications sector',
                health_social: 'Health or social assistance',
                manufacturing_industrial:	'Manufacturing or industrial production',
                arts_crafts:	'Arts & Crafts, Jeweler & Pawn Shop',
                logistics_wholesale: 'Logistics, wholesale or retail',
                media_technology:	'Media, technology or consulting',
                production_sale: 'Production or sale in the defense industry',
                law_tax_sector: 'Law, tax sector, auditor or accountant',
                travel_real_estate: 'Travel service or real estate sector',
                sports_gambling: 'Sports, gambling, entertainment or gastronomy',
                trust_non_profit: 'Trust or non-profit organization',
                sales_services: 'Sales or services in the automotive sector',
                public_service_hospitality_industry: 'Public service or hospitality industry',
            },
        },
        occupation_level: {
            title: 'Level of occupation',
            type: 'radio',
            values: {
                junior_analyst: 'junior Analyst',
                assistant: 'Assistant',
                trainee: 'Trainee',
                coordinator: 'Coordinator',
                other_entry_level: 'Other (Entry Level)',
                department_manager: 'Department Manager',
                project_leader: 'Project Leader',
                supervisor: 'Supervisor',
                team_lead: 'Team Lead',
                other_mid_level: 'Other (Mid-Level)',
                director: 'Director',
                senior_manager: 'Senior Manager',
                vice_president: 'Vice President',
                chief_officer: 'Chief Officer (C-suite)',
                other_senior_level: 'Other (Senior Level)',
                small_business_owner: 'Small Business Owner',
                startup_founder: 'Startup Founder',
                partner: 'Partner/Co-Owner',
            },
        },
        monthly_income: {
            title: 'Monthly Income (EUR)',
            type: 'radio',
            values: {
                '0-2000': '0 to 2,000',
                '2001-4000': '2,001 to 4,000',
                '4001-6000': '4,001 to 6,000',
                '>6001': 'more than 6,001',
            },
        },
    },
    // [STEP_LEVEL2]: {
    //     employment_relationship: {
    //         title: 'Employment Relationship',
    //         type: 'radio',
    //         values: {
    //             employed: 'Employed (full-time or part-time)',
    //             self_employed: 'Self-employed or freelancer',
    //             student: 'Student/Pupil',
    //             pensioner: 'Pensioner or housewife/man',
    //             unemployed: 'Job seeking',
    //         },
    //     },
    //
    //     proof_of_income: {
    //         title: 'Proof of income (Tantum reserves the right to request proofs)',
    //         type: 'radio',
    //         values: {
    //             inheritance_certificate: 'Certificates of inheritance',
    //             sales_contracts: 'Sales contracts',
    //             tax_returns: 'Tax returns',
    //             employment_contract: 'Employment contracts',
    //             land_register: 'Extracts from the land register',
    //             dividend_distribution: 'Resolutions on dividend distributions',
    //             financial_statement: 'Annual financial statements',
    //             gift_agreement: 'Gift agreements',
    //             loan_agreement: 'Loan agreements',
    //             blockchain_data: 'Blockchain data in connection with other documents',
    //             other: 'Other',
    //         },
    //     },
    // },
    // [STEP_OCCUPATION]: {
    //     occupation: {
    //         title: 'Occupation',
    //         type: 'radio',
    //         values: {
    //             employed: 'Employed',
    //             pensioner: 'Pensioner',
    //             student_schoolchild: 'Student / schoolchild',
    //             unemployed: 'Unemployed',
    //             self_employed: 'Self-employed',
    //         },
    //     },
    //     occupation_self_employed: {
    //         title: 'Occupation > Self-employed',
    //         type: 'checkboxes',
    //         showIf: {
    //             field: 'occupation',
    //             value: 'self_employed',
    //         },
    //         values: {
    //             real_estate_activities: 'Real estate activities',
    //             trading_and_exchanging_virtual_currencies: 'Trading and exchanging virtual currencies',
    //             forex_trading: 'Forex trading',
    //             investment_service: 'Investment service',
    //             sale_of_motor_vehicles: 'Sale of motor vehicles',
    //             adult_materials_products_or_services: 'Adult materials, products or services',
    //             tax_advising: 'Tax advising',
    //             provision_of_legal_services: 'Provision of legal services, legal practice or notary practice',
    //             computer_software_or_hardware: 'Computer software or hardware',
    //             wholesale_trade_in_spirits: 'Wholesale trade in spirits and other alcohol products and tobacco goods',
    //             other: 'Other',
    //         },
    //     },
    //     occupation_self_employed_other: {
    //         title: 'Occupation > Self-employed > Other',
    //         showIf: {
    //             field: 'occupation_self_employed',
    //             value: 'other',
    //         },
    //     },
    //     occupation_employed_workspace: {
    //         title: 'Occupation > Employed > Name of workplace',
    //         showIf: {
    //             field: 'occupation',
    //             value: 'employed',
    //         },
    //     },
    //     occupation_employed_position: {
    //         title: 'Occupation > Employed > Position',
    //         showIf: {
    //             field: 'occupation',
    //             value: 'employed',
    //         },
    //     },
    //     occupation_employed_business_area: {
    //         title: 'Occupation > Employed > The business area of your employer',
    //         showIf: {
    //             field: 'occupation',
    //             value: 'employed',
    //         },
    //     },
    // },
    // [STEP_FUNDS_AND_ACTIVITY]: {
    //     tax_purposes: {
    //         title: 'Do you pay taxes only in the country of residence?',
    //     },
    //     tax_purposes_country: {
    //         title: 'Country',
    //         type: 'country',
    //         showIf: {
    //             field: 'tax_purposes',
    //             value: 'no',
    //         },
    //     },
    //     tax_purposes_tin: {
    //         title: 'TIN',
    //         showIf: {
    //             field: 'tax_purposes',
    //             value: 'no',
    //         },
    //         hideIfEmpty: true,
    //     },
    //     funds: {
    //         title: 'Source of funds',
    //         type: 'checkboxes',
    //         values: {
    //             salary: 'Salary',
    //             savings: 'Savings',
    //             pension: 'Pension / social benefits',
    //             inheritance: 'Inheritance',
    //             real_estate_sold: 'Real estate sold',
    //             loans: 'Loans',
    //             winnings: 'Winnings (lotteries / betting)',
    //             scholarship: 'Scholarship',
    //             other: 'Other',
    //             business: 'Business',
    //         },
    //     },
    //     funds_other: {
    //         title: 'Origin of funds > Other',
    //         showIf: {
    //             field: 'funds',
    //             value: 'other',
    //         },
    //     },
    //     services: {
    //         title: 'Purpose of the account',
    //         type: 'checkboxes',
    //         values: {
    //             account_payments: 'Daily banking',
    //             investments_dividends_business_activities: 'Investments, dividends or business activity',
    //             other: 'Other',
    //         },
    //     },
    //     services_other: {
    //         title: 'Services you use or plan to use > Other',
    //         showIf: {
    //             field: 'services',
    //             value: 'other',
    //         },
    //     },
    //     financial_institutions: {
    //         title: 'Please indicate financial institutions where You have an account(s)',
    //         type: 'financial-institutions',
    //         hideIfEmpty: true,
    //     },
    // },
    // [STEP_PLANNED_MONTHLY_ACTIVITY]: {
    //     incoming_quantity: {
    //         title: 'Incoming transfer number of transactions',
    //         type: 'radio',
    //         values: {
    //             '1-10': '1-10',
    //             '10-30': '10-30',
    //             '30-100': '30-100',
    //             '>100': '> 100',
    //         },
    //     },
    //     incoming_turnover: {
    //         title: 'Incoming transfer turnover (EUR)',
    //         type: 'radio',
    //         values: {
    //             '<1000': '<1000',
    //             '1000-5000': '1000-5000',
    //             '5000-15000': '5000-15000',
    //             '>15000': 'more than 15000',
    //         },
    //     },
    //     incoming_countries: {
    //         title: 'Incoming transfer countries',
    //         type: 'country-list',
    //     },
    //     outgoing_quantity: {
    //         title: 'Outgoing transfer quantity',
    //         type: 'radio',
    //         values: {
    //             '1-10': '1-10',
    //             '10-30': '10-30',
    //             '30-100': '30-100',
    //             '>100': '> 100',
    //         },
    //         hideIfEmpty: true,
    //     },
    //     outgoing_turnover: {
    //         title: 'Outgoing transfer turnover (EUR)',
    //         type: 'radio',
    //         values: {
    //             '<1000': '<1000',
    //             '1000-5000': '1000-5000',
    //             '5000-15000': '5000-15000',
    //             '>15000': '> 15000',
    //         },
    //         hideIfEmpty: true,
    //     },
    //     outgoing_countries: {
    //         title: 'Outgoing transfer countries',
    //         type: 'country-list',
    //         hideIfEmpty: true,
    //     },
    // },
    // [STEP_PARTICIPATION_IN_POLITICS]: {
    //     participation_in_politics: {
    //         title: 'The Client is a politically exposed person',
    //     },
    //     family_politically_exposed: {
    //         title: 'The Client is a close family member of a politically exposed person',
    //     },
    //     associate_politically_exposed: {
    //         title: 'The Client is a close associate of a politically exposed person',
    //     },
    //     exposed_description: {
    //         title:
    //             'If the Client is a close family member or close associate of a politically exposed person,\n' +
    //             'please specify the politically exposed person and positions she/he holds',
    //     },
    //     understand_politically_definition: {
    //         title: 'I clearly understand the definition of politically exposed person',
    //         type: 'bool',
    //     },
    // },
};
