import React, { useState, useMemo } from 'react';
import SiteWrapper from '../../SiteWrapper';
import 'react-table/react-table.css';
import { objectToOptions } from '../../../helpers';
import { FILTER_MASKS, BOOKKEEPING_ENTRY_TYPES } from '../../../constants';
import PaginatedFilteredList from '../../Common/PaginatedFilteredList';

const uri = '/admin-bookkeeping/booking-entries';
const exportConfig = { uri: '/admin-bookkeeping/booking-entries/download', filename: 'booking-entries.csv' };

const BookingEntries = () => {
    const [items, setItems] = useState([]);

    const updateItems = (items) => {
        setItems(items);
    };

    const renderList = () => (
        <PaginatedFilteredList
            uri={uri}
            columns={columns}
            filterFields={filterFields}
            updateItems={updateItems}
            items={items}
            exportConfig={exportConfig}
            sortBy={'booking_entry.id'}
        />
    );

    const filterFields = useMemo(
        () => [
            {
                title: 'Entry Type',
                name: 'entry_type',
                type: 'select',
                options: objectToOptions(BOOKKEEPING_ENTRY_TYPES),
            },
            {
                title: 'From',
                name: 'created_at_from',
                type: 'text',
                placeholder: FILTER_MASKS.date.placeholder,
                mask: FILTER_MASKS.date.mask,
            },
            {
                title: 'To',
                name: 'created_at_to',
                type: 'text',
                placeholder: FILTER_MASKS.date.placeholder,
                mask: FILTER_MASKS.date.mask,
            },
        ],
        [],
    );

    const columns = useMemo(
        () => [
            {
                Header: 'Date',
                accessor: 'date',
                sortable: false,
            },
            {
                Header: 'Account',
                accessor: 'account',
                sortable: false,
            },
            {
                Header: 'Entry type',
                accessor: 'type',
                sortable: false,
            },
            {
                Header: 'Description',
                accessor: 'description',
                sortable: false,
                width: 300,
            },
            {
                Header: 'Tax Code',
                accessor: 'tax_code',
                sortable: false,
            },
            {
                Header: 'Currency',
                accessor: 'currency',
                sortable: false,
            },
            {
                Header: 'Amount EUR',
                accessor: 'amount_eur',
                sortable: false,
            },
            {
                Header: 'Amount CHF',
                accessor: 'amount_chf',
                sortable: false,
            },
        ],
        [],
    );

    return  <SiteWrapper title='Booking Entries'>{renderList()}</SiteWrapper>;
};

export default BookingEntries;
