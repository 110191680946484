import React, { useMemo, useState } from 'react';
import PaginatedFilteredList from '../../../Common/PaginatedFilteredList';
import { connect } from 'react-redux';
import { capitalize, formatDateAndTime } from '../../../../helpers';
import { Link } from 'react-router-dom';
import {
    TRANSACTION_DOCUMENTATION_STATUS,
} from '../../../../constants';

const TransactionDocumentation = ({ adminId }) => {
    const [items, setItems] = useState([]);

    const columns = useMemo(
        () => [
            {
                Header: 'Date',
                accessor: 'created_at',
                Cell: (row) => formatDateAndTime(row.value),
            },
            {
                Header: 'Name',
                accessor: 'uploader_name',
                Cell: (row) => <Link to={{ pathname: `/compliance/users/${row.original.user_id}` }}>{capitalize(row.value)}</Link>,
            },
            {
                Header: 'Number',
                accessor: 'transaction_number',
                sortable: false,
                Cell: (row) => <Link to={{ pathname: `/transactions/${row.original.transaction_id}` }}>{row.value}</Link>,
            },
            {
                Header: 'Status',
                accessor: 'status',
                Cell: (row) => renderStatusRow(row.value),
            },
            {
                Header: 'Requested by',
                accessor: 'requested_user_name',
                sortable: false,
                Cell: (row) => capitalize(row.value),
            },
        ],
        [],
    );

    const filterFields = useMemo(
        () => [
            {
                title: 'Status',
                name: 'status',
                type: 'select',
                options: [
                    {
                        title: 'Requested',
                        value: 'created',
                    },
                    {
                        title: 'Uploaded',
                        value: 'completed',
                    },
                ],
            },
            {
                title: 'My requests',
                name: 'requested_user_id',
                type: 'select',
                valueType: 'integer',
                options: [
                    {
                        title: 'Yes',
                        value: adminId,
                    },
                ],
            },
        ],
        [adminId],
    );

    const renderStatusRow = (value) => {
        if (TRANSACTION_DOCUMENTATION_STATUS[value]) {
            return TRANSACTION_DOCUMENTATION_STATUS[value];
        }

        return value;
    };

    const uri = useMemo(() => 'admin-transaction-document/document-requests', []);

    const updateItems = (items) => setItems(items);

    return <PaginatedFilteredList updateItems={updateItems} columns={columns} items={items} uri={uri} filterFields={filterFields} />;
};

const mapStateToProps = (state) => ({
    adminId: state.admin.information.id,
});

export default connect(mapStateToProps)(TransactionDocumentation);
