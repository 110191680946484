import React, { Component } from 'react';
import { Form } from 'tabler-react';
import PropTypes from 'prop-types';

class SelectField extends Component {
    render () {
        const { name, value, title, handleChange, options, includeEmptyOption } = this.props;

        return (
            <Form.Select name={name} label={title} onChange={handleChange} value={value}>
                {includeEmptyOption && <option />}
                {options.map((option) => (
                    <option key={option.value} value={option.value}>
                        {option.title}
                    </option>
                ))}
            </Form.Select>
        );
    }
}

SelectField.propTypes = {
    name: PropTypes.string.isRequired,
    title: PropTypes.string,
    handleChange: PropTypes.func.isRequired,
    options: PropTypes.array.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    includeEmptyOption: PropTypes.bool,
};

SelectField.defaultProps = {
    value: '',
    includeEmptyOption: true,
};

export default SelectField;
