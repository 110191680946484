import React, { Component } from 'react';
import SiteWrapper from '../SiteWrapper';
import { Grid } from 'tabler-react';
import TableBlock from '../Common/TableBlock';
import FilterForm from '../Common/FilterForm';
import moment from 'moment';
import { FILTER_MASKS } from '../../constants';
import { createApiClient as api } from '../../services/api-client';
import { connect } from 'react-redux';
import { getLabelByCountryCode } from '../../selectors/location/countries';
import { fetchCountries } from '../../actions/location/countries';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { getDateErrors } from '../../services/validations';
import { getLabelForAmountRange } from '../../selectors/get-label';

class Reporting extends Component {
    constructor (props) {
        super(props);

        this.state = {
            isLoading: false,
            error: null,
            date_from: moment()
                .startOf('month')
                .format('YYYY-MM-DD'),
            date_to: moment()
                .endOf('month')
                .format('YYYY-MM-DD'),
            blocks: [
                {
                    key: 'outgoing_external_transactions_by_currency',
                    columns: [
                        {
                            Header: 'Total number of outgoing external transactions by currency',
                            accessor: 'currency',
                            className: 'uppercase',
                            headerClassName: 'text-left',
                            width: 750,
                            sortable: false,
                        },
                        {
                            Header: 'Total',
                            accessor: 'count',
                            headerClassName: 'text-left',
                            sortable: false,
                        },
                    ],
                    items: [],
                },
                {
                    key: 'outgoing_external_transactions_by_amount',
                    columns: [
                        {
                            Header: 'Total number of outgoing external transactions by amount',
                            accessor: 'label',
                            headerClassName: 'text-left',
                            width: 750,
                            sortable: false,
                        },
                        {
                            Header: 'Currency',
                            accessor: 'currency',
                            className: 'uppercase',
                            headerClassName: 'text-left',
                            sortable: false,
                        },
                        {
                            Header: 'Total',
                            accessor: 'count',
                            headerClassName: 'text-left',
                            sortable: false,
                        },
                    ],
                    items: [],
                },
                {
                    key: 'incoming_external_transactions_by_currency',
                    columns: [
                        {
                            Header: 'Total number of incoming external transactions',
                            accessor: 'currency',
                            className: 'uppercase',
                            headerClassName: 'text-left',
                            width: 750,
                            sortable: false,
                        },
                        {
                            Header: 'Total',
                            accessor: 'count',
                            headerClassName: 'text-left',
                            sortable: false,
                        },
                    ],
                    items: [],
                },
                {
                    key: 'outgoing_external_transactions_by_country',
                    columns: [
                        {
                            Header: 'Total number of external transactions by country (All currencies)',
                            accessor: 'country_label',
                            headerClassName: 'text-left',
                            width: 750,
                            sortable: false,
                        },
                        {
                            Header: 'ISO code',
                            accessor: 'country_code',
                            className: 'uppercase',
                            headerClassName: 'text-left',
                            sortable: false,
                        },
                        {
                            Header: 'Total',
                            accessor: 'count',
                            headerClassName: 'text-left',
                            sortable: false,
                        },
                    ],
                    items: [],
                },
            ],
        };

        this.filterFields = [
            {
                title: 'From',
                name: 'date_from',
                type: 'text',
                placeholder: FILTER_MASKS.date.placeholder,
                mask: FILTER_MASKS.date.mask,
                value: this.state.date_from,
            },
            {
                title: 'To',
                name: 'date_to',
                type: 'text',
                placeholder: FILTER_MASKS.date.placeholder,
                mask: FILTER_MASKS.date.mask,
                value: this.state.date_to,
            },
        ];

        this.setDates = this.setDates.bind(this);
        this.fetchReports = this.fetchReports.bind(this);
    }

    async componentDidMount () {
        await this.props.fetchCountries();
        this.fetchReports(this.state);
    }

    setDates (dates) {
        this.setState({
            dates,
        });
    }

    async fetchReports (params) {
        const newParams = {
            date_from: params.date_from,
            date_to: params.date_to,
        };

        const dateErrors = getDateErrors(newParams);

        if (dateErrors.length !== 0) {
            return this.setState({
                error: {
                    additional_data: dateErrors,
                },
            });
        }

        this.setState({
            isLoading: true,
        });

        await api()
            .get('admin-reporting/transactions-report', {
                params: newParams,
            })
            .then((response) => {
                this.formatItems(response);

                this.setState({
                    isLoading: false,
                    error: null,
                });
            })
            .catch((data) => {
                this.setState({
                    error: data.data,
                    isLoading: false,
                });
            });
    }

    formatItems (res) {
        const items = [];

        for (const [key, value] of Object.entries(res.data)) {
            items[key] = value.map((item) => {
                const label = getLabelForAmountRange(item.amount_from, item.amount_to);

                if (label) {
                    item.label = label;
                }

                if (item.country_code) {
                    item.country_label = getLabelByCountryCode(this.props.countries, item.country_code);
                }

                return item;
            });
        }

        const blocks = this.state.blocks.map((block) => {
            block.items = items[block.key];

            return block;
        });

        this.setState({
            blocks,
        });
    }

    renderBlocks () {
        const { isLoading } = this.state;

        return this.state.blocks.map((block, key) => (
            <TableBlock isLoading={isLoading} key={key} items={block.items} columns={block.columns}></TableBlock>
        ));
    }

    render () {
        const { error } = this.state;

        return (
            <SiteWrapper title='Reporting'>
                <Grid.Row>
                    <Grid.Col>
                        <FilterForm
                            fields={this.filterFields}
                            setDates={this.setDates}
                            submitCallback={this.fetchReports}
                            dates={this.state.dates}
                            show={true}
                            error={error}
                        />
                    </Grid.Col>
                </Grid.Row>

                <Grid.Row>
                    <Grid.Col>{this.renderBlocks()}</Grid.Col>
                </Grid.Row>
            </SiteWrapper>
        );
    }
}

Reporting.propTypes = {
    fetchCountries: PropTypes.func.isRequired,
    countries: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
    countries: state.location.countries,
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            fetchCountries,
        },
        dispatch,
    );

export default connect(mapStateToProps, mapDispatchToProps)(Reporting);
