import React, { Component } from 'react';
import Modal from 'react-modal';
import PropTypes from 'prop-types';
import ApiError from './ApiError';
import { Icon } from 'tabler-react';

class ModalSkeleton extends Component {
    constructor (props) {
        super(props);

        this.state = {
            isModalOpen: false,
        };

        this.closeModal = this.closeModal.bind(this);
        this.openModal = this.openModal.bind(this);
    }

    openModal () {
        this.setState({
            isModalOpen: true,
        });
    }

    closeModal () {
        this.setState({
            isModalOpen: false,
        });
    }

    render () {
        const { isModalOpen } = this.state;
        const { error } = this.props;

        return (
            <>
                {isModalOpen && (
                    <Modal isOpen={isModalOpen} className='react-modal'>
                        <Icon name='x-circle' className='w-4 react-modal__closeButton' onClick={this.closeModal} />

                        <div className='react-modal__content'>
                            <ApiError error={error} />
                            {this.props.content}
                        </div>
                    </Modal>
                )}

                <span onClick={this.openModal}>{this.props.initialBody}</span>
            </>
        );
    }
}

ModalSkeleton.propTypes = {
    content: PropTypes.object.isRequired,
    initialBody: PropTypes.object.isRequired,
    error: PropTypes.object,
};

export default ModalSkeleton;
