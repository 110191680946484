import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Icon, Button, Dimmer, Table, Card } from 'tabler-react';
import Modal from 'react-modal';
import { createApiClient as api } from '../../../services/api-client';
import ApiError from '../../Common/ApiError';
import { formatDateAndTime, capitalize } from '../../../helpers';
import { Link } from 'react-router-dom';

const TransactionDetailsModal = ({ visible, onModalClose, selectedTransaction }) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [transactionDetails, setTransactionDetails] = useState(null);

    const handleModalClose = useCallback(() => {
        onModalClose();
    }, [onModalClose]);

    const fetchTransactionDetails = useCallback(async () => {
        if (!selectedTransaction) {
            return;
        }

        setLoading(true);

        try {
            const { data } = await api().get(`/admin-sepa-integration/transactions/${selectedTransaction.id}`);

            setTransactionDetails(data);
            setLoading(false);
        } catch (error) {
            setError(error.data);
            setLoading(false);
        }
    }, [selectedTransaction]);

    useEffect(() => {
        if (!visible) {
            return;
        }

        fetchTransactionDetails();

        return () => setTransactionDetails(null);
    }, [fetchTransactionDetails, visible]);

    const renderTransactionDetails = () => {
        if (!selectedTransaction || !transactionDetails) {
            return null;
        }

        const getFormattedValue = (key, value) => {
            const dateKeys = ['created_at', 'accepted_at', 'rejected_at', 'settled_at', 'returned_at'];

            if (dateKeys.includes(key)) {
                return formatDateAndTime(value);
            }

            return value;
        };

        const getFormattedKey = (key) => capitalize(key.split('_').join(' '));

        return Object.entries(transactionDetails).map(([key, value], index) => (
            <Table.Row key={index}>
                <Table.Col>{getFormattedKey(key)}</Table.Col>
                <Table.Col>
                    {key === 'transaction_number' ? (
                        <Link to={{ pathname: `/transactions/${transactionDetails.transaction_id}` }}>{value}</Link>
                    ) : (
                        getFormattedValue(key, value)
                    )}
                </Table.Col>
            </Table.Row>
        ));
    };

    return (
        <Modal isOpen={visible} className='react-modal'>
            <Icon name='x-circle' className='w-4 react-modal__closeButton' onClick={handleModalClose} />

            <div className='react-modal__content'>
                <ApiError error={error} />
                <Dimmer active={loading} loader>
                    <Card className='card-scroll'>
                        <Card.Header>
                            <Card.Title>Transaction details</Card.Title>
                        </Card.Header>
                        <Table className='small' cards>
                            <Table.Body>{renderTransactionDetails()}</Table.Body>
                        </Table>
                    </Card>
                    <div className='buttonContainer'>
                        <Button onClick={handleModalClose} color='secondary' disabled={loading} loading={loading}>
                            Close
                        </Button>
                    </div>
                </Dimmer>
            </div>
        </Modal>
    );
};

TransactionDetailsModal.propTypes = {
    visible: PropTypes.bool.isRequired,
    onModalClose: PropTypes.func.isRequired,
    selectedTransaction: PropTypes.object,
};

export default TransactionDetailsModal;
