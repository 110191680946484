import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import SiteWrapper from '../../../SiteWrapper';
import { Button, Card, Dimmer, Grid } from 'tabler-react';
import { createApiClient as api } from '../../../../services/api-client';
import PropTypes from 'prop-types';
import ApiError from '../../../Common/ApiError';
import { navigate } from '../../../../actions/navigate';
import { toast } from 'react-toastify';
import Type from './FormFields/Type';
import Direction from './FormFields/Direction';
import TypeFields from './FormFields/TypeFields';
import Currency from './FormFields/Currency';
import Country from './FormFields/Country';
import ReturnFee from './FormFields/ReturnFee';
import TransactionType from './FormFields/TransactionType';
import { buildSubmitData } from './services/transaction-fee';
import isString from 'lodash/isString';
import { INTERNAL_TRANSFER, EXTERNAL_TRANSFER, OUTGOING_TRANSACTION } from '../../../../constants';
import InputTextField from '../../../Common/FilterFormFields/InputTextField';
import WalletType from 'src/components/Content/Fees/TransactionFees/FormFields/WalletType';

class TransactionFeeCreate extends Component {
    constructor (props) {
        super(props);

        this.state = {
            isLoading: false,
            error: null,
            type: 'fixed',
            fee: {
                name: '',
                direction: OUTGOING_TRANSACTION,
                transaction_type: INTERNAL_TRANSFER,
                amount: '0',
                minimum: '',
                maximum: '',
                percent: '0',
                currency: '',
                countries: [],
                return_fee: true,
                wallet_type: '',
            },
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit (event) {
        event.preventDefault();

        const { fee, type } = this.state;

        this.setState({
            isLoading: true,
            error: null,
        });

        api()
            .post('admin-fee/transaction-fee-configurations', buildSubmitData(fee, type))
            .then(() => {
                toast.success('Created successful');
                this.props.navigate('/fee/transaction-fees');
            })
            .catch((data) => {
                this.setState({
                    error: data.data,
                    isLoading: false,
                });
            });
    }

    handleChange (event) {
        const { target } = event;

        this.setState((prevState) => ({
            fee: {
                ...prevState.fee,
                [target.name]: target.type === 'checkbox'
                    ? target.checked
                    : target.value.length > 0 || !isString(target.value) ? target.value : '',
            },
        }));
    }

    changeType = (event) => {
        const { target } = event;

        this.setState({
            [target.name]: target.value,
        });
    };

    render () {
        const { fee, error, type, isLoading } = this.state;

        return (
            <SiteWrapper title='Create transaction fee'>
                <Grid.Row>
                    <Grid.Col md={6} sm={8} xs={12}>
                        <Card>
                            <form onSubmit={this.handleSubmit}>
                                <Dimmer active={isLoading} loader>
                                    <Card.Body>
                                        <Type onChange={this.changeType} value={type} />

                                        <InputTextField name='name' title='Name' value={fee.name} handleChange={this.handleChange} />

                                        <Direction onChange={this.handleChange} value={fee.direction} />

                                        <TransactionType onChange={this.handleChange} value={fee.transaction_type} />

                                        <TypeFields onChange={this.handleChange} fee={fee} type={type} />

                                        <Currency onChange={this.handleChange} value={fee.currency} />

                                        {
                                            fee.transaction_type === EXTERNAL_TRANSFER && (
                                                <Country onChange={this.handleChange} value={fee.countries}/>
                                            )
                                        }

                                        <ReturnFee onChange={this.handleChange} value={fee.return_fee}/>

                                        <WalletType onChange={this.handleChange} value={fee.wallet_type} />

                                        <ApiError error={error} />
                                    </Card.Body>
                                    <Card.Footer className='text-center'>
                                        <Button type='submit' color='primary' onClick={this.handleSubmit}>
                                            Submit
                                        </Button>
                                    </Card.Footer>
                                </Dimmer>
                            </form>
                        </Card>
                    </Grid.Col>
                </Grid.Row>
            </SiteWrapper>
        );
    }
}

TransactionFeeCreate.propTypes = {
    navigate: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            navigate,
        },
        dispatch,
    );

export default connect(null, mapDispatchToProps)(TransactionFeeCreate);
