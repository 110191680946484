import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Icon, Button } from 'tabler-react';
import Modal from 'react-modal';
import { createApiClient as api } from '../../../services/api-client';
import ApiError from '../../Common/ApiError';
import SelectField from '../../Common/FilterFormFields/SelectField';

const REJECT_REASONS = {
    NOOR: 'No Original Transaction Received',
    RNPR: 'Original Transaction Received but Not Processable',
    ARJT: 'Already Rejected Transaction',
    ARDT: 'Already Returned Transaction',
    RR04: 'Regulatory Reason',
};

const DEFAULT_FORM_VALUES = {
    reject_reason: '',
};

const rejectOptions = Object.entries(REJECT_REASONS).map(([key, description]) => ({ value: key, title: `${key} (${description})` }));

const RejectClaimRequestModal = ({ visible, onModalClose, onClaimReject, selectedTransaction }) => {
    const [formValues, setFormValues] = useState(DEFAULT_FORM_VALUES);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const handleModalClose = useCallback(() => {
        onModalClose();
        setFormValues(DEFAULT_FORM_VALUES);
    }, [onModalClose]);

    const handleRejectClaim = useCallback(
        async (event) => {
            event.preventDefault();

            if (!selectedTransaction) {
                return;
            }

            setLoading(true);

            try {
                await api().put(`admin-sepa-integration/claims/${selectedTransaction.id}/reject`, {
                    code: formValues.reject_reason,
                });
                setLoading(false);
                onClaimReject();
                setFormValues(DEFAULT_FORM_VALUES);
            } catch (error) {
                setError(error.data);
                setLoading(false);
            }
        },
        [formValues, onClaimReject, selectedTransaction],
    );

    const handleChange = (event) => {
        const { target } = event;

        setFormValues((prev) => ({ ...prev, [target.name]: target.value }));
    };

    return (
        <Modal isOpen={visible} className='react-modal'>
            <Icon name='x-circle' className='w-4 react-modal__closeButton' onClick={handleModalClose} />

            <div className='react-modal__content'>
                <form onSubmit={handleRejectClaim}>
                    <ApiError error={error} />

                    <SelectField
                        name='reject_reason'
                        title='Rejection reason'
                        handleChange={handleChange}
                        options={rejectOptions}
                        value={formValues.reject_reason}
                    />

                    <div className='buttonContainer'>
                        <Button onClick={handleModalClose} color='secondary' disabled={loading} loading={loading}>
                            Cancel
                        </Button>

                        <Button type='submit' color='primary' disabled={loading} loading={loading}>
                            Reject
                        </Button>
                    </div>
                </form>
            </div>
        </Modal>
    );
};

RejectClaimRequestModal.propTypes = {
    visible: PropTypes.bool.isRequired,
    onModalClose: PropTypes.func.isRequired,
    onClaimReject: PropTypes.func.isRequired,
    selectedTransaction: PropTypes.object,
};

export default RejectClaimRequestModal;
