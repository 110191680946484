import { createAction } from 'redux-actions';
import { fetchCurrentAdminInformation } from './admin-information';

export const AUTHENTICATE_USER_REQUEST = 'user/AUTHENTICATE_USER_REQUEST';
export const AUTHENTICATE_USER_SUCCESS = 'user/AUTHENTICATE_USER_SUCCESS';
export const AUTHENTICATE_USER_FAILURE = 'user/AUTHENTICATE_USER_FAILURE';

export const CONFIRM_AUTHENTICATION_REQUEST = 'user/CONFIRM_AUTHENTICATION_REQUEST';
export const CONFIRM_AUTHENTICATION_SUCCESS = 'user/CONFIRM_AUTHENTICATION_SUCCESS';
export const CONFIRM_AUTHENTICATION_FAILURE = 'user/CONFIRM_AUTHENTICATION_FAILURE';

export const CREATE_AUTHENTICATION_SUCCESS = 'user/CREATE_AUTHENTICATION_SUCCESS';
export const CREATE_AND_GET_AUTHENTICATION_REQUEST = 'user/CREATE_AND_GET_AUTHENTICATION_REQUEST';
export const CREATE_AND_GET_AUTHENTICATION_SUCCESS = 'user/CREATE_AND_GET_AUTHENTICATION_SUCCESS';
export const CREATE_AND_GET_AUTHENTICATION_FAILURE = 'user/CREATE_AND_GET_AUTHENTICATION_FAILURE';

export const USER_AUTHENTICATED = 'user/AUTHENTICATED';

const authenticateUserRequest = createAction(AUTHENTICATE_USER_REQUEST);
const authenticateUserSuccess = createAction(AUTHENTICATE_USER_SUCCESS);
const authenticateUserFailure = createAction(AUTHENTICATE_USER_FAILURE);

const confirmAuthenticationRequest = createAction(CONFIRM_AUTHENTICATION_REQUEST);
const confirmAuthenticationSuccess = createAction(CONFIRM_AUTHENTICATION_SUCCESS);
const confirmAuthenticationFailure = createAction(CONFIRM_AUTHENTICATION_FAILURE);

const createAuthenticationSuccess = createAction(CREATE_AUTHENTICATION_SUCCESS);

const createAndGetAuthenticationRequest = createAction(CREATE_AND_GET_AUTHENTICATION_REQUEST);
const createAndGetAuthenticationSuccess = createAction(CREATE_AND_GET_AUTHENTICATION_SUCCESS);
const createAndGetAuthenticationFailure = createAction(CREATE_AND_GET_AUTHENTICATION_FAILURE);

export const userAuthenticated = createAction(USER_AUTHENTICATED);

export const authenticateUser = (username, password) => async (dispatch, getState, { api }) =>
    new Promise(async (resolve) => {
        try {
            dispatch(authenticateUserRequest());
            const result = await api.post('/admin-authentication/authenticate', { username, password });

            dispatch(authenticateUserSuccess(result.data));
            resolve(result.data);
        } catch (response) {
            dispatch(authenticateUserFailure(response.data));
        }
    });

export const confirmAuthentication = (code) => async (dispatch, getState, { api }) => {
    try {
        dispatch(confirmAuthenticationRequest());

        const result = await api.put('/admin-authentication/confirm-authentication', {
            code: code.toString(),
        });

        dispatch(confirmAuthenticationSuccess(result.data));

        await dispatch(fetchCurrentAdminInformation());

        dispatch(userAuthenticated());
    } catch (response) {
        dispatch(confirmAuthenticationFailure(response.data));
    }
};

export const createAndGetAuthentication = () => async (dispatch, getState, { api }) =>
    new Promise(async (resolve) => {
        try {
            dispatch(createAndGetAuthenticationRequest());

            const tokens = await api.put('admin-authentication/authentication/google');

            dispatch(createAuthenticationSuccess(tokens.data));

            const result = await api.get('admin-authentication/authentication/google');

            dispatch(createAndGetAuthenticationSuccess(result.data));
            resolve(result.data);
        } catch (response) {
            dispatch(createAndGetAuthenticationFailure(response.data));
        }
    });
