import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Card, Form, FormTextInput, Button } from 'tabler-react';
import { bindActionCreators } from 'redux';
import { getError } from '../../selectors/getError';
import { confirmAuthentication, createAndGetAuthentication } from '../../actions/user/authenticate-user';
import { connect } from 'react-redux';
import ApiError from '../Common/ApiError';
import { isLoading } from '../../selectors/isLoading';
import TwoFAInstructions from './TwoFAInstructions';

class TwoFAForm extends Component {
    constructor (props) {
        super(props);

        this.state = {
            code: '',
            authenticator: {
                secret: null,
                url: null,
            },
        };

        this.onSubmit = this.onSubmit.bind(this);
        this.onInputChange = this.onInputChange.bind(this);
    }

    componentDidMount () {
        if (!this.props.hasAuthentication) {
            this.props.createAndGetAuthentication().then((response) => {
                this.setState({
                    ...this.state.authenticator,
                    authenticator: response,
                });
            });
        }
    }

    onSubmit (event) {
        event.preventDefault();

        this.props.confirmAuthentication(this.state.code);
    }

    onInputChange (event) {
        const { target } = event;

        this.setState({
            [target.name]: target.value,
        });
    }

    render () {
        const { hasAuthentication, creatingError, isCreating, confirmError, isConfirming } = this.props;

        const { authenticator, code } = this.state;

        return (
            <Card>
                <Card.Body>
                    <Card.Title>Two factor authentication</Card.Title>
                    {!hasAuthentication && <TwoFAInstructions authenticator={authenticator} error={creatingError} isLoading={isCreating} />}

                    {!isCreating && (
                        <>
                            <ApiError error={confirmError} />
                            <form onSubmit={this.onSubmit}>
                                <FormTextInput
                                    autoFocus
                                    autoComplete='off'
                                    name='code'
                                    label='Google authenticator code'
                                    placeholder='Enter Google authenticator code'
                                    value={code}
                                    onChange={this.onInputChange}
                                />
                                <Form.Footer>
                                    <Button
                                        block
                                        color='primary'
                                        name='authenticate'
                                        disabled={isConfirming || code === ''}
                                        loading={isConfirming}
                                    >
                                        Authenticate
                                    </Button>
                                </Form.Footer>
                            </form>
                        </>
                    )}
                </Card.Body>
            </Card>
        );
    }
}

TwoFAForm.propTypes = {
    hasAuthentication: PropTypes.bool,
    confirmAuthentication: PropTypes.func.isRequired,
    createAndGetAuthentication: PropTypes.func.isRequired,
    isConfirming: PropTypes.bool.isRequired,
    confirmError: PropTypes.object,
    isCreating: PropTypes.bool.isRequired,
    creatingError: PropTypes.object,
};

TwoFAForm.defaultProps = {
    hasAuthentication: true,
};

const mapStateToProps = (state) => ({
    isConfirming: isLoading(state, 'confirmAuthentication'),
    confirmError: getError(state, 'authentication', 'confirm'),
    isCreating: isLoading(state, 'createAndGetAuthentication'),
    creatingError: getError(state, 'authentication', 'createAndGet'),
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            confirmAuthentication,
            createAndGetAuthentication,
        },
        dispatch,
    );

export default connect(mapStateToProps, mapDispatchToProps)(TwoFAForm);
