import React, { Component } from 'react';
import 'react-table/react-table.css';
import PropTypes from 'prop-types';
import SiteWrapper from '../../../../../SiteWrapper';
import { Button, Card, Dimmer, Grid } from 'tabler-react';
import { bindActionCreators } from 'redux';
import { navigate } from '../../../../../../actions/navigate';
import { connect } from 'react-redux';
import { createApiClient } from '../../../../../../services/api-client';
import ApiError from '../../../../../Common/ApiError';
import GroupsSelectBox from '../../Misc/GroupsSelectBox';
import { fetchPermissionGroups } from '../../../../../../actions/permission-groups/groups';
import { toast } from 'react-toastify';

class EditAdminUser extends Component {
    constructor (props) {
        super(props);

        this.state = {
            error: null,
            isLoading: false,
            permission_groups: [],
        };

        this.api = createApiClient();

        this.onFormSubmit = this.onFormSubmit.bind(this);
        this.onInputChange = this.onInputChange.bind(this);
        this.navigateToAdminUser = this.navigateToAdminUser.bind(this);
    }

    componentDidMount () {
        this.fetchAdminPermissionGroups();

        const { fetchPermissionGroups } = this.props;

        fetchPermissionGroups();
    }

    onInputChange (event) {
        const { target } = event;

        this.setState({
            [target.name]: target.value,
        });
    }

    fetchAdminPermissionGroups () {
        this.setState({
            isLoading: true,
        });

        const { id } = this.props.match.params;

        this.api.get(`admin-user/admins/${id}/permission-groups`).then((response) => {
            this.setState({
                isLoading: false,
                permission_groups: response.data.map((group) => group.id),
            });
        });
    }

    onFormSubmit (event) {
        event.preventDefault();

        this.setState({
            isLoading: true,
        });

        const { permission_groups } = this.state;
        const { id } = this.props.match.params;

        this.api
            .put(`admin-user/admins/${id}/permission-groups`, { permission_groups })
            .then(() => {
                this.setState({
                    isLoading: false,
                });

                toast.success('Admin has been edited successfully.');

                this.navigateToAdminUser();
            })
            .catch((error) => {
                this.setState({
                    isLoading: false,
                    error: error.data,
                });
            });
    }

    navigateToAdminUser () {
        const { navigate, match: { params: { id } } } = this.props;

        navigate(`/configuration/admin-users/${id}`);
    }

    render () {
        const { isLoading, error, permission_groups } = this.state;

        return (
            <SiteWrapper title='Edit admin user'>
                <Grid.Row>
                    <Grid.Col md={4} sm={6} xs={12}>
                        <Card>
                            <form onSubmit={this.onFormSubmit}>
                                <Dimmer active={isLoading} loader>
                                    <Card.Body>
                                        <GroupsSelectBox assignedGroups={permission_groups} onInputChange={this.onInputChange} />
                                        <ApiError error={error} />
                                    </Card.Body>
                                    <Card.Footer className='text-center'>
                                        <div className='d-flex'>
                                            <Button type='button' link onClick={this.navigateToAdminUser}>
                                                Cancel
                                            </Button>

                                            <Button type='submit' color='primary' className='ml-auto'>
                                                Submit
                                            </Button>
                                        </div>
                                    </Card.Footer>
                                </Dimmer>
                            </form>
                        </Card>
                    </Grid.Col>
                </Grid.Row>
            </SiteWrapper>
        );
    }
}

EditAdminUser.propTypes = {
    navigate: PropTypes.func.isRequired,
    fetchPermissionGroups: PropTypes.func.isRequired,
    match: PropTypes.object,
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            navigate,
            fetchPermissionGroups,
        },
        dispatch,
    );

export default connect(null, mapDispatchToProps)(EditAdminUser);
