import React, { useMemo, useState } from 'react';
import 'react-table/react-table.css';
import SiteWrapper from '../../SiteWrapper';
import PaginatedFilteredList from '../../Common/PaginatedFilteredList';
import { Button } from 'tabler-react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { navigate } from '../../../actions/navigate';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

const AdminUsers = () => {
    const [items, setItems] = useState([]);

    const uri = useMemo(() => 'admin-user/admins', []);

    const updateItems = (items) => {
        setItems(items);
    };

    const renderOptions = () => (
        <Link to='/configuration/admin-users/create'>
            <Button pill icon='plus' color='primary'>
                Create new
            </Button>
        </Link>
    );

    const columns = useMemo(
        () => [
            {
                Header: 'Full name',
                sortable: false,
                Cell: (row) => (
                    <Link to={{ pathname: `/configuration/admin-users/${row.original.id}` }}>
                        {row.original.first_name} {row.original.last_name}
                    </Link>
                ),
            },
            {
                Header: 'Email',
                accessor: 'email',
                sortable: false,
            },
            {
                Header: 'Groups',
                Cell: (row) => {
                    if (row.original.permission_groups.length === 0) {
                        return '-';
                    }

                    const groups = row.original.permission_groups.map((group) => group.name);

                    return groups.join(', ');
                },
            },
        ],
        [],
    );

    return (
        <SiteWrapper title='Admin users' options={renderOptions()}>
            <PaginatedFilteredList uri={uri} columns={columns} updateItems={updateItems} items={items} />
        </SiteWrapper>
    );
};

AdminUsers.propTypes = {
    navigate: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    currentAdminId: state.admin.information.id,
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            navigate,
        },
        dispatch,
    );

export default connect(mapStateToProps, mapDispatchToProps)(AdminUsers);
