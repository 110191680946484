import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { isLoading } from '../../../../../selectors/isLoading';
import Select from 'react-select';
import { fetchCountries } from '../../../../../actions/location/countries';
import { countriesCodes, countriesOptions, selectedCountries } from '../services/country';

class Country extends Component {
    constructor (props) {
        super(props);

        this.onChange = this.onChange.bind(this);
    }

    componentDidMount () {
        this.props.fetchCountries();
    }

    onChange (selected) {
        this.props.onChange({
            target: {
                name: 'countries',
                value: countriesCodes(selected),
            },
        });
    }

    render () {
        const { countries, value } = this.props;

        return (
            <div className='form-group'>
                <label className='form-label'>Country</label>
                <Select
                    isSearchable={true}
                    className='multi-select'
                    isMulti={true}
                    value={selectedCountries(countries, value)}
                    onChange={this.onChange}
                    options={countriesOptions(countries)}
                />
            </div>
        );
    }
}

Country.propTypes = {
    countries: PropTypes.array.isRequired,
    fetchCountries: PropTypes.func.isRequired,
    isFetchingCountries: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.array,
};

const mapStateToProps = (state) => ({
    countries: state.location.countries,
    isFetchingCountries: isLoading(state, 'countries'),
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            fetchCountries,
        },
        dispatch,
    );

export default connect(mapStateToProps, mapDispatchToProps)(Country);
