import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { auditColumns } from '../../../../utils/columns/audit-columns';
import PaginatedFilteredList from '../../../Common/PaginatedFilteredList';

const TransactionAudit = ({ transactionId }) => {
    const [items, setItems] = useState([]);

    const filter = [
        {
            name: 'type',
            value: 'transaction',
        },
        {
            name: 'relation_id',
            value: transactionId,
        },
    ];

    const uri = useMemo(() => '/admin-audit/audit-records', []);

    const updateItems = (items) => setItems(items);

    return (
        <PaginatedFilteredList
            uri={uri}
            updateItems={updateItems}
            columns={auditColumns}
            items={items}
            filterFields={filter}
            renderOnlyList={true}
            showTableHeader={false}
            sortBy='audited_at'
            tableClassNames='mb-0'
        />
    );
};

TransactionAudit.propTypes = {
    transactionId: PropTypes.number.isRequired,
};

export default TransactionAudit;
