import moment from 'moment';

export const getDateErrors = (dates) => {
    const errors = [];

    for (const [name, date] of Object.entries(dates)) {
        if (!moment(date).isValid()) {
            errors.push({
                code: 'invalid_format',
                field: `${name}`,
                message: `Field ${name} is not a valid date`,
            });
        }
    }

    return errors;
};
