import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import 'react-table/react-table.css';
import ReactTable from 'react-table';
import SiteWrapper from '../../SiteWrapper';
import { Button, Card, Grid } from 'tabler-react';
import { navigate } from '../../../actions/navigate';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createApiClient } from '../../../services/api-client';
import { toast } from 'react-toastify';
import CreateSatchelPayMasterAccountModal from './SatchelPayMasterAccount/CreateSatchelPayMasterAccountModal';
import useModalState from '../../../hooks/useModalState';

const SatchelPayMasterAccount = () => {
    const [isLoading, setLoading] = useState(false);
    const createModal = useModalState(false);
    const [masterAccounts, setMasterAccounts] = useState([]);

    const api = useMemo(createApiClient, []);

    const columns = useMemo(
        () => [
            {
                Header: 'Account name',
                accessor: 'name',
                sortable: false,
                Cell: (row) => row.value,
            },
            {
                Header: 'Iban',
                accessor: 'iban',
                sortable: false,
                Cell: (row) => row.value,
            },
            {
                Header: 'BIC',
                accessor: 'bic',
                sortable: false,
                Cell: (row) => row.value,
            },
            {
                Header: 'Account number',
                accessor: 'account_number',
                sortable: false,
                Cell: (row) => row.value,
            },
        ],
        [],
    );

    const renderOptions = () => {
        if (masterAccounts.length > 0) {
            return null;
        }

        return (
            <>
                <Button pill icon='plus' color='primary' onClick={createModal.open}>
                    Add
                </Button>
                <CreateSatchelPayMasterAccountModal
                    onSuccess={setMasterAccounts}
                    opened={createModal.opened}
                    onClose={createModal.close}
                />
            </>
        );
    };

    const fetchData = useCallback(async () => {
        setLoading(true);

        try {
            const { data } = await api.get('/admin-satchel-pay-integration/master-accounts');

            setMasterAccounts(data);
        } catch (error) {
            toast.error(`Failed to get satchel pay master accounts: ${error.data.message}`);
        } finally {
            setLoading(false);
        }
    }, [api]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    return (
        <SiteWrapper title='SatchelPay master account' options={renderOptions()}>
            <Grid.Row>
                <Grid.Col>
                    <Card>
                        <Card.Body>
                            <ReactTable
                                manual
                                data={masterAccounts}
                                loading={isLoading}
                                columns={columns}
                                className='-striped -highlight'
                                multiSort={false}
                                minRows={2}
                                showPagination={false}
                            />
                        </Card.Body>
                    </Card>
                </Grid.Col>
            </Grid.Row>
        </SiteWrapper>
    );
};

SatchelPayMasterAccount.propTypes = {
    navigate: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            navigate,
        },
        dispatch,
    );

export default connect(null, mapDispatchToProps)(SatchelPayMasterAccount);
