import { createAction } from 'redux-actions';

export const FETCH_MONTHLY_FEE_CONFIGURATIONS_REQUEST = 'fee/FETCH_MONTHLY_FEE_CONFIGURATIONS_REQUEST';
export const FETCH_MONTHLY_FEE_CONFIGURATIONS_SUCCESS = 'fee/FETCH_MONTHLY_FEE_CONFIGURATIONS_SUCCESS';
export const FETCH_MONTHLY_FEE_CONFIGURATIONS_FAILURE = 'fee/FETCH_MONTHLY_FEE_CONFIGURATIONS_FAILURE';

const fetchMonthlyFeeConfigurationsRequest = createAction(FETCH_MONTHLY_FEE_CONFIGURATIONS_REQUEST);
const fetchMonthlyFeeConfigurationsSuccess = createAction(FETCH_MONTHLY_FEE_CONFIGURATIONS_SUCCESS);
const fetchMonthlyFeeConfigurationsFailure = createAction(FETCH_MONTHLY_FEE_CONFIGURATIONS_FAILURE);

export const fetchMonthlyFeeConfigurations = (params) => (dispatch, getState, { api }) =>
    new Promise(async (resolve) => {
        try {
            dispatch(fetchMonthlyFeeConfigurationsRequest());

            const result = await api.get('/admin-fee/monthly-fee-configurations', { params });

            dispatch(fetchMonthlyFeeConfigurationsSuccess(result.data.items));
            resolve(result.data.items);
        } catch (response) {
            dispatch(fetchMonthlyFeeConfigurationsFailure());
        }
    });
