import React, { useCallback, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'tabler-react';
import { createApiClient } from '../../../services/api-client';
import { download } from '../../../services/download';
import { toast } from 'react-toastify';

const DownloadButton = ({ downloadUrl, fileName }) => {
    const [isDocumentDownloading, setDocumentDownloading] = useState(false);

    const api = useMemo(() => createApiClient(), []);

    const onDownload = useCallback(async () => {
        setDocumentDownloading(true);

        try {
            const response = await api.get(downloadUrl, {
                responseType: 'blob',
            });

            download(fileName, response.data);
        } catch (error) {
            toast.error(error.data.message);
        } finally {
            setDocumentDownloading(false);
        }
    }, [api, fileName, downloadUrl]);

    return (
        <Button
            size='sm'
            icon='download-cloud'
            outline
            color='primary'
            onClick={onDownload}
            loading={isDocumentDownloading}
            disabled={isDocumentDownloading}
        />
    );
};

DownloadButton.propTypes = {
    downloadUrl: PropTypes.string.isRequired,
    fileName: PropTypes.string,
};

DownloadButton.defaultProps = {
    fileName: 'file',
};

export default DownloadButton;
