import { handleActions } from 'redux-actions';
import {
    AUTHENTICATE_USER_FAILURE,
    AUTHENTICATE_USER_REQUEST,
    CONFIRM_AUTHENTICATION_FAILURE,
    CONFIRM_AUTHENTICATION_REQUEST,
    CREATE_AND_GET_AUTHENTICATION_FAILURE,
    CREATE_AND_GET_AUTHENTICATION_REQUEST,
} from '../actions/user/authenticate-user';

const setError = (state, page, action, error) => ({ ...state, [page]: { [action]: error } });
const removeError = (state, page) =>
    Object.assign(
        {},
        Object.keys(state).reduce((result, currentValue) => {
            if (currentValue !== page) {
                result[currentValue] = state[currentValue];
            }

            return result;
        }, {}),
    );

export default handleActions(
    {
        ['@@router/LOCATION_CHANGE']: () => {},
        [AUTHENTICATE_USER_REQUEST]: (state) => removeError(state, 'authentication'),
        [AUTHENTICATE_USER_FAILURE]: (state, action) => setError(state, 'authentication', 'authenticate', action.payload),
        [CONFIRM_AUTHENTICATION_REQUEST]: (state) => removeError(state, 'authentication'),
        [CONFIRM_AUTHENTICATION_FAILURE]: (state, action) => setError(state, 'authentication', 'confirm', action.payload),
        [CREATE_AND_GET_AUTHENTICATION_REQUEST]: (state) => removeError(state, 'authentication'),
        [CREATE_AND_GET_AUTHENTICATION_FAILURE]: (state, action) => removeError(state, 'authentication', 'createAndGet', action.payload),
    },
    {},
);
