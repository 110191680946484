import { connectedRouterRedirect } from 'redux-auth-wrapper/history4/redirect';
import locationHelperBuilder from 'redux-auth-wrapper/history4/locationHelper';
import SessionResumePage from '../components/SessionResumePage';

const locationHelper = locationHelperBuilder();

export const requireAuthentication = connectedRouterRedirect({
    redirectPath: '/authentication',
    authenticatedSelector: (state) => state.authentication.isAuthenticated,
    wrapperDisplayName: 'RequireAuthentication',
    authenticatingSelector: (state) => state.authentication.isCheckingAuthentication,
    AuthenticatingComponent: SessionResumePage,
});

export const redirectIfAuthenticated = connectedRouterRedirect({
    redirectPath: (state, ownProps) => locationHelper.getRedirectQueryParam(ownProps) || '/dashboard',
    allowRedirectBack: false,
    authenticatedSelector: (state) => !state.authentication.isAuthenticated && !state.authentication.isCheckingAuthentication,
    wrapperDisplayName: 'RedirectIfAuthenticated',
    authenticatingSelector: (state) => state.authentication.isCheckingAuthentication,
    AuthenticatingComponent: SessionResumePage,
});
