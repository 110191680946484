import React, { Component } from 'react';
import { Card, Dimmer, Table, Text } from 'tabler-react';
import PropTypes from 'prop-types';
import { formatDateAndTime, walletStatus } from '../../../../../helpers';
import { createApiClient as api } from '../../../../../services/api-client';
import { WALLET_TYPES } from '../../../../../constants';

class MainData extends Component {
    constructor (props) {
        super(props);

        this.state = {
            isLoading: true,
            error: null,
            wallet: {
                id: null,
                number: null,
                is_active: null,
                iban: null,
                created_at: null,
                deactivated_at: null,
            },
        };
    }

    componentDidMount () {
        this.fetchWallet();
    }

    fetchWallet () {
        const { wallet_id } = this.props;

        this.setState({
            isLoading: true,
        });

        api()
            .get(`/admin-wallet/wallets/${wallet_id}`)
            .then((data) => {
                this.setState({
                    wallet: data.data,
                    isLoading: false,
                });
            })
            .catch((data) => {
                this.setState({
                    error: data.data.message,
                    isLoading: false,
                });
            });
    }

    renderWalletType (type) {
        if (WALLET_TYPES[type]) {
            return WALLET_TYPES[type];
        }

        return type;
    }

    renderBody () {
        const { wallet, isLoading } = this.state;

        return (
            <>
                <Dimmer active={this.state.isLoading} loader>
                    <Table className='small' striped={true} cards={true}>
                        <Table.Body>
                            <Table.Row>
                                <Table.Col>Wallet number</Table.Col>
                                <Table.Col>{wallet.number}</Table.Col>
                            </Table.Row>

                            <Table.Row>
                                <Table.Col>IBAN</Table.Col>
                                <Table.Col>{wallet.iban}</Table.Col>
                            </Table.Row>

                            <Table.Row>
                                <Table.Col>Provider</Table.Col>
                                <Table.Col>{this.renderWalletType(wallet.type)}</Table.Col>
                            </Table.Row>
                        </Table.Body>
                    </Table>
                </Dimmer>
                {isLoading === false && (
                    <Card.Footer>
                        <Text.Small>
                            Created at {formatDateAndTime(wallet.created_at)}
                            {wallet.deactivated_at !== null && <div>Deactivated at {formatDateAndTime(wallet.deactivated_at)}</div>}
                        </Text.Small>
                    </Card.Footer>
                )}
            </>
        );
    }

    renderError () {
        return (
            <Card.Alert color='danger'>
                <Text.Small>{this.state.error}</Text.Small>
            </Card.Alert>
        );
    }

    render () {
        const { wallet, isLoading, error } = this.state;

        return (
            <Card>
                <Card.Header>
                    <Card.Title>Main wallet data</Card.Title>
                    {isLoading === false && <Card.Options>{walletStatus(wallet.is_active)}</Card.Options>}
                </Card.Header>

                {error ? this.renderError() : this.renderBody()}
            </Card>
        );
    }
}

MainData.propTypes = {
    wallet_id: PropTypes.number.isRequired,
};

export default MainData;
