import { useRef, useEffect, useCallback } from 'react';

export const useDidMount = (callback) => {
    const callbackHandler = useRef(callback);
    callbackHandler.current = callback;

    const setCallbackHandler = useCallback((next) => {
        callbackHandler.current = next;
    }, []);

    useEffect(() => {
        const res = callbackHandler.current && callbackHandler.current();

        return () => typeof res === 'function' && res();
    }, []);

    return setCallbackHandler;
};
