import React from 'react';
import PropTypes from 'prop-types';
import { Table, Button } from 'tabler-react';
import { formatDateAndTime, getFileExtension } from '../../../helpers';
import DownloadButton from '../../Common/Buttons/DownloadButton';
import RemoveButton from '../../Common/Buttons/RemoveButton';
import FilePreview from '../../Common/FilePreview';
import { TRANSACTION_STATUS_MANUAL_APPROVAL } from '../../../constants';
import './Document.scss';

const Document = ({ document, downloadUrl, transactionStatus, onDocumentRemove }) => (
    <Table.Row key={document.id} className='small'>
        <Table.Col>{formatDateAndTime(document.created_at)}</Table.Col>
        <Table.Col>{document.file_name}</Table.Col>
        <Table.Col className='text-capitalize'>{document.uploaded_by_admin ? `Admin (${document.uploader_name})` : 'User'}</Table.Col>
        <Table.Col className='text-right'>
            <Button.List className='actions-wrapper'>
                <DownloadButton downloadUrl={downloadUrl} fileName={document.file_name} />
                <FilePreview downloadUrl={downloadUrl} fileType={getFileExtension(document.file_name)} />
                {
                    transactionStatus === TRANSACTION_STATUS_MANUAL_APPROVAL && (
                        <RemoveButton documentId={document.id} onSuccess={onDocumentRemove} />
                    )
                }
            </Button.List>
        </Table.Col>
    </Table.Row>
);

Document.propTypes = {
    document: PropTypes.object.isRequired,
    downloadUrl: PropTypes.string.isRequired,
    transactionStatus: PropTypes.string.isRequired,
    onDocumentRemove: PropTypes.func.isRequired,
};

export default Document;
