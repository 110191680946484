import React from 'react';
import PropTypes from 'prop-types';
import { Card, Table } from 'tabler-react';
import { capitalize } from '../../../../helpers';

const TransactionScoring = ({ data }) => (
    <Card>
        <Card.Header>
            <Card.Title>Transaction scoring</Card.Title>
        </Card.Header>
        <Table className='small' striped={true} cards={true}>
            <Table.Body>
                <Table.Row>
                    <Table.Col>Alert level</Table.Col>
                    <Table.Col>{data.alert_level !== null ? data.alert_level : '-'}</Table.Col>
                </Table.Row>
                <Table.Row>
                    <Table.Col>Total score</Table.Col>
                    <Table.Col>{data.total_score !== null ? data.total_score : '-'}</Table.Col>
                </Table.Row>
                <Table.Row>
                    <Table.Col>Status</Table.Col>
                    <Table.Col>{data.status !== null ? capitalize(data.status) : '-'}</Table.Col>
                </Table.Row>
            </Table.Body>
        </Table>
    </Card>
);

TransactionScoring.propTypes = {
    data: PropTypes.object.isRequired,
};

export default TransactionScoring;
