import { BAD_REQUEST, IDENTIFICATION_NOT_FOUND, SESSION_NOT_CREATED, APPROVED, SUCCESSFUL, PENDING, FAILED } from './constants';

export const formatOndatoStatus = (status) => {
    switch (status) {
        case BAD_REQUEST:
            return 'Bad Request';
        case IDENTIFICATION_NOT_FOUND:
            return 'Not Found';
        case SESSION_NOT_CREATED:
            return 'Session not created';
        case APPROVED:
            return 'Approved';
        case SUCCESSFUL:
            return 'Successful';
        case PENDING:
            return 'Pending';
        case FAILED:
            return 'Failed';
        default:
            return status;
    }
};

export const getOndatoStatusColor = (status) => {
    if (status === BAD_REQUEST || status === IDENTIFICATION_NOT_FOUND || status === SESSION_NOT_CREATED || status === APPROVED) {
        return 'secondary';
    } else if (status === SUCCESSFUL) {
        return 'success';
    } else if (status === FAILED) {
        return 'danger';
    }

    return 'secondary';
};
