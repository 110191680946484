import { createAction } from 'redux-actions';

export const FETCH_COUNTRIES_REQUEST = 'location/FETCH_COUNTRIES_REQUEST';
export const FETCH_COUNTRIES_SUCCESS = 'location/FETCH_COUNTRIES_SUCCESS';
export const FETCH_COUNTRIES_FAILURE = 'location/FETCH_COUNTRIES_FAILURE';

const fetchCountriesRequest = createAction(FETCH_COUNTRIES_REQUEST);
const fetchCountriesSuccess = createAction(FETCH_COUNTRIES_SUCCESS);
const fetchCountriesFailure = createAction(FETCH_COUNTRIES_FAILURE);

export const fetchCountries = () => (dispatch, getState, { api }) =>
    new Promise(async (resolve) => {
        try {
            if (getState().location.countries.length !== 0) {
                resolve(getState().location.countries);
                return;
            }

            dispatch(fetchCountriesRequest());

            const result = await api.get('/admin-location/countries');

            dispatch(fetchCountriesSuccess(result.data));
            resolve(result.data);
        } catch (response) {
            dispatch(fetchCountriesFailure());
        }
    });
