import React from 'react';
import { List } from 'tabler-react';

interface IProps {
    components: Record<
        string,
        {
            title: string;
            icon: string;
        }
    >;
    currentComponent: string;
    handleComponentChange: (component: string) => () => void;
}

const SidebarNavigation = ({ currentComponent, handleComponentChange, components }: IProps) => (
    <List.Group>
        {Object.entries(components).map(([name, component]) => (
            <div key={name} onClick={handleComponentChange(name)}>
                <List.GroupItem
                    className='d-flex align-items-center btn mb-1'
                    icon={component.icon}
                    action
                    onClick={handleComponentChange(name)}
                    active={currentComponent === name}
                >
                    {component.title}
                </List.GroupItem>
            </div>
        ))}
    </List.Group>
);

export default SidebarNavigation;
