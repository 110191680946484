import React, { useState, useMemo } from 'react';
import PaginatedFilteredList from '../../../Common/PaginatedFilteredList';
import { userStatus } from '../../../../helpers';
import { Link } from 'react-router-dom';
import {
    DOCUMENT_VERIFICATION_STATUSES,
    IDENTITY_VERIFICATION_STATUSES,
    QUESTIONNAIRE_VERIFICATION_STATUSES,
} from '../../../../constants';

const URL = '/admin-user/users/pending-verification';

const PendingVerificationList = () => {
    const [items, setItems] = useState([]);

    const nameCell = ({ original }) => {
        if (original.type === 'business') {
            return original.company_name;
        }

        return `${original.first_name} ${original.last_name}`;
    };

    const renderIdentityCell = ({ original }) => {
        if (IDENTITY_VERIFICATION_STATUSES.hasOwnProperty(original.identity_verification_status)) {
            return IDENTITY_VERIFICATION_STATUSES[original.identity_verification_status];
        }

        return '-';
    };

    const renderQuestionnaireCell = ({ original }) => {
        if (QUESTIONNAIRE_VERIFICATION_STATUSES.hasOwnProperty(original.questionnaire_verification_status)) {
            return QUESTIONNAIRE_VERIFICATION_STATUSES[original.questionnaire_verification_status];
        }

        return '-';
    };

    const renderDocumentsCell = ({ original }) => {
        if (DOCUMENT_VERIFICATION_STATUSES.hasOwnProperty(original.document_verification_status)) {
            return DOCUMENT_VERIFICATION_STATUSES[original.document_verification_status];
        }

        return '-';
    };

    const columns = useMemo(
        () => [
            {
                Header: 'Name',
                Cell: (row) => <Link to={{ pathname: `/compliance/users/${row.original.id}` }}>{nameCell(row)}</Link>,
            },
            {
                Header: 'User status',
                accessor: 'is_verified',
                Cell: (row) => userStatus(row.value),
            },
            {
                Header: 'Identity',
                accessor: 'identity_verification_status',
                Cell: renderIdentityCell,
            },
            {
                Header: 'Questionnaire',
                accessor: 'questionnaire_verification_status',
                Cell: renderQuestionnaireCell,
            },
            {
                Header: 'Documents',
                accessor: 'document_verification_status',
                Cell: renderDocumentsCell,
            },
        ],
        []
    );

    const isConfirmed = (status) => status === 'confirmed';

    const filteredItems = useMemo(() =>
        items.filter((item) => !(
            item.is_verified
            && isConfirmed(item.identity_verification_status)
            && isConfirmed(item.questionnaire_verification_status)
            && isConfirmed(item.document_verification_status)
        )),
    [items],
    );

    return (
        <PaginatedFilteredList
            updateItems={setItems}
            uri={URL}
            columns={columns}
            items={filteredItems}
        />
    );
};

export default PendingVerificationList;
