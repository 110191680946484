import React, { Component } from 'react';
import { Icon } from 'tabler-react';
import PropTypes from 'prop-types';

class Bool extends Component {
    render () {
        const { answer } = this.props;

        if (answer === true) {
            return <Icon prefix='fe' name='check-square' />;
        }

        return <Icon prefix='fe' name='square' />;
    }
}

Bool.propTypes = {
    answer: PropTypes.bool,
    field: PropTypes.object.isRequired,
};

export default Bool;
