import React, { Component } from 'react';
import SiteWrapper from '../../SiteWrapper';
import { capitalize, formatDateAndTime, objectToOptions, userStatus } from '../../../helpers';
import PaginatedFilteredList from '../../Common/PaginatedFilteredList';
import {
    DOCUMENT_VERIFICATION_STATUSES,
    FILTER_MASKS,
    IDENTITY_VERIFICATION_STATUSES,
    QUESTIONNAIRE_VERIFICATION_STATUSES,
} from '../../../constants';
import { Link } from 'react-router-dom';

class Users extends Component {
    constructor (props) {
        super(props);

        this.state = {
            items: [],
            error: null,
            isLoading: false,
        };

        this.uri = '/admin-user/users';

        this.columns = [
            {
                Header: 'Created at',
                accessor: 'created_at',
                Cell: (row) => formatDateAndTime(row.value),
                width: 130,
            },
            {
                Header: 'Name',
                sortable: false,
                Cell: (row) => <Link to={{ pathname: `/compliance/users/${row.original.id}` }}>{this.renderNameCell(row)}</Link>,
            },
            {
                Header: 'Status',
                accessor: 'is_verified',
                Cell: (row) => userStatus(row.value),
                width: 100,
            },
            {
                Header: 'Identity',
                accessor: 'identity_verification_status',
                sortable: false,
                Cell: this.renderIdentityCell,
            },
            {
                Header: 'Questionnaire',
                accessor: 'questionnaire_verification_status',
                sortable: false,
                Cell: this.renderQuestionnaireCell,
            },
            {
                Header: 'Documents',
                accessor: 'document_verification_status',
                sortable: false,
                Cell: this.renderDocumentsCell,
            },
            {
                Header: 'Type',
                accessor: 'type',
                sortable: false,
                Cell: (row) => capitalize(row.value),
                width: 100,
            },
            {
                Header: 'Email',
                accessor: 'email',
                sortable: false,
            },
            {
                Header: 'Risk level',
                accessor: 'risk_level',
                sortable: false,
                Cell: (row) => this.renderRiskLevelCell(row),
                width: 100,
            },
        ];

        this.filterFields = [
            {
                title: 'Created from',
                name: 'created_at_from',
                type: 'text',
                placeholder: FILTER_MASKS.date.placeholder,
                mask: FILTER_MASKS.date.mask,
            },
            {
                title: 'Created to',
                name: 'created_at_to',
                type: 'text',
                placeholder: FILTER_MASKS.date.placeholder,
                mask: FILTER_MASKS.date.mask,
            },
            {
                title: 'Company name',
                name: 'company_name',
                type: 'text',
            },
            {
                title: 'First name',
                name: 'first_name',
                type: 'text',
            },
            {
                title: 'Last name',
                name: 'last_name',
                type: 'text',
            },
            {
                title: 'Email',
                name: 'email',
                type: 'text',
            },
            {
                title: 'Identity',
                name: 'identity_verification_status',
                type: 'select',
                options: objectToOptions(IDENTITY_VERIFICATION_STATUSES),
            },
            {
                title: 'Questionnaire',
                name: 'questionnaire_verification_status',
                type: 'select',
                options: objectToOptions(QUESTIONNAIRE_VERIFICATION_STATUSES),
            },
            {
                title: 'Documents',
                name: 'document_verification_status',
                type: 'select',
                options: objectToOptions(DOCUMENT_VERIFICATION_STATUSES),
            },
            {
                title: 'Type',
                name: 'type',
                type: 'select',
                options: [
                    {
                        title: 'Business',
                        value: 'business',
                    },
                    {
                        title: 'Private',
                        value: 'private',
                    },
                ],
            },
            {
                title: 'Risk level',
                name: 'risk_level',
                type: 'select',
                options: [
                    {
                        title: 'Low',
                        value: 'low',
                    },
                    {
                        title: 'Medium',
                        value: 'medium',
                    },
                    {
                        title: 'High',
                        value: 'high',
                    },
                ],
            },
            {
                title: 'Verified',
                name: 'is_verified',
                type: 'select',
                options: [
                    {
                        title: 'Yes',
                        value: 1,
                    },
                    {
                        title: 'No',
                        value: 0,
                    },
                ],
            },
        ];

        this.updateItems = this.updateItems.bind(this);
    }

    renderNameCell (row) {
        if (row.original.type === 'business') {
            return row.original.company_name;
        }

        return `${row.original.first_name} ${row.original.last_name}`;
    }

    renderRiskLevelCell (row) {
        return capitalize(row.original.risk_level);
    }

    renderIdentityCell (row) {
        if (IDENTITY_VERIFICATION_STATUSES.hasOwnProperty(row.original.identity_verification_status)) {
            return IDENTITY_VERIFICATION_STATUSES[row.original.identity_verification_status];
        }

        return '-';
    }

    renderQuestionnaireCell (row) {
        if (QUESTIONNAIRE_VERIFICATION_STATUSES.hasOwnProperty(row.original.questionnaire_verification_status)) {
            return QUESTIONNAIRE_VERIFICATION_STATUSES[row.original.questionnaire_verification_status];
        }

        return '-';
    }

    renderDocumentsCell (row) {
        if (DOCUMENT_VERIFICATION_STATUSES.hasOwnProperty(row.original.document_verification_status)) {
            return DOCUMENT_VERIFICATION_STATUSES[row.original.document_verification_status];
        }

        return '-';
    }

    updateItems (items) {
        this.setState({
            items,
        });
    }

    render () {
        return (
            <SiteWrapper title='Users'>
                <PaginatedFilteredList
                    uri={this.uri}
                    columns={this.columns}
                    filterFields={this.filterFields}
                    updateItems={this.updateItems}
                    items={this.state.items}
                    isLoading={this.state.isLoading}
                    parentError={this.state.error}
                />
            </SiteWrapper>
        );
    }
}

export default Users;
