import React, { Component } from 'react';
import { auditColumns } from '../../../../../utils/columns/audit-columns';
import { objectToOptions } from '../../../../../helpers';
import PaginatedFilteredList from '../../../../Common/PaginatedFilteredList';
import { AUDIT_TYPES, FILTER_MASKS, USER_VERIFICATION_TYPES } from '../../../../../constants';
import PropTypes from 'prop-types';
import { Header } from 'tabler-react';
import moment from 'moment';

class Audit extends Component {
    constructor (props) {
        super(props);

        this.state = {
            items: [],
            error: null,
            isLoading: false,
        };

        this.uri = `/admin-audit/users/${props.match.params.id}/audit-records`;

        this.filterFields = [
            {
                title: 'Date from',
                name: 'audited_at_from',
                type: 'text',
                placeholder: FILTER_MASKS.date.placeholder,
                mask: FILTER_MASKS.date.mask,
                value: moment().format('YYYY-MM-DD'),
            },
            {
                title: 'Date to',
                name: 'audited_at_to',
                type: 'text',
                placeholder: FILTER_MASKS.date.placeholder,
                mask: FILTER_MASKS.date.mask,
            },
            {
                title: 'Event type',
                name: 'type',
                type: 'select',
                options: objectToOptions(AUDIT_TYPES),
            },
        ];

        this.updateItems = this.updateItems.bind(this);
    }

    resolveAuditType (type) {
        if (AUDIT_TYPES.hasOwnProperty(type)) {
            return AUDIT_TYPES[type];
        }

        if (USER_VERIFICATION_TYPES.hasOwnProperty(type)) {
            return USER_VERIFICATION_TYPES[type];
        }

        return type;
    }

    updateItems (items) {
        this.setState({
            items,
        });
    }

    render () {
        return (
            <>
                <Header.H3>Audit log</Header.H3>

                <PaginatedFilteredList
                    uri={this.uri}
                    columns={auditColumns}
                    filterFields={this.filterFields}
                    updateItems={this.updateItems}
                    items={this.state.items}
                    isLoading={this.state.isLoading}
                    parentError={this.state.error}
                    sortBy='audited_at'
                    showFilter={true}
                />
            </>
        );
    }
}

Audit.propTypes = {
    match: PropTypes.object,
};

export default Audit;
