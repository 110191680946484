import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Route, Switch } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import { Form, Grid } from 'tabler-react';
import Transactions from '../Transactions';
import SiteWrapper from '../../SiteWrapper';
import TransactionDocumentation from './ActionsList/TransactionDocumentations';
import { navigate } from '../../../actions/navigate';

import PendingVerificationList from './ActionsList/PendingVerificationList';
import ActionListTab from './ActionListTab';
import { ACTION_LIST_TABS } from '../../../constants';

const PATH_PREFIX = '/compliance/actions-list';

const ActionsList = ({ match: { path }, history, navigate }) => {
    const changeListType = (event) => {
        const { target: { value } } = event;

        navigate(`${PATH_PREFIX}/${value}`);
    };

    const predefinedFilters = {
        status: 'manual_approval',
    };

    const renderListSelection = () => (
        <Grid.Row>
            <Grid.Col width={6}>
                <Form.Group>
                    <Form.SelectGroup>
                        {ACTION_LIST_TABS.map((tab) => (
                            <ActionListTab
                                name='listType'
                                key={tab.value}
                                value={tab.value}
                                label={tab.label}
                                onChange={changeListType}
                                selected={path === `${PATH_PREFIX}/${tab.value}`}
                            />
                        ))}
                    </Form.SelectGroup>
                </Form.Group>
            </Grid.Col>
        </Grid.Row>
    );

    return (
        <SiteWrapper title='Actions List'>
            {renderListSelection()}
            <ConnectedRouter history={history}>
                <Switch>
                    <Route
                        exact
                        path={`${PATH_PREFIX}/pending-verification`}
                        component={PendingVerificationList}
                    />
                    <Route
                        exact
                        path={`${PATH_PREFIX}/pending-transactions`}
                        render={() => <Transactions renderOnlyList={true} predefinedFilters={predefinedFilters}/>}
                    />
                    <Route
                        exact
                        path={`${PATH_PREFIX}/transaction-documentation`}
                        component={TransactionDocumentation}
                    />
                </Switch>
            </ConnectedRouter>
        </SiteWrapper>
    );
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            navigate,
        },
        dispatch,
    );

export default connect(null, mapDispatchToProps)(ActionsList);
