import React from 'react';
import * as Sentry from '@sentry/browser';

import { render } from 'react-dom';
import store, { history } from './store/configure-store';
import App from './components/App';
import 'tabler-react/dist/Tabler.css';
import { config } from './utils/config';

if (config.sentry.environment) {
    Sentry.init(config.sentry);
}

render(<App store={store} history={history} />, document.getElementById('app'));
