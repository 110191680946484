import React, { useState } from 'react';
import { Button, Dimmer, Form, Icon } from 'tabler-react';
import Modal from 'react-modal';
import PropTypes from 'prop-types';
import AmountField from '../../../Common/FilterFormFields/AmountField';
import { createApiClient as api } from '../../../../services/api-client';
import { toast } from 'react-toastify';
import { formatAmount } from '../../../../helpers';

const MarkupUpdateModal = ({ onModalClose, onUpdated, markupFee }) => {
    const handleCreationSubmit = (event) => {
        event.preventDefault();

        setIsloading(true);

        api()
            .put(`/admin-currency-exchange/markup-fee-configurations/${markupFee.id}`, {
                markup_amount: markupAmount,
            })
            .then(() => {
                toast.success('Updated successful');
                onUpdated();
            })
            .catch(({ data }) => {
                toast.error(data.message);
            })
            .finally(() => {
                onModalClose();
            });
    };

    const [markupAmount, setMarkupAmount] = useState(formatAmount(markupFee.markup_amount));
    const [isLoading, setIsloading] = useState(false);

    return (
        <Modal isOpen className='react-modal w-450'>
            <div className='react-modal__content'>
                <Icon name='x-circle' className='w-4 react-modal__closeButton' onClick={onModalClose} />
                <Form onSubmit={handleCreationSubmit}>
                    <Dimmer active={isLoading} loader>
                        <AmountField
                            name='markup_amount'
                            title='Markup Amount'
                            value={markupAmount}
                            handleChange={({ target }) => setMarkupAmount(target.value)}
                            maxAmount={5}
                        />
                        <Button type='submit' color='primary' className='w-100' disabled={markupAmount < 0.01}>
                            Update
                        </Button>
                    </Dimmer>
                </Form>
            </div>
        </Modal>
    );
};

MarkupUpdateModal.propTypes = {
    onModalClose: PropTypes.func,
    onUpdated: PropTypes.func,
    markupFee: PropTypes.object,
};

export default MarkupUpdateModal;
