import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { Card, Header, Text, Button } from 'tabler-react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createApiClient as api } from '../../../../services/api-client';
import Wallet from './Wallet';
import CreateWalletModal from '../CreateWalletModal';
import { fetchDefaultWalletLimits } from '../../../../actions/limits/default-wallet';

class Wallets extends Component {
    constructor (props) {
        super(props);

        this.state = {
            isLoading: false,
            items: [],
            error: null,
            isCreateWalletModalVisible: false,
        };
    }

    componentDidMount () {
        this.fetchWallets();
        this.props.fetchDefaultWalletLimitsAction();
    }

    fetchWallets = () => {
        const { id } = this.props.match.params;

        this.setState({
            isLoading: true,
            error: null,
        });

        api()
            .get(`/admin-user/users/${id}/wallets`)
            .then((data) => {
                this.setState({
                    items: data.data,
                    isLoading: false,
                });
            })
            .catch((data) => {
                this.setState({
                    error: data.data.additional_data,
                    isLoading: false,
                });
            });
    };

    renderError () {
        return (
            <Card.Alert color='secondary'>
                <Text.Small>{this.state.error}</Text.Small>
            </Card.Alert>
        );
    }

    renderBody () {
        const { isChargeModalButtonVisible, user } = this.props;

        return this.state.items.map((wallet) => (
            <Wallet
                key={wallet.id}
                wallet={wallet}
                user={user}
                defaultWalletLimits={this.props.defaultWalletLimits}
                isChargeModalButtonVisible={isChargeModalButtonVisible}
            />
        ));
    }

    setCreateWalletModalVisible = (value) => () => {
        this.setState({
            isCreateWalletModalVisible: value,
        });
    };

    render () {
        const { error, isCreateWalletModalVisible } = this.state;
        const { user } = this.props;

        return (
            <>
                <Header.H3>
                    Wallets
                    {user.is_verified && (
                        <Button
                            size='sm'
                            className='ml-3'
                            pill
                            icon='plus'
                            color='primary'
                            onClick={this.setCreateWalletModalVisible(true)}
                        >
                            Create new
                        </Button>
                    )}
                </Header.H3>
                {error ? this.renderError() : this.renderBody()}
                {user && user.id && (
                    <CreateWalletModal
                        selectedUserId={user.id}
                        visible={isCreateWalletModalVisible}
                        onWalletCreate={this.fetchWallets}
                        onCloseModal={this.setCreateWalletModalVisible(false)}
                    />
                )}
            </>
        );
    }
}

Wallets.propTypes = {
    defaultWalletLimits: PropTypes.array,
    isChargeModalButtonVisible: PropTypes.bool,
    match: PropTypes.object,
    user: PropTypes.object.isRequired,
    fetchDefaultWalletLimitsAction: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            fetchDefaultWalletLimitsAction: fetchDefaultWalletLimits,
        },
        dispatch,
    );

const mapStateToProps = (state) => ({
    defaultWalletLimits: state.limits.defaultWallet,
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Wallets));
