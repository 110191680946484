export const resolvePermissions = (items, permissions) => {
    if (typeof permissions === 'undefined') {
        return items;
    }

    return items.filter((item) => {
        if (!Object.hasOwnProperty.call(item, 'permission')) {
            return true;
        }

        return permissions && permissions.map((permission) => permission.key).includes(item.permission);
    });
};
