import { createAction } from 'redux-actions';
import isEmpty from 'lodash/isEmpty';
import omitBy from 'lodash/omitBy';
import sortBy from 'lodash/sortBy';

export const FETCH_DOCUMENT_TYPES_REQUEST = 'document-type/FETCH_DOCUMENT_TYPES_REQUEST';
export const FETCH_DOCUMENT_TYPES_SUCCESS = 'document-type/FETCH_DOCUMENT_TYPES_SUCCESS';
export const FETCH_DOCUMENT_TYPES_FAILURE = 'document-type/FETCH_DOCUMENT_TYPES_FAILURE';

const fetchDocumentTypesRequest = createAction(FETCH_DOCUMENT_TYPES_REQUEST);
const fetchDocumentTypesSuccess = createAction(FETCH_DOCUMENT_TYPES_SUCCESS);
const fetchDocumentTypesFailure = createAction(FETCH_DOCUMENT_TYPES_FAILURE);

export const fetchDocumentTypes = (filter) => (dispatch, getState, { api }) =>
    new Promise(async (resolve) => {
        try {
            dispatch(fetchDocumentTypesRequest());

            const result = await api.get('/admin-user-document-verification/document-types', {
                params: {
                    filter: omitBy(filter, isEmpty),
                },
            });

            const documentTypes = sortBy(result.data, (documentType) => documentType.document_type !== 'mandatory');

            dispatch(fetchDocumentTypesSuccess(documentTypes));

            resolve();
        } catch (response) {
            dispatch(fetchDocumentTypesFailure());
        }
    });
