import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Header, Icon } from 'tabler-react';
import moment from 'moment';
import PaginatedFilteredList from '../../../Common/PaginatedFilteredList';
import { formatDateAndTime, objectToOptions, uppercase } from '../../../../helpers';
import { ADMIN_AUDIT_FILTER_TYPES, FILTER_MASKS, ADMIN_AUDIT_ALL_TYPES } from '../../../../constants';
import ReactTooltip from 'react-tooltip';

const AdminAudit = ({ adminId }) => {
    const [items, setItems] = useState([]);

    const uri = useMemo(() => `admin-audit/admins/${adminId}/audit-records`, [adminId]);

    const columns = useMemo(
        () => [
            {
                Header: 'Date/time',
                accessor: 'audited_at',
                Cell: (row) => formatDateAndTime(row.value),
                width: 150,
            },
            {
                Header: 'Type',
                accessor: 'type',
                sortable: false,
                Cell: (row) => resolveAdminAuditType(row.value),
                width: 150,
            },
            {
                Header: 'Activity',
                accessor: 'activity',
                sortable: false,
            },
            {
                Header: 'Country',
                accessor: 'country',
                sortable: false,
                Cell: (row) => (
                    <div
                        data-tip={`${row.value ? uppercase(row.original.country) : 'N / A'} <br /> 
                        ${row.original.ip} <br /> 
                        ${row.original.browser} <br /> 
                        ${row.original.os}`}
                    >
                        {row.value ? <Icon prefix='flag' name={row.value} /> : 'N / A'}
                        <ReactTooltip className='react-tooltip' multiline={true} />
                    </div>
                ),
                width: 150,
            },
            {
                Header: 'User name',
                accessor: 'user_name',
                sortable: false,
                Cell: (row) => (row.value ? row.value : '-'),
                width: 150,
            },
            {
                Header: 'User email',
                accessor: 'user_email',
                sortable: false,
                Cell: (row) => (row.value ? row.value : '-'),
                width: 150,
            },
        ],
        [],
    );

    const filterFields = useMemo(
        () => [
            {
                title: 'Date from',
                name: 'audited_at_from',
                type: 'text',
                placeholder: FILTER_MASKS.date.placeholder,
                mask: FILTER_MASKS.date.mask,
                value: moment().format('YYYY-MM-DD'),
            },
            {
                title: 'Date to',
                name: 'audited_at_to',
                type: 'text',
                placeholder: FILTER_MASKS.date.placeholder,
                mask: FILTER_MASKS.date.mask,
            },
            {
                title: 'Event type',
                name: 'type',
                type: 'select',
                options: objectToOptions(ADMIN_AUDIT_FILTER_TYPES),
            },
        ],
        [],
    );

    const resolveAdminAuditType = (type) => {
        if (ADMIN_AUDIT_ALL_TYPES.hasOwnProperty(type)) {
            return ADMIN_AUDIT_ALL_TYPES[type];
        }

        return type;
    };

    const updateItems = (items) => setItems(items);

    return (
        <>
            <Header.H3>Admin audit</Header.H3>

            <PaginatedFilteredList
                uri={uri}
                columns={columns}
                filterFields={filterFields}
                updateItems={updateItems}
                items={items}
                sortBy='audited_at'
                showFilter={true}
            />
        </>
    );
};

AdminAudit.propTypes = {
    adminId: PropTypes.number.isRequired,
};

export default AdminAudit;
