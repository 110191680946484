import React, { Component } from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { createApiClient } from '../../../../../services/api-client';
import { fetchPermissionGroups } from '../../../../../actions/permission-groups/groups';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

class GroupsSelectBox extends Component {
    constructor (props) {
        super(props);

        this.state = {
            groups: [],
            selected: [],
        };

        this.api = createApiClient();

        this.onChange = this.onChange.bind(this);
    }

    async componentDidMount () {
        await this.props.fetchPermissionGroups();
    }

    onChange (selected) {
        let mapped = [];

        if (selected !== null) {
            mapped = selected.map((item) => item.value);
        }

        const { onInputChange } = this.props;

        onInputChange({
            target: {
                name: 'permission_groups',
                value: mapped,
            },
        });
    }

    options () {
        const { groups } = this.props;

        return groups.map((group) => ({
            value: group.id,
            label: group.name,
        }));
    }

    renderValues (groups, selected) {
        const selectedGroups = [];

        groups.map((group) => {
            if (selected.includes(group.id)) {
                selectedGroups.push({
                    value: group.id,
                    label: group.name,
                });
            }

            return group;
        });

        return selectedGroups;
    }

    render () {
        const { groups, assignedGroups } = this.props;

        return (
            <div className='form-group'>
                <label className='form-label'>Groups</label>
                <Select
                    className='multi-select'
                    isSearchable={true}
                    isMulti={true}
                    onChange={this.onChange}
                    options={this.options()}
                    value={this.renderValues(groups, assignedGroups)}
                />
            </div>
        );
    }
}

GroupsSelectBox.propTypes = {
    groups: PropTypes.array.isRequired,
    fetchPermissionGroups: PropTypes.func.isRequired,
    onInputChange: PropTypes.func.isRequired,
    assignedGroups: PropTypes.array,
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            fetchPermissionGroups,
        },
        dispatch,
    );

const mapStateToProps = (state) => ({
    groups: state.groupAndPermissions.groups,
});

export default connect(mapStateToProps, mapDispatchToProps)(GroupsSelectBox);
