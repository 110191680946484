import React, { useState, useCallback, useMemo } from 'react';
import Modal from 'react-modal';
import PropTypes from 'prop-types';
import pickBy from 'lodash/pickBy';
import { Button, Icon } from 'tabler-react';
import InputTextField from '../../../Common/FilterFormFields/InputTextField';
import { createApiClient } from '../../../../services/api-client';
import ApiError from '../../../Common/ApiError';

const DocumentRequestReasonModal = ({ onModalClose, modalIsOpen, updateCurrentTransactionDocumentRequest, transactionId }) => {
    const [requestReason, setRequestReason] = useState('');
    const [error, setError] = useState(null);
    const [isLoading, setLoading] = useState(false);

    const api = useMemo(() => createApiClient(), []);

    const handleModalClose = useCallback(() => {
        setRequestReason('');
        onModalClose();
    }, [onModalClose]);

    const createTransactionDocumentRequest = useCallback(async () => {
        const params = { reason: requestReason };

        setLoading(true);
        setError(null);

        try {
            const { data } = await api.post(`admin-transaction-document/transactions/${transactionId}/document-requests`, pickBy(params));

            updateCurrentTransactionDocumentRequest(data);
            handleModalClose();
        } catch (error) {
            setError(error.data);
        } finally {
            setLoading(false);
        }
    }, [api, handleModalClose, requestReason, transactionId, updateCurrentTransactionDocumentRequest]);

    const handleRequestReasonChange = (event) => setRequestReason(event.target.value);

    return (
        <Modal isOpen={modalIsOpen} className='react-modal'>
            <Icon name='x-circle' className='w-4 react-modal__closeButton' onClick={onModalClose} />

            <ApiError error={error} />
            <div className='react-modal__content'>
                <h2 className='text-center'>Request reason</h2>
                <h5 className='pt-4 pb-4 text-info'>This request reason will be shown for the user</h5>
                <InputTextField
                    name='requestReason'
                    title='Please input reason for document request:'
                    value={requestReason}
                    handleChange={handleRequestReasonChange}
                />
                <Button
                    block
                    color='primary'
                    onClick={createTransactionDocumentRequest}
                    disabled={isLoading}
                    loading={isLoading}
                >
                    Request
                </Button>
            </div>
        </Modal>
    );
};

DocumentRequestReasonModal.propTypes = {
    modalIsOpen: PropTypes.bool.isRequired,
    onModalClose: PropTypes.func.isRequired,
    updateCurrentTransactionDocumentRequest: PropTypes.func.isRequired,
    transactionId: PropTypes.string.isRequired,
};

export default DocumentRequestReasonModal;
