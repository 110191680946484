import { createAction } from 'redux-actions';

export const FETCH_FEE_WALLETS_RELATIONS_REQUEST = 'fee/FETCH_FEE_WALLETS_RELATIONS_REQUEST';
export const FETCH_FEE_WALLETS_RELATIONS_SUCCESS = 'fee/FETCH_FEE_WALLETS_RELATIONS_SUCCESS';
export const FETCH_FEE_WALLETS_RELATIONS_FAILURE = 'fee/FETCH_FEE_WALLETS_RELATIONS_FAILURE';

const fetchFeeWalletRelationsRequest = createAction(FETCH_FEE_WALLETS_RELATIONS_REQUEST);
const fetchFeeWalletRelationsSuccess = createAction(FETCH_FEE_WALLETS_RELATIONS_SUCCESS);
const fetchFeeWalletRelationsFailure = createAction(FETCH_FEE_WALLETS_RELATIONS_FAILURE);

export const fetchFeeWalletRelations = () => (dispatch, getState, { api }) =>
    new Promise(async (resolve) => {
        try {
            dispatch(fetchFeeWalletRelationsRequest());

            const result = await api.get('admin-fee/fee-wallet-relations');

            dispatch(fetchFeeWalletRelationsSuccess(result.data));
            resolve(result.data);
        } catch (response) {
            dispatch(fetchFeeWalletRelationsFailure());
        }
    });
