import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Country from './Country';

class CountryList extends Component {
    render () {
        const { answer } = this.props;

        return answer.map((key) => (
            <div key={key}>
                <Country answer={key} />
            </div>
        ));
    }
}

CountryList.propTypes = {
    answer: PropTypes.array.isRequired,
};

export default CountryList;
