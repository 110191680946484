import React, { Component } from 'react';
import PropTypes from 'prop-types';
import 'react-table/react-table.css';
import { Dimmer } from 'tabler-react';
import SiteWrapper from '../../../SiteWrapper';
import { createApiClient } from '../../../../services/api-client';
import CreateGroupModal from './Misc/CreateGroupModal';
import Groups from './Misc/Groups';
import './PermissionGroups.scss';
import { bindActionCreators } from 'redux';
import { fetchPermissionGroups } from '../../../../actions/permission-groups/groups';
import { connect } from 'react-redux';

class PermissionGroups extends Component {
    constructor (props) {
        super(props);

        this.state = {
            isLoadingGlobally: false,
            idOfLoadingGroupPermissions: null,
            permissions: [],
        };

        this.api = createApiClient();
        this.fetchPermissionGroupsAndPermissions = this.fetchPermissionGroupsAndPermissions.bind(this);
    }

    componentDidMount () {
        this.fetchPermissionGroupsAndPermissions();
    }

    async fetchPermissionGroupsAndPermissions () {
        this.setState({
            isLoadingGlobally: true,
        });

        await this.props.fetchPermissionGroups();
        await this.fetchPermissions();

        this.setState({
            isLoadingGlobally: false,
        });
    }

    fetchPermissions () {
        this.api.get('/admin-permission/permissions').then((response) => {
            this.setState({
                permissions: response.data,
            });
        });
    }

    render () {
        const { isLoadingGlobally, permissions } = this.state;
        const { groups } = this.props;

        return (
            <SiteWrapper
                title='Roles and permissions'
                options={<CreateGroupModal successCallback={this.fetchPermissionGroupsAndPermissions} />}
            >
                <Dimmer loader active={isLoadingGlobally && groups.length === 0} className='roles-loader'></Dimmer>

                <Groups groups={groups} permissions={permissions} />
            </SiteWrapper>
        );
    }
}

PermissionGroups.propTypes = {
    fetchPermissionGroups: PropTypes.func.isRequired,
    groups: PropTypes.array.isRequired,
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            fetchPermissionGroups,
        },
        dispatch,
    );

const mapStateToProps = (state) => ({
    groups: state.groupAndPermissions.groups,
});

export default connect(mapStateToProps, mapDispatchToProps)(PermissionGroups);
