import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormTextInput, Form, Card, Button } from 'tabler-react';
import { connect } from 'react-redux';
import jwtDecode from 'jwt-decode';
import { bindActionCreators } from 'redux';
import ApiError from '../Common/ApiError';
import { getError } from '../../selectors/getError';
import { isLoading } from '../../selectors/isLoading';
import { authenticateUser } from '../../actions/user/authenticate-user';

class AuthenticationForm extends Component {
    constructor (props) {
        super(props);

        this.state = {
            username: '',
            password: '',
        };

        this.onInputChange = this.onInputChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    onInputChange (event) {
        const { target } = event;

        this.setState({
            [target.name]: target.value,
        });
    }

    onSubmit (event) {
        event.preventDefault();

        this.props.authenticateUser(this.state.username, this.state.password).then((response) => {
            const { scopes } = jwtDecode(response.access_token);

            if (scopes[0] === 'admin:authentication:write') {
                this.props.changeStep('create_second_factory');
            } else if (scopes[0] === 'admin:authenticate') {
                this.props.changeStep('second_factory');
            }
        });
    }

    render () {
        const { username, password } = this.state;

        return (
            <Card>
                <Card.Body>
                    <Card.Title>Login to your Account</Card.Title>

                    <ApiError error={this.props.authenticationError} />

                    <form onSubmit={this.onSubmit}>
                        <FormTextInput
                            name='username'
                            label='Email Address'
                            placeholder='Enter email'
                            value={username}
                            onChange={this.onInputChange}
                        />
                        <FormTextInput
                            name='password'
                            type='password'
                            label='Password'
                            placeholder='Password'
                            value={password}
                            onChange={this.onInputChange}
                        />

                        <Form.Footer>
                            <Button
                                block
                                color='primary'
                                onClick={this.onSubmit}
                                disabled={username === '' || password === '' || this.props.isAuthenticating}
                                loading={this.props.isAuthenticating}
                            >
                                Login
                            </Button>
                        </Form.Footer>
                    </form>
                </Card.Body>
            </Card>
        );
    }
}

AuthenticationForm.propTypes = {
    authenticateUser: PropTypes.func.isRequired,
    isAuthenticating: PropTypes.bool.isRequired,
    authenticationError: PropTypes.object,
    changeStep: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    isAuthenticating: isLoading(state, 'authenticate'),
    authenticationError: getError(state, 'authentication', 'authenticate'),
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            authenticateUser,
        },
        dispatch,
    );

export default connect(mapStateToProps, mapDispatchToProps)(AuthenticationForm);
