import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { navigate } from '../../actions/navigate';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import SiteWrapper from '../SiteWrapper';
import { Button } from 'tabler-react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import 'react-table/react-table.css';
import {
    formatDateAndTime,
    transactionStatus,
    objectToOptions,
    mapToOptions,
    transactionType,
    formatAmountAndCurrency,
} from '../../helpers';
import {
    TRANSACTION_DIRECTIONS,
    TRANSACTION_STATUSES,
    TRANSACTION_TYPES,
    TRANSACTION_PROVIDERS,
    FILTER_MASKS,
    ApiErrorCodes,
} from '../../constants';
import { fetchCurrencies } from '../../actions/currency/currencies';
import PaginatedFilteredList from '../Common/PaginatedFilteredList';
import { createApiClient as api } from '../../services/api-client';
import DeclineTransactionModal from './Transactions/DeclineTransactionModal';
import ErrorTransactionModal from './Transactions/ErrorTransactionModal';
import TransactionDocuments from './Transactions/TransactionDocuments';
import ReactTooltip from 'react-tooltip';
import { getAccountInformation } from '../../selectors/user/get-participants-information';
import { getLabelByCountryCode } from '../../selectors/location/countries';
import { fetchCountries } from '../../actions/location/countries';
import ApiError from '../Common/ApiError';
import { useDidMount } from '../../hooks/useDidMount';

const uri = '/admin-transaction/transactions';
const exportConfig = { uri: '/admin-transaction/download-report/', filename: 'transactions.csv' };

const Transactions = ({ fetchCountries, fetchCurrencies, renderOnlyList, countries, currencies, predefinedFilters }) => {
    const [items, setItems] = useState([]);
    const [activeTransaction, setActiveTransaction] = useState(null);
    const [isDeclineTransactionModalOpen, setDeclineTransactionModalOpen] = useState(false);
    const [approveTransactionError, setApproveTransactionError] = useState(null);
    const [isApproveTransactionErrorModalOpen, setApproveTransactionErrorModalOpen] = useState(false);
    const [transactionDocumentsModalData, setTransactionDocumentsModalData] = useState({
        isOpen: false,
        transactionId: null,
        transactionStatus: null,
    });

    useDidMount(() => {
        fetchCurrencies({ status: 'active' });
    });

    const updateItems = (items) => {
        setItems(items);
    };

    const updateItem = useCallback(
        (item) => {
            const filteredItems = items.map((transaction) => (item.id === transaction.id ? item : transaction));

            setItems(filteredItems);
        },
        [items],
    );

    const setDeclineTransactionModalVisible = useCallback(
        (value, item) => () => {
            if (!value) {
                setActiveTransaction(null);
            } else {
                setActiveTransaction(item);
            }

            setDeclineTransactionModalOpen(value);
        },
        [],
    );

    const setApproveTransactionErrorModalVisible = useCallback((value) => () => setApproveTransactionErrorModalOpen(value), []);

    const setTransactionDocumentsModalDataCallback = useCallback(
        (value, transactionId, transactionStatus) => () =>
            setTransactionDocumentsModalData({ isOpen: value, transactionId, transactionStatus }),
        [],
    );

    const renderTransactionStatus = (item) => {
        if (item.original.fail_reason !== null) {
            return (
                <div data-tip={item.original.fail_reason}>
                    {transactionStatus(item.value)}
                    <span className='icon-fail-reason' />

                    <ReactTooltip className='react-tooltip' />
                </div>
            );
        }

        return transactionStatus(item.value);
    };

    const approveTransaction = useCallback(
        (item) => async () => {
            setApproveTransactionError(null);

            try {
                const { data } = await api().put(`/admin-transaction/transactions/${item.id}/approve`);

                item.status = data.status;
                updateItem(item);
            } catch (exception) {
                setApproveTransactionError(exception.data);
                setApproveTransactionErrorModalVisible(true);
            }
        },
        [setApproveTransactionErrorModalVisible, updateItem],
    );

    const renderList = () => (
        <>
            {approveTransactionError && approveTransactionError.code === ApiErrorCodes.TRANSACTION_NOT_APPROVABLE && (
                <ApiError error={approveTransactionError} />
            )}
            <DeclineTransactionModal
                activeTransaction={activeTransaction}
                modalIsOpen={isDeclineTransactionModalOpen}
                onModalClose={setDeclineTransactionModalVisible(false)}
                updateTransaction={updateItem}
            />
            <ErrorTransactionModal
                error={approveTransactionError}
                modalIsOpen={isApproveTransactionErrorModalOpen}
                onModalClose={setApproveTransactionErrorModalVisible(false)}
            />
            <TransactionDocuments
                isModalOpen={transactionDocumentsModalData.isOpen}
                transactionId={transactionDocumentsModalData.transactionId}
                transactionStatus={transactionDocumentsModalData.transactionStatus}
                onModalClose={setTransactionDocumentsModalDataCallback(false, null, null)}
                isModal={true}
                title='Documents attached to this transaction'
            />
            <PaginatedFilteredList
                uri={uri}
                columns={columns}
                filterFields={filterFields}
                updateItems={updateItems}
                items={items}
                exportConfig={exportConfig}
                renderOnlyList={renderOnlyList}
                predefinedFilters={predefinedFilters}
            />
        </>
    );

    useEffect(() => {
        fetchCountries();
    }, [fetchCountries]);

    const exchangeCurrenciesMap = useMemo(() => currencies.reduce(
        (accumulator, currency) => ({
            ...accumulator,
            [currency.code]: currency.code.toUpperCase(),
        }),
        {}
    ), [currencies]);

    const filterFields = useMemo(
        () => [
            {
                title: 'Transaction number',
                name: 'number',
                type: 'text',
            },
            {
                title: 'Sender name',
                name: 'sender_name',
                type: 'text',
            },
            {
                title: 'Sender account',
                name: 'sender_account',
                type: 'text',
            },
            {
                title: 'Receiver name',
                name: 'receiver_name',
                type: 'text',
            },
            {
                title: 'Receiver account',
                name: 'receiver_account',
                type: 'text',
            },
            {
                title: 'Transaction type',
                name: 'type',
                type: 'select',
                options: objectToOptions(TRANSACTION_TYPES),
            },
            {
                title: 'Provider',
                name: 'provider_keys',
                type: 'select',
                options: mapToOptions(TRANSACTION_PROVIDERS),
            },
            {
                title: 'Direction',
                name: 'direction',
                type: 'select',
                options: objectToOptions(TRANSACTION_DIRECTIONS),
            },
            {
                title: 'Country',
                name: 'country_code',
                type: 'countries',
                status: 'active',
            },
            {
                title: 'Status',
                name: 'status',
                type: 'select',
                options: objectToOptions(TRANSACTION_STATUSES),
            },
            {
                title: 'Exchange currency',
                name: 'exchange_currency',
                type: 'select',
                options: objectToOptions(exchangeCurrenciesMap),
            },
            {
                title: 'From',
                name: 'created_at_from',
                type: 'text',
                placeholder: FILTER_MASKS.date.placeholder,
                mask: FILTER_MASKS.date.mask,
            },
            {
                title: 'To',
                name: 'created_at_to',
                type: 'text',
                placeholder: FILTER_MASKS.date.placeholder,
                mask: FILTER_MASKS.date.mask,
            },
        ],
        [exchangeCurrenciesMap],
    );

    const columns = useMemo(
        () => [
            {
                Header: 'Created at',
                accessor: 'created_at',
                Cell: (row) => formatDateAndTime(row.value),
            },
            {
                Header: 'Number',
                accessor: 'number',
                sortable: false,
                Cell: (row) => <Link to={{ pathname: `/transactions/${row.original.id}` }}>{row.original.number}</Link>,
            },
            {
                Header: 'Sender',
                accessor: 'sender_name',
                sortable: false,
                Cell: (row) =>
                    row.original.sender_user_id !== null ? (
                        <Link to={{ pathname: `/compliance/users/${row.original.sender_user_id}` }}>{row.original.sender_name}</Link>
                    ) : (
                        <span>{row.original.sender_name}</span>
                    ),
            },
            {
                Header: 'Sender account',
                accessor: 'sender_account',
                sortable: false,
                Cell: (row) => getAccountInformation(row.original.sender_account, row.original.sender_wallet),
            },
            {
                Header: 'Receiver',
                accessor: 'receiver_name',
                sortable: false,
                Cell: (row) =>
                    row.original.receiver_user_id !== null ? (
                        <Link to={{ pathname: `/compliance/users/${row.original.receiver_user_id}` }}>{row.original.receiver_name}</Link>
                    ) : (
                        <span>{row.original.receiver_name}</span>
                    ),
            },
            {
                Header: 'Receiver account',
                accessor: 'receiver_account',
                sortable: false,
                Cell: (row) => getAccountInformation(row.original.receiver_account, row.original.receiver_wallet),
            },
            {
                Header: 'Amount',
                id: 'amount',
                Cell: (row) => {
                    if (row.original.exchange_amount) {
                        return <>
                            <div>{formatAmountAndCurrency(row.original.exchange_amount, row.original.exchange_currency)}</div>
                            <div>({formatAmountAndCurrency(row.original.amount, row.original.currency)})</div>
                        </>;
                    }

                    return formatAmountAndCurrency(row.original.amount, row.original.currency);
                },
            },
            {
                Header: 'Amount',
                id: 'chf_amount',
                Cell: (row) => formatAmountAndCurrency(row.original.chf_amount, 'chf'),
            },
            {
                Header: 'Fee Amount',
                id: 'fee_amount',
                sortable: false,
                Cell: (row) => formatAmountAndCurrency(row.original.fee_amount, row.original.currency),
            },
            {
                Header: 'Fee Amount',
                id: 'chf_fee_amount',
                Cell: (row) => formatAmountAndCurrency(row.original.chf_fee_amount, 'chf'),
            },
            {
                Header: 'Details',
                accessor: 'details',
                sortable: false,
            },
            {
                Header: 'Country',
                sortable: false,
                Cell: (row) =>
                    ['internal_transfer', 'between_accounts'].includes(row.original.type)
                        ? ''
                        : getLabelByCountryCode(countries, row.original.country_code),
            },
            {
                Header: 'Direction',
                accessor: 'direction',
                Cell: (row) => transactionType(row.value),
                sortable: false,
            },
            {
                Header: 'Type',
                accessor: 'type',
                Cell: (row) => transactionType(row.value),
                sortable: false,
            },
            {
                Header: 'Status',
                accessor: 'status',
                Cell: (row) => renderTransactionStatus(row),
                sortable: false,
            },
            {
                Header: 'Actions',
                id: 'actions',
                sortable: false,
                width: 150,
                Cell: (row) => (
                    <Button.List>
                        {row.original.status === 'manual_approval' && (
                            <>
                                <Button size='sm' color='success' onClick={approveTransaction(row.original)}>
                                    Approve
                                </Button>
                                <Button size='sm' color='danger' onClick={setDeclineTransactionModalVisible(true, row.original)}>
                                    Decline
                                </Button>
                            </>
                        )}
                    </Button.List>
                ),
            },
            {
                Header: 'Documents',
                id: 'documents',
                sortable: false,
                Cell: (row) => (
                    <Button.List>
                        <Button
                            icon='file-text'
                            size='sm'
                            color='primary'
                            onClick={setTransactionDocumentsModalDataCallback(true, row.original.id, row.original.status)}
                        />
                    </Button.List>
                ),
            },
        ],
        [approveTransaction, countries, setDeclineTransactionModalVisible, setTransactionDocumentsModalDataCallback],
    );

    return renderOnlyList ? renderList() : <SiteWrapper title='Transactions'>{renderList()}</SiteWrapper>;
};

Transactions.defaultProps = {
    renderOnlyList: false,
};

Transactions.propTypes = {
    navigate: PropTypes.func.isRequired,
    fetchCurrencies: PropTypes.func.isRequired,
    currencies: PropTypes.array.isRequired,
    fetchCountries: PropTypes.func.isRequired,
    countries: PropTypes.array.isRequired,
    renderOnlyList: PropTypes.bool.isRequired,
    predefinedFilters: PropTypes.object,
};

const mapStateToProps = (state) => ({
    currencies: state.currency.currencies,
    countries: state.location.countries,
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            navigate,
            fetchCurrencies,
            fetchCountries,
        },
        dispatch,
    );

export default connect(mapStateToProps, mapDispatchToProps)(Transactions);
