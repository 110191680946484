import { combineReducers } from 'redux';
import isAuthenticated from './is-authenticated';
import isCheckingAuthentication from './is-checking-authentication';
import isAuthenticating from './is-authenticating';

export default combineReducers({
    isAuthenticated,
    isCheckingAuthentication,
    isAuthenticating,
});
