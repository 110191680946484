import React, { Component } from 'react';
import PropTypes from 'prop-types';

class MainActivityType extends Component {
    render () {
        const { answer } = this.props;

        return (
            <>
                <strong>{answer.code}</strong>) {answer.name}
            </>
        );
    }
}

MainActivityType.propTypes = {
    answer: PropTypes.object,
};

export default MainActivityType;
