export const STEP_INFORMATION = 'information';
export const STEP_REPRESENTATIVE = 'representative';
export const STEP_BUSINESS_ACTIVITY = 'business_activity';
export const STEP_FINANCIAL_INDICATORS = 'financial_indicators';
export const STEP_PURPOSE_AND_PLANNED_ACTIVITY = 'purpose_and_planned_activity';
export const STEP_PLANNED_MONTHLY_ACTIVITY = 'planned_monthly_activity';
export const STEP_FIRST_PAYMENT = 'first_payment';
export const STEP_SOURCE_OF_FUNDS = 'source_of_funds';
export const STEP_OTHER_ACCOUNTS = 'other_accounts';
export const STEP_BENEFICIAL_OWNERS = 'beneficial_owners';
export const STEP_OWNERSHIP_STRUCTURE = 'ownership_structure';
export const STEP_PARTICIPATION_IN_POLITICS = 'participation_in_politics';

export const groups = {
    [STEP_INFORMATION]: 'Information about the Client',
    [STEP_REPRESENTATIVE]: 'Company representative',
    [STEP_BUSINESS_ACTIVITY]: 'Information about client business activity',
    [STEP_FINANCIAL_INDICATORS]: 'Company’s financial indicators',
    [STEP_PURPOSE_AND_PLANNED_ACTIVITY]: 'Purpose of account and the client’s planned activity in account',
    [STEP_PLANNED_MONTHLY_ACTIVITY]: 'Expected monthly activity',
    [STEP_FIRST_PAYMENT]: 'First payment to the account information',
    [STEP_SOURCE_OF_FUNDS]: 'Source of funds of Your business',
    [STEP_OTHER_ACCOUNTS]: 'Your accounts in other banks (financial institutions)',
    [STEP_BENEFICIAL_OWNERS]: 'Information about the company’s beneficial owners',
    [STEP_OWNERSHIP_STRUCTURE]: 'The ownership structure of the client',
    [STEP_PARTICIPATION_IN_POLITICS]: 'Information whether any of directors or beneficial owners participates in politics',
};

export const fields = {
    [STEP_INFORMATION]: {
        full_legal_name: {
            title: 'Full legal name',
        },
        full_entity_code: {
            title: 'Legal entity code',
        },
        legal_form: {
            title: 'Legal form',
            type: 'radio',
            values: {
                private_limited_company: 'Private Limited Company',
                public_limited_company: 'Public Limited Company',
                limited_liability_company: 'Limited Liability Company',
                general_partnership: 'General Partnership',
                non_profit_organisation: 'Non-profit Organisation',
                other: 'Other',
            },
        },
        legal_form_other: {
            title: 'Legal form > Other',
            showIf: {
                field: 'legal_form',
                value: 'other',
            },
        },
        legal_address_country: {
            title: 'Incorporation address (Country)',
            type: 'country',
        },
        legal_address_city: {
            title: 'Incorporation address (City)',
        },
        legal_address_street: {
            title: 'Incorporation address (Street)',
        },
        legal_address_street_number: {
            title: 'Incorporation address (Street number)',
        },
        legal_address_zip: {
            title: 'Incorporation address (ZIP code)',
        },
        actual_address: {
            title: 'Business (Actual) address',
            type: 'radio',
            values: {
                same: 'Same as incorporation address',
                other: 'Other',
            },
        },
        actual_address_country: {
            title: 'Business (Actual) address (Country)',
            type: 'country',
            showIf: {
                field: 'actual_address',
                value: 'other',
            },
        },
        actual_address_city: {
            title: 'Business (Actual) address (City)',
            showIf: {
                field: 'actual_address',
                value: 'other',
            },
        },
        actual_address_street: {
            title: 'Business (Actual) address (Street)',
            showIf: {
                field: 'actual_address',
                value: 'other',
            },
        },
        actual_address_street_number: {
            title: 'Business (Actual) address (Street number)',
            showIf: {
                field: 'actual_address',
                value: 'other',
            },
        },
        actual_address_zip: {
            title: 'Business (Actual) address (ZIP code)',
            showIf: {
                field: 'actual_address',
                value: 'other',
            },
        },
        legal_entity_incorporation_date: {
            title: 'Incorporation date',
        },
        phone_number: {
            title: 'Phone number',
            hideIfEmpty: true,
        },
        website_address: {
            title: 'Website address',
        },
        email_address: {
            title: 'Email address',
            hideIfEmpty: true,
        },
        country_of_residence_for_tax: {
            title: 'Country of residence for tax purpose',
            type: 'country',
        },
        tax_purposes_tin: {
            title: 'Taxpayer identification number (TIN)',
            hideIfEmpty: true,
        },
        is_solely_resident_for_tax_in_one_country: {
            title: 'Company solely resident for tax purposes in one country',
        },
        resident_for_tax_country: {
            title: 'Solely resident for tax purposes countries',
            type: 'country',
            showIf: {
                field: 'is_solely_resident_for_tax_in_one_country',
                value: 'no',
            },
        },
        passive_income: {
            title: 'Does the passive income exceed 50% of the annual gross income or its total assets?',
        },
    },
    [STEP_REPRESENTATIVE]: {
        full_name: {
            title: 'Full name',
            hideIfEmpty: true,
        },
        passport_number: {
            title: 'The number of the passport or ID card',
            hideIfEmpty: true,
        },
        passport_valid_till: {
            title: 'The validity period of passport or ID',
            hideIfEmpty: true,
        },
        personal_code: {
            title: 'Personal code',
            hideIfEmpty: true,
        },
        document_issued: {
            title: 'Citizenship or document issued',
            type: 'country',
            hideIfEmpty: true,
        },
        address_country: {
            title: 'Company representative residence address (Country)',
            type: 'country',
        },
        address_city: {
            title: 'Company representative residence address (City)',
        },
        address_street: {
            title: 'Company representative residence address (Street)',
        },
        address_street_number: {
            title: 'Company representative residence address (Street number)',
        },
        address_zip_code: {
            title: 'Company representative residence address (ZIP code)',
        },
        position_in_company: {
            title: 'Position held in the company',
            hideIfEmpty: true,
        },
        representative_is_director: {
            title: 'Company’s representative is the same person as a director',
        },
        director_first_name: {
            title: 'Company director first name',
            showIf: {
                field: 'representative_is_director',
                value: 'no',
            },
        },
        director_last_name: {
            title: 'Company director last name',
            showIf: {
                field: 'representative_is_director',
                value: 'no',
            },
        },
        director_nationality: {
            title: 'Company\'s director nationality',
            type: 'country',
            showIf: {
                field: 'representative_is_director',
                value: 'no',
            },
            hideIfEmpty: true,
        },
        director_personal_code: {
            title: 'Personal code',
            showIf: {
                field: 'representative_is_director',
                value: 'no',
            },
            hideIfEmpty: true,
        },
        director_passport_number: {
            title: 'The number of the passport or ID card',
            showIf: {
                field: 'representative_is_director',
                value: 'no',
            },
            hideIfEmpty: true,
        },
        director_passport_valid_till: {
            title: 'The validity period of passport or ID',
            showIf: {
                field: 'representative_is_director',
                value: 'no',
            },
            hideIfEmpty: true,
        },
        director_document_issued: {
            title: 'Citizenship or document issued',
            type: 'country',
            showIf: {
                field: 'representative_is_director',
                value: 'no',
            },
            hideIfEmpty: true,
        },
        director_address_country: {
            title: 'Country',
            type: 'country',
            showIf: {
                field: 'representative_is_director',
                value: 'no',
            },
        },
        director_address_city: {
            title: 'City',
            showIf: {
                field: 'representative_is_director',
                value: 'no',
            },
        },
        director_address_street: {
            title: 'Street',
            showIf: {
                field: 'representative_is_director',
                value: 'no',
            },
        },
        director_address_street_number: {
            title: 'Street number',
            showIf: {
                field: 'representative_is_director',
                value: 'no',
            },
        },
        director_address_zip_code: {
            title: 'ZIP code',
            showIf: {
                field: 'representative_is_director',
                value: 'no',
            },
        },
    },
    [STEP_BUSINESS_ACTIVITY]: {
        main_activity_type: {
            title: 'Your main activity',
            type: 'main-activity-type',
            hideIfEmpty: true,
        },
        main_activity: {
            title: 'Describe your business',
        },
        activity_relations: {
            title: 'Company’s activity is related to',
            type: 'checkboxes',
            values: {
                gambling_betting_lotteries: 'Gambling and betting activities, lotteries',
                trading_exchanging_virtual_currency: 'Trading and exchanging virtual currencies',
                forex_trading: 'Forex trading',
                investment_service: 'Investment service',
                money_transfer_services: 'Money Transfer Services',
                charities: 'Organizing and operating charities',
                weapons_ammunition: 'Manufacture and/or trading of weapons and ammunition',
                tax_advising: 'Tax advising',
                legal_notary_practice: 'Provision of legal services, legal practice or notary practice',
                software_hardware: 'Computer software or hardware',
                none: 'None of the above',
            },
            hideIfEmpty: true,
        },
        activity_require_licence: {
            title: 'Does company’s activity require a license',
        },
        activity_licence: {
            title: 'Please provide the license number or public source (web site, other…) where it can be found',
            showIf: {
                field: 'activity_require_licence',
                value: 'yes',
            },
        },
        cash_dominate: {
            title: 'Does cash dominate in the business?',
        },
        cash_make_up_more: {
            title: 'Does cash make up more than 60%?',
            showIf: {
                field: 'cash_dominate',
                value: 'yes',
            },
        },
    },
    [STEP_FINANCIAL_INDICATORS]: {
        turnover_last_year: {
            title: 'Turnover of the last year (EUR)',
            type: 'radio',
            values: {
                '<100000': '< 100 000',
                '100000-1000000': '100 000 - 1 000 000',
                '>1000000': '> 1 000 000',
            },
        },
        turnover_current_year: {
            title: 'Turnover of the current year (EUR)',
            hideIfEmpty: true,
            type: 'radio',
            values: {
                '<100000': '< 100 000',
                '100000-1000000': '100 000 - 1 000 000',
                '>1000000': '> 1 000 000',
            },
        },
        turnover_next_year: {
            title: 'Turnover planned for next year (EUR)',
            type: 'radio',
            values: {
                '<100000': '< 100 000',
                '100000-1000000': '100 000 - 1 000 000',
                '>1000000': '> 1 000 000',
            },
        },
    },
    [STEP_PURPOSE_AND_PLANNED_ACTIVITY]: {
        account_using_for: {
            title: 'What will you use the account for',
            type: 'checkboxes',
            values: {
                salary_payments: 'Salary payments',
                business_partners_payments: 'Payments for/from business partners',
                loans: 'Loans',
                investments: 'Investments',
                participation_in_other_companies: 'Proceeds from participation in other companies',
                other: 'Other',
            },
        },
        account_using_for_other: {
            title: 'What will you use the account for > Other',
            showIf: {
                field: 'account_using_for',
                value: 'other',
            },
        },
        services_to_use: {
            title: 'Purpose of the account',
            type: 'checkboxes',
            values: {
                salary_payments: 'Operational account (Salary payments, daily expenses etc.)',
                settlement_account: 'Settlement account (Payments with partners/service providers)',
                trading: 'Trading',
                investments: 'Investment/Dividends',
                loans: 'Loans',
                other: 'Other',
            },
        },
        services_to_use_other: {
            title: 'Purpose of the account > Other',
            showIf: {
                field: 'services_to_use',
                value: 'other',
            },
        },
    },
    [STEP_PLANNED_MONTHLY_ACTIVITY]: {
        incoming_quantity: {
            title: 'Incoming transfer quantity',
            type: 'radio',
            values: {
                '10-50': '10-50',
                '51-100': '51-100',
                '101-500': '101-500',
                '>500': '> 500',
            },
        },
        incoming_turnover: {
            title: 'Incoming transfer account volume (EUR)',
            type: 'radio',
            values: {
                '<30000': '< 30 000',
                '30001-100000': '30 001 - 100 000',
                '100001-500000': '100 001 - 500 000',
                '500001-1000000': '500 001 - 1 000 000',
                '>1000000': '> 1 000 000',
            },
        },
        incoming_countries: {
            title: 'Incoming transfers countries',
            type: 'country-list',
        },
        incoming_partners: {
            title: 'Incoming transfers business partners',
            hideIfEmpty: true,
        },
        outgoing_quantity: {
            title: 'Outgoing transfers quantity',
            type: 'radio',
            values: {
                '<10': 'up to 10',
                '10-50': '11-50',
                '51-100': '51-100',
                '101-500': '101-500',
                '>500': 'more than 500',
            },
        },
        outgoing_turnover: {
            title: 'Outgoing transfers account volume (EUR)',
            type: 'radio',
            values: {
                '<30000': '< 30 000',
                '30001-100000': '30 001 - 100 000',
                '100001-500000': '100 001 - 500 000',
                '500001-1000000': '500 001 - 1 000 000',
                '>1000000': 'more than 1 000 000',
            },
        },
        outgoing_countries: {
            title: 'Outgoing transfers countries',
            type: 'country-list',
        },
        outgoing_partners: {
            title: 'Outgoing transfers business partners',
            hideIfEmpty: true,
        },
        business_partners: {
            title: 'Main business partners',
            type: 'business-partners',
        },
    },
    [STEP_FIRST_PAYMENT]: {
        first_amount: {
            title: 'Amount',
            type: 'radio',
            values: {
                '<100000': 'up to 100 000 EUR',
                '<500000': 'up to 500 000 EUR',
                '<1000000': 'up to 1 000 000 EUR',
                '>1000000': 'more than 1 000 000 EUR',
            },
            hideIfEmpty: true,
        },
        originator_bank: {
            title: 'Originator bank (financial institution) jurisdiction (country)',
            type: 'radio',
            values: {
                european_economic_area: 'European Economic Area',
                other: 'Other',
            },
            hideIfEmpty: true,
        },
        originator_bank_other: {
            title: 'Other',
            showIf: {
                field: 'originator_bank',
                value: 'other',
            },
            hideIfEmpty: true,
        },
    },
    [STEP_SOURCE_OF_FUNDS]: {
        source_of_funds: {
            title: 'Company source of funds',
            type: 'checkboxes',
            values: {
                operating_income: 'Operating income',
                financial_market_instruments: 'Financial market instruments',
                shareholders_funds: 'Shareholders funds',
                from_clients: 'Loans',
                other: 'Other',
            },
        },
        source_of_funds_other: {
            title: 'Other',
            showIf: {
                field: 'source_of_funds',
                value: 'other',
            },
        },
    },
    [STEP_OTHER_ACCOUNTS]: {
        have_accounts_in_banks: {
            title: 'Does the company has accounts in other financial institutions?',
        },
        accounts_in_banks: {
            type: 'accounts-in-banks',
            showIf: {
                field: 'have_accounts_in_banks',
                value: 'yes',
            },
        },
    },
    [STEP_BENEFICIAL_OWNERS]: {
        beneficial_owners: {
            type: 'beneficial-owners',
        },
        shares_owned_directly: {
            title: 'Shares of the company are owned by the beneficial owners directly',
        },
    },
    [STEP_OWNERSHIP_STRUCTURE]: {
        ownership_structure: {
            type: 'radio',
            values: {
                one_owner: 'The client is owned by one beneficial owner and/or 1 layer',
                more_owners: 'The client is owned by more than one beneficial owner and/or more than one layer',
            },
        },
    },
    [STEP_PARTICIPATION_IN_POLITICS]: {
        director_politically_exposed: {
            title: 'The Company’s director is a politically exposed person',
        },
        beneficial_owner_politically_exposed: {
            title: 'The Company’s beneficial owner is a politically exposed person',
        },
        director_family_politically_exposed: {
            title: 'The Company’s director is a close family member of a politically exposed person',
        },
        beneficial_owner_family_politically_exposed: {
            title: 'The Company’s beneficial owner is a close family member of a politically exposed person',
        },
        director_associate_politically_exposed: {
            title: 'The Company’s director is a close associate of a politically exposed person',
        },
        beneficial_owner_associate_politically_exposed: {
            title: 'The Client’ beneficial owner is a close associate of a politically exposed person',
        },
        understand_politically_definition: {
            title: 'I clearly understand the definition of politically exposed person',
            type: 'bool',
        },
        exposed_description: {
            title:
                'If the Company’s director or beneficial owner is a close family member or close associate of\n' +
                'a politically exposed person, please specify the politically exposed person and the position he/she holds',
        },
    },
};
