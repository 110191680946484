import React, { Component } from 'react';
import { Card, Dimmer, Table, Text } from 'tabler-react';
import PropTypes from 'prop-types';
import { formatAmount, uppercase } from '../../../../../helpers';
import { createApiClient as api } from '../../../../../services/api-client';

class CurrencyBalances extends Component {
    constructor (props) {
        super(props);

        this.state = {
            isLoading: true,
            error: null,
            balances: [],
        };
    }

    componentDidMount () {
        this.fetchCurrencyBalances();
    }

    fetchCurrencyBalances () {
        this.setState({
            isLoading: true,
        });

        api()
            .get(`/admin-wallet/wallets/${this.props.wallet_id}/currency-balances`)
            .then((data) => {
                this.setState({
                    balances: data.data,
                    isLoading: false,
                });
            })
            .catch((data) => {
                this.setState({
                    error: data.data.message,
                    isLoading: false,
                });
            });
    }

    renderBody () {
        const { balances, isLoading } = this.state;

        return (
            <Dimmer active={isLoading} loader>
                <Table cards={true} striped={true} responsive={true}>
                    <Table.Header>
                        <Table.Row>
                            <Table.ColHeader>Currency</Table.ColHeader>
                            <Table.ColHeader>Amount</Table.ColHeader>
                            <Table.ColHeader>Reserved</Table.ColHeader>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {balances.map((balance) => (
                            <Table.Row className='small' key={balance.currency}>
                                <Table.Col>{uppercase(balance.currency)}</Table.Col>
                                <Table.Col>{formatAmount(balance.balance_amount)}</Table.Col>
                                <Table.Col>{balance.reserved_amount}</Table.Col>
                            </Table.Row>
                        ))}
                    </Table.Body>
                </Table>
            </Dimmer>
        );
    }

    renderError () {
        return (
            <Card.Alert color='danger'>
                <Text.Small>{this.state.error}</Text.Small>
            </Card.Alert>
        );
    }

    render () {
        const { error } = this.state;

        return (
            <Card>
                <Card.Header>
                    <Card.Title>Currency balances</Card.Title>
                </Card.Header>

                {error ? this.renderError() : this.renderBody()}
            </Card>
        );
    }
}

CurrencyBalances.propTypes = {
    wallet_id: PropTypes.number.isRequired,
};

export default CurrencyBalances;
