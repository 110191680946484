import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import { LOGOUT_USER_SUCCESS } from '../actions/user/logout-user';
import admin from './admin';
import authentication from './authentication';
import isLoading from './is-loading';
import errors from './errors';
import location from './location';
import currency from './currency';
import fee from './fee';
import documentType from './document-type';
import groupAndPermissions from './group-permissions';
import balances from './balances';
import limits from './limits';

const createAppReducer = (history) => combineReducers({
    router: connectRouter(history),
    admin,
    authentication,
    isLoading,
    errors,
    location,
    currency,
    fee,
    documentType,
    groupAndPermissions,
    balances,
    limits,
});

const createRootReducer = (history) => (state, action) => {
    let newState = state;

    if (action.type === LOGOUT_USER_SUCCESS) {
        newState = undefined;
    }

    return createAppReducer(history)(newState, action);
};

export default createRootReducer;
