import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Card, Dimmer, Table, Text } from 'tabler-react';
import { formatDate } from '../../../../../../../helpers';
import isUndefined from 'lodash/isUndefined';
import isEmpty from 'lodash/isEmpty';

class DocumentData extends Component {
    renderEmptyBody () {
        return (
            <Card.Alert color='secondary'>
                <Text.Small>No document data provided</Text.Small>
            </Card.Alert>
        );
    }

    render () {
        const { documentData, isLoading, identityId } = this.props;

        if (isEmpty(documentData) && identityId === null && !isLoading) {
            return this.renderEmptyBody();
        }

        return (
            <Dimmer active={isLoading} loader>
                <Table className='small' striped={true} cards={true}>
                    <Table.Body>
                        {!isUndefined(documentData.first_name) &&
                        <Table.Row>
                            <Table.Col>First name</Table.Col>
                            <Table.Col>{documentData.first_name}</Table.Col>
                        </Table.Row>
                        }

                        {!isUndefined(documentData.last_name) &&
                        <Table.Row>
                            <Table.Col>Last name</Table.Col>
                            <Table.Col>{documentData.last_name}</Table.Col>
                        </Table.Row>
                        }

                        {!isUndefined(documentData.gender) &&
                        <Table.Row>
                            <Table.Col>Gender</Table.Col>
                            <Table.Col>{documentData.gender}</Table.Col>
                        </Table.Row>
                        }

                        {!isUndefined(documentData.birthdate) &&
                        <Table.Row>
                            <Table.Col>Birth date</Table.Col>
                            <Table.Col>{formatDate(documentData.birthdate)}</Table.Col>
                        </Table.Row>
                        }

                        {!isUndefined(documentData.country) &&
                        <Table.Row>
                            <Table.Col>Country</Table.Col>
                            <Table.Col className='text-uppercase'>{documentData.country}</Table.Col>
                        </Table.Row>
                        }

                        {!isUndefined(documentData.nationality) &&
                        <Table.Row>
                            <Table.Col>Nationality</Table.Col>
                            <Table.Col className='text-uppercase'>{documentData.nationality}</Table.Col>
                        </Table.Row>
                        }

                        {!isUndefined(documentData.person_code) &&
                        <Table.Row>
                            <Table.Col>Personal code</Table.Col>
                            <Table.Col>{documentData.person_code}</Table.Col>
                        </Table.Row>
                        }

                        {!isUndefined(documentData.document_number) &&
                        <Table.Row>
                            <Table.Col>Document number</Table.Col>
                            <Table.Col>{documentData.document_number}</Table.Col>
                        </Table.Row>
                        }

                        {!isUndefined(documentData.document_type) &&
                        <Table.Row>
                            <Table.Col>Document type</Table.Col>
                            <Table.Col>{documentData.document_type}</Table.Col>
                        </Table.Row>
                        }

                        {!isUndefined(documentData.expire_date) &&
                        <Table.Row>
                            <Table.Col>Document expire date</Table.Col>
                            <Table.Col>{formatDate(documentData.expire_date)}</Table.Col>
                        </Table.Row>
                        }
                    </Table.Body>
                </Table>
            </Dimmer>
        );
    }
}

DocumentData.propTypes = {
    documentData: PropTypes.object,
    isLoading: PropTypes.bool.isRequired,
    identityId: PropTypes.string,
};

export default DocumentData;
