import { handleActions } from 'redux-actions';

import { USER_AUTHENTICATED } from '../../actions/user/authenticate-user';
import { LOGOUT_USER_SUCCESS } from '../../actions/user/logout-user';

export default handleActions(
    {
        [USER_AUTHENTICATED]: () => true,
        [LOGOUT_USER_SUCCESS]: () => false,
    },
    false,
);
