import React, { useState, useCallback } from 'react';
import pickBy from 'lodash/pickBy';
import { createApiClient as api } from '../../../services/api-client';
import Modal from 'react-modal';
import PropTypes from 'prop-types';
import { Button, Icon } from 'tabler-react';
import InputTextField from '../../Common/FilterFormFields/InputTextField';
import { toast } from 'react-toastify';
import { transactionDeclineFilter } from '../../../services/input-filter';

const RefundTransactionModal = ({ onModalClose, modalIsOpen, activeTransaction, updateTransaction }) => {
    const [inputValues, setInputValues] = useState({
        refundReason: '',
    });
    const [loading, setLoading] = useState(false);

    const resetForm = useCallback(() => {
        setInputValues({ refundReason: '' });
    }, []);

    const handleModalClose = useCallback(() => {
        onModalClose();
        resetForm();
    }, [onModalClose, resetForm]);

    const refundTransaction = useCallback(async () => {
        setLoading(true);

        const params = {
            refund_reason: inputValues.refundReason,
        };

        try {
            const { data } = await api().put(
                `/admin-bank-account/bank-account-transactions/${activeTransaction.id}/refund`,
                pickBy(params)
            );

            activeTransaction.status = data.status;
            activeTransaction.refund_reason = data.refund_reason;

            updateTransaction(activeTransaction);
        } catch (error) {
            toast.error(`Unable to refund user transaction: ${error.data.message}`);
        } finally {
            setLoading(false);
            handleModalClose();
        }
    }, [activeTransaction, handleModalClose, inputValues.refundReason, updateTransaction]);

    const handleRefundReasonChange = (event) => {
        const {
            target: { name, value },
        } = event;

        setInputValues((prevInputValues) => ({ ...prevInputValues, [name]: value }));
    };

    return (
        <Modal isOpen={modalIsOpen} className='react-modal'>
            <Icon name='x-circle' className='w-4 react-modal__closeButton' onClick={handleModalClose} />

            <div className='react-modal__content'>
                <InputTextField
                    name='refundReason'
                    title='Please input the reason of refunding:'
                    value={inputValues.refundReason}
                    filter={[transactionDeclineFilter]}
                    handleChange={handleRefundReasonChange}
                />
                <Button
                    block
                    color='danger'
                    onClick={refundTransaction}
                    disabled={!inputValues.refundReason || loading}
                    loading={loading}
                >
                    Refund
                </Button>
            </div>
        </Modal>
    );
};

RefundTransactionModal.propTypes = {
    modalIsOpen: PropTypes.bool.isRequired,
    activeTransaction: PropTypes.object,
    onModalClose: PropTypes.func.isRequired,
    updateTransaction: PropTypes.func.isRequired,
};

export default RefundTransactionModal;
